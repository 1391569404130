import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VBtn,{attrs:{"color":"success"},on:{"click":_vm.addRule}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v("Add new rule ")],1),_c(VExpansionPanels,{ref:"loadRules",staticClass:"py-4 px-1",model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.rulesWithHeaders),function(ruleWithHeader,ruleIndex){return _c(VExpansionPanel,{key:ruleIndex},[_c(VExpansionPanelHeader,[_c('span',[_c('strong',[_vm._v(_vm._s(ruleWithHeader.title))])])]),_c(VExpansionPanelContent,[_c('load-rule-component',{key:ruleIndex,ref:'load-rule-' + ruleIndex,refInFor:true,attrs:{"itemRules":_vm.itemRules,"value":ruleWithHeader.rule,"ruleIndex":ruleIndex,"holds":_vm.holds,"items":_vm.items,"typeName":_vm.typeName,"class_ids":_vm.class_ids},on:{"input":(v) => _vm.updateRule(v, ruleIndex),"removeRule":_vm.removeRule}})],1)],1)}),1),(!_vm.rulesWithHeaders.length)?_c(VAlert,{attrs:{"type":"info","outlined":""}},[_vm._v("No custom rules in use")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }