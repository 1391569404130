import {
  Group,
  LineBasicMaterial,
  Line,
  BufferGeometry,
  BufferAttribute,
  CanvasTexture,
  Points,
  PointsMaterial,
  Vector3,
  Sprite,
  SpriteMaterial,
  Object3D,
  Mesh,
  Material,
  OrthographicCamera,
  PerspectiveCamera,
  Event,
  LineDashedMaterial,
  Box3,
  PlaneGeometry,
} from 'three';
import { Container, deleteobjects } from './container';
import { palette } from '@/misc/colorUtils';
import { MeshBasicMaterial } from 'three';
import { NestedItem } from './item/nestedItem';
import { Item } from './item/item';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { InteractiveMode, ViewSettings } from '@/models/GraphicsModel.js';
import { HoldData, HoldDataPreview, HoldTables } from '../models/LoadlistModel';
import { createSnapPointTexture } from './utils';
// would be called Scene, except it collides with threeJS
class CargoScene extends Group {
  rulers: Group;
  canvasRatio = 1.0;
  containers = new Group();
  floorsAreSplit = false;
  floorDividers = new Group();
  fromSnapPoint: Points;
  toSnapPoint: Points;
  snapLine: Line;
  snapHelperLocked = false;
  snapGhostObjects: Group;
  isImperial: boolean;
  distance: number;
  cameraAngle: number[];
  measurements: Object3D;
  plane: Mesh;

  constructor(interactiveMode: InteractiveMode) {
    super();
    this.userData = { camera: {} };
    this.add(this.containers);

    this.add(this.floorDividers);

    if (interactiveMode) {
      const geometry = new BufferGeometry();
      geometry.setAttribute('position', new BufferAttribute(new Float32Array([0.0, 0.0, 0.0]), 3));

      const snapMaterial = new PointsMaterial({
        map: createSnapPointTexture(),
        color: 0xffffff,
        depthTest: false,
        sizeAttenuation: false,
        size: 8,
        alphaTest: 0.5,
        transparent: true,
      });
      this.fromSnapPoint = new Points(geometry.clone(), snapMaterial);
      this.toSnapPoint = this.fromSnapPoint.clone();

      const lineGeometry = new BufferGeometry();
      lineGeometry.setAttribute(
        'position',
        new BufferAttribute(new Float32Array([0.0, 0.0, 0.0, 0.0, 0.0, 0.0]), 3)
      );
      this.snapLine = new Line(
        lineGeometry,
        new LineBasicMaterial({
          color: 0x000000,
        })
      );
      this.snapGhostObjects = new Group();

      this.snapLine.renderOrder = 999;
      this.snapLine.onBeforeRender = (renderer) => {
        renderer.clearDepth();
      };

      this.hideSnapHelper();
      this.plane = new Mesh(new PlaneGeometry(100, 100));
      this.plane.position.setZ(-0.01);
      this.plane.visible = false;
      this.add(
        this.plane,
        this.snapLine,
        this.fromSnapPoint,
        this.toSnapPoint,
        this.snapGhostObjects
      );
    }
  }

  async init(
    containersData: HoldData[],
    interactiveMode: InteractiveMode,
    hideLabels: boolean,
    itemLabels: string[],
    displayNestedItemAsCargo: boolean
  ): Promise<void> {
    const maxDimension = containersData
      .map((h) => Math.max(h.H, h.L, h.W))
      .reduce((p, c) => p + c, 0);
    const maxPPM = maxDimension < 1.3 ? 1024 : maxDimension < 5 ? 512 : 256;
    let item_count = 0;
    for (let i = 0; i < containersData.length; i++) {
      const containerObject = new Container(containersData[i]);
      containerObject.userData.partOfSet =
        interactiveMode === 'container_mode' || containersData.length > 1;
      containerObject.userData.containerIndex = i;
      await containerObject.init(
        interactiveMode,
        item_count,
        hideLabels,
        itemLabels,
        displayNestedItemAsCargo,
        maxPPM
      );
      item_count += containersData[i].items.length;
      this.addContainer(containerObject);
    }
  }

  addContainer(container: Container): void {
    this.containers.add(container);
  }

  dispose(): void {
    this.containers.children.forEach((c: Container) => c.dispose());
    deleteobjects(this.containers);
    this.containers = null;
    deleteobjects(this);
    this.snapLine = null;
    this.fromSnapPoint = null;
    this.toSnapPoint = null;
    this.rulers = null;
    this.snapGhostObjects = null;
    this.measurements = null;
    this.plane = null;
  }

  deselectAllCargoes(): void {
    this.containers.children.forEach((c: Container) => c.deselectAllCargoes());
  }
  getCargoes(): (Item | NestedItem)[] {
    return this.containers.children.flatMap((c: Container) => c.getCargoes());
  }

  getContainers(): Container[] {
    return this.containers.children.map((c: Container) => c);
  }

  joinFloors(): void {
    if (this.floorsAreSplit && this.moveFloors()) {
      this.floorsAreSplit = false;
      this.floorDividers.clear();
    }
  }

  separateFloors(): void {
    if (!this.floorsAreSplit && this.moveFloors()) {
      this.floorsAreSplit = true;
    }
  }

  moveFloors(): boolean {
    const heightsMap = new Map<number, Container[]>();
    this.containers.children.forEach((c: Container) => {
      const bb = c.getBox3();
      for (const [key, value] of heightsMap.entries()) {
        if (Math.abs(key - bb.min.z) < 0.1) {
          value.push(c);
          return;
        }
      }
      heightsMap.set(bb.min.z, [c]);
    });
    if (heightsMap.size < 2) {
      return false;
    }

    // let m = new Map();
    const m = [...heightsMap.entries()].sort((a, b) => a[0] - b[0]).map((v) => v[1]);

    const box3s = m.map((c) => c.map((a) => a.getBox3()).reduce((a, b) => a.union(b)));

    const padding = 2.0;
    let totalY = 0;
    const material = new LineDashedMaterial({
      color: palette.greenSea.dark,
      gapSize: 0.03,
      dashSize: 0.1,
    });
    m.forEach((cs, i) => {
      cs.forEach((c) => {
        c.position.set(c.position.x, c.position.y + totalY, c.position.z);
      });
      let diff = (box3s[i].max.y - box3s[i].min.y) / 2 + padding;
      if (i < m.length - 1) {
        diff += (box3s[i + 1].max.y - box3s[i + 1].min.y) / 2;
      }
      if (this.floorsAreSplit) {
        totalY -= diff;
      } else {
        totalY += diff;
        box3s[i] = cs.map((a) => a.getBox3()).reduce((a, b) => a.union(b));
        if (i != m.length - 1) {
          const line = new Line(
            new BufferGeometry().setFromPoints([
              new Vector3(box3s[i].min.x, box3s[i].max.y + padding / 2, box3s[i].min.z),
              new Vector3(box3s[i].max.x, box3s[i].max.y + padding / 2, box3s[i].min.z),
            ]),
            material
          );
          line.computeLineDistances();
          this.floorDividers.add(line);
        }
      }
    });
    return true;
  }

  getChildren(): Object3D[] {
    return this.containers.children.flatMap((c: Container) => c.items.children);
  }

  saveState(viewSettings: ViewSettings): void {
    this.containers.children.forEach((c: Container, i) => {
      if (i == 0) {
        if (this.containers.children.length > 1) {
          c.userData.container.setCamera = viewSettings;
        } else {
          c.userData.container.camera = viewSettings;
        }
      }
      c.saveState();
    });
  }

  selectAllCargoes(): void {
    this.containers.children.forEach((c: Container) => c.selectAllCargoes());
  }

  getSelectedItems(): (Item | NestedItem)[] {
    return this.containers.children.flatMap((c: Container) => c.getSelectedItems());
  }
  getPartsAndItems(): (Item | NestedItem)[] {
    return this.containers.children.flatMap((c: Container) => c.getPartsAndItems());
  }

  showCargoesByIndex(highlighted: number[], visible: number[]): Box3 {
    return this.containers.children
      .map((c: Container) => c.showCargoesByIndex(highlighted, visible))
      .reduce((a, b) => a.union(b));
  }

  showContainerNumbers(): void {
    this.containers.children.forEach((c: Container, i) => c.showContainerNumber(i + 1));
  }

  hideContainers(): void {
    this.containers.children.forEach((c: Container) => c.hideContainerParts());
  }

  showItemsByWeightScale(): void {
    this.containers.children.forEach((c: Container) => c.showItemsByWeightScale());
  }

  unloadSelectedItems(): number[] {
    return this.containers.children.flatMap((c: Container) => c.unloadSelectedItems());
  }
  showAll(): void {
    this.containers.children.forEach((c: Container) => c.showAll());
  }
  updateCog(): void {
    // TODO: Do we want to have an overall cog of the set of containers
    this.containers.children.forEach((c: Container) => c.updateCog());
  }

  updateSnapHelper(pos: Vector3, showGhostObjects: boolean): void {
    if (!this.snapHelperLocked) {
      this.fromSnapPoint.position.copy(pos.clone());
      this.fromSnapPoint.visible = true;
      this.snapLine.geometry.attributes.position.setXYZ(0, pos.x, pos.y, pos.z);
      this.snapLine.geometry.attributes.position.needsUpdate = true;
      this.snapGhostObjects.userData.prePosition = new Vector3(0, 0, 0);
      this.snapGhostObjects.position.copy(new Vector3(0, 0, 0));
    } else {
      this.toSnapPoint.position.copy(pos.clone());
      this.snapLine.geometry.attributes.position.setXYZ(1, pos.x, pos.y, pos.z);
      this.snapLine.geometry.attributes.position.needsUpdate = true;
      this.snapLine.visible = true;
      this.toSnapPoint.visible = true;

      this.snapGhostObjects.position.copy(this.getSnapLineVector());
      if (showGhostObjects) {
        this.snapGhostObjects.visible = true;

        if (this.snapGhostObjects.children.length === 0) {
          const material = new MeshBasicMaterial({
            color: 0x00ff00,
            transparent: true,
            opacity: 0.4,
          });
          this.getSelectedItems().forEach((i) => {
            const mesh = new Mesh(i.geometry.clone(), material);
            // Do we need to also consider parents rotation here?
            mesh.rotation.copy(i.rotation);
            mesh.position.copy(i.position);
            this.snapGhostObjects.add(mesh);
          });
        }
        const bb = new Box3().setFromObject(this.snapGhostObjects).expandByScalar(-0.01);
        const p = this.getPartsAndItems().filter(
          (i) =>
            !i.isSelected &&
            (i.type === 'Item' || i.type === 'NestedItem' || i.name === 'container')
        );
        for (let i = 0; i < p.length; i++) {
          const bb2 = new Box3().setFromObject(p[i]);
          if (bb.intersectsBox(bb2)) {
            if (p[i].name === 'container' && bb2.containsBox(bb)) {
              continue;
            }
            this.snapGhostObjects.position.copy(this.snapGhostObjects.userData.prePosition);
            return;
          }
        }
        this.snapGhostObjects.userData.prePosition = this.getSnapLineVector().clone();
      }
    }
  }
  hasFirstSnapPoint(): boolean {
    return this.fromSnapPoint.visible;
  }
  lockSnapHelper(pos: Vector3 = null): void {
    if (pos) {
      this.snapLine.geometry.attributes.position.setXYZ(0, pos.x, pos.y, pos.z);
      this.snapLine.geometry.attributes.position.setXYZ(1, pos.x, pos.y, pos.z);

      this.snapLine.geometry.attributes.position.needsUpdate = true;
    }
    this.snapHelperLocked = true;
  }
  getSnapLineVector(): Vector3 {
    return new Vector3(
      this.snapLine.geometry.attributes.position.getX(1),
      this.snapLine.geometry.attributes.position.getY(1),
      this.snapLine.geometry.attributes.position.getZ(1)
    ).sub(
      new Vector3(
        this.snapLine.geometry.attributes.position.getX(0),
        this.snapLine.geometry.attributes.position.getY(0),
        this.snapLine.geometry.attributes.position.getZ(0)
      )
    );
  }
  hideSnapHelper(): void {
    if (this.fromSnapPoint) {
      this.fromSnapPoint.visible = false;
    }
    if (this.toSnapPoint) {
      this.toSnapPoint.visible = false;
    }
    if (this.snapLine) this.snapLine.visible = false;
    if (this.snapGhostObjects) this.snapGhostObjects.visible = false;

    deleteobjects(this.snapGhostObjects);
    this.snapHelperLocked = false;
  }
  getSnapLineDistance(): number {
    return new Vector3(
      this.snapLine.geometry.attributes.position.getX(0),
      this.snapLine.geometry.attributes.position.getY(0),
      this.snapLine.geometry.attributes.position.getZ(0)
    ).distanceTo(
      new Vector3(
        this.snapLine.geometry.attributes.position.getX(1),
        this.snapLine.geometry.attributes.position.getY(1),
        this.snapLine.geometry.attributes.position.getZ(1)
      )
    );
  }

  hideCog(): void {
    this.containers.children.forEach((child: Container) => child.hideCog());
  }

  toggleRuler(length_dim: string): void {
    if (this.rulers) {
      this.removeRulers();
    } else {
      this.createRulers(imperialDim(length_dim));
    }
  }

  removeRulers(): void {
    this.remove(this.rulers);
    deleteobjects(this.rulers);
    this.rulers = undefined;
  }

  createRulers(
    isImperial: boolean,
    withSubdividers = true,
    withFullTexts = true,
    withText = true
  ): void {
    this.rulers = new Group();
    this.isImperial = isImperial;

    for (let i = 0; i < this.containers.children.length; i++) {
      const container = this.containers.children[i].userData.container;
      let precision = 0;

      precision +=
        (this.isShortRange(this.distance) || this.isMediumRange(this.distance)) && withFullTexts
          ? 1
          : 0;
      precision += this.isShortRange(this.distance) && withSubdividers ? 1 : 0;
      const xFocus = angleIsXFocused(this.cameraAngle);
      const zFocus = angleIsZFocused(this.cameraAngle);
      if (xFocus || !zFocus) {
        const xRuler = createRuler(
          new Vector3(1, 0, 0),
          container.W,
          container.L,
          isImperial,
          precision,
          withText
        );
        this.rulers.add(xRuler);
      }
      if (!xFocus || !zFocus) {
        const yRuler = createRuler(
          new Vector3(0, 1, 0),
          0,
          container.W,
          isImperial,
          precision,
          withText
        );
        this.rulers.add(yRuler);
      }
      if (zFocus) {
        const zRuler = createRuler(
          new Vector3(0, 0, 1),
          0,
          Math.max(container.items_bb?.max.z, container.H),
          isImperial,
          precision,
          withText
        );
        this.rulers.add(zRuler);
      }
    }
    this.add(this.rulers);
  }

  setCanvasSize(width: number): void {
    this.canvasRatio = width / 800;
  }

  cameraChange(orbitControls: OrbitControls): { distance: number; angle: number[] } {
    const prevDistance = this.distance || 0;
    this.distance =
      orbitControls.getDistance() /
      (orbitControls.object as OrthographicCamera | PerspectiveCamera).zoom;
    const prevAngle = this.cameraAngle || [0, 0];
    this.cameraAngle = calculateAngle(orbitControls);
    return { distance: prevDistance, angle: prevAngle };
  }

  onCameraChange(o: OrbitControls): void {
    const prev = this.cameraChange(o);
    const prevDistance = prev.distance;
    const prevAngle = prev.angle;
    if (this.rulers) {
      if (
        // angle diff
        angleIsZFocused(prevAngle) != angleIsZFocused(this.cameraAngle) ||
        angleIsXFocused(prevAngle) != angleIsXFocused(this.cameraAngle) ||
        // zoom difference
        (this.isShortRange(this.distance) && !this.isShortRange(prevDistance)) ||
        (this.isMediumRange(this.distance) && !this.isMediumRange(prevDistance)) ||
        (this.isLongRange(this.distance) && !this.isLongRange(prevDistance))
      ) {
        // we have a significant difference. Rebuild the ruler
        this.removeRulers();
        this.createRulers(this.isImperial);
      }
    }
  }
  isShortRange(distance: number): boolean {
    const short = (this.isImperial ? 5 : 17) * this.canvasRatio;
    return distance < short;
  }
  isMediumRange(distance: number): boolean {
    const medium = this.isImperial ? 15 : 40 * this.canvasRatio;
    return !this.isShortRange(distance) && distance < medium;
  }
  isLongRange(distance: number): boolean {
    return !this.isShortRange(distance) && !this.isMediumRange(distance);
  }

  createContainerMeasurements(lengthDim: string): void {
    this.measurements = new Object3D();
    const material = new LineDashedMaterial({
      color: palette.greenSea.dark,
      gapSize: 0.03,
      dashSize: 0.1,
    });
    const data = this.containers.children[0].userData.container as HoldData;
    const spacing = -0.3;
    let multiplier = 1;
    let precision = 1000;
    switch (lengthDim) {
      case 'MM':
        multiplier = 1000;
        precision = 1;
        break;
      case 'CM':
        multiplier = 100;
        precision = 10;
        break;
      case 'DM':
        multiplier = 10;
        precision = 100;
        break;
      case 'IN':
        multiplier = 1 / 0.0254;
        precision = 100;
        break;
      case 'FT':
        multiplier = 1 / 0.3048;
        precision = 1000;
        break;
    }

    // X-axis
    createMeasurement(
      this.measurements,
      material,
      new Vector3(0, 0, 0),
      new Vector3(data.L, 0, 0),
      new Vector3(0, spacing, spacing),
      data.L * multiplier,
      1,
      precision
    );

    // Y - Axis
    createMeasurement(
      this.measurements,
      material,
      new Vector3(0, 0, 0),
      new Vector3(0, data.W, 0),
      new Vector3(spacing, 0, spacing),
      data.W * multiplier,
      1,
      precision
    );

    // Z - Axis
    const height = Math.max(data.H, data.max_height || 0);
    if (height > 0) {
      createMeasurement(
        this.measurements,
        material,
        new Vector3(data.L, 0, 0),
        new Vector3(data.L, 0, height),
        new Vector3(data.L - spacing, spacing, 0),
        height * multiplier,
        1,
        precision
      );
    }
    // Floor height
    if (data.floor_height) {
      createMeasurement(
        this.measurements,
        material,
        new Vector3(data.L, 0, -data.floor_height),
        new Vector3(data.L, 0, 0),
        new Vector3(data.L - spacing, spacing, 0),
        data.floor_height * multiplier,
        0.5,
        precision
      );
    }

    // Doors
    if (data.door && data.door.H && data.door.W) {
      const doorMaterial = new LineDashedMaterial({
        color: palette.greenSea.lighter,
        gapSize: 0.03,
        dashSize: 0.1,
      });
      if (data.door.longside) {
        const doorMargin = (data.L - data.door.W) / 2;
        createMeasurement(
          this.measurements,
          doorMaterial,
          new Vector3(doorMargin, 0, 0),
          new Vector3(data.L - doorMargin, 0, 0),
          new Vector3(0, spacing * 0.5, spacing * 0.5),
          data.door.W * multiplier,
          0.5,
          precision
        );
        createMeasurement(
          this.measurements,
          doorMaterial,
          new Vector3(data.L - doorMargin, 0, 0),
          new Vector3(data.L - doorMargin, 0, data.door.H),
          new Vector3(data.L - doorMargin - spacing * 0.5, spacing * 0.5, 0),
          data.door.H * multiplier,
          0.5,
          precision
        );
      } else {
        const doorMargin = (data.W - data.door.W) / 2;
        createMeasurement(
          this.measurements,
          doorMaterial,
          new Vector3(data.L, doorMargin, 0),
          new Vector3(data.L, data.W - doorMargin, 0),
          new Vector3(data.L - spacing, 0, spacing),
          data.door.W * multiplier,
          0.5,
          precision
        );
        createMeasurement(
          this.measurements,
          doorMaterial,
          new Vector3(data.L, data.W, 0),
          new Vector3(data.L, data.W, data.door.H),
          new Vector3(data.L - spacing, data.W - spacing, 0),
          data.door.H * multiplier,
          0.5,
          precision
        );
      }
    }

    // Decks
    if (data.tables) {
      const tables = data.tables as HoldTables;
      if (tables.front_table_h && tables.front_table_l) {
        createMeasurement(
          this.measurements,
          material,
          new Vector3(0, 0, tables.front_table_h),
          new Vector3(tables.front_table_l, 0, tables.front_table_h),
          new Vector3(0, spacing, tables.front_table_h + spacing),
          tables.front_table_l * multiplier,
          0.5,
          precision
        );
        createMeasurement(
          this.measurements,
          material,
          new Vector3(tables.front_table_l, 0, 0),
          new Vector3(tables.front_table_l, 0, tables.front_table_h),
          new Vector3(tables.front_table_l - spacing, spacing, 0),
          tables.front_table_h * multiplier,
          0.5,
          precision
        );
      }
      if (tables.rear_table_h && tables.rear_table_l) {
        createMeasurement(
          this.measurements,
          material,
          new Vector3(data.L - tables.rear_table_l, 0, tables.rear_table_h),
          new Vector3(data.L, 0, tables.rear_table_h),
          new Vector3(0, spacing, tables.rear_table_h + spacing),
          tables.rear_table_l * multiplier,
          0.5,
          precision
        );
        createMeasurement(
          this.measurements,
          material,
          new Vector3(data.L - tables.rear_table_l, 0, 0),
          new Vector3(data.L - tables.rear_table_l, 0, tables.rear_table_h),
          new Vector3(data.L - tables.rear_table_l + spacing, spacing, 0),
          tables.rear_table_h * multiplier,
          0.5,
          precision
        );
      }
      // Extra Floor
      if (tables.deck_z && tables.deck_l) {
        createMeasurement(
          this.measurements,
          material,
          new Vector3(0, 0, 0),
          new Vector3(0, 0, tables.deck_z),
          new Vector3(spacing, spacing, 0),
          tables.deck_z * multiplier,
          0.5,
          precision
        );
      }
    }
    // Dimensions
    // const text = `[${lengthDim.toLowerCase()}]`
    // const sprite = new Sprite(
    //   new SpriteMaterial({
    //     map: createMeasurementText(text),
    //     color: 0xffffff,
    //     depthTest: false,
    //     sizeAttenuation: false,
    //   })
    // );
    // sprite.position.set(data.L + 1, 0, data.H + 1);
    // sprite.scale.set(text.length * 0.1, 0.1, 0.1)
    // this.measurements.add(sprite);

    // Contours
    if (data.contours) {
      const c = data.contours;
      // switch materials for contours
      const materialContours = new LineDashedMaterial({
        color: palette.carrot.normal,
        gapSize: 0.03,
        dashSize: 0.1,
      });
      // Front top
      if (c.front_top_contour_h && c.front_top_contour_l) {
        createMeasurement(
          this.measurements,
          materialContours,
          new Vector3(0, data.W, height - c.front_top_contour_h),
          new Vector3(0, data.W, height),
          new Vector3(spacing / 2, data.W - spacing / 2, 0),
          c.front_top_contour_h * multiplier,
          0.5,
          precision
        );
        createMeasurement(
          this.measurements,
          materialContours,
          new Vector3(0, data.W, height),
          new Vector3(c.front_top_contour_l, data.W, height),
          new Vector3(0, data.W - spacing / 2, height - spacing / 2),
          c.front_top_contour_l * multiplier,
          0.5,
          precision
        );
      }
      // Front bottom
      if (c.front_bottom_contour_h && c.front_bottom_contour_l) {
        createMeasurement(
          this.measurements,
          materialContours,
          new Vector3(0, data.W, 0),
          new Vector3(0, data.W, c.front_bottom_contour_h),
          new Vector3(spacing / 2, data.W - spacing / 2, 0),
          c.front_bottom_contour_h * multiplier,
          0.5,
          precision
        );
        createMeasurement(
          this.measurements,
          materialContours,
          new Vector3(0, data.W, 0),
          new Vector3(c.front_bottom_contour_l, data.W, 0),
          new Vector3(0, data.W - spacing / 2, spacing / 2),
          c.front_bottom_contour_l * multiplier,
          0.5,
          precision
        );
      }
      // Rear top
      if (c.rear_top_contour_h && c.rear_top_contour_l) {
        createMeasurement(
          this.measurements,
          materialContours,
          new Vector3(data.L, 0, height - c.rear_top_contour_h),
          new Vector3(data.L, 0, height),
          new Vector3(data.L - spacing / 2, spacing / 2, 0),
          c.rear_top_contour_h * multiplier,
          0.5,
          precision
        );
        createMeasurement(
          this.measurements,
          materialContours,
          new Vector3(data.L - c.rear_top_contour_l, 0, height),
          new Vector3(data.L, 0, height),
          new Vector3(0, spacing / 2, height - spacing / 2),
          c.rear_top_contour_l * multiplier,
          0.5,
          precision
        );
      }
      // Rear bottom
      if (c.rear_bottom_contour_h && c.rear_bottom_contour_l) {
        createMeasurement(
          this.measurements,
          materialContours,
          new Vector3(data.L, 0, 0),
          new Vector3(data.L, 0, c.rear_bottom_contour_h),
          new Vector3(data.L - spacing / 2, spacing / 2, 0),
          c.rear_bottom_contour_h * multiplier,
          0.5,
          precision
        );
        createMeasurement(
          this.measurements,
          materialContours,
          new Vector3(data.L - c.rear_bottom_contour_l, 0, 0),
          new Vector3(data.L, 0, 0),
          new Vector3(0, spacing / 2, spacing / 2),
          c.rear_bottom_contour_l * multiplier,
          0.5,
          precision
        );
      }
      // Side 1 top
      if (c.side1_top_contour_h && c.side1_top_contour_l) {
        createMeasurement(
          this.measurements,
          materialContours,
          new Vector3(0, 0, height - c.side1_top_contour_h),
          new Vector3(0, 0, height),
          new Vector3(spacing / 2, spacing / 2, 0),
          c.side1_top_contour_h * multiplier,
          0.5,
          precision
        );
        createMeasurement(
          this.measurements,
          materialContours,
          new Vector3(0, c.side1_top_contour_l, height),
          new Vector3(0, 0, height),
          new Vector3(spacing / 2, 0, height - spacing / 2),
          c.side1_top_contour_l * multiplier,
          0.5,
          precision
        );
      }
      // Side 1 bottom
      if (c.side1_bottom_contour_h && c.side1_bottom_contour_l) {
        createMeasurement(
          this.measurements,
          materialContours,
          new Vector3(0, 0, 0),
          new Vector3(0, 0, c.side1_bottom_contour_h),
          new Vector3(spacing / 2, spacing / 2, 0),
          c.side1_bottom_contour_h * multiplier,
          0.5,
          precision
        );
        createMeasurement(
          this.measurements,
          materialContours,
          new Vector3(0, c.side1_bottom_contour_l, 0),
          new Vector3(0, 0, 0),
          new Vector3(spacing / 2, 0, spacing / 2),
          c.side1_bottom_contour_l * multiplier,
          0.5,
          precision
        );
      }
      // Side 2 top
      if (c.side2_top_contour_h && c.side2_top_contour_l) {
        createMeasurement(
          this.measurements,
          materialContours,
          new Vector3(data.L, data.W, height - c.side2_top_contour_h),
          new Vector3(data.L, data.W, height),
          new Vector3(data.L - spacing / 2, data.W - spacing / 2, 0),
          c.side2_top_contour_h * multiplier,
          0.5,
          precision
        );

        createMeasurement(
          this.measurements,
          materialContours,
          new Vector3(data.L, data.W, height),
          new Vector3(data.L, data.W - c.side2_top_contour_l, height),
          new Vector3(data.L - spacing / 2, 0, height - spacing / 2),
          c.side2_top_contour_l * multiplier,
          0.5,
          precision
        );
      }
      // Side 2 bottom
      if (c.side2_bottom_contour_h && c.side2_bottom_contour_l) {
        createMeasurement(
          this.measurements,
          materialContours,
          new Vector3(data.L, data.W, 0),
          new Vector3(data.L, data.W, c.side2_bottom_contour_h),
          new Vector3(data.L - spacing / 2, data.W - spacing / 2, 0),
          c.side2_bottom_contour_h * multiplier,
          0.5,
          precision
        );

        createMeasurement(
          this.measurements,
          materialContours,
          new Vector3(data.L, data.W, 0),
          new Vector3(data.L, data.W - c.side2_bottom_contour_l, 0),
          new Vector3(data.L - spacing / 2, 0, spacing / 2),
          c.side2_bottom_contour_l * multiplier,
          0.5,
          precision
        );
      }
    }

    this.measurements.position.add(new Vector3(0, -data.W / 2, 0));

    this.add(this.measurements);
  }
}

function createMeasurement(
  measurement: Object3D,
  material: Material,
  minPoint: Vector3,
  maxPoint: Vector3,
  spacingVec: Vector3,
  value: number,
  textScale = 1.0,
  valuePrecision = 1000
) {
  const textMargin = 0.3;
  let line = new Line(
    new BufferGeometry().setFromPoints([
      minPoint,
      new Vector3(
        spacingVec.x || minPoint.x,
        spacingVec.y || minPoint.y,
        spacingVec.z || minPoint.z
      ),
      new Vector3(
        spacingVec.x || Math.max((maxPoint.x + minPoint.x) / 2 - textMargin, minPoint.x),
        spacingVec.y || Math.max((maxPoint.y + minPoint.y) / 2 - textMargin, minPoint.y),
        spacingVec.z || Math.max((maxPoint.z + minPoint.z) / 2 - textMargin, minPoint.z)
      ),
    ]),
    material
  );
  line.computeLineDistances();
  measurement.add(line);
  line = new Line(
    new BufferGeometry().setFromPoints([
      new Vector3(
        spacingVec.x || Math.min((maxPoint.x + minPoint.x) / 2 + textMargin, maxPoint.x),
        spacingVec.y || Math.min((maxPoint.y + minPoint.y) / 2 + textMargin, maxPoint.y),
        spacingVec.z || Math.min((maxPoint.z + minPoint.z) / 2 + textMargin, maxPoint.z)
      ),
      new Vector3(
        spacingVec.x || maxPoint.x,
        spacingVec.y || maxPoint.y,
        spacingVec.z || maxPoint.z
      ),
      maxPoint,
    ]),
    material
  );
  line.computeLineDistances();
  measurement.add(line);
  const text = `${Math.round(value * valuePrecision) / valuePrecision}`;
  const sprite = new Sprite(
    new SpriteMaterial({
      map: createMeasurementText(text),
      color: 0xffffff,
      depthTest: false,
      sizeAttenuation: true,
    })
  );
  sprite.position.set(
    spacingVec.x || (maxPoint.x + minPoint.x) / 2,
    spacingVec.y || (maxPoint.y + minPoint.y) / 2,
    spacingVec.z || (maxPoint.z + minPoint.z) / 2
  );
  sprite.scale.set(text.length * textScale, textScale, textScale);
  measurement.add(sprite);
}

function createRulerItemText(text: string) {
  const cargoCanvasTexture = document.createElement('canvas');
  const ctx = cargoCanvasTexture.getContext('2d');
  const canvasSize = 64;
  const canvasWidth = canvasSize * text.length;
  cargoCanvasTexture.width = canvasWidth;
  cargoCanvasTexture.height = canvasSize;
  ctx.textAlign = 'center';
  ctx.fillStyle = '#73c6b6';
  ctx.strokeStyle = '#73c6b6';
  ctx.lineWidth = 2;
  ctx.textBaseline = 'middle';
  ctx.font = '64px Roboto, monospace';
  ctx.fillText(text, canvasWidth * 0.5, canvasSize * 0.5 + ctx.lineWidth);
  ctx.lineWidth = 1;
  ctx.strokeText(text, canvasWidth * 0.5, canvasSize * 0.5 + 2);

  // document.getElementsByTagName("body")[0].appendChild(cargoCanvasTexture);

  return new CanvasTexture(cargoCanvasTexture);
}

function createMeasurementText(text: string) {
  const cargoCanvasTexture = document.createElement('canvas');
  const ctx = cargoCanvasTexture.getContext('2d');
  const canvasSize = 256;
  const canvasWidth = canvasSize * text.length;
  cargoCanvasTexture.width = canvasWidth;
  cargoCanvasTexture.height = canvasSize;
  ctx.textAlign = 'center';
  ctx.fillStyle = '#000000';
  ctx.strokeStyle = '#000000';
  ctx.lineWidth = 2;
  ctx.textBaseline = 'middle';
  ctx.font = '64px Roboto, monospace';
  ctx.fillText(text, canvasWidth * 0.5, canvasSize * 0.5 + ctx.lineWidth);
  ctx.lineWidth = 1;
  ctx.strokeText(text, canvasWidth * 0.5, canvasSize * 0.5 + 2);

  // document.getElementsByTagName("body")[0].appendChild(cargoCanvasTexture);

  return new CanvasTexture(cargoCanvasTexture);
}

/// constructs a ruler
/// filter: which axis do you want a ruler in? expexts on of Vec3(1,0,0), Vec3(0,1,0) and Vec3(0,0,1)
/// w: width of the lines in positive direction (through the container)
/// l: length of the ruler
/// isImperial: feet or meters?
/// precision: integer, 0 => 0.1 line per meter, 1 => 1 line per meter, 2 => 10 lines per meter. Slightly different for imperial
/// withTexts: should we print text
function createRuler(
  filter: Vector3,
  w: number,
  l: number,
  isImperial: boolean,
  precision: number,
  withTexts: boolean
) {
  const ruler = new Object3D();

  // large lines material and geometry
  const material = new LineBasicMaterial({
    color: palette.greenSea.normalDark,
    linewidth: 3,
  });
  const points = [];
  // line through the container
  points.push(new Vector3(w * filter.z, w * filter.x, w * filter.y));
  points.push(new Vector3(0, 0, 0));
  const ll = -0.5;
  // line out from the container
  points.push(
    new Vector3(
      ll * (filter.z || filter.y),
      ll * (filter.x || filter.z),
      ll * (filter.y || filter.x)
    )
  );
  const lineGeometry = new BufferGeometry().setFromPoints(points);

  // the shorter decimal lines
  const points2 = [];
  points2.push(new Vector3(0, 0, 0));
  const sh = -0.25;
  points2.push(
    new Vector3(
      sh * (filter.z || filter.y),
      sh * (filter.x || filter.z),
      sh * (filter.y || filter.x)
    )
  );
  const smallLine = new BufferGeometry().setFromPoints(points2);
  const smallMaterial = new LineBasicMaterial({
    color: palette.greenSea.normal,
    linewidth: 1,
  });

  const scale = isImperial ? 1 / 3.28 : 1;
  const length = isImperial ? l * 3.28 : l;
  const part = isImperial ? 1 / 12 : 1 / 10;

  for (let i = 0; i < length; i++) {
    if (precision >= 1 || i % 10 == 0) {
      // create the major line
      const line = new Line(lineGeometry, material);
      line.position.set(
        i * scale * filter.x,
        i * scale * filter.y,
        Math.max(i * scale * filter.z, 0.001)
      );
      ruler.add(line);

      // create the minor lines. Every 10th for meters, every 12th for feet
      for (let j = i + part; j < Math.min(i + 1, length) && precision >= 2; j += part) {
        const line = new Line(smallLine, smallMaterial);
        line.position.set(filter.x * j * scale, filter.y * j * scale, filter.z * j * scale);
        ruler.add(line);
      }

      if (i == 0 || !withTexts) {
        continue;
      }
      // Create the labels for a major line
      const text = `${i}${isImperial ? "'" : ''}`;
      const sprite = new Sprite(
        new SpriteMaterial({
          map: createRulerItemText(text),
          color: 0xffffff,
          depthTest: false,
          sizeAttenuation: true,
        })
      );
      // the magic numbers (-0.7, -1.2) are for label displacement, so they don't overlap with the lines
      if (precision >= 1) {
        sprite.position.set(
          (i * filter.x || -0.7) * scale,
          (i * filter.y || -0.7) * scale,
          (i * filter.z || -0.7) * scale
        );
        sprite.scale.set(scale * 0.5 * text.length, scale * 0.5, scale * 0.5);
      } else {
        sprite.position.set(
          i * scale * filter.x,
          Math.min(-1.2, i * filter.y) * scale,
          Math.min(-1.2, i * filter.z) * scale
        );
        sprite.scale.set(1.5 * text.length * scale, 1.5 * scale, 1.5 * scale);
      }
      ruler.add(sprite);
    }
  }
  return ruler;
}

function calculateAngle(controls: OrbitControls) {
  return [
    Math.abs(Math.sin(controls.getPolarAngle())),
    Math.abs(Math.sin(controls.getAzimuthalAngle())),
  ];
}
const vAngle = 0.5;
const hAngle = 0.7;
function angleIsXFocused(cameraAngle: number[]) {
  return (cameraAngle || [0, 0])[1] < hAngle;
}
function angleIsZFocused(cameraAngle: number[]) {
  return (cameraAngle || [0, 0])[0] > vAngle;
}
function imperialDim(length_dim: string) {
  return length_dim == 'IN';
}

export { CargoScene };
