<template>
  <div>
    <v-data-table
      :items="loadlists"
      :headers="headers"
      :headers-length="8"
      :options.sync="pagination"
      :server-items-length="totalItems"
      single-expand
      :loading="isLoading"
      must-sort
      :footer-props="{
        disableItemsPerPage: true,
        itemsPerPageOptions: [10],
      }"
      class="elevation-2"
      id="loadlistsTable">
      <template v-slot:item="{ item, isExpanded }">
        <tr
          @click="
            !readonly
              ? $router.push({ name: 'loadlist', params: { id: item.id } })
              : $emit('selectedLoadlist', item.id)
          "
          draggable
          @dragstart="dragStart(item, $event)"
          style="cursor: pointer">
          <td style="width: 5%">
            <v-icon x-large style="pointer-events: none">
              {{ `$vuetify.icons.${item.list_type}` }}
            </v-icon>
          </td>
          <td :class="{ 'font-weight-bold': isExpanded }">
            {{ item.name }}
          </td>
          <td>
            {{ item.etd ? new Date(item.etd).toLocaleDateString() : '-' }}
          </td>
          <td>
            {{ new Date(item.modified_date).toLocaleDateString() }}
          </td>
          <td>{{ item.pol }}</td>
          <td>{{ item.pod }}</td>
          <td v-if="!readonly">
            <v-btn
              icon
              class="mr-2"
              @click.stop="
                fullLoadlist(item);
                showPreview = true;
              ">
              <v-icon> mdi-magnify </v-icon>
            </v-btn>
            <v-btn
              icon
              class="mr-2"
              @click.stop="
                selectedList = item;
                showCopyLoadlistModal = true;
              ">
              <v-icon> mdi-content-copy </v-icon>
            </v-btn>
            <v-btn
              icon
              color="error"
              @click.stop="
                selectedList = item;
                showDeleteLoadlistModal = true;
              ">
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </td>
          <td v-else></td>
        </tr>
      </template>

      <template slot="no-data">
        <div class="my-2">
          <p class="my-2">No load lists found.</p>

          <v-btn v-if="!searchString && !groupId" color="success" @click="$emit('generateExamples')"
            >Show Examples</v-btn
          >
        </div></template
      >
    </v-data-table>
    <v-dialog width="500" v-model="showPreview">
      <v-card>
        <v-card-title> {{ (selectedLoadlist || { name: 'Loading' }).name }}</v-card-title>
        <v-divider />
        <v-card-text class="grey lighten-4">
          <loadlist-preview :loadlist="selectedLoadlist" />
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="showPreview = false">Close</v-btn>
          <v-spacer /><v-btn
            color="primary"
            @click.stop="
              $router.push({
                name: 'loadlist',
                params: { id: selectedLoadlist.id },
              })
            "
            >Open</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <delete-loadlist-modal
      :visible="showDeleteLoadlistModal"
      :loadlistId="selectedList.id"
      :loadlistName="selectedList.name"
      @deleted="
        fetchLoadlists();
        showDeleteLoadlistModal = false;
      "
      @close="showDeleteLoadlistModal = false">
    </delete-loadlist-modal>

    <copy-loadlist-modal
      :visible="showCopyLoadlistModal"
      :loadlistToCopy="selectedList"
      @close="
        if ($event) {
          fetchLoadlists();
        }
        showCopyLoadlistModal = false;
      ">
    </copy-loadlist-modal>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import API from '@/API';
import { Loadlist, LoadlistBase } from '@/models/LoadlistModel';
import { APIResponse } from '@/models/APIModel';
import newListModal from '@/components/Modals/NewList.vue';
import copyLoadlistModal from '@/components/Modals/CopyLoadlist.vue';
import deleteLoadlistModal from '@/components/Modals/DeleteLoadlist.vue';
import LoadlistPreview from './LoadlistPreview.vue';

export default Vue.extend({
  name: 'loadlists-table',
  components: {
    newListModal,
    copyLoadlistModal,
    deleteLoadlistModal,
    LoadlistPreview,
  },
  props: {
    groupId: Number,
    forceUpdate: Number,
    readonly: Boolean,
    searchString: String,
  },
  created() {
    if (this.$route.params.page) this.pagination.page = parseInt(this.$route.params.page) || 1;
    if (this.$route.query.sortBy) this.pagination.sortBy = [String(this.$route.query.sortBy)];
    if (this.$route.query.sortDesc)
      this.pagination.sortDesc = [String(this.$route.query.sortDesc) === 'true'];
  },
  computed: {},
  data() {
    return {
      showPreview: false,
      selectedLoadlist: null as Loadlist,
      loadlists: [] as LoadlistBase[],
      selectedList: {} as LoadlistBase,
      isLoading: false,
      totalItems: 0,
      showAddNewLoadlistModal: false,
      showCopyLoadlistModal: false,
      showDeleteLoadlistModal: false,
      pagination: {
        page: 1,
        sortBy: ['modified_date'],
        sortDesc: [false],
      },
      headers: [
        {
          text: 'Type',
          align: 'left',
          sortable: false,
          value: 'list_type',
        },
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'ETD',
          align: 'left',
          sortable: true,
          value: 'etd',
        },
        {
          text: 'Modified',
          align: 'left',
          sortable: true,
          value: 'modified_date',
        },
        {
          text: 'PoL',
          align: 'left',
          sortable: true,
          value: 'pol',
        },
        {
          text: 'PoD',
          align: 'left',
          sortable: true,
          value: 'pod',
        },
        {
          text: 'Actions',
          align: 'left',
          sortable: false,
          value: 'actions',
        },
      ],
    };
  },
  methods: {
    dragStart(loadlist: LoadlistBase, e: any) {
      this.selectedList = loadlist;
      e.dataTransfer.setData('text', loadlist.id);
    },
    updateRoute(): void {
      if (!this.readonly)
        this.$router
          .push({
            name: 'loadlists',
            params: { page: this.pagination.page.toString() },
            query: {
              sortBy: this.pagination.sortBy[0],
              sortDesc: String(this.pagination.sortDesc[0]),
              group: this.groupId?.toString(),
              search: this.searchString,
            },
          })
          .catch((error: any) => {
            if (error.name != 'NavigationDuplicated') {
              throw error;
            }
          });
      this.fetchLoadlists();
    },
    fullLoadlist(item: LoadlistBase): void {
      this.selectedLoadlist = null;

      API.getLoadlist(item.id).then((data: APIResponse) => {
        this.selectedLoadlist = data.data;
      });
    },
    fetchLoadlists(): void {
      this.isLoading = true;
      API.getLoadlists({
        page: this.pagination.page,
        search: this.searchString,
        group: this.groupId,
        ordering: (this.pagination.sortDesc[0] ? '' : '-') + this.pagination.sortBy[0],
      })
        .then((data: APIResponse) => {
          this.totalItems = data.data.count;
          this.loadlists = data.data.results;
          this.isLoading = false;
        })
        .catch((error: any) => {
          console.log('Error fetching loadlists');
          this.isLoading = false;
        });
    },
  },
  watch: {
    forceUpdate: {
      handler(val, old) {
        this.fetchLoadlists();
      },
    },
    groupId: {
      handler(val, old) {
        this.pagination.page = 1;
        this.updateRoute();
      },
    },
    pagination: {
      handler(val, old) {
        this.updateRoute();
      },
      deep: false,
    },
    searchString: {
      handler(val, old) {
        this.pagination.page = 1;
        this.updateRoute();
      },
    },
  },
});
</script>

<style scoped></style>
