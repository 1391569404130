import API from '@/API';
import { getSerializerError } from '@/misc/errorUtils';
import { defineStore } from 'pinia';
import Vue from 'vue';
import {
  HoldData,
  InsertItemsInLoadplanParams,
  LoadConfiguration,
  LoadIntoSpaceParams,
  Loadlist,
  LoadlistGroup,
  Loadplan,
  MoveItemsInLoadplanParams,
  RemoveItemsInLoadplanParams,
  UnloadedItem,
  UpdateLoadplanHoldsParams,
} from '../models/LoadlistModel';
import { useMiscStore } from './miscStore';
import { Set, SetTypeData } from '@/models/SetsModel';
import itemUtils from '../misc/itemUtils';
import { Mutex } from 'async-mutex';
import { CalcData } from '../models/CalculationModel';

const mutex = new Mutex();
const APP_VERSION = process.env.VUE_APP_VERSION;
const worker = new Worker(new URL('@/workers/unloaded_items.worker.ts', import.meta.url));
worker.onmessage = (e: { data: UnloadedItem[] }) => {
  useLoadlistStore().setUnloadedItems(e.data);
};

const isValidHttpUrl = (string: string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

const useLoadlistStore = defineStore('loadlist', {
  state: () => {
    return {
      hold_uid: 0,
      is_calculating: false,
      loadlist: null as Loadlist | null,
      loadplan_version: 0,
      calculation_info_message: null as string | null,
      groups: [] as LoadlistGroup[],
      unloaded_reasons: [] as UnloadedItem[],
      result_has_changed: false,
    };
  },
  getters: {
    loadlistGroups: (state) => state.groups,
    readonly: (state) =>
      !useMiscStore().is_authenticated && state?.loadlist?.public_access === 'RO',
    loadplan: (state) => state.loadlist?.result?.versions?.[state.loadplan_version],
    loadplans: (state) => state.loadlist?.result?.versions,
    logo_url: (state) => {
      if (state.loadlist?.logo_url) {
        if (isValidHttpUrl(state.loadlist.logo_url)) {
          return state.loadlist.logo_url;
        } else if (process.env.VUE_APP_API_HOST && state.loadlist.logo_url.startsWith('/')) {
          return process.env.VUE_APP_API_HOST + state.loadlist.logo_url;
        }
      }
      return null;
    },
    loadplan_holds(): HoldData[] | undefined {
      return this.loadplan?.holds;
    },
    unloaded_items(): UnloadedItem[] {
      return this.loadplan?.unloaded_items || [];
    },
    loadlist_result_has_changed: (state) => state.result_has_changed,
    is_locked: (state) => state.loadlist?.result?.locked,
  },
  actions: {
    setLoadlist(loadlist: Loadlist | null): void {
      if (loadlist?.result?.versions) {
        try {
          loadlist.result.app_version = APP_VERSION;
          for (let i = 0; i < loadlist.result.versions.length; i++) {
            for (let j = 0; j < loadlist.result.versions[i].holds.length; j++) {
              this.hold_uid++;
              loadlist.result.versions[i].holds[j].uid = this.hold_uid;
            }
            Object.freeze(loadlist.result.versions[i].holds);
          }
        } catch (e) {
          console.error('Wrong load list format', e);
        }
      }
      this.loadlist = loadlist;
      this.loadplan_version = 0;
      this.result_has_changed = false;

      if (this.unloaded_items.length) this.updateUnloadedReasons(this.unloaded_items, true);
      if (loadlist?.data) {
        this.updateUnloadedItems();
      }
    },
    setLoadlistProperty(payload: { key: string; value: any }): void {
      Vue.set(this.loadlist, payload.key, payload.value);
      if (payload.key === 'data') {
        this.updateUnloadedItems();
      }
    },
    setLoadlistResultProperty(payload: { key: string; value: any }): void {
      Vue.set(this.loadlist.result, payload.key, payload.value);
    },
    setLoadplanVersion(version: number): void {
      this.loadplan_version = version;
    },
    setLoadplanProperty(payload: { key: string; value: any }): void {
      Vue.set(this.loadlist.result.versions[this.loadplan_version], payload.key, payload.value);
    },
    changeLoadplanSets(payload: { index?: number; replace?: number; sets?: Set[] }): void {
      const version = this.loadlist.result.versions[this.loadplan_version];
      if (version.sets) {
        version.sets.splice(payload.index ?? 0, payload.replace ?? 0, ...(payload?.sets || []));
      } else {
        version.sets = payload.sets;
      }
    },
    updateUnloadedReasons(data: UnloadedItem[], overwrite: boolean): void {
      if (overwrite) this.unloaded_reasons = data;
      else {
        for (const item of data) {
          const index = this.unloaded_reasons.findIndex((i) => i.sku === item.sku);
          if (index > -1) {
            if (this.unloaded_reasons[index].reason != item.reason)
              this.unloaded_reasons[index] = item;
          } else this.unloaded_reasons.push(item);
        }
      }
    },
    updateUnloadedItems() {
      worker.postMessage({
        loadlist: this.loadlist?.data,
        holds: this.loadplan?.holds,
        reasons: this.unloaded_reasons,
      });
    },
    setUnloadedItems(data: UnloadedItem[]) {
      if (this.loadplan) {
        this.setLoadplanProperty({ key: 'unloaded_items', value: data });
      }
    },
    setLoadlistResultHasChanged(value: boolean): void {
      this.result_has_changed = value;
      if (value) {
        this.updateUnloadedItems();
      }
    },
    syncGroups(): Promise<unknown> {
      return new Promise((resolve, reject) => {
        API.getLoadlistGroups()
          .then((response) => {
            this.groups = response.data.results ?? [];
            resolve(undefined);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    clearLoadlistData(): void {
      this.groups = [];
      this.setLoadlist(null);
    },
    clearLoadlist(): void {
      this.setLoadlist(null);
    },
    getLoadlist(id: string): Promise<undefined> {
      this.setLoadlist(null);

      return new Promise((resolve, reject) => {
        if (!id) {
          reject();
        } else {
          API.getLoadlist(id)
            .then((response) => {
              this.setLoadlist(response.data);
              if (!this.loadplan) {
                this.addLoadplanVersion();
              }
              resolve(undefined);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },

    async saveLoadlist(): Promise<undefined> {
      return await mutex.runExclusive(async () => {
        return new Promise((resolve, reject) => {
          if (
            !this.loadlist ||
            (!useMiscStore().is_authenticated && this.loadlist.public_access !== 'RW')
          )
            return reject(undefined);

          API.saveLoadlist(this.loadlist)
            .then((r) => {
              this.setLoadlistProperty({ key: 'data', value: r.data.data });
              // update the version number of our loadlist so we can save again and again :)
              if (this?.loadlist?.id === r.data.id)
                this.setLoadlistProperty({ key: 'version', value: r.data.version });
              resolve(undefined);
            })
            .catch((error) => {
              if (error.response?.status == 409) {
                this.getLoadlist(this.loadlist.id);
              }
              reject(error);
            });
        });
      });
    },
    async saveLoadlistResult(): Promise<unknown> {
      return await mutex.runExclusive(async () => {
        return new Promise((resolve, reject) => {
          if (
            !this.loadlist ||
            (!useMiscStore().is_authenticated && this.loadlist.public_access !== 'RW')
          )
            return reject(undefined);
          API.patchLoadlist({
            id: this.loadlist.id,
            result: this.loadlist.result,
            version: this.loadlist.version,
          })
            .then((r) => {
              if (this?.loadlist?.id === r.data.id)
                this.setLoadlistProperty({ key: 'version', value: r.data.version });
              resolve(undefined);
            })
            .catch((error) => {
              // We have a conflict
              if (error.response?.status == 409) {
                this.getLoadlist(this.loadlist.id);
              }
              reject(error);
            });
        });
      });
    },
    addLoadplanVersion(): void {
      const loadplanTemplate: Loadplan = {
        holds: <HoldData[]>[],
        notes: '',
        settings: {},
        selected_holds: <HoldData[]>[],
        pallet_types: <HoldData[]>[],
        set_types: <SetTypeData[]>[],
        sets: <Set[]>[],
        unloaded_items: [],
      };
      this.loadlist.result.versions.push(loadplanTemplate);
      this.setLoadplanVersion(this.loadlist.result.versions.length - 1);
    },
    removeLoadplanVersion(): void {
      this.loadlist.result.versions.splice(this.loadplan_version, 1);
      this.setLoadplanVersion(this.loadlist.result.versions.length - 1);
      this.setLoadlistResultHasChanged(true);
    },
    useLoadConfiguration(configuration: LoadConfiguration): void {
      this.setLoadplanProperty({
        key: 'preset',
        value: configuration.id,
      });
      if (configuration?.data?.settings)
        this.setLoadplanProperty({
          key: 'settings',
          value: JSON.parse(JSON.stringify(configuration?.data?.settings)),
        });
      this.setLoadplanProperty({
        key: 'selected_holds',
        value: JSON.parse(JSON.stringify(configuration?.data?.holds || [])),
      });
      this.setLoadplanProperty({
        key: 'pallet_types',
        value: JSON.parse(JSON.stringify(configuration?.data?.pallet_types || [])),
      });
      this.setLoadplanProperty({
        key: 'set_types',
        value: JSON.parse(JSON.stringify(configuration?.data?.set_types || [])),
      });
    },
    calculateLoadplan(
      payload:
        | CalcData
        | MoveItemsInLoadplanParams
        | InsertItemsInLoadplanParams
        | RemoveItemsInLoadplanParams
        | LoadIntoSpaceParams
    ): Promise<{
      containers: HoldData[];
      unloaded_items: UnloadedItem[];
      sets: Set[];
    }> {
      let custom_operation = 'operation' in payload ? payload.operation : null;

      return new Promise((resolve, reject) => {
        if (this.is_calculating) {
          reject();
          return;
        }

        let endpoint = API.calculateLoadlist;

        switch (custom_operation) {
          case 'move_items':
            endpoint = API.moveItemsLoadlist;
            break;
          case 'remove_items':
            endpoint = API.removeItemsLoadlist;
            break;
          case 'insert_items':
            endpoint = API.insertItemsLoadlist;
            (payload as InsertItemsInLoadplanParams).length_dim = this.loadlist.length_dim;
            (payload as InsertItemsInLoadplanParams).weight_dim = this.loadlist.weight_dim;
            break;
          case 'load_into_space':
            endpoint = API.loadIntoSpaceLoadlist;
          default:
            payload = {
              settings: this.loadplan.settings,
              length_dim: this.loadlist.length_dim,
              weight_dim: this.loadlist.weight_dim,
              pallet_types: this.loadplan.pallet_types,
              ...payload,
            };
            this.is_calculating = true;
            this.calculation_info_message = null;
        }

        endpoint(payload)
          .then((response) => {
            this.is_calculating = false;

            switch (response.data.status) {
              case 'couldNotLoadAllItems':
                this.calculation_info_message = 'Could not load all items';
                break;
              case 'couldNotLoadAnyItems':
                this.calculation_info_message = 'Items cannot be fitted into provided containers';
                break;
              case 'tooSmallItems':
                this.calculation_info_message =
                  'One or more items is too small. Maybe you have set the wrong cargo dimensions?';
                break;
              case 'tooManyItems':
                this.calculation_info_message =
                  'Too many items provided. Try to split the list into multiple ones';
                break;
              case 'noItems':
                this.calculation_info_message = 'No items provided';
                break;
              case 'couldNotPalletizeSomeItems':
                this.calculation_info_message =
                  'Some items could not be fitted onto the provided pallets. Loading them without pallets.';
                break;
            }

            if (Array.isArray(response.data.solutions) && response.data.solutions.length) {
              const overwrite_reasons =
                (!custom_operation || custom_operation === 'calculate') &&
                !(payload as CalcData).containers;
              this.updateUnloadedReasons(
                response.data.solutions[0].unloaded_items,
                overwrite_reasons
              );
              resolve(response.data.solutions[0]);
            } else {
              reject();
            }
          })
          .catch((error) => {
            this.is_calculating = false;
            if (error?.response?.status === 403) {
              useMiscStore().logout(null);
            }

            if (error?.response?.data) {
              this.calculation_info_message = getSerializerError(error.response.data);
            }
            reject();
          });
      });
    },
    resetLoadlistResults(): void {
      this.setLoadlistProperty({
        key: 'result',
        value: { versions: [], app_version: APP_VERSION },
      });
      this.addLoadplanVersion();
      API.getDefaultLoadConfiguration(this.loadlist.list_type)
        .then((response) => {
          this.useLoadConfiguration(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateLoadplanHolds(payload: UpdateLoadplanHoldsParams): void {
      if (payload) {
        const clone = [...this.loadlist.result.versions[this.loadplan_version].holds];
        clone.splice(
          payload.index ?? 0,
          payload.replace ?? 0,
          ...(payload?.holds?.map((i) => {
            i.uid = ++this.hold_uid;
            return i;
          }) || [])
        );
        Object.freeze(clone);
        this.loadlist.result.versions[this.loadplan_version].holds = clone;
        this.setLoadlistResultHasChanged(true);
      }
    },
    updateLoadplanSets(payload: { replace?: number; index?: number; sets?: Set[] }): void {
      if (payload) {
        this.changeLoadplanSets(payload);
        this.setLoadlistResultHasChanged(true);
      }
    },
  },
});

export { useLoadlistStore };
