<template>
  <v-card outlined class="mb-4">
    <v-card-text>
      <v-row align="center">
        <v-col>
          <v-chip>1</v-chip>
          <span class="ml-2">Step 1 - Select items you want to include</span>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col v-if="rule.items">
          <item-selector-component v-model="rule.items" :items="items" :class_ids="class_ids" />
        </v-col>
      </v-row>
      <v-row>
        <v-icon x-large class="mx-auto">mdi-arrow-down-bold</v-icon>
      </v-row>
      <v-row align="center">
        <v-col>
          <v-chip>2</v-chip>
          <span class="ml-2">Step 2 - Select your condition</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-md-flex">
          <v-select
            v-model="rule.condition"
            :items="itemRules"
            :menu-props="{ maxHeight: '420' }"
            @change="
              () => {
                rule.value = undefined;
              }
            "
            label="Condition"></v-select>

          <v-text-field
            v-if="rule.condition === 'max_qty'"
            class="ml-4"
            label="Quantity"
            v-model.number="rule.value"
            min="0"
            step="1"
            type="number" />
          <v-select
            v-if="rule.condition === 'must_load_with_pattern'"
            :items="[
              { text: 'One block', value: 'one_block' },
              { text: 'Two block', value: 'two_block' },
              { text: 'Four block', value: 'four_block' },
              { text: 'No pattern building', value: 'one_by_one' },
            ]"
            v-model="rule.value"
            label="Pattern"
            class="ml-4"
            hint="The higher block values includes the simpler variants. Two block can result in a one block solution"
            persistent-hint />
          <item-selector-component
            v-if="['can_only_support', 'cannot_support'].includes(rule.condition)"
            class="ml-0 ml-md-4"
            v-model="rule.value"
            :items="items"
            :class_ids="class_ids" />
        </v-col>
      </v-row>
      <v-row>
        <v-icon x-large class="mx-auto">mdi-arrow-down-bold</v-icon>
      </v-row>
      <v-row align="center">
        <v-col>
          <v-chip>3</v-chip>
          <span class="ml-2"
            >Step 3 - Select {{ typeName }}s you want to include. Rule will be applied to all
            {{ typeName }}s if no {{ typeName }}s are selected</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            v-model="rule.in_containers_with_ids"
            :items="holds"
            item-text="name"
            item-value="id"
            :menu-props="{ maxHeight: '400' }"
            :placeholder="`Any ${typeName}s`"
            :label="`${capitalizedTypeName}s`"
            persistent-placeholder
            multiple>
            <template v-slot:selection="{ item, index }">
              <span v-if="index < rule.in_containers_with_ids.length - 2"
                >{{ item.name }},&nbsp;</span
              >
              <span v-else-if="index === rule.in_containers_with_ids.length - 2"
                >{{ item.name }} or&nbsp;</span
              >
              <span v-else>{{ item.name }}</span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row class="text-right">
        <v-col>
          <v-btn class="mx-auto" color="error" @click="$emit('removeRule', ruleIndex)">
            <v-icon left>mdi-minus-circle</v-icon>Remove rule
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { HoldData, HoldInputItem, LoadRule } from '@/models/LoadlistModel';
import Vue, { PropType } from 'vue';
import ItemSelectorComponent from './ItemSelector.vue';
export default Vue.extend({
  components: { ItemSelectorComponent },
  name: 'load-rule',
  data: function () {
    return {
      rule: JSON.parse(JSON.stringify(this.value)) as LoadRule,
    };
  },
  props: {
    value: Object as PropType<LoadRule>,
    ruleIndex: Number,
    typeName: String,
    holds: {
      type: Array as PropType<HoldData[]>,
      default: () => [] as HoldData[],
    },
    items: {
      type: Array as PropType<HoldInputItem[]>,
      default: () => [] as HoldInputItem[],
    },
    class_ids: Array as PropType<string[]>,
    itemRules: Array as PropType<{ text: string; value: string }[]>,
  },
  watch: {
    rule: {
      handler(o): void {
        this.$emit('input', o);
      },
      deep: true,
    },
  },
  computed: {
    capitalizedTypeName(): string {
      return this.typeName[0].toUpperCase() + this.typeName.slice(1);
    },
  },
  mounted(): void {},
  methods: {},
});
</script>
