<template>
  <div v-resize.quiet="onResize">
    <v-toolbar
      v-if="showAnnotationControls"
      dense
      flat
      class="align-start no-print annotation-toolbar">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="controlsExpandedChange" outlined text>
            <v-icon>{{ !controlsExpanded ? 'mdi-pencil' : 'mdi-pencil-off' }}</v-icon>
          </v-btn>
        </template>
        <span class="no-print">Edit annotations</span>
      </v-tooltip>
      <v-tooltip bottom v-if="controlsExpanded">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :icon="$vuetify.breakpoint.xsOnly"
            :text="$vuetify.breakpoint.smAndUp"
            @click="addTextBox">
            <v-icon>mdi-form-textbox</v-icon>
            <template v-if="$vuetify.breakpoint.smAndUp">Add text</template>
          </v-btn>
        </template>
        <span class="no-print">Add text annotation</span>
      </v-tooltip>
      <v-tooltip bottom v-if="controlsExpanded">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :icon="$vuetify.breakpoint.xsOnly"
            :text="$vuetify.breakpoint.smAndUp"
            @click="addLine">
            <v-icon>mdi-vector-line</v-icon>
            <template v-if="$vuetify.breakpoint.smAndUp">Add line</template>
          </v-btn>
        </template>
        <span class="no-print">Add line annotation</span>
      </v-tooltip>
      <v-tooltip bottom v-if="controlsExpanded">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!selectedAnnotation"
            :icon="$vuetify.breakpoint.xsOnly"
            :text="$vuetify.breakpoint.smAndUp"
            @click="deleteAnnotation">
            <v-icon color="red">mdi-delete-outline</v-icon>
            <template v-if="$vuetify.breakpoint.smAndUp">Remove</template>
          </v-btn>
        </template>
        <span class="no-print">Remove selected annotation</span>
      </v-tooltip>
      <v-tooltip bottom v-if="controlsExpanded">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :icon="$vuetify.breakpoint.xsOnly"
            :text="$vuetify.breakpoint.smAndUp"
            @click="saveAnnotations">
            <v-icon color="success">mdi-floppy</v-icon>
            <template v-if="$vuetify.breakpoint.smAndUp">Save</template>
          </v-btn>
        </template>
        <span class="no-print">Save annotations</span>
      </v-tooltip>
    </v-toolbar>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { Annotation, HoldData, Loadplan } from '@/models/LoadlistModel';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
let fabric: any = null;

export default Vue.extend({
  name: 'annotations-component',
  components: {},
  props: {
    showAnnotationControls: Boolean,
    sceneDiv: HTMLDivElement,
    uuid: String,
    annotations: {
      type: Array as PropType<Annotation[]>,
      default: () => [] as Annotation[],
    },
  },
  mounted() {
    this.lazyLoadCanvasIfNeeded();
  },
  beforeDestroy() {
    this.annotationCanvas?.dispose();
  },
  data() {
    return {
      annotationCanvas: null as fabric.Canvas,
      canvasMaxWidth: 800,
      controlsExpanded: false,
      selectedAnnotation: null,
      annotationsLocked: true,
    };
  },
  computed: {
    ...mapStores(useLoadlistStore),
    loadplan(): Loadplan {
      return this.loadlistStore.loadplan;
    },
  },
  methods: {
    controlsExpandedChange(): void {
      this.controlsExpanded = !this.controlsExpanded;
      this.lazyLoadCanvasIfNeeded();
      this.changeAnnotationLock(!this.controlsExpanded);
    },
    lazyLoadCanvasIfNeeded(): void {
      const requiredProps = this.annotationCanvas == null;
      const isCanvasNeeded = this.annotations.length > 0 || this.controlsExpanded;
      if (requiredProps && isCanvasNeeded) {
        this.createCanvas();
      }
    },
    createCanvas(): void {
      const canvasName = `fabricjs-canvas-${this.uuid}`;
      const fabricCanvas = document.getElementById(canvasName);
      if (fabricCanvas) {
        fabricCanvas.parentElement.removeChild(fabricCanvas);
      }
      this.createAnnotationCanvas(canvasName);
    },
    createAnnotationCanvas(canvasName: string): void {
      import('fabric').then((module) => {
        fabric = module.fabric;
        const fabricCanvas = document.createElement('canvas');
        const threejsCanvas = this.sceneDiv.querySelector('.threejs-canvas');
        fabricCanvas.id = canvasName;
        fabricCanvas.width = threejsCanvas.clientWidth;
        fabricCanvas.height = threejsCanvas.clientHeight;
        fabricCanvas.style.position = 'absolute';
        this.sceneDiv.insertBefore(fabricCanvas, threejsCanvas);

        this.annotationCanvas = new fabric.Canvas(canvasName, {
          containerClass: 'fabric-container',
        });
        this.annotationCanvas.on('mouse:down', this.onMouseDown);
        this.annotationCanvas.selection = !this.annotationsLocked;
        this.addAnnotationsToCanvas();
      });
    },
    addAnnotationsToCanvas(): void {
      this.annotations.forEach((annotation, index) => {
        let annotationObject: fabric.Textbox | fabric.Line = undefined;
        const objectProps = {
          ...annotation,
          selectable: !this.annotationsLocked,
        };
        if ('text' in annotation) {
          annotationObject = new fabric.Textbox(annotation.text, objectProps);
        } else {
          const points = [annotation.x1, annotation.y1, annotation.x2, annotation.y2];
          annotationObject = new fabric.Line(points, objectProps);
        }
        this.annotationCanvas.add(annotationObject);
      });
      this.setSize(this.annotationCanvas.width);
    },
    addTextBox(): void {
      const numberOfObjects = this.annotationCanvas._objects.length;
      const text = 'Add text here...';
      const width = 200;
      const height = 100;
      const left = 100 + numberOfObjects * 10;
      const top = 100 + numberOfObjects * 10;
      const id = numberOfObjects + 1;
      const textBox = new fabric.Textbox(text, {
        id,
        backgroundColor: 'whitesmoke',
        width,
        height,
        left,
        top,
        fontSize: 24,
        fontFamily: 'Roboto',
      });
      this.annotationCanvas.add(textBox);
    },
    addLine(): void {
      const numberOfObjects = this.annotationCanvas._objects.length;
      const x1 = 50;
      const y1 = 10;
      const x2 = 200;
      const y2 = 150;
      const id = numberOfObjects + 1;
      const line = new fabric.Line([x1, y1, x2, y2], {
        id,
        stroke: 'red',
        strokeWidth: 2,
      });
      this.annotationCanvas.add(line);
    },
    deleteAnnotation(): void {
      this.annotationCanvas.remove(this.selectedAnnotation);
      this.selectedAnnotation = null;
    },
    saveAnnotations(): void {
      const annotationObjects: Annotation[] = this.annotationCanvas._objects.map(
        (object, index) => {
          const annotation = {
            id: index + 1,
            width: object.width,
            height: object.height,
            top: object.top,
            left: object.left,
            angle: object.angle,
            scaleX: object.scaleX,
            scaleY: object.scaleY,
          };
          if (object.isType('textbox')) {
            const textbox = object as fabric.Textbox;
            return {
              ...annotation,
              text: textbox.text,
              fontSize: textbox.fontSize,
              fontFamily: textbox.fontFamily,
              backgroundColor: textbox.backgroundColor,
            };
          } else if (object.isType('line')) {
            const line = object as fabric.Line;
            return {
              ...annotation,
              x1: line.x1,
              y1: line.y1,
              x2: line.x2,
              y2: line.y2,
              stroke: line.stroke,
              strokeWidth: line.strokeWidth,
            };
          }
        }
      );
      this.updateLoadplanAnnotations(annotationObjects);
      this.saveLoadlistResult();
      this.annotationCanvas.discardActiveObject().renderAll();
      this.selectedAnnotation = null;
    },
    updateLoadplanAnnotations(annotationObjects: Annotation[]): void {
      const loadplan = JSON.parse(JSON.stringify(this.loadplan)) as Loadplan;
      const hold = loadplan.holds.find((h) => h.uuid === this.uuid);
      if (hold) {
        hold.annotations = annotationObjects;
        this.setLoadplanProperty({ key: 'holds', value: loadplan.holds });
      } else {
        const set = loadplan.sets.find((s) => s.uuid === this.uuid);
        if (set) {
          set.annotations = annotationObjects;
          this.setLoadplanProperty({ key: 'sets', value: loadplan.sets });
        }
      }
    },
    setLoadplanProperty(propertyInfo: { key: string; value: any }) {
      this.loadlistStore.setLoadplanProperty(propertyInfo);
    },
    saveLoadlistResult(): void {
      this.loadlistStore.saveLoadlistResult();
    },
    onMouseDown(evt: any): void {
      const object = evt.target;
      this.selectedAnnotation = object;
    },
    setSize(width: number): void {
      const zoom = width / 800;
      this.annotationCanvas.setZoom(zoom);
      this.annotationCanvas.setWidth(800 * zoom);
      this.annotationCanvas.setHeight(400 * zoom);
    },
    changeAnnotationLock(isLocked: boolean): void {
      this.annotationsLocked = isLocked;
      if (this.annotationCanvas) {
        this.annotationCanvas._objects.forEach(
          (object) => (object.selectable = !this.annotationsLocked)
        );
        this.annotationCanvas.selection = !this.annotationsLocked;
        this.annotationCanvas.discardActiveObject().renderAll();
      }
    },
    onResize(): void {
      if (this.annotationCanvas) {
        const threejsCanvas = this.sceneDiv.querySelector('.threejs-canvas');
        this.setSize(threejsCanvas.clientWidth);
      }
    },
  },
  watch: {
    showAnnotationControls: {
      handler: function (val: boolean): void {
        if (val === false) {
          this.changeAnnotationLock(true);
        }
        // deselects objects in canvas
        this.annotationCanvas?.discardActiveObject().renderAll();
        this.controlsExpanded = false;
      },
    },
  },
});
</script>

<style>
.fabric-container,
.annotation-toolbar {
  position: absolute !important;
  top: 0;
  left: 0;
}
.annotation-toolbar .v-toolbar__content {
  padding: 0px !important;
}
</style>
