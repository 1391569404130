<template>
  <div v-if="set || hold">
    <div>
      <v-toolbar dense class="my-1" elevation="1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              @click="$router.go(-1)"
              v-on="on"
              v-if="!$route.query.iframe"
              :ripple="false">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>Go back</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="showNotes" v-on="on" :ripple="false" v-show="hold">
              <v-icon>mdi-message-bulleted</v-icon>
            </v-btn>
          </template>
          <span
            >{{ typeName[0].toUpperCase() + typeName.substring(1) }} notes - will be visible in load
            plan</span
          >
        </v-tooltip>

        <v-menu bottom open-on-hover offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" :ripple="false">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item id="saveImageButton" @click="downloadImage">
              <v-list-item-title> Download as PNG </v-list-item-title>
            </v-list-item>
            <v-list-item @click="emitSceneEvent('get-gltf', hold ? hold.name : set.set.name)">
              <v-list-item-title> Download as glTF </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu bottom open-on-hover offset-y>
          <template v-slot:activator="{ on }">
            <v-btn id="interactiveCameraButton" icon v-on="on" :ripple="false">
              <v-icon>mdi-video</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="emitSceneEvent('set-view', { view: views.TOP })">
              <v-list-item-title>
                Top
                <kbd>1</kbd>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="emitSceneEvent('set-view', { view: views.SIDE })">
              <v-list-item-title>
                Side
                <kbd>2</kbd>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="emitSceneEvent('set-view', { view: views.THREED })">
              <v-list-item-title>
                3D
                <kbd>3</kbd>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="emitSceneEvent('set-view', { view: views.FRONT })">
              <v-list-item-title>
                Front
                <kbd>4</kbd>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="toggleSearch" v-on="on">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
          <span>Search for cargo</span>
        </v-tooltip>

        <v-btn-toggle v-model="interactionState" v-show="hold">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn :value="interactiveStates.SELECT_BOX" v-on="on" ref="selectButton">
                <v-icon>mdi-select-group</v-icon>
              </v-btn>
            </template>
            <span>Select multiple cargoes at once <kbd>E</kbd></span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                ref="rulerButton"
                @click="emitSceneEvent('toggle-ruler', length_dim)"
                v-on="on">
                <v-icon>mdi-ruler</v-icon>
              </v-btn>
            </template>
            <span>Show ruler </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn :value="interactiveStates.MEASURE_TAPE" v-on="on" ref="tapeButton">
                <v-icon>mdi-tape-measure</v-icon>
              </v-btn>
            </template>
            <span>Measure the distance between two points <kbd>T</kbd></span>
          </v-tooltip>
        </v-btn-toggle>

        <v-menu bottom v-if="selectedItems">
          <template v-slot:activator="{ on: menu }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn icon v-on="{ ...tooltip, ...menu }" :ripple="false">
                  <v-icon>mdi-crop-rotate</v-icon>
                </v-btn>
              </template>
              <span>Rotate cargo(es) around the selected axis</span>
            </v-tooltip>
          </template>

          <v-list>
            <v-list-item @click="emitSceneEvent('rotate-cargo', 'x')">
              <v-list-item-title>X</v-list-item-title>
            </v-list-item>
            <v-list-item @click="emitSceneEvent('rotate-cargo', 'y')">
              <v-list-item-title>Y</v-list-item-title>
            </v-list-item>
            <v-list-item @click="emitSceneEvent('rotate-cargo', 'z')">
              <v-list-item-title>Z</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-tooltip bottom v-if="hold">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="green"
              @click="goToContainer(holdIndex, selectedItems[0].index)"
              v-show="showNestedInteractiveView"
              v-on="on"
              :ripple="false">
              <v-icon>mdi-arrow-expand-all</v-icon>
            </v-btn>
          </template>
          <span>Open pallet</span>
        </v-tooltip>

        <v-tooltip bottom v-if="hold">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="red"
              @click="emitSceneEvent('remove-cargo')"
              v-show="selectedItems"
              v-on="on"
              :disabled="!isNaN(itemIndex)"
              :ripple="false">
              <v-icon>mdi-minus-circle</v-icon>
            </v-btn>
          </template>
          <span>Unload selected cargo(es)</span>
        </v-tooltip>
        <v-tooltip bottom v-if="hold">
          <template v-slot:activator="{ on }">
            <v-btn
              v-show="selectedItems"
              icon
              @click="emitSceneEvent('undo')"
              v-on="on"
              :ripple="false">
              <v-icon>mdi-undo</v-icon>
            </v-btn>
          </template>
          <span>Undo last move</span>
        </v-tooltip>

        <v-spacer />

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon color="primary" @click="showQuickGuide = true" v-on="on" :ripple="false">
              <v-icon>fa-question-circle</v-icon>
            </v-btn>
          </template>
          <span>Show the quick guide</span>
        </v-tooltip>

        <v-tooltip bottom v-if="hold">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-if="loadplan.holds.length > 1"
              :disabled="holdIndex <= 0"
              @click="
                $router.replace({
                  name: 'detail',
                  params: {
                    version: String(loadplan_version),
                    hold: String(holdIndex - 1),
                  },
                })
              "
              v-on="on"
              :ripple="false">
              <v-icon>mdi-skip-previous</v-icon>
            </v-btn>
          </template>
          <span>Go to no. {{ holdIndex }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="hold">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-if="loadplan.holds.length > 1"
              :disabled="holdIndex >= loadplan.holds.length - 1"
              @click="
                $router.replace({
                  name: 'detail',
                  params: {
                    version: String(loadplan_version),
                    hold: String(holdIndex + 1),
                  },
                })
              "
              v-on="on"
              :ripple="false">
              <v-icon>mdi-skip-next</v-icon>
            </v-btn>
          </template>
          <span>Go to no. {{ holdIndex + 2 }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="hold">
          <template v-slot:activator="{ on }">
            <v-btn icon color="orange" @click="showResetDialog = true" v-on="on" :ripple="false">
              <v-icon>mdi-file-undo</v-icon>
            </v-btn>
          </template>
          <span>Reset</span>
        </v-tooltip>

        <v-tooltip bottom v-if="hold">
          <template v-slot:activator="{ on }">
            <v-btn icon color="green" @click="save" v-on="on" :disabled="!dirty" :ripple="false">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </template>
          <span>
            Save this {{ typeName }}. Note - the {{ typeName }} will also be saved automatically
            when leaving this view
          </span>
        </v-tooltip>
      </v-toolbar>

      <v-expansion-panels flat v-model="showSearch">
        <v-expansion-panel>
          <v-expansion-panel-content
            ><v-text-field
              prepend-icon="mdi-magnify"
              label="Search for cargo:"
              autofocus
              @input="searchForCargo($event)"
              @keydown="(e) => (e.keyCode == 27 ? toggleSearch() : () => {})"
              clearable></v-text-field></v-expansion-panel-content></v-expansion-panel
      ></v-expansion-panels>

      <div class="float-container">
        <div v-if="hold">
          <div class="planner-container" v-if="hold">
            <v-sheet class="planner-list">
              <v-card :loading="isLoading">
                <v-card-title primary-title>
                  <h3 class="text-h5 mb-0">{{ holdIndex + 1 }} : {{ hold.name }}</h3>
                </v-card-title>
                <v-card-text>
                  <p>
                    <v-icon>mdi-weight</v-icon>
                    {{ augmentedHold.weight | toWeight }} ({{
                      augmentedHold.weightUtilization | percentage
                    }}
                    %)
                  </p>
                  <p>
                    <v-icon>mdi-image-size-select-small</v-icon>
                    {{ augmentedHold.volume | toVolume }}
                    <span v-if="augmentedHold.volumeUtilization"
                      >({{ augmentedHold.volumeUtilization | percentage }} %)</span
                    >
                  </p>
                  <p>
                    <v-icon> $vuetify.icons.COG </v-icon>
                    <!-- <v-icon><svg-icon icon="cog" /></v-icon> -->

                    From center: {{ cogDiff.x | toLength(false) }} x
                    {{ cogDiff.y | toLength(false) }} x
                    {{ cogDiff.z | toLength }}
                  </p>
                </v-card-text>

                <v-card-text v-if="augmentedHold.axleWeights">
                  <h4 v-if="hold.tare > 0" class="text-h6 is-4">Axle Group Weights</h4>
                  <h4 v-else class="text-h6 is-4">Net Axle Group Weights</h4>

                  <div v-for="axleSet in augmentedHold.axleWeights" :key="axleSet.title">
                    {{ axleSet.title }} : {{ axleSet.value | toWeight }}
                    <v-icon v-if="axleSet.warning" color="red">mdi-alert</v-icon>
                  </div>
                </v-card-text>
                <planner-items-component
                  v-if="$vuetify.breakpoint.smAndUp"
                  :hold="holdWithIndices"
                  @loadingContainers="isLoading = !!$event.length"
                  :externalItemsSelection="selectedItems">
                </planner-items-component>
              </v-card>
            </v-sheet>
          </div>

          <div class="scene-container" ref="sceneContainer">
            <scene-component
              id="interactiveScene"
              :key="sceneKey"
              interactiveMode="full"
              :hold-object="hold"
              @drop.native="cargoDrop"
              @dragover.native="dragOver"
              @renderDone="renderDone"
              :custom-view-settings="viewSettings" />
          </div>
        </div>

        <div v-else-if="set" @click="selectCoordinates" style="position: relative">
          <div
            class="container-overlay"
            ref="containerOverlay"
            v-if="selectedHold"
            :style="`left:${clickedCoordinates.x}px;top:${clickedCoordinates.y}px`"
            @click="(e) => e.preventDefault()">
            <v-card>
              <v-card-title>
                {{ selectedHold.position_name }}: {{ selectedHold.name }}
              </v-card-title>
              <v-card-text>
                <table class="container-summary-table">
                  <tr>
                    <td>Pieces:</td>
                    <td>{{ selectedHold.items_count }}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Weight:</td>
                    <td>{{ selectedHold.weight | toWeight }}</td>
                    <td>{{ selectedHold.weightUtilization | percentage }}%</td>
                  </tr>
                  <tr>
                    <td>Volume:</td>
                    <td>{{ selectedHold.volume | toVolume }}</td>
                    <td>{{ selectedHold.volumeUtilization | percentage }}%</td>
                  </tr>
                </table>
              </v-card-text>
              <v-card-actions>
                <v-btn text @click.stop="selectedHold = null">Close</v-btn>
                <v-spacer />
                <v-btn @click.stop="goToContainer(selectedHold.__indices.start)" color="primary"
                  >Open</v-btn
                >
              </v-card-actions>
            </v-card>
          </div>
          <div class="scene-container" ref="sceneContainer">
            <scene-component
              id="interactiveScene"
              :key="`${sceneKey}-set`"
              interactiveMode="container_mode"
              :set="set"
              @dragover.native="dragOver"
              @renderDone="renderDone"
              :custom-view-settings="{
                ...viewSettings,
                hideCog: true,
              }" />
          </div>
        </div>
      </div>
    </div>

    <v-snackbar :timeout="6000" top v-model="showSnackbar" multi-line>
      {{ snackBarText }}
      <v-btn text color="pink" @click.native="showSnackbar = false">Ok</v-btn>
    </v-snackbar>

    <!-- MODALS -->
    <interactive-view-guide
      :visible="showQuickGuide"
      @close="showQuickGuide = false"></interactive-view-guide>

    <v-dialog v-model="showNotesDialog" v-if="hold" width="600">
      <v-card>
        <v-card-title class="text-h5">Container notes</v-card-title>
        <v-card-text>
          <v-form>
            <v-textarea
              autofocus
              class="mt-2"
              label="Notes:"
              v-model="hold.notes"
              outlined
              rows="3"></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="showNotesDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-modal
      :visible="showResetDialog"
      :title="`Reset ${typeName}`"
      confirmColor="warning"
      @confirm="
        showResetDialog = false;
        updateScene(true);
      "
      @close="showResetDialog = false"></confirm-modal>

    <button style="visibility: hidden" id="getImageDataButton" @click="setCanvasDataUrl"></button>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { SceneManager, STATES, VIEWS } from '@/graphics/sceneManager';
import sceneComponent from '@/components/Custom/SceneComponent.vue';
import FileSaver from 'file-saver';

import InteractiveViewGuide from '@/components/Modals/InteractiveViewGuide.vue';
import plannerItemsComponent from '@/components/Custom/PlannerItems.vue';
import confirmModal from '@/components/Modals/Confirm.vue';
import {
  HoldData,
  HoldInputItem,
  UpdateLoadplanHoldsParams,
  Loadlist,
  Loadplan,
  HoldItem,
  HoldDataWithIndices,
  HoldDataWithPosition,
} from '@/models/LoadlistModel';
import { CalcData } from '@/models/CalculationModel';
import { States, Views, ViewSettings } from '@/models/GraphicsModel';
import { Scene, Vector3 } from 'three';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { useMiscStore } from '@/stores/miscStore';
import { AugmentedSet } from '@/models/augmented/set';
import { AugmentedHold } from '@/models/augmented/hold';
import { ContainerClickInfo } from '@/models/SetsModel';
import containerUtils from '@/misc/containerUtils';
import SvgIcon from '@/components/Custom/SvgIcon.vue';

interface HoldItemWithIndex extends HoldItem {
  index: number;
}

export default Vue.extend({
  name: 'interactive',
  components: {
    sceneComponent,
    InteractiveViewGuide,
    plannerItemsComponent,
    confirmModal,
    SvgIcon,
  },
  data() {
    return {
      leavingComponent: false,
      sceneKey: 1,
      showPlannerSheet: true,
      hold: null as HoldData,
      cog: null as Vector3,
      holdIndex: Number(this.$route.params.hold),
      itemIndex: Number(this.$route.params.item),
      set: null as AugmentedSet,
      currentInteractionState: null as States,
      toggleGrid: false,
      selectedItems: null as HoldItemWithIndex[],
      showQuickGuide: false,
      showNotesDialog: false,
      showResetDialog: false,
      showHull: true,
      showSnackbar: false,
      showSearch: false as number | boolean,
      searchInput: undefined as string,
      snackBarText: '',
      viewSettings: undefined as ViewSettings,
      loadedItemsByMouse: null as number,
      showAllItemsTimer: null as number,
      isLoading: false,
      originalHold: null as HoldData,
      selectedHold: null as AugmentedHold,
      clickedCoordinates: { x: 10, y: 10 },
      dirty: true,
    };
  },
  computed: {
    ...mapStores(useMiscStore, useLoadlistStore),
    augmentedHold(): AugmentedHold {
      return new AugmentedHold(this.hold, this.cog);
    },
    cogDiff(): { x: number; y: number; z: number } {
      if (!this.cog) {
        return { x: 0, y: 0, z: 0 };
      }
      return {
        x: Math.abs(this.cog.x - this.hold.L * 0.5),
        y: Math.abs(this.cog.y - this.hold.W * 0.5),
        z: Math.abs(this.cog.z - this.hold.H * 0.5),
      };
    },
    holdWithIndices(): HoldDataWithIndices {
      return {
        ...this.hold,
        __indices: { start: this.holdIndex, end: this.holdIndex },
      };
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return this.loadlistStore.loadplan;
    },
    loadplan_version(): number {
      return this.loadlistStore.loadplan_version;
    },
    length_dim(): string {
      return this.miscStore.length_dim;
    },
    typeName(): string {
      return this.$typeNames(this.loadlist.list_type);
    },
    interactiveStates(): {
      MOVE: string;
      MEASURE_TAPE: string;
      SELECT_BOX: string;
    } {
      return STATES;
    },
    views(): {
      TOP: string;
      SIDE: string;
      SIDE2: string;
      FRONT: string;
      THREED: string;
      CUSTOM: string;
    } {
      return VIEWS;
    },
    interactionState: {
      get(): States {
        return this.currentInteractionState;
      },
      set(s: States): void {
        this.emitSceneEvent('interactive-state', s);
      },
    },
    showNestedInteractiveView(): boolean {
      return (
        Array.isArray(this.selectedItems) &&
        this.selectedItems.length === 1 &&
        !!this.selectedItems[0]?.from_container
      );
    },
  },
  watch: {
    'loadplan.holds'(): void {
      if (!this.leavingComponent) {
        this.updateScene();
      }
    },
    '$route.params.hold'(a): void {
      const index = Number(a);
      if (!isNaN(index)) {
        this.holdIndex = index;
        this.originalHold = Object.freeze(JSON.parse(JSON.stringify(this.loadplan.holds[index])));
      } else {
        this.holdIndex = undefined;
      }
      this.updateScene();
    },
    '$route.params.item'(a): void {
      const index = Number(a);
      if (!isNaN(index)) {
        this.itemIndex = index;
      } else {
        this.itemIndex = undefined;
      }
      this.updateScene();
    },
  },
  created(): void {
    // Create backup hold
    if (!isNaN(this.holdIndex)) {
      this.originalHold = Object.freeze(
        JSON.parse(JSON.stringify(this.loadplan.holds[this.holdIndex]))
      );
    }
    this.updateScene();

    SceneManager.eventBus.on('select-cargoes', this.selectItem);
    SceneManager.eventBus.on('removed-cargo', this.removeCargo);
    SceneManager.eventBus.on('measured-distance', this.measuredDistance);
    SceneManager.eventBus.on('cog-updated', this.updateCog);
    SceneManager.eventBus.on('open-container', this.goToContainer);
    SceneManager.eventBus.on('drop-on-container', this.cargoDropOnSet);
    SceneManager.eventBus.on('select-container', this.selectContainer);
    SceneManager.eventBus.on('search', this.toggleSearch);
    SceneManager.eventBus.on('save', this.save);
    SceneManager.eventBus.on('notes', this.showNotes);
    SceneManager.eventBus.on('select-multiple', this.selectMultiple);
    SceneManager.eventBus.on('ruler', this.showRuler);
    SceneManager.eventBus.on('tape', this.showMeasuringTape);

    SceneManager.eventBus.on('interaction-state-changed', this.changedInteractionState);
  },
  beforeRouteUpdate(to: never, from: never, next: any) {
    this.saveBeforeLeave(to, from, next);
  },
  beforeRouteLeave(to: never, from: never, next: any) {
    this.saveBeforeLeave(to, from, next);
  },
  beforeDestroy(): void {
    SceneManager.eventBus.off('select-cargoes', this.selectItem);
    SceneManager.eventBus.off('removed-cargo', this.removeCargo);
    SceneManager.eventBus.off('measured-distance', this.measuredDistance);
    SceneManager.eventBus.off('cog-updated', this.updateCog);
    SceneManager.eventBus.off('open-container', this.goToContainer);
    SceneManager.eventBus.off('drop-on-container', this.cargoDropOnSet);
    SceneManager.eventBus.off('select-container', this.selectContainer);
    SceneManager.eventBus.off('search', this.toggleSearch);
    SceneManager.eventBus.off('save', this.save);
    SceneManager.eventBus.off('notes', this.showNotes);
    SceneManager.eventBus.off('select-multiple', this.selectMultiple);
    SceneManager.eventBus.off('ruler', this.showRuler);
    SceneManager.eventBus.off('tape', this.showMeasuringTape);
    SceneManager.eventBus.off('interaction-state-changed', this.changedInteractionState);

    this.clearScene();
  },
  methods: {
    saveBeforeLeave(to: never, from: never, next: any) {
      this.leavingComponent = true;
      this.save();
      next();
      // if (isNaN(this.itemIndex)) {
      //   this.save().finally(() => {
      //     // set to false to prevent loadlist from being saved twice if leaving loadlist main route
      //     this.loadlistStore.setLoadlistResultHasChanged(false)
      //     next()
      //   })
      // } else {
      //   next()
      // }
    },
    updateScene(fromBackup = false): void {
      if (!isNaN(this.holdIndex)) {
        if (
          !isNaN(this.itemIndex) &&
          this.loadplan.holds[this.holdIndex].items?.[this.itemIndex]?.from_container
        ) {
          this.hold = JSON.parse(
            JSON.stringify(
              fromBackup
                ? this.originalHold
                : this.loadplan.holds[this.holdIndex].items[this.itemIndex].from_container
            )
          );
        } else {
          this.hold = JSON.parse(
            JSON.stringify(fromBackup ? this.originalHold : this.loadplan.holds[this.holdIndex])
          );
        }
      } else if (this.$route.params.set !== undefined) {
        const setUuid = this.$route.params.set;
        const set = this.loadplan.sets.find((s) => s.uuid == setUuid);
        this.set = new AugmentedSet(set, this.loadplan.holds);
        this.hold = undefined;
      }
      this.sceneKey++;
    },
    toggleSearch(): void {
      this.showSearch = this.showSearch === 0 ? false : 0;
      if (this.showSearch !== 0) {
        this.emitSceneEvent('show-all-cargoes', null);
      }
    },
    showNotes(): void {
      this.showNotesDialog = true;
    },
    showRuler(): void {
      (this.$refs.rulerButton as any)?.$el.click();
    },
    showMeasuringTape(): void {
      (this.$refs.tapeButton as any)?.$el.click();
    },
    selectMultiple(): void {
      (this.$refs.selectButton as any)?.$el.click();
    },
    save(): Promise<unknown> {
      if (this.loadplan && this.hold) {
        SceneManager.saveContainerState();

        if (!isNaN(this.itemIndex)) {
          const parentHoldData = JSON.parse(
            JSON.stringify(this.loadplan.holds[this.holdIndex])
          ) as HoldData;

          const nestedItem = containerUtils.createNestedItemFromHold(this.hold, true);
          parentHoldData.items[this.itemIndex] = {
            ...parentHoldData.items[this.itemIndex],
            ...nestedItem,
          };
          this.updateLoadplanHolds({
            index: this.holdIndex,
            replace: 1,
            holds: [parentHoldData],
          });
          this.$router.go(-1);

          // parentHoldData.items.splice(this.itemIndex, 1);
          // const nestedItem = containerUtils.createNestedItemFromHold(this.hold);
          // nestedItem.qty = 1;

          // this.calculateLoadplan({
          //   items: [nestedItem],
          //   containers: [parentHoldData],
          // }).then((solution) => {
          //   if (!solution.unloaded_items.length) {
          //     this.updateLoadplanHolds({
          //       index: this.holdIndex,
          //       replace: 1,
          //       holds: solution.containers,
          //     });
          //     this.$router.go(-1);
          //   }
          // });
        } else {
          this.updateLoadplanHolds({
            index: this.holdIndex,
            replace: 1,
            holds: [JSON.parse(JSON.stringify(this.hold))],
          });
        }
      } else if (this.set) {
        const indices = this.set.holdIndices;
        this.updateLoadplanHolds({
          index: indices.start,
          replace: indices.end - indices.start + 1,
          holds: JSON.parse(JSON.stringify(this.set.rendering)),
        });
      }
      this.dirty = false;
      setTimeout(() => {
        this.dirty = true;
      }, 1000);
      return this.saveLoadlistResult();
    },
    emitSceneEvent(event: string, data?: any): void {
      SceneManager.eventBus.emit(event, data);
    },
    clearScene(): void {
      clearTimeout(this.showAllItemsTimer);
      this.viewSettings = undefined;
      SceneManager.clearScene();
    },
    saveCamera(): void {
      this.viewSettings = SceneManager.getViewSettings();
      if (this.viewSettings.pos[0]) {
        this.viewSettings.view = 'custom';
      }
    },
    measuredDistance(d: number): void {
      this.snackBarText = 'Distance: ' + this.$options.filters.toLength(d);
      this.showSnackbar = true;
    },
    selectItem(selected: HoldItemWithIndex[]): void {
      this.selectedItems = selected;
    },
    dragOver(e: any): void {
      e.preventDefault();
    },
    getLoadItems(e: DragEvent): HoldInputItem[] {
      let loadItems: any = null;
      try {
        loadItems = JSON.parse(e.dataTransfer.getData('text'));
      } catch {
        return [];
      }
      return loadItems?.unloaded || [];
    },
    cargoDropOnSet(data: { containerIndex: number; e: DragEvent }): void {
      const hold = this.set.rendering.find((c) => c.__indices.start == data.containerIndex);
      if (hold) {
        const loadItems = this.getLoadItems(data.e);
        if (loadItems.length) {
          this.loadCargo(loadItems, hold)
            .then((hold) => {
              const index = this.set.rendering.findIndex(
                (h) => h.__indices.start == data.containerIndex
              );
              if (index >= 0) {
                this.saveCamera();
                const current = this.set.rendering[index];

                this.set.rendering[index] = { ...current, ...hold };
                this.updateLoadplanHolds({
                  index: data.containerIndex,
                  replace: 1,
                  holds: [this.set.rendering[index]],
                });
              }
            })
            .catch(() => {});
        }
      }
    },
    cargoDrop(e: DragEvent): void {
      const loadItems: HoldInputItem[] = this.getLoadItems(e);
      if (loadItems.length) {
        let p = SceneManager.getDropPoint(e);
        this.loadCargo(loadItems, this.hold, p)
          .then((hold) => {
            if (!isNaN(this.itemIndex)) return;

            this.updateLoadplanHolds({
              index: this.holdIndex,
              replace: 1,
              holds: [hold],
            });
          })
          .catch((r) => {
            if (r == 'not loaded') {
              this.loadItemManually(loadItems[0]);
            }
          });
      }
    },
    loadCargo(loadItems: HoldItem[], hold: HoldData, point?: Vector3): Promise<HoldData> {
      SceneManager.saveContainerState();
      return this.loadlistStore
        .calculateLoadplan({
          operation: 'load_into_space',
          items: loadItems,
          container: hold,
          load_at_position: point,
        })
        .then((solution) => {
          const numberOfLoadedItems = solution.containers?.[0]?.items_count - hold.items_count;
          this.loadedItemsByMouse = numberOfLoadedItems;
          if (numberOfLoadedItems == 0) {
            return Promise.reject('not loaded');
          }
          return solution.containers[0];
        });
    },
    changedInteractionState(state: States): void {
      this.currentInteractionState = state;
    },
    updateCog(cog: Vector3): void {
      this.cog = cog;
    },
    selectContainer(containerEvent: ContainerClickInfo): void {
      if (containerEvent) {
        const holdIndex = this.set.rendering.findIndex(
          (h) => h.__indices.start == containerEvent.containerIndex
        );
        if (holdIndex >= 0) {
          const hold = JSON.parse(
            JSON.stringify(this.set.rendering[holdIndex])
          ) as HoldDataWithPosition;
          hold.position_name = hold.position_name || `${holdIndex + 1}`;
          this.selectedHold = new AugmentedHold(hold);
        } else {
          this.selectedHold = null;
        }
      }
    },

    selectCoordinates(e: any): void {
      if (e && !e.defaultPrevented) {
        const padding = 10;
        this.clickedCoordinates = { x: e.layerX, y: e.layerY + padding };
        const w = (this.$refs.containerOverlay as any)?.offsetWidth;
        if (this.clickedCoordinates.x + w > window.innerWidth) {
          this.clickedCoordinates.x -= w + padding;
        } else {
          this.clickedCoordinates.x += padding;
        }
      }
    },
    goToContainer(containerIndex: number, itemIndex?: number): void {
      this.selectedHold = null;
      this.$router.push({
        name: 'detail',
        params: {
          version: String(this.loadplan_version),
          hold: String(containerIndex),
          item: !isNaN(itemIndex) ? String(itemIndex) : undefined,
        },
      });
    },
    setCanvasDataUrl(e: any): void {
      e.target.dataset.imagedata = SceneManager.getCanvasCopy().toDataURL('image/png');
    },
    downloadImage(e: any): void {
      const name = `${this.loadlist.name} - ${this.hold ? this.hold.name : this.set.set.name}`;
      FileSaver.saveAs(SceneManager.getCanvasCopy().toDataURL('image/png'), name);
    },
    loadItemManually(item: HoldInputItem): void {
      if (!isNaN(this.itemIndex)) return;
      const firstItem: HoldItem = {
        ...item,
        ...{
          pos: {
            x: this.hold.L + (item.l * this.$toSI(this.loadlist.length_dim)) / 2,
            y: (item.w * this.$toSI(this.loadlist.length_dim)) / 2,
            z: (item.h * this.$toSI(this.loadlist.length_dim)) / 2,
          },
          L: item.l * this.$toSI(this.loadlist.length_dim),
          W: item.w * this.$toSI(this.loadlist.length_dim),
          H: item.h * this.$toSI(this.loadlist.length_dim),
          WT: item.wt * this.$toSI(this.loadlist.weight_dim),
          rotation: [0, 0, 0, 'XYZ'],
        },
      };

      this.updateLoadplanHolds({
        index: this.holdIndex,
        replace: 1,
        holds: [
          {
            ...this.hold,
            WT: this.hold.WT + firstItem.WT,
            volume: this.hold.volume + firstItem.L * firstItem.W * firstItem.H,
            items: [...this.hold.items, firstItem],
          },
        ],
      });
    },
    removeItem(hold: HoldData, itemIndex: number) {
      const item = hold.items[itemIndex];
      hold.WT = Math.max(hold.WT - item.WT, 0);
      hold.volume = Math.max(
        hold.volume - (item.from_container ? item.from_container.volume : item.L * item.W * item.H),
        0
      );
      hold.items.splice(itemIndex, 1);
    },
    removeCargo(itemsIndices: number[]): void {
      if (!isNaN(this.itemIndex)) return;
      SceneManager.saveContainerState();
      const hold = JSON.parse(JSON.stringify(this.hold)) as HoldData;
      itemsIndices.forEach((itemIndex) => {
        this.removeItem(hold, itemIndex);
      });
      this.updateLoadplanHolds({
        index: this.holdIndex,
        replace: 1,
        holds: [hold],
      });

      SceneManager.saveContainerState();
    },
    renderDone(): void {
      if (this.loadedItemsByMouse > 0 && this.hold) {
        const r = new Array(this.loadedItemsByMouse)
          .fill(this.hold.items.length)
          .map((x, i) => x - this.loadedItemsByMouse + i);
        this.loadedItemsByMouse = null;
        this.emitSceneEvent('highlight-cargo-by-index', r);
        this.showAllItemsTimer = window.setTimeout(() => {
          this.emitSceneEvent('show-all-cargoes', null);
        }, 1000);
      }
    },
    searchForCargo(name?: string): void {
      if (!name) {
        this.emitSceneEvent('show-all-cargoes', null);
        return;
      }

      const searchSpace = this.hold ? [this.hold] : this.set.rendering;
      let r: number[] = [];
      let items_count = 0;
      for (let i = 0; i < searchSpace.length; i++) {
        const hold = searchSpace[i];

        r = hold.items.reduce(
          (acc: number[], el: HoldItem, i: number) =>
            el.label.toLowerCase().includes(name.toLowerCase()) ||
            (el.sku && el.sku.toLowerCase().includes(name.toLowerCase()))
              ? [...acc, i + items_count]
              : acc,
          r
        );
        items_count += hold.items.length;
      }
      this.emitSceneEvent('highlight-cargo-by-index', r);
    },
    resizeSceneContainer(): void {
      console.log('resize-scene-container');
    },
    togglePlannerSheet(): void {
      this.showPlannerSheet = !this.showPlannerSheet;
      this.$nextTick(() => {
        window.dispatchEvent(new Event('resize'));
      });
    },
    updateLoadplanHolds(options: UpdateLoadplanHoldsParams): void {
      this.loadlistStore.updateLoadplanHolds(options);
    },
    calculateLoadplan(calcData: CalcData): Promise<{
      containers: HoldData[];
      unloaded_items: HoldItem[];
    }> {
      return this.loadlistStore.calculateLoadplan(calcData);
    },
    saveLoadlistResult(): Promise<unknown> {
      return this.loadlistStore.saveLoadlistResult();
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.planner-container {
  width: 300px;
  float: left;
  margin-right: 10px;
}

.planner-container-minimized {
  width: 50px;
  float: left;
  margin-right: 10px;
}
.scene-container {
  float: none;
  overflow: hidden;
  border: 1px solid lightgray;
}
.container-overlay {
  transition: all 100ms cubic-bezier(0.075, 0.82, 0.165, 1);
  /* transition: left 50ms cubic-bezier(0.075, 0.82, 0.165, 1); */
  position: absolute;
  /* right: 20px;
  top: 20px; */
  z-index: 10;
}
.container-summary-table {
  min-width: 200px;
}
.container-summary-table td {
  padding: 6px 12px 0 0;
}
.container-summary-table td:first-child {
  font-weight: bold;
}
</style>
