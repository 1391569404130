<template>
  <div>
    <v-row class="align-center mx-0">
      <v-col v-if="editable">
        <div class="mt-2">
          <v-btn class="ml-2" @click.stop="showImportDialog = true" :disabled="!user.is_editor">
            <v-icon left>mdi-import</v-icon>Import
          </v-btn>
          <v-btn class="ml-2" @click.stop="showExportDialog = true">
            <v-icon left>mdi-export</v-icon>Export
          </v-btn>
          <v-btn
            class="ml-8"
            :disabled="!selected.length || !user.is_editor"
            color="error"
            @click.stop="showDeleteDialog = true">
            <v-icon left>mdi-delete</v-icon>Delete
          </v-btn>
        </div>
      </v-col>
      <v-col>
        <v-textarea
          v-model="search"
          hint="You can search for multiple items at once. Just hit enter"
          outlined
          append-icon="mdi-magnify"
          label="Search"
          auto-grow
          persistent-hint
          rows="1" />
      </v-col>
    </v-row>

    <v-row class="mx-0">
      <v-col>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="filteredCargoes"
          :loading="loading"
          :items-per-page="15"
          :show-select="true"
          class="cargo-lib-table elevation"
          no-data-text="You do not have any saved cargoes">
          <template v-slot:item="props">
            <tr>
              <td>
                <v-simple-checkbox
                  :ripple="false"
                  :value="props.isSelected"
                  @input="props.select($event)"></v-simple-checkbox>
              </td>

              <td>
                <slot name="first-column" v-bind:cargo="props.item"></slot>
              </td>
              <td class="justify-center" v-if="editable">
                <v-icon
                  :disabled="!user.is_editor"
                  color="primary"
                  @click="
                    editItem = JSON.parse(JSON.stringify(props.item));
                    showEditDialog = true;
                  "
                  >mdi-pencil</v-icon
                >
              </td>

              <td
                v-for="column in headers.slice(editable ? 2 : 1, headers.length)"
                :key="column.value">
                <span v-if="column.dimension === 'length'">
                  {{ toLength(props.item.data[column.value], props.item.length_dim) }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-if="!props.item.length_dim" class="dim" v-on="on">[?]</span>
                    </template>
                    <span>No dimension set</span>
                  </v-tooltip>
                </span>
                <span v-else-if="column.dimension === 'weight'">
                  {{ toWeight(props.item.data[column.value], props.item.weight_dim) }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-if="!props.item.weight_dim" class="dim" v-on="on">[?]</span>
                    </template>
                    <span>No dimension set</span>
                  </v-tooltip>
                </span>
                <span v-else-if="column.input === 'text'">
                  {{ props.item.data[column.value] }}
                </span>
                <!-- <v-simple-checkbox
                  v-else-if="column.input === 'checkbox'"
                  :disabled="true"
                  :value="props.item.data[column.value]"
                /> -->
                <span v-else-if="column.input === 'checkbox'">
                  <v-icon v-if="props.item.data[column.value]"> mdi-check </v-icon>
                </span>
                <v-icon v-else-if="column.input === 'color'" :color="props.item.data.color"
                  >mdi-checkbox-blank-circle</v-icon
                >

                <div v-else-if="column.input == 'object'">
                  <div v-if="props.item.data[column.value]">
                    <div v-if="props.item.data[column.value].name">
                      {{ props.item.data[column.value].name }}
                    </div>
                    <div v-else-if="props.item.data[column.value].label">
                      {{ props.item.data[column.value].label }}
                    </div>
                    <div v-else>
                      {{ JSON.stringify(props.item.data[column.value]).substring(0, 30) }}...
                    </div>
                  </div>
                </div>
                <div v-else-if="column.input === 'hold_select'">
                  <v-tooltip
                    top
                    v-if="props.item.data[column.value] && props.item.data[column.value].length">
                    <template v-slot:activator="{ on }">
                      <div v-on="on" style="height: 100%; width: 100%">
                        {{
                          (
                            holdsLibrary.find(
                              (hl) => hl.id === props.item.data[column.value][0]
                            ) || {
                              name: 'Unknown',
                            }
                          ).name
                        }}...
                      </div>
                    </template>
                    <span style="white-space: pre-line"
                      >{{
                        (props.item.data[column.value] || [])
                          .map(
                            (h) =>
                              (
                                holdsLibrary.find((hl) => hl.id === h) || {
                                  name: 'Unknown',
                                }
                              ).name
                          )
                          .join('\n')
                      }}
                    </span>
                  </v-tooltip>
                </div>
                <span v-else-if="column.input === 'orientations_picker'">
                  <orientations-picker
                    :value="props.item.data.orientations"
                    :item="props.item"
                    readOnly></orientations-picker>
                </span>
                <span v-else-if="column.input === 'select'">
                  {{
                    (
                      column.values.find((v) => v.key == props.item.data[column.value]) ||
                      column.values.find((v) => v.default) || { text: '' }
                    ).text
                  }}
                </span>
                <span v-else> {{ props.item.data[column.value] }}</span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-if="editItem" v-model="showEditDialog" scrollable width="1000">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <scene-component
            class="d-flex justify-center"
            :hold-object="holdForItem(editItem)"
            :customViewSettings="{
              hideContainer: true,
            }"
            :canvas-width="800"
            :canvas-height="300"
            :key="JSON.stringify(editItem)" />
          <v-container>
            <v-row>
              <v-col>
                <v-text-field v-model.trim="editItem.sku" label="Sku"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model.trim="editItem.data.label"
                  label="Name"
                  required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  :disabled="!!editItem.data.from_container"
                  v-model.number="editItem.data.l"
                  type="number"
                  label="Length"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  :disabled="!!editItem.data.from_container"
                  v-model.number="editItem.data.w"
                  type="number"
                  label="Width"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  :disabled="!!editItem.data.from_container"
                  v-model.number="editItem.data.h"
                  type="number"
                  label="Height"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  :disabled="!!editItem.data.from_container"
                  v-model.number="editItem.data.wt"
                  type="number"
                  label="Weight"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col>
                <v-select
                  :disabled="!!editItem.data.from_container"
                  id="editLengthDimSelect"
                  required
                  v-bind:items="lengthDims"
                  :value="editItem.length_dim"
                  label="Select length dimension for cargo"
                  single-line
                  @input="showConvertDialog($event, editItem)"
                  :rules="dimensionRules"></v-select>
              </v-col>
              <v-col>
                <v-select
                  required
                  :disabled="!!editItem.data.from_container"
                  v-bind:items="weightDims"
                  :value="editItem.weight_dim"
                  @input="showConvertDialog($event, editItem, false)"
                  label="Select weight dimension cargo"
                  single-line
                  :rules="dimensionRules"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="editItem.data.not_stackable"
                  label="Not Stackable"></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox v-model="editItem.data.bottom_only" label="Bottom only"></v-checkbox>
              </v-col>
              <!-- <v-col>
                <v-checkbox
                  v-model="editItem.data.tiltable"
                  label="Tiltable"
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="editItem.data.not_rotatable"
                  label="Longship only"
                ></v-checkbox>
              </v-col> -->
              <v-col>
                <orientations-picker
                  flat
                  hide-details
                  v-model="editItem.data.orientations"
                  :item="editItem.data">
                </orientations-picker>
              </v-col>
              <v-col>
                <v-checkbox v-model="editItem.data.palletize" label="Preload"></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  :disabled="!!editItem.data.from_container"
                  v-model="editItem.data.geometry"
                  :items="[
                    { value: 'box', text: 'Box' },
                    { value: 'cylinder', text: 'Cylinder' },
                    { value: 'hollow_cylinder', text: 'Hollow cylinder' },
                  ]"
                  label="Geometry"></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  type="number"
                  :value="editItem.data.max_layers"
                  @input="editItem.data.max_layers = parseInt($event) || null"
                  max="100"
                  label="Max number of layers"></v-text-field>
              </v-col>

              <v-col>
                <v-text-field
                  type="number"
                  :value="editItem.data.max_load"
                  @input="editItem.data.max_load = parseFloat($event) || null"
                  label="Max load"
                  hint="Max weight that this cargo can support on top"></v-text-field>
              </v-col>

              <v-col>
                <v-text-field
                  v-model.number="editItem.data.priority"
                  type="number"
                  label="Priority"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model.number="editItem.data.unit_qty"
                      type="number"
                      label="Unit Quantity"
                      v-on="on"
                      v-bind="attrs"></v-text-field>
                  </template>
                  <div>
                    If this is a package of multiple items of the same kind, the Unit Quantity sets
                    how many items the package holds.<br /><br />
                    Example: We have an item called 'Pens (20 pcs)'. We set the
                    <i>Unit Quantity</i> to '20'. <br />
                    When we fill in our loadlist, we can set the
                    <i>Quantity</i> to 1000 pens, which will result in 50 boxes of 'Pens (20 pcs)'.
                  </div>
                </v-tooltip>
              </v-col>
              <v-col
                ><v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editItem.data.free_space"
                      label="Zone"
                      v-on="on"
                      v-bind="attrs"
                      hint="If entered as X;Y - X will be added to length direction and Y to width direction"></v-text-field>
                  </template>
                  <span> How much free space is required to be around the box </span>
                </v-tooltip>
              </v-col>
              <v-col>
                <v-text-field
                  v-model.trim="editItem.data.shipment_id"
                  label="Group/Consignment"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model.trim="editItem.data.allowed_containers"
                  label="Load in"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model.trim="editItem.data.class_id" label="Class"></v-text-field>
              </v-col>
            </v-row>
            <v-row> </v-row>
            <v-row>
              <v-textarea
                label="Notes"
                v-model="editItem.data.metadata"
                outlined
                rows="1"></v-textarea>
            </v-row>
            <v-row>
              <v-color-picker
                v-model="editItem.data.color"
                hide-mode-switch
                hide-canvas
                mode="hexa"
                show-swatches></v-color-picker>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="showEditDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn class="success" text @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDeleteDialog" width="600">
      <v-card>
        <v-card-title>
          <span class="text-h5">Delete cargo?</span>
        </v-card-title>

        <v-card-text>
          <p>Are you sure you want to delete the selected items?</p>
        </v-card-text>

        <v-card-actions>
          <v-btn text @click="showDeleteDialog = false">No</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="
              deleteItems();
              showDeleteDialog = false;
            "
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showExportDialog" width="600">
      <v-card>
        <v-card-title>
          <span class="text-h5">Export Cargo-Library</span>
        </v-card-title>

        <v-card-text>
          <p>Do you want to export items to an Excel file?</p>
        </v-card-text>

        <v-card-actions>
          <v-btn text @click="showExportDialog = false">No</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="!selected.length"
            @click="
              exportItems(selected);
              showExportDialog = false;
            "
            >Export selected</v-btn
          >
          <v-btn
            color="success"
            @click="
              exportItems();
              showExportDialog = false;
            "
            >Export all</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="editable" v-model="showImportDialog" scrollable>
      <v-card>
        <v-card-title>
          <span class="text-h5">Importing items</span>
        </v-card-title>

        <v-card-text>
          <v-row class="mt-2">
            <v-col>
              <v-select
                id="editLengthDimSelect"
                required
                v-bind:items="lengthDims"
                v-model="length_dim"
                label="Select length dimension for below cargoes"
                single-line
                :rules="dimensionRules"></v-select>
            </v-col>
            <v-col>
              <v-select
                required
                v-bind:items="weightDims"
                v-model="weight_dim"
                label="Select weight dimension for below cargoes"
                single-line
                :rules="dimensionRules"></v-select>
            </v-col>
          </v-row>
          <input-table
            ref="inputTable"
            :objects="importObjects"
            :extra-hidden-columns="['qty']"
            :disableAutocomplete="true"
            :lengthDim="length_dim"
            :weightDim="weight_dim"></input-table>
        </v-card-text>

        <packlist-reader
          v-model="importObjects"
          :show.sync="showParseExcelModal"
          :files="dropFiles"
          @updateWeightDim="weight_dim = $event"
          @updateLengthDim="length_dim = $event"></packlist-reader>

        <v-card-actions>
          <v-btn text @click="showImportDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>

          <v-btn @click="openFileImporter()">Import from Excel</v-btn>

          <v-btn color="success" @click="bulkCreate()">Import</v-btn>
        </v-card-actions>
      </v-card>
      <input
        type="file"
        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ref="fileInput"
        style="display: none"
        @click="resetFile"
        @change="selectedFile" />
    </v-dialog>
    <v-dialog v-model="conversion.showDialog" width="600">
      <v-card>
        <v-card-text class="pt-4">
          Do you want to convert the
          {{ conversion.length ? 'length, width and height' : 'weight' }}?
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="conversion.showDialog = false">Skip</v-btn>
          <v-spacer />
          <v-btn
            class="success"
            @click="
              if (conversion.length) {
                editItem.data.l *= conversion.factor;
                editItem.data.w *= conversion.factor;
                editItem.data.h *= conversion.factor;
              } else {
                editItem.data.wt *= conversion.factor;
              }
              conversion.factor = 1.0;
              conversion.showDialog = false;
            "
            >Convert</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar :timeout="5000" v-model="displayError" top>
      {{ errorMessage }}
      <v-btn text color="primary" @click.native="displayError = false">Ok</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor, PropType } from 'vue';
import API from '@/API';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import InputTable from '@/components/Custom/CargoInputTable/index.vue';
import PacklistReader from '@/components/Modals/PacklistReader.vue';
import { Cargo, Hold, HoldData, HoldItem, LengthDim, WeightDim } from '@/models/LoadlistModel';
import OrientationsPicker from '@/components/Custom/OrientationPicker.vue';
import ItemProperties from '@/misc/itemProperties';
import { User } from '@/models/UserCompanyModel';
import { getSerializerError } from '@/misc/errorUtils';

import SceneComponent from '@/components/Custom/SceneComponent.vue';
import ExcelService from '@/services/excelService';
import { Workbook } from 'exceljs';
import FileSaver from 'file-saver';

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        inputTable: InstanceType<typeof InputTable>;
      };
    }
  >
).extend({
  name: 'cargo-library',
  components: {
    InputTable,
    OrientationsPicker,
    PacklistReader,
    SceneComponent,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    preSelected: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
  },
  computed: {
    ...mapStores(useMiscStore),

    holdsLibrary(): Hold[] {
      return this.miscStore.holds;
    },
    formTitle(): string {
      return this.editItem.id ? 'Edit cargo' : 'New cargo';
    },
    user(): User {
      return this.miscStore.user;
    },
    preferences(): any {
      return this.miscStore.preferences;
    },
    hiddenColumns(): string[] {
      return Array.isArray(this.preferences?.hidden_input_columns)
        ? this.preferences.hidden_input_columns
        : [];
    },
    headers(): {
      value?: string;
      text: string;
      sortable: boolean;
      dimension?: string;
      input?: string;
      values?: any[];
    }[] {
      return [
        {
          text: '',
          value: 'id',
          sortable: false,
        },
        ...(this.editable ? [{ text: 'Actions', value: 'name', sortable: false }] : []),
        ...ItemProperties.props()
          .filter((i) => i.key !== 'qty' && !i.additional && !this.hiddenColumns.includes(i.key))
          .map((i) => {
            let text = i.text;
            if (i.dimension === 'length') {
              text = `${text} [${this.miscStore.length_dim}]`;
            } else if (i.dimension === 'weight') {
              `${text} [${this.miscStore.weight_dim}]`;
            }
            return {
              value: i.key,
              text: text,
              sortable: false,
              dimension: i.dimension,
              input: i.input,
              values: i.values,
            };
          }),
      ];
    },
    filteredCargoes(): Cargo[] {
      if (this.search) {
        const parts = this.search
          .toLowerCase()
          .split('\n')
          .map((part) => part.trim())
          .filter((p) => p.length);
        if (parts.length) {
          let cargoes = this.miscStore.cargoes;
          return parts.flatMap((query) => {
            const results = cargoes
              .map((cargo, index) => {
                return { index, cargo };
              })
              .filter(
                (i) =>
                  i.cargo.name.toLowerCase().includes(query) ||
                  (i.cargo.sku ? i.cargo.sku.toLowerCase().includes(query) : false) ||
                  (i.cargo.data.shipment_id
                    ? i.cargo.data.shipment_id.toLowerCase().includes(query)
                    : false)
              );
            cargoes = cargoes.filter((_, index) => !results.find((c) => c.index == index));
            return results.map((c) => c.cargo);
          });
        }
      }
      return this.miscStore.cargoes;
    },
  },
  data() {
    return {
      lengthDims: [
        { text: 'Millimeters', value: 'MM' },
        { text: 'Centimeters', value: 'CM' },
        { text: 'Decimeters', value: 'DM' },
        { text: 'Meters', value: 'M' },
        { text: 'Inches', value: 'IN' },
        { text: 'Feet', value: 'FT' },
      ],
      weightDims: [
        { text: 'Kilograms', value: 'KG' },
        { text: 'Tonnes', value: 'MT' },
        { text: 'Pounds', value: 'LB' },
      ],
      length_dim: undefined,
      weight_dim: undefined,
      conversion: {
        showDialog: false,
        factor: 1.0,
        length: true,
      },
      loading: false,
      selected: [] as Cargo[],
      showEditDialog: false,
      showDeleteDialog: false,
      showExportDialog: false,
      showImportDialog: false,
      showParseExcelModal: false,
      dropFiles: [],
      importObjects: [],
      multisearch: true,
      search: '',
      editItem: null as Cargo,
      hasUsedPreSelected: false,
      errorMessage: '',
      displayError: false,
      dimensionRules: [(v: string) => !!v || 'Dimension is required'],
    };
  },
  watch: {
    preSelected: {
      handler(val): void {
        if (!this.hasUsedPreSelected) {
          this.selected = this.filteredCargoes.filter((c) => val.includes(c.name));
          this.hasUsedPreSelected = true;
        }
      },
      immediate: true,
    },
    selected: {
      handler(changed: Cargo[], old): void {
        // Items added
        const sortedChanges = this.filteredCargoes.filter((cargo) =>
          changed.find((c) => c.id === cargo.id)
        );

        const added = sortedChanges.filter((r: Cargo) => old.indexOf(r) < 0);
        if (added.length > 0) {
          this.$emit('addItems', added);
        }
        // Items removed
        const removed = old.filter((r: any) => changed.indexOf(r) < 0);
        if (removed.length > 0) {
          this.$emit('removeItems', removed);
        }
      },
    },
  },
  mounted() {},
  methods: {
    holdForItem(cargo: Cargo): HoldData {
      const item = {
        ...cargo.data,
      } as HoldItem;
      const c = cargo.length_dim ? this.$toSI(cargo.length_dim) : 1.0;
      item.L = c * item.l;
      item.W = c * item.w;
      item.H = c * item.h;
      item.WT = item.wt;
      item.pos = { x: item.L * 0.5, y: item.W * 0.5, z: item.H * 0.5 };
      item.qty = 1;

      const h = {
        items: [item],
        L: item.L,
        W: item.W,
        H: item.H,
        no_end_walls: true,
        no_side_walls: true,
        no_roof: true,
      } as HoldData;

      return h;
    },
    openFileImporter(): void {
      (this.$refs.fileInput as any).click();
    },
    toLength(l: number, dim: LengthDim): number {
      if (dim) {
        return this.$options.filters.toLength(l * this.$toSI(dim), false);
      } else {
        return l;
      }
    },
    toWeight(wt: number, dim: WeightDim): number {
      if (dim) {
        return this.$options.filters.toWeight(wt * this.$toSI(dim), false);
      } else {
        return wt;
      }
    },
    showConvertDialog(dim: LengthDim | WeightDim, item: Cargo, length = true) {
      if (length) {
        if (item.length_dim) {
          this.conversion.factor = this.$toSI(item.length_dim) / this.$toSI(dim);
          this.conversion.showDialog = true;
          this.conversion.length = true;
        }
        item.length_dim = dim as LengthDim;
      } else {
        if (item.weight_dim) {
          this.conversion.factor = this.$toSI(item.weight_dim) / this.$toSI(dim);
          this.conversion.showDialog = true;
          this.conversion.length = false;
        }
        item.weight_dim = dim as WeightDim;
      }
    },
    deleteItems(): void {
      this.loading = true;
      API.deleteCargoes({ ids: this.selected.map((i) => i.id) })
        .then((response) => {
          this.loading = false;
          this.miscStore.downloadCargoes();
          this.selected = [];
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    exportItems(items?: Cargo[]): void {
      this.loading = true;
      const loadExcelJS = () => import('exceljs');
      loadExcelJS()
        .then((exceljs) => {
          const excelService = new ExcelService();

          const workbook = excelService.cargoToXlsx(new exceljs.Workbook(), {
            items: items || this.miscStore.cargoes,
            headers: this.headers.slice(2).map((h) => {
              return { key: h.value, value: h.text, dimension: h.dimension };
            }),
            toLength: this.toLength,
            toWeight: this.toWeight,
          });

          this.saveLoadlistFile(`Cargo-Library export ${new Date().toDateString()}.xlsx`, workbook);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    async saveLoadlistFile(fileName: string, workbook: Workbook): Promise<void> {
      const xls64 = await workbook.xlsx.writeBuffer();
      FileSaver.saveAs(
        new Blob([xls64], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        fileName
      );
    },
    save(): void {
      this.loading = true;
      const func = this.editItem.id ? API.updateCargo : API.createCargo;

      func({
        ...this.editItem,
        name: this.editItem.data.label,
      })
        .then((response) => {
          this.loading = false;
          this.miscStore.downloadCargoes();
        })
        .catch((e) => {
          if (e?.response?.data) {
            this.errorMessage = getSerializerError(e.response.data);
            this.displayError = true;
          }
          this.loading = false;
        });

      this.showEditDialog = false;
    },
    resetFile(e: any): void {
      e.target.value = null;
    },
    selectedFile(e: any): void {
      if (e.target.files && e.target.files[0]) {
        this.dropFiles = [e.target.files[0]];
      }
      this.showParseExcelModal = true;
    },
    bulkCreate(): void {
      const objects = this.$refs.inputTable.getObjects() as any;
      API.createCargoes(
        objects.map((x: any) => {
          return {
            name: x.label,
            sku: x.sku,
            data: { ...x, qty: undefined },
            length_dim: this.length_dim,
            weight_dim: this.weight_dim,
          };
        })
      )
        .then((_) => {
          this.showImportDialog = false;
          this.loading = false;
          this.importObjects = [];
          this.miscStore.downloadCargoes();
        })
        .catch((e) => {
          if (e?.response?.data) {
            this.errorMessage = getSerializerError(e.response.data);
            this.displayError = true;
          }
          this.loading = false;
        });
    },
  },
});
</script>

<style scoped>
.cargo-lib-table table {
  overflow-x: scroll;
}
.pre {
  white-space: pre-wrap;
}
.dim {
  color: #999;
  cursor: pointer;
}
</style>
