<template>
  <v-container>
    <v-card>
      <v-card-title>
        <p class="text-h6">Units</p>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              label="Length unit"
              v-model="length_dim"
              :items="['MM', 'CM', 'DM', 'M', 'IN']"></v-select>
          </v-col>
          <v-col>
            <v-select
              label="Default data length unit"
              hint="When creating a new load list - automatically set the length dimension to this value"
              v-model="default_length_dim"
              :items="[null, 'MM', 'CM', 'DM', 'M', 'IN', 'FT']"
              clearable></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select label="Weight unit" v-model="weight_dim" :items="['KG', 'LB']"></v-select>
          </v-col>
          <v-col>
            <v-select
              label="Default data weight unit "
              hint="When creating a new load list - automatically set the weight dimension to this value"
              v-model="default_weight_dim"
              :items="['KG', 'MT', 'LB']"
              clearable></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title>
        <p class="text-h6">Graphics</p>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-switch v-model="darkMode" label="Dark mode"></v-switch>
            <v-select
              label="Default graphics view"
              v-model="default_view"
              :items="[
                { text: 'Top', value: 'top' },
                { text: 'Side', value: 'side' },
                { text: 'Front', value: 'front' },
                { text: '3D', value: '3d' },
              ]"></v-select>
            <v-checkbox
              label="Auto assign colors to consignments"
              v-model="autoGroupColors"
              hint="If enabled then all items belonging to same consignment / group will be assigned the same color"
              persistent-hint></v-checkbox>

            <v-checkbox
              label="Display secondary equipments as cargoes"
              v-model="displayNestedItemAsCargo"
              hint="If enabled then secondary equipments, such as pallets, will be shown as a single cargo"
              persistent-hint></v-checkbox>
            <v-checkbox
              label="Hide labels on cargoes"
              v-model="hideLabels"
              hint="Hiding the text label on cargoes can lead to cleaner and a more visually appealing image"
              persistent-hint></v-checkbox>
            <v-select
              class="mt-2"
              v-model="itemLabels"
              item-text="displayValue"
              item-value="key"
              required
              :rules="requiredRules"
              :disabled="hideLabels"
              :items="cargoLabels"
              :menu-props="{ top: false, offsetY: true }"
              multiple
              attach
              chips
              label="Cargo labels"></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- <v-card-title>
        <p class="title">Misc</p>
      </v-card-title> -->
    </v-card>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { UserPreferences } from '@/models/UserCompanyModel';

export default Vue.extend({
  data() {
    return {
      requiredRules: [(v: string[]) => v.length > 0 || 'At least one label is required'],
      cargoLabels: [
        { key: 'sku', displayValue: 'SKU' },
        { key: 'label', displayValue: 'Name' },
        { key: 'class_id', displayValue: 'Class' },
        { key: 'lwh', displayValue: 'LxWxH' },
        { key: 'wt', displayValue: 'Weight' },
        { key: 'shipment_id', displayValue: 'Group/Consignment' },
        { key: 'priority', displayValue: 'Priority' },
      ],
    };
  },
  computed: {
    ...mapStores(useMiscStore),
    length_dim: {
      get(): string {
        return this.preferences.length_dim;
      },
      set(value: string): void {
        this.updateMe({ preferences: { length_dim: value } });
      },
    },
    default_length_dim: {
      get(): string {
        return this.preferences.default_loadlist_length_dim;
      },
      set(value: string): void {
        this.updateMe({
          preferences: { default_loadlist_length_dim: value },
        });
      },
    },
    weight_dim: {
      get(): string {
        return this.preferences.weight_dim;
      },
      set(value: string): void {
        this.updateMe({ preferences: { weight_dim: value } });
      },
    },
    default_weight_dim: {
      get(): string {
        return this.preferences.default_loadlist_weight_dim;
      },
      set(value: string): void {
        this.updateMe({
          preferences: { default_loadlist_weight_dim: value },
        });
      },
    },
    default_view: {
      get(): string {
        return this.preferences.default_view || '3d';
      },
      set(value: string): void {
        this.updateMe({ preferences: { default_view: value } });
      },
    },
    hideLabels: {
      get(): boolean {
        return !!this.preferences.hide_labels;
      },
      set(value: boolean): void {
        this.updateMe({ preferences: { hide_labels: value } });
      },
    },
    itemLabels: {
      get(): string[] {
        return this.preferences.item_labels;
      },
      set(value: string[]): void {
        if (value.length > 0) {
          this.updateMe({ preferences: { item_labels: value } });
        }
      },
    },
    displayNestedItemAsCargo: {
      get(): boolean {
        return !!this.preferences.displayNestedItemAsCargo;
      },
      set(value: boolean): void {
        this.updateMe({ preferences: { displayNestedItemAsCargo: value } });
      },
    },
    autoGroupColors: {
      get(): boolean {
        return !!this.preferences.autoGroupColors;
      },
      set(value: boolean): void {
        this.updateMe({ preferences: { autoGroupColors: value } });
      },
    },
    preferences(): UserPreferences {
      return this.miscStore.preferences;
    },
    darkMode: {
      get(): boolean {
        return !!this.preferences.darkMode;
      },
      set(value: boolean): void {
        this.$vuetify.theme.dark = !!value;
        this.updateMe({ preferences: { darkMode: value } });
      },
    },
  },
  methods: {
    updateMe(updateUserObject: any): void {
      this.miscStore.updateMe(updateUserObject);
    },
  },
});
</script>

<style scoped></style>
