import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,[_c(VAlert,{attrs:{"type":"info","outlined":""}},[_vm._v("Set default values for data input in 1. Data view")]),_c(VRow,_vm._l((_vm.defaultsSelection),function(i){return _c(VCol,{key:i.key,attrs:{"cols":"6"}},[(i.input === 'checkbox')?_c(VCheckbox,{attrs:{"label":i.text},model:{value:(_vm.item[i.key]),callback:function ($$v) {_vm.$set(_vm.item, i.key, $$v)},expression:"item[i.key]"}}):(i.input === 'select')?_c(VSelect,{attrs:{"items":i.values,"label":i.text},model:{value:(_vm.item[i.key]),callback:function ($$v) {_vm.$set(_vm.item, i.key, $$v)},expression:"item[i.key]"}}):(i.input === 'orientations_picker')?_c('orientation-picker',{attrs:{"item":{}},model:{value:(_vm.item[i.key]),callback:function ($$v) {_vm.$set(_vm.item, i.key, $$v)},expression:"item[i.key]"}}):_c(VTextField,{attrs:{"label":i.text,"type":"number"},model:{value:(_vm.item[i.key]),callback:function ($$v) {_vm.$set(_vm.item, i.key, _vm._n($$v))},expression:"item[i.key]"}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }