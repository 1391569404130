<template>
  <v-container>
    <subscription>
      <v-row v-if="showPlans">
        <v-card-text align="center" class="pt-0">
          <v-btn-toggle v-model="subscription_show_monthly" mandatory v-if="!customPriceId">
            <v-btn
              :class="{
                primary: !subscription_show_monthly,
                'white--text': !subscription_show_monthly,
              }"
              :value="false"
              >Billed yearly</v-btn
            >
            <v-btn
              :class="{
                primary: subscription_show_monthly,
                'white--text': subscription_show_monthly,
              }"
              :value="true"
              >Billed monthly</v-btn
            >
          </v-btn-toggle>
        </v-card-text>

        <v-card-text class="pb-6 pt-4">
          <v-row class="limit-width mx-auto">
            <v-col class="limit-width-card" v-for="plan in visibleSubscriptionPlans" :key="plan.id">
              <v-card
                class="d-flex flex-column"
                :class="{
                  highlight: plan.product.metadata.plan === 'standard',
                }"
                height="100%">
                <v-card-title class="text-capitalize justify-center">{{
                  plan.product.metadata.plan
                }}</v-card-title>

                <v-card-text v-if="plan.product.description">
                  <p v-for="feature in plan.product.description.split(',')" :key="feature">
                    <v-icon color="primary">mdi-check</v-icon> {{ feature }}
                  </p>
                </v-card-text>
                <v-card-text v-if="plan.tiers">
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th>Calculations/month</th>
                        <th>Unit price ({{ plan.currency | uppercase }})</th>
                        <th>Fixed fee/{{ plan.recurring.interval }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(tier, index) in plan.tiers" :key="index">
                        <td>
                          <span v-if="index === 0">0-</span>
                          <span v-else-if="index == plan.tiers.length - 1"
                            >{{ plan.tiers[index - 1].up_to + 1 }} -</span
                          >
                          <span v-else
                            ><span v-if="plan.tiers_mode === 'graduated'">Next </span
                            >{{ plan.tiers[index - 1].up_to + 1 }} -
                          </span>
                          {{ tier.up_to }}
                        </td>
                        <td>{{ tier.unit_amount | currency }}</td>
                        <td>
                          {{ plan.currency | uppercase }}
                          {{ tier.flat_amount | currency }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
                <v-spacer></v-spacer>

                <v-card-text class="text-center" v-if="plan.unit_amount">
                  <span class="price-tag font-weight-bold">
                    {{ plan.currency | uppercase }}
                    {{
                      plan.recurring.interval === 'year'
                        ? plan.unit_amount / 12
                        : plan.unit_amount | currency
                    }}</span
                  >
                  <span> /month</span>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    block
                    @click="openPaymentMethodSelection(plan)"
                    :class="{
                      primary: plan.product.metadata.plan === 'standard',
                    }"
                    >Start subscription</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col v-if="!customPriceId" class="limit-width-card">
              <v-card class="d-flex flex-column" height="100%">
                <v-card-title class="text-capitalize justify-center">Enterprise</v-card-title>
                <v-card-text>
                  <p><v-icon color="primary">mdi-check</v-icon> Single Sign On</p>
                  <p><v-icon color="primary">mdi-check</v-icon> Audit trails</p>
                  <p><v-icon color="primary">mdi-check</v-icon> SLA</p>
                  <p>
                    <v-icon color="primary">mdi-check</v-icon> Great discounts for multiple branches
                  </p>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="justify-center">
                  <v-btn block href="mailto:support@cargo-planner.com">Contact us</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-row>
      <v-card-actions v-if="showCustomerPortalButton" class="mt-5">
        <v-btn @click="updateSubscription" class="primary" :loading="isLoadingSubscriptionPortal"
          ><v-icon>mdi-credit-card-outline</v-icon>Update your subscription</v-btn
        >
      </v-card-actions>
    </subscription>

    <v-row>
      <v-col>
        <v-card class="mt-3">
          <v-card-title>
            <p class="text-h6">Company details</p>
          </v-card-title>
          <v-card-text>
            <v-form class="mt-4" v-model="validForm" ref="companyForm" lazy-validation>
              <v-text-field
                :error-messages="companyFormErrors.name"
                required
                label="Company name"
                v-model="companyData.name"
                :rules="requiredRule('Company name is required')"></v-text-field>

              <v-text-field
                :error-messages="companyFormErrors.street_name"
                label="Street name:"
                v-model="companyData.street_name"
                :rules="requiredRule('Street name is required')"></v-text-field>

              <v-row>
                <v-col>
                  <v-text-field
                    :error-messages="companyFormErrors.postal_code"
                    label="Postal code:"
                    v-model="companyData.postal_code"
                    :rules="requiredRule('Postal code is required')"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    :error-messages="companyFormErrors.city"
                    label="City:"
                    v-model="companyData.city"
                    :rules="requiredRule('City is required')"></v-text-field>
                </v-col>
              </v-row>
              <v-select
                required
                :error-messages="companyFormErrors.country"
                v-model="companyData.country"
                :items="countriesList"
                label="Select country"
                :rules="requiredRule('Country is required')"
                single-line></v-select>

              <v-select
                v-if="countryCode === 'US'"
                required
                :error-messages="companyFormErrors.state"
                v-model="companyData.state"
                :items="usStates"
                label="Select state"
                :rules="requiredRule('State is required')"
                single-line></v-select>
              <v-text-field
                v-else
                :error-messages="companyFormErrors.state"
                label="State / region / province:"
                v-model="companyData.state"></v-text-field>

              <v-text-field
                :error-messages="companyFormErrors.vat"
                label="VAT:"
                required
                v-if="vatRequired"
                :rules="requiredRule('VAT is required')"
                v-model="companyData.vat"></v-text-field>

              <v-btn block @click="updateCompanyDetails" :loading="isLoading">
                <v-icon>mdi-sync</v-icon> Update
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card></v-col
      >
      <v-col>
        <v-card class="mt-3">
          <v-card-title><p class="text-h6">Logo</p></v-card-title>
          <v-card-text class="text-center">
            <img v-if="logo_url" id="logoPreview" :src="logo_url" width="50%" alt="No logo added" />

            <v-file-input accept="image/*" label="File input" @change="logoChange"></v-file-input>
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" block v-if="logo_file" @click="uploadLogo">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showWaitingForPayment" v-if="!valid_subscription">
      <v-card>
        <v-card-title class="text-h5">Updating subscription...</v-card-title>
        <v-card-text>
          <p>
            If your subscription has not been updated, please contact us through email or by the
            chat below
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block @click.stop="showWaitingForPayment = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar :timeout="5000" v-model="showSnackbar" top>
      Information updated
      <v-btn text color="primary" @click.native="showSnackbar = false">Ok</v-btn>
    </v-snackbar>
    <v-snackbar :timeout="5000" :value="stripeError" top>
      {{ stripeError }}
      <v-btn
        text
        color="error"
        @click.native="
          stripeError = null;
          $vuetify.goTo($refs.companyForm.$el);
        "
        >Ok</v-btn
      >
    </v-snackbar>
    <payment-method-selection-modal
      :visible="showPaymentMethodSelection"
      :selectedPlan="selectedPlan"
      :companyData="companyData"
      @useCredit="startStripeSubscription(selectedPlan.id)"
      @close="closePaymentMethodSelection()"></payment-method-selection-modal>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue';
import API from '@/API';
import countryList from '@/misc/countries';
import { loadStripe } from '@stripe/stripe-js/pure';
import Subscription from '@/components/Custom/SubscriptionInfo.vue';
import PaymentMethodSelectionModal from '@/components/Modals/PaymentMethodSelection.vue';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import {
  CompanyStoreTemplate,
  User,
  SubscriptionPlan,
  CompanySerializer,
} from '@/models/UserCompanyModel';
import { Stripe } from '@stripe/stripe-js';

let stripePromise: Stripe = null;

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        companyForm: any;
      };
    }
  >
).extend({
  name: 'companysettings',
  components: {
    Subscription,
    PaymentMethodSelectionModal,
  },
  data() {
    return {
      isLoading: false,
      isLoadingSubscriptionPortal: false,
      companyData: {
        name: null,
        street_name: null,
        postal_code: null,
        city: null,
        state: null,
        country: null,
        vat: null,
      } as CompanySerializer,
      companyFormErrors: {} as any,
      showSnackbar: false,
      showCustomerPortalButton: false,
      showWaitingForPayment: false,
      stripeError: null as string,
      logo_file: null as File,
      logo_url: '',
      validForm: false,
      subscription_plans: [] as SubscriptionPlan[],
      subscription_show_monthly: false,
      startCheckoutAfterCompanyUpdate: null as SubscriptionPlan,
      showPaymentMethodSelection: false,
      selectedPlan: null as SubscriptionPlan,
    };
  },
  watch: {
    company() {
      this.getCompanyDetails();
    },
  },
  mounted() {
    this.getCompanyDetails();

    if (!this.valid_subscription && this.$route.query.progress) {
      this.showWaitingForPayment = true;
    }
    API.getSubscriptionPlans(this.customPriceId).then((response) => {
      this.subscription_plans = response.data;
    });
  },
  computed: {
    ...mapStores(useMiscStore),
    countryCode(): string {
      return Object.keys(countryList.countries).find(
        (key) => countryList.countries[key] === this.companyData.country
      );
    },
    countriesList(): string[] {
      return Object.values(countryList.countries);
    },
    usStates(): string[] {
      return countryList.usStates.map((i) => i.name);
    },
    vatRequired(): boolean {
      return countryList.isEuCountry(this.companyData.country);
    },
    valid_subscription(): boolean {
      return this.company.valid_subscription;
    },
    customPriceId(): string {
      return this?.$route?.query?.price_id as string;
    },
    visibleSubscriptionPlans(): SubscriptionPlan[] {
      if (this.customPriceId) {
        return this.subscription_plans.filter((i) => i.id === this.customPriceId);
      }
      return this.subscription_plans
        .filter((i) =>
          this.subscription_show_monthly
            ? i.recurring.interval === 'month'
            : i.recurring.interval === 'year'
        )
        .sort((a, b) => a.unit_amount - b.unit_amount);
    },
    showPlans(): boolean {
      return !this.valid_subscription || this.company.plan === '1' || !!this.customPriceId;
    },
    company(): CompanyStoreTemplate {
      return this.miscStore.company;
    },
    user(): User {
      return this.miscStore.user;
    },
  },
  methods: {
    requiredRule(errorMessage: string): ((v: string) => string | true)[] {
      return [(v: string) => !!v || errorMessage];
    },
    getCompanyDetails(): void {
      if (!this.company.id) return;
      API.getCompany(this.company.id).then((response) => {
        if (response.data.logo) {
          this.logo_url = response.data.logo;
          delete response.data['logo'];
        }
        this.companyData = response.data;
        this.$refs.companyForm.resetValidation();
      });
      API.hasActiveStripeSubscription().then((response) => {
        this.showCustomerPortalButton = response.data.status;
      });
    },
    logoChange(file: File): void {
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.logo_url = e.target.result as string;
        };
        reader.readAsDataURL(file);
        this.logo_file = file;
      } else {
        this.logo_file = null;
      }
    },
    uploadLogo(e: any): void {
      if (!this.logo_file) return;
      const fd = new FormData();
      fd.append('logo', this.logo_file);

      API.updateCompany(this.company.id, fd)
        .then((success) => {
          this.showSnackbar = true;
        })
        .catch((error) => {});
    },
    updateCompanyDetails(): void {
      if (!this.vatRequired) {
        this.companyData.vat = null;
      }
      if (this.$refs.companyForm.validate()) {
        this.companyFormErrors = {};
        API.updateCompany(this.company.id, this.companyData)
          .then((success) => {
            this.showSnackbar = true;
            if (this.startCheckoutAfterCompanyUpdate)
              this.openPaymentMethodSelection(this.startCheckoutAfterCompanyUpdate);
          })
          .catch((error) => {
            if (error.response && error.response.data && error.response.data) {
              this.companyFormErrors = error.response.data;
            }
          });
      }
    },
    async startStripeSubscription(price_id: number): Promise<void> {
      this.stripeError = null;
      if (this.$refs.companyForm.validate()) {
        if (!stripePromise)
          stripePromise = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);

        API.startStripeSubscription(price_id)
          .then((response) => {
            stripePromise
              .redirectToCheckout({
                sessionId: response.data.id,
              })
              .then((result: any) => {});
          })
          .catch((error) => {
            if (error.response && error.response.data && error.response.data.user_message)
              this.stripeError = error.response.data.user_message;
          });
      } else {
        this.$vuetify.goTo(this.$refs.companyForm.$el);
        this.startCheckoutAfterCompanyUpdate = this.selectedPlan;
      }
    },
    updateSubscription(): void {
      if (this.$refs.companyForm.validate()) {
        this.isLoadingSubscriptionPortal = true;
        API.stripePortal()
          .then((response) => {
            window.location.replace(response.data.url);
          })
          .catch((error) => {
            this.isLoadingSubscriptionPortal = false;
          });
      } else {
        this.$vuetify.goTo(this.$refs.companyForm.$el);
      }
    },
    openPaymentMethodSelection(plan: SubscriptionPlan): void {
      if (this.$refs.companyForm.validate()) {
        this.selectedPlan = plan;

        if (this.customPriceId) {
          this.startStripeSubscription(this.selectedPlan.id);
        } else {
          this.showPaymentMethodSelection = true;
        }
      } else {
        this.$vuetify.goTo(this.$refs.companyForm.$el);
        this.startCheckoutAfterCompanyUpdate = plan;
      }
    },
    closePaymentMethodSelection(): void {
      this.showPaymentMethodSelection = false;
      this.selectedPlan = null;
    },
  },
  filters: {
    currency(val: number): string | number {
      const convertedVal = val * 0.01;
      return convertedVal % 1 !== 0 ? convertedVal.toFixed(2) : convertedVal;
    },
    uppercase(val: string): string {
      return val.toUpperCase();
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.limit-width {
  max-width: 1500px;
}
.limit-width-card {
  max-width: 500px;
}
.v-btn-toggle button::before {
  opacity: 0;
}
.v-card__title {
  font-size: 1.75em;
}
.highlight {
  border: 2px solid #4678b2;
}
.highlight .v-card__title {
  font-size: 2em;
  color: #32629a;
}
.highlight .price-tag {
  color: #32629a;
}
.price-tag {
  font-size: 3em;
}
</style>
