import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"scrollable":"","width":"1200"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VSnackbar,{attrs:{"timeout":5000,"bottom":""},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" Column settings updated ")]),_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.show = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VToolbarTitle,[_vm._v("Visible columns")])],1),_c(VContainer,_vm._l((_vm.columns),function(column){return _c(VChip,{key:column.key,staticClass:"ma-2",attrs:{"label":"","color":_vm.selectedColumns.includes(column.key) ? 'primary' : 'gray'},on:{"click":function($event){return _vm.toggleColumn(column.key)}}},[_vm._v(" "+_vm._s(column.title)+" ")])}),1),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();_vm.show = false}}},[_vm._v("Close")]),_c(VSpacer),_c(VBtn,{staticClass:"primary",on:{"click":function($event){$event.stopPropagation();return _vm.update.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-autorenew")]),_vm._v("Update")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }