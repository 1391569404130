<template>
  <v-dialog v-model="show" class="no-print" :transition="false" width="600">
    <v-card>
      <v-card-title class="text-h5">New loadlist</v-card-title>

      <v-card-text>
        <slot name="description"> </slot>
        <v-form @submit.prevent="create" id="new-loadlist-form" v-model="newLoadlistValid">
          <v-text-field
            label="Name"
            v-model="new_loadlist.name"
            :rules="nameRules"
            required></v-text-field>

          <v-select
            v-model="new_loadlist.list_type"
            v-bind:items="$list_types"
            required
            label="Select type of loadlist"></v-select>
          <v-select
            v-model="new_loadlist.group"
            item-value="id"
            item-text="name"
            v-bind:items="groups"
            required
            label="Project (Optional)"></v-select>
          <v-dialog
            ref="dialog"
            v-model="showEtdDialog"
            :return-value.sync="etd"
            persistent
            width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="new_loadlist.etd"
                label="ETD"
                readonly
                v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="new_loadlist.etd" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="showEtdDialog = false">Cancel</v-btn>
              <v-btn text color="primary" @click="showEtdDialog = false">OK</v-btn>
            </v-date-picker>
          </v-dialog>

          <v-textarea v-model="new_loadlist.notes" label="Notes (Optional)" outlined></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click.stop="show = false">Close</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          type="submit"
          :disabled="!newLoadlistValid || isLoading"
          :loading="isLoading"
          form="new-loadlist-form"
          >Create</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import API from '@/API';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
import {
  HoldData,
  HoldInputItem,
  LengthDim,
  ListType,
  LoadlistGroup,
  LoadlistResult,
  WeightDim,
} from '@/models/LoadlistModel';
import { useMiscStore } from '@/stores/miscStore';
export default Vue.extend({
  props: {
    visible: Boolean,
    group: {
      type: Number,
      default: null,
    },
    etd: {
      type: String,
      default: null,
    },
    length_dim: {
      type: String as PropType<LengthDim>,
      default: () => {
        return useMiscStore().preferences?.default_loadlist_length_dim || 'M';
      },
    },
    weight_dim: {
      type: String as PropType<WeightDim>,
      default: () => {
        return useMiscStore().preferences?.default_loadlist_weight_dim || 'KG';
      },
    },
    // data: {
    //   type: Array as PropType<HoldInputItem[]>,
    //   default: () => {
    //     return [] as HoldInputItem[];
    //   },
    // },
    // result: {
    //   type: Object as PropType<LoadlistResult | null>,
    //   default: () => {
    //     return null as LoadlistResult;
    //   },
    // },
  },
  computed: {
    ...mapStores(useMiscStore, useLoadlistStore),
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    groups(): LoadlistGroup[] {
      return [{ name: '(Default)', id: null, private: true, data: {} }, ...this.loadlistGroups];
    },
    loadlistGroups(): LoadlistGroup[] {
      return this.loadlistStore.loadlistGroups;
    },
  },
  watch: {
    group: {
      handler: function (a): void {
        this.new_loadlist.group = a;
      },
      immediate: true,
    },
    etd: {
      handler: function (a): void {
        this.new_loadlist.etd = a;
      },
      immediate: true,
    },
  },
  data() {
    return {
      isLoading: false,
      showEtdDialog: false,
      nameRules: [
        (v: string) => !!v || 'Name is required',
        (v: string) => v.length <= 50 || 'Name must be less than 50 characters',
      ],
      newLoadlistValid: false,
      new_loadlist: {
        name: '',
        list_type: (localStorage.getItem('cplLastLoadlistType') || 'SEA') as ListType,
        length_dim: useMiscStore().preferences?.default_loadlist_length_dim || undefined,
        weight_dim: useMiscStore().preferences?.default_loadlist_weight_dim || undefined,
        group: null as number,
        etd: null as string,
        data: [] as HoldData[],
        notes: '',
      },
    };
  },
  methods: {
    extendLoadlist(o: {
      length_dim?: string;
      weight_dim?: string;
      data?: HoldData[];
      result?: LoadlistResult;
    }): void {
      this.new_loadlist = { ...this.new_loadlist, ...o };
    },
    create(): void {
      this.isLoading = true;

      API.createLoadlist(this.new_loadlist)
        .then((data) => {
          localStorage.setItem('cplLastLoadlistType', this.new_loadlist.list_type);

          this.isLoading = false;
          this.show = false;

          this.$emit('created', data.data.id);
        })
        .catch((error) => {
          console.log('Error creating loadlist');
          this.isLoading = false;
        });
    },
  },
});
</script>
