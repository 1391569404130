import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"mb-4",attrs:{"outlined":""}},[_c(VCardText,[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,[_c(VChip,[_vm._v("1")]),_c('span',{staticClass:"ml-2"},[_vm._v("Step 1 - Select items you want to include")])],1)],1),_c(VRow,{attrs:{"align":"center"}},[(_vm.rule.items)?_c(VCol,[_c('item-selector-component',{attrs:{"items":_vm.items,"class_ids":_vm.class_ids},model:{value:(_vm.rule.items),callback:function ($$v) {_vm.$set(_vm.rule, "items", $$v)},expression:"rule.items"}})],1):_vm._e()],1),_c(VRow,[_c(VIcon,{staticClass:"mx-auto",attrs:{"x-large":""}},[_vm._v("mdi-arrow-down-bold")])],1),_c(VRow,{attrs:{"align":"center"}},[_c(VCol,[_c(VChip,[_vm._v("2")]),_c('span',{staticClass:"ml-2"},[_vm._v("Step 2 - Select your condition")])],1)],1),_c(VRow,[_c(VCol,{staticClass:"d-md-flex"},[_c(VSelect,{attrs:{"items":_vm.itemRules,"menu-props":{ maxHeight: '420' },"label":"Condition"},on:{"change":() => {
              _vm.rule.value = undefined;
            }},model:{value:(_vm.rule.condition),callback:function ($$v) {_vm.$set(_vm.rule, "condition", $$v)},expression:"rule.condition"}}),(_vm.rule.condition === 'max_qty')?_c(VTextField,{staticClass:"ml-4",attrs:{"label":"Quantity","min":"0","step":"1","type":"number"},model:{value:(_vm.rule.value),callback:function ($$v) {_vm.$set(_vm.rule, "value", _vm._n($$v))},expression:"rule.value"}}):_vm._e(),(_vm.rule.condition === 'must_load_with_pattern')?_c(VSelect,{staticClass:"ml-4",attrs:{"items":[
            { text: 'One block', value: 'one_block' },
            { text: 'Two block', value: 'two_block' },
            { text: 'Four block', value: 'four_block' },
            { text: 'No pattern building', value: 'one_by_one' },
          ],"label":"Pattern","hint":"The higher block values includes the simpler variants. Two block can result in a one block solution","persistent-hint":""},model:{value:(_vm.rule.value),callback:function ($$v) {_vm.$set(_vm.rule, "value", $$v)},expression:"rule.value"}}):_vm._e(),(['can_only_support', 'cannot_support'].includes(_vm.rule.condition))?_c('item-selector-component',{staticClass:"ml-0 ml-md-4",attrs:{"items":_vm.items,"class_ids":_vm.class_ids},model:{value:(_vm.rule.value),callback:function ($$v) {_vm.$set(_vm.rule, "value", $$v)},expression:"rule.value"}}):_vm._e()],1)],1),_c(VRow,[_c(VIcon,{staticClass:"mx-auto",attrs:{"x-large":""}},[_vm._v("mdi-arrow-down-bold")])],1),_c(VRow,{attrs:{"align":"center"}},[_c(VCol,[_c(VChip,[_vm._v("3")]),_c('span',{staticClass:"ml-2"},[_vm._v("Step 3 - Select "+_vm._s(_vm.typeName)+"s you want to include. Rule will be applied to all "+_vm._s(_vm.typeName)+"s if no "+_vm._s(_vm.typeName)+"s are selected")])],1)],1),_c(VRow,[_c(VCol,[_c(VSelect,{attrs:{"items":_vm.holds,"item-text":"name","item-value":"id","menu-props":{ maxHeight: '400' },"placeholder":`Any ${_vm.typeName}s`,"label":`${_vm.capitalizedTypeName}s`,"persistent-placeholder":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index < _vm.rule.in_containers_with_ids.length - 2)?_c('span',[_vm._v(_vm._s(item.name)+", ")]):(index === _vm.rule.in_containers_with_ids.length - 2)?_c('span',[_vm._v(_vm._s(item.name)+" or ")]):_c('span',[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.rule.in_containers_with_ids),callback:function ($$v) {_vm.$set(_vm.rule, "in_containers_with_ids", $$v)},expression:"rule.in_containers_with_ids"}})],1)],1),_c(VRow,{staticClass:"text-right"},[_c(VCol,[_c(VBtn,{staticClass:"mx-auto",attrs:{"color":"error"},on:{"click":function($event){return _vm.$emit('removeRule', _vm.ruleIndex)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-minus-circle")]),_vm._v("Remove rule ")],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }