<template>
  <div>
    <v-expansion-panels focusable>
      <v-expansion-panel
        v-for="company in companies"
        @change="updateSelectedCompany(company)"
        :key="company.id">
        <v-expansion-panel-header>
          <span>
            <v-icon>mdi-domain</v-icon>
            {{ company.name }}
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="elevation-1">
          <v-card class="mx-auto text-center" max-width="1000" v-if="company_stats.length">
            <v-card-text>
              <v-sheet class="v-sheet--offset mx-auto" max-width="calc(100% - 32px)">
                <v-sparkline
                  :labels="company_stats.map((i) => `${i.month} ${i.total}`)"
                  :value="company_stats.map((i) => i.total)"
                  color="primary"
                  line-width="2"
                  stroke-linecap="round"
                  padding="16"
                  smooth
                  label-size="5"></v-sparkline>
              </v-sheet>
            </v-card-text>
            <v-card-text>
              <div class="text-h4 font-weight-thin">Number of calculations</div>
              <div class="">Total within the company past 6 months</div>
            </v-card-text>
          </v-card>
          <v-data-table
            v-if="user.is_admin"
            v-bind:items="users"
            hide-default-footer
            class="elevation-2 mt-3"
            no-data-text="No users"
            :items-per-page="100">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Users</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>

                <v-dialog v-model="showAddUserModal" width="600">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mb-2"
                      :disabled="createUsersDisabled"
                      @click="error_messages = []"
                      v-on="on">
                      <v-icon>mdi-plus</v-icon>Add
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">Create user</v-card-title>
                    <v-card-text>
                      <v-alert type="info" outlined
                        >An email will be sent to the user with below login credentials</v-alert
                      >
                      <v-form>
                        <v-text-field
                          label="Username"
                          :error-messages="new_user_errors.username"
                          v-model="new_user.username"
                          min="4"
                          maxlength="30"
                          required></v-text-field>
                        <v-text-field
                          label="Password (leave empty to auto generate)"
                          :error-messages="new_user_errors.password"
                          v-model="new_user.password"
                          maxlength="30"
                          autocomplete="new-password"
                          type="password"></v-text-field>
                        <v-text-field
                          label="Email"
                          :error-messages="new_user_errors.email"
                          v-model="new_user.email"
                          min="10"
                          maxlength="80"
                          required
                          type="email"></v-text-field>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text @click.stop="showAddUserModal = false">Close</v-btn>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        :disabled="new_user.username.length === 0 || new_user.email.length === 0"
                        @click.stop="createUser"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-dialog v-model="showInviteModal" width="600">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mb-2"
                      :disabled="createUsersDisabled"
                      @click="error_messages = []"
                      v-on="on">
                      <v-icon>mdi-email</v-icon>invite
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">Invite user</v-card-title>
                    <v-card-text>
                      <v-alert type="info" outlined
                        >An email will be sent to the user with an invitation link</v-alert
                      >
                      <v-text-field
                        label="Email"
                        :error-messages="new_user_errors.email"
                        v-model="new_user.email"
                        min="10"
                        maxlength="80"
                        required
                        type="email"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text @click.stop="showInviteModal = false">Close</v-btn>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        :disabled="new_user.email.length === 0"
                        @click.stop="inviteUser"
                        >Send</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="showDeleteModal" width="600">
                  <v-card>
                    <v-card-title class="text-h5">Delete user</v-card-title>
                    <v-card-text>
                      <v-alert type="error" outlined
                        >Do you really want to delete the user? Select a user if you want to
                        transfer created load lists. All load lists created by the user will be
                        deleted if no user is selected for transfer.</v-alert
                      >
                      <v-data-table
                        :headers="transferTableHeaders"
                        :items="transferTableUsers"
                        show-select
                        :single-select="true"
                        hide-default-footer
                        hide-default-header
                        class="elevation-2 mt-3"
                        no-data-text="No users"
                        v-model="selectedTransferUsers"
                        item-key="email"
                        :items-per-page="100">
                      </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        text
                        @click.stop="
                          selected_user = null;
                          selectedTransferUsers = [];
                          showDeleteModal = false;
                        "
                        >Cancel</v-btn
                      >
                      <v-spacer />
                      <v-btn
                        color="error"
                        @click.stop="
                          deleteUser(selected_user, selectedTransferUsers);
                          showDeleteModal = false;
                        "
                        >{{ deleteButtonText }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template slot="header">
              <thead>
                <tr>
                  <th class="text-left">Username</th>
                  <th class="text-left">Email</th>
                  <th class="text-left">Last login</th>
                  <th class="text-left">
                    Editor
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small>mdi-help-circle</v-icon>
                      </template>
                      <span
                        >Users with Editor rights are able to create and modify equipment, presets
                        and projects</span
                      >
                    </v-tooltip>
                  </th>
                  <th class="text-left">
                    Administrator
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small>mdi-help-circle</v-icon>
                      </template>
                      <span
                        >Users with Administrator rights are able to perform user management and
                        handle company details as well as subscriptions</span
                      >
                    </v-tooltip>
                  </th>
                </tr>
              </thead>
            </template>
            <template slot="item" slot-scope="props">
              <tr>
                <td>{{ props.item.username }}</td>
                <td>{{ props.item.email }}</td>
                <td>{{ props.item.last_login }}</td>

                <!-- <td>
                  <v-select
                    :disabled="props.item.id == user.id"
                    :value="props.item.groups[0]"
                    :items="['Administrator', 'Editor', 'User']"
                    @change="
                      updateUser({
                        id: props.item.id,
                        groups: [$event],
                      })
                    "
                    :menu-props="{ maxHeight: '400' }"
                  ></v-select>
                </td> -->
                <td>
                  <v-checkbox
                    :input-value="props.item.groups.includes('Editor')"
                    @change="
                      !!$event
                        ? updateUser({
                            id: props.item.id,
                            groups: ['Editor', ...props.item.groups],
                          })
                        : updateUser({
                            id: props.item.id,
                            groups: props.item.groups.filter((i) => i != 'Editor'),
                          })
                    "></v-checkbox>
                </td>
                <td>
                  <v-checkbox
                    :disabled="props.item.id == user.id"
                    :input-value="props.item.groups.includes('Administrator')"
                    @change="
                      !!$event
                        ? updateUser({
                            id: props.item.id,
                            groups: ['Administrator', ...props.item.groups],
                          })
                        : updateUser({
                            id: props.item.id,
                            groups: props.item.groups.filter((i) => i != 'Administrator'),
                          })
                    "></v-checkbox>
                </td>

                <td>
                  <v-btn
                    icon
                    :disabled="props.item.id === user.id"
                    v-on:click.native="
                      selected_user = props.item;
                      showDeleteModal = true;
                    "
                    ><v-icon>fa-trash</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-alert
            v-if="users.length >= maxNumberOfSeats"
            class="mt-4"
            color="info"
            icon="fa-lightbulb"
            outlined
            >Contact us if you want to add more than {{ maxNumberOfSeats }} users</v-alert
          >
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-snackbar :timeout="5000" v-model="showSnackbar" top>
      Information updated
      <v-btn text color="primary" @click.native="showSnackbar = false">Ok</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import API from '@/API';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import {
  CompanyAdminUserSerializer,
  CompanyListSerializer,
  CompanyStoreTemplate,
  User,
} from '@/models/UserCompanyModel';

const MAX_NUMBER_OF_USERS = 5;

interface CompanyStats {
  month: string;
  total: number;
}
export default Vue.extend({
  name: 'usermanagement',
  data() {
    return {
      isLoading: false,
      new_user: {
        username: '',
        password: '',
        email: '',
      },
      new_user_errors: {
        username: [],
        password: [],
        email: [],
      },
      error_messages: [] as string[],
      showAddUserModal: false,
      showInviteModal: false,
      showDeleteModal: false,
      showSnackbar: false,
      companies: [] as CompanyListSerializer[],
      users: [] as CompanyAdminUserSerializer[],
      selected_user: null as CompanyAdminUserSerializer,
      selected_company: null as CompanyListSerializer,
      company_stats: [] as CompanyStats[],
      selectedTransferUsers: [] as CompanyAdminUserSerializer[],
      transferTableHeaders: [
        { text: 'User name', value: 'username' },
        { text: 'Email', value: 'email' },
      ],
    };
  },
  mounted() {
    API.getCompanies().then((response) => {
      this.companies = response.data;
    });
  },
  computed: {
    ...mapStores(useMiscStore),
    maxNumberOfSeats(): number {
      return this.selected_company?.seats;
    },
    createUsersDisabled(): boolean {
      return this.users.length >= this.maxNumberOfSeats || this.lite_version;
    },
    transferTableUsers(): CompanyAdminUserSerializer[] {
      return this.users.filter((u) => u.email !== this.selected_user?.email);
    },
    deleteButtonText(): string {
      return this.selectedTransferUsers.length
        ? 'Transfer load lists and delete user'
        : 'Delete load lists and user';
    },
    company(): CompanyStoreTemplate {
      return this.miscStore.company;
    },
    user(): User {
      return this.miscStore.user;
    },
    lite_version(): boolean {
      return this.miscStore.lite_version;
    },
  },
  methods: {
    updateSelectedCompany(company: CompanyListSerializer): void {
      if (company !== null) {
        this.selected_company = company;
        this.getUsers();
        API.getCompanyStats(this.selected_company.id).then((response) => {
          this.company_stats = response.data.map((i: CompanyStats) => {
            const date = new Date(i.month);
            const short_month = date.toLocaleString('default', {
              month: 'short',
            });
            return {
              total: i.total,
              month: short_month,
            };
          });
        });
      }
    },
    getUsers(): void {
      API.getUsers(this.selected_company.id).then((response) => {
        this.users = response.data;
      });
    },
    updateUser(user: CompanyAdminUserSerializer): void {
      API.updateUserFromCompany(this.selected_company.id, user.id, user)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    },
    deleteUser(
      user: CompanyAdminUserSerializer,
      transferUsers: CompanyAdminUserSerializer[]
    ): void {
      const transferUserId = transferUsers.length ? transferUsers[0].id : null;
      console.log(user);
      API.deleteUserFromCompany(this.selected_company.id, user.id, transferUserId)
        .then((response) => {
          this.getUsers();
          this.showAddUserModal = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createUser(): void {
      API.addUser(this.selected_company.id, this.new_user)
        .then((response) => {
          this.getUsers();
          this.showAddUserModal = false;

          this.new_user_errors.username = [];
          this.new_user_errors.password = [];
          this.new_user_errors.email = [];
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.new_user_errors.username = [];
            this.new_user_errors.password = [];
            this.new_user_errors.email = [];

            if ('username' in error.response.data) {
              this.new_user_errors.username = error.response.data.username;
            }
            if ('password' in error.response.data) {
              this.new_user_errors.password = error.response.data.password;
            }
            if ('email' in error.response.data) {
              this.new_user_errors.email = error.response.data.email;
            }
          }
        });
    },
    inviteUser(): void {
      API.inviteUser({
        company: this.selected_company.id,
        email: this.new_user.email,
      })
        .then((response) => {
          this.showInviteModal = false;
          this.new_user_errors.email = [];
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.new_user_errors.email = [];
            if ('email' in error.response.data) {
              this.new_user_errors.email = error.response.data.email;
            }
          }
        });
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input[type='file'] {
  display: none;
}
</style>
