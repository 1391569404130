<template>
  <div>
    <v-sheet tile class="d-flex align-center">
      <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday"> Today </v-btn>
      <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn icon class="ma-2" @click="$refs.calendar.next()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-toolbar-title v-if="$refs.calendar">{{ $refs.calendar.title }}</v-toolbar-title>
    </v-sheet>
    <v-sheet>
      <v-calendar
        :key="key"
        v-model="focus"
        ref="calendar"
        type="month"
        :events="shownEntries"
        :eventColor="getEventColor"
        :eventHeight="eventHeight"
        :eventMore="false"
        @click:event="showEvent"
        @click:date="newList($event)"
        @change="getEntries($event.start.date, $event.end.date)"
        :start="start"
        weekdays="1,2,3,4,5,6,0"
        style="border-right: 1px solid">
        <template v-slot:event="{ event }">
          <div
            class="d-flex align-center"
            :style="{
              'border-left': `solid ${event.color} 3px`,
              margin: '0',
              height: '100%',
              padding: '2px 2px 2px 4px',
              borderRadius: 0,
              opacity:
                new Date(event.start.toDateString()) < new Date(new Date().toDateString())
                  ? 0.3
                  : 1.0,
              background: event.loadlist
                ? getEventAlternateColor(event)
                : 'repeating-linear-gradient(127deg, #ffffff,#ffffff 2px, #e7e7e7 2px, #e7e7e7 6px)',
            }">
            <!-- <v-icon color="white" v-if="event.loadlist" small
              >mdi-timer-sand</v-icon
            > -->
            <span
              :style="{
                color: event.loadlist ? event.color : `#333333`,
                'font-weight': 'bold',
              }"
              >{{ event.name }}</span
            >
          </div>
        </template>
      </v-calendar>
      <!-- <calendar-context-dialog
        :selectedOpen="selectedOpen"
        :selectedEvent="selectedEvent"
        :selectedElement="selectedElement"
        @close="selectedOpen = false"
      ></calendar-context-dialog> -->
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        z-index="2147483001">
        <v-card min-width="350px" flat>
          <v-toolbar :color="selectedEvent.color" dark>
            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
            <v-spacer />
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item :disabled="!hasResults" @click="toggleLoadlistLock()">
                  {{
                    selectedLoadlist && selectedLoadlist.result.locked
                      ? 'Unlock loadlist'
                      : 'Lock loadlist'
                  }}
                </v-list-item></v-list
              >
            </v-menu>
          </v-toolbar>
          <v-card-text>
            <div v-if="selectedEvent.loadlist">
              <loadlist-preview :loadlist="selectedLoadlist" />
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="secondary" @click="selectedOpen = false"> Close </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              v-if="selectedEvent.loadlist"
              @click="
                $router.push({
                  name: 'loadlist',
                  params: { id: selectedEvent.loadlist.id },
                })
              ">
              Open
            </v-btn>

            <v-btn text color="primary" v-else @click="createLoadlistFromEvent(selectedEvent)">
              Create loadlist
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-sheet>
    <v-dialog v-model="showSelectListTypeModal" max-width="380">
      <v-card>
        <v-card-title>Select type of loadlist</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="tyype in $list_types"
              :key="tyype.value"
              @click="
                selectedEvent.list_type = tyype.value;
                createLoadlistFromEvent(selectedEvent);
              ">
              <v-list-item-title>
                <v-icon x-large>{{ `$vuetify.icons.${tyype.value}` }}</v-icon>
                {{ tyype.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <new-list-modal
      :visible="showNewListModal"
      @close="showNewListModal = false"
      :group="groupId"
      :etd="newListDate"
      @created="
        $emit('selectedLoadlist', $event);
        $router.push({ name: 'loadlist', params: { id: $event } });
      "></new-list-modal>
  </div>
</template>

<script lang="ts">
import {
  LoadlistBase,
  Loadlist,
  LoadlistGroup,
  HoldData,
  LoadConfigurationData,
  LoadConfiguration,
} from '@/models/LoadlistModel';
import { CalendarEntry } from '@/models/LoadlistCalendarModel';
import API from '@/API';
import Vue, { PropType } from 'vue';
import { RRule } from 'rrule';
import { APIResponse } from '@/models/APIModel';
import SceneComponent from '../Custom/SceneComponent.vue';
import NewListModal from '@/components/Modals/NewList.vue';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import LoadlistPreview from './LoadlistPreview.vue';

interface LoadlistsLookup {
  [key: string]: Loadlist;
}

export default Vue.extend({
  name: 'loadlists-calendar',
  components: {
    SceneComponent,
    NewListModal,
    LoadlistPreview,
  },
  props: {
    groups: {
      type: Array as PropType<LoadlistGroup[]>,
      default: () => [] as LoadlistGroup[],
    },
    groupId: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    searchString: String,
  },
  watch: {
    groups: function (a): void {
      this.key++;
    },
    groupId: function (a): void {
      this.key++;
    },
    searchString: function (a): void {
      this.key++;
    },
  },
  data() {
    return {
      loadlists: [] as LoadlistBase[],
      detailedLoadlists: {} as LoadlistsLookup,
      focus: '',
      key: 1,
      start: new Date(),
      entries: [] as CalendarEntry[],
      selectedEvent: {} as CalendarEntry,
      selectedElement: null,
      selectedOpen: false,
      selectedLoadlistId: null as string,
      // searchString: "",
      isLoading: false,
      showSelectListTypeModal: false,
      showNewListModal: false,
      newListDate: null as string,
      etd: null as { etd_after: string; etd_before: string },
    };
  },
  computed: {
    ...mapStores(useMiscStore),
    loadlist_calendar_entries(): CalendarEntry[] {
      return this.loadlists.map((loadlist) => {
        return {
          name: loadlist.name,
          group_id: loadlist.group,
          loadlist: loadlist,
          start: new Date(loadlist.etd),
          details: `${loadlist.pol && loadlist.pod ? loadlist.pol + ' --> ' + loadlist.pod : ''}`,
          color: '#4678b2',
          timed: false,
          list_type: loadlist.list_type,
        };
      });
    },
    shownEntries(): CalendarEntry[] {
      if (this.groupId) return this.entries.filter((i) => i.group_id === this.groupId);
      return this.entries;
    },
    eventHeight(): number {
      return 30;
    },
    selectedLoadlist(): Loadlist {
      return this.detailedLoadlists[this.selectedLoadlistId];
    },
    hasResults(): boolean {
      return !!this.selectedLoadlist?.result?.versions?.find((v) => v.holds.length);
    },
  },
  mounted() {},
  methods: {
    toggleLoadlistLock() {
      const locked = !!this.selectedLoadlist.result?.locked;
      if (this.selectedLoadlist?.result) {
        this.selectedLoadlist.result.locked = !locked;
        API.saveLoadlist(this.selectedLoadlist)
          .then((r) => (this.selectedLoadlist.version = r.data.version))
          .catch((e) => console.log(e))
          .finally(() => this.getEntries(this.etd.etd_after, this.etd.etd_before));
      }
    },
    setToday() {
      this.focus = '';
    },
    filterByName(calendarEntries: CalendarEntry[], searchString: string): CalendarEntry[] {
      return calendarEntries.filter((ce) =>
        ce.name.toLowerCase().includes(searchString.toLowerCase())
      );
    },
    getEntries(etd_after: string, etd_before: string): void {
      this.isLoading = true;
      this.etd = { etd_before, etd_after };
      API.getLoadlists({
        group: this.groupId,
        etd_after: etd_after,
        etd_before: etd_before,
        size: 100,
        search: this.searchString,
      })
        .then((data: APIResponse) => {
          this.loadlists = data.data.results;
          const loadlists = this.loadlist_calendar_entries;
          const groups = this.getRecurringGroupsBetweenDates(
            new Date(etd_after),
            new Date(etd_before)
          );

          // if (this.searchString) {
          //   loadlists = this.filterByName(loadlists, this.searchString);
          //   groups = this.filterByName(groups, this.searchString);
          // }

          // Check if there are loadlists belonging to a group which has the same etd as the date - then merge the two
          groups.forEach((i) => {
            const loadlist_index = loadlists.findIndex(
              (j) =>
                i.start.toLocaleDateString('en-CA') == j.start.toLocaleDateString('en-CA') &&
                i.group_id == j.group_id
            );
            if (loadlist_index > -1) {
              i.loadlist = loadlists[loadlist_index].loadlist;
              loadlists.splice(loadlist_index, 1);
            }
          });

          this.entries = [...groups, ...loadlists];
          this.isLoading = false;
        })
        .catch((error: any) => {
          console.log('Error fetching loadlists');
          this.isLoading = false;
        });
    },
    getEventColor(event: CalendarEntry): string {
      return event.color;
    },
    getEventAlternateColor(event: CalendarEntry): string {
      return this.hexToRGBA(event.color, 0.2);
    },

    hexToRGBA(hex: string, alpha: number): string {
      const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    },
    showEvent(e: { event: CalendarEntry }, nativeEvent: PointerEvent): void {
      if (this.readonly) {
        if (!e.event.loadlist?.id) {
          this.createLoadlist(e.event);
        } else {
          this.$emit('selectedLoadlist', e.event.loadlist.id);
        }
        return;
      }

      const open = () => {
        this.selectedEvent = e.event;
        this.selectedElement = nativeEvent.target;
        if (this.selectedEvent.loadlist?.id) {
          this.selectedLoadlistId = this.selectedEvent.loadlist.id;

          if (!this.selectedLoadlist) {
            API.getLoadlist(this.selectedEvent.loadlist.id)
              .then((data) => {
                this.isLoading = false;
                this.$set(this.detailedLoadlists, this.selectedEvent.loadlist.id, data.data);
                this.$vuetify.goTo(this.selectedElement);
              })
              .catch((error) => {
                console.log('Error fetching loadlist');
                this.isLoading = false;
              });
          }
        } else {
          this.selectedLoadlistId = null;
        }
        requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    getRecurringGroupsBetweenDates(start: Date, end: Date): CalendarEntry[] {
      return this.groups
        .filter((i) => i.data.rrule)
        .map((i) => {
          const rrule = RRule.fromString(i.data.rrule);

          if (i.data.start_date && new Date(i.data.start_date) > start) {
            rrule.options.dtstart = new Date(i.data.start_date);
          } else rrule.options.dtstart = start;

          return {
            rrule_obj: rrule,
            ...i,
          };
        })
        .map((i) => {
          return (
            i.rrule_obj
              // Adding a day to the date, because the "inc" keyword doesn't seem to do what it is supposed to
              .between(start, new Date(end.setDate(end.getDate() + 1)))
              .map((j) => {
                return {
                  name: i.name,
                  group_id: i.id,
                  loadlist: null,
                  start: j,
                  details: ``,
                  color: i.data.color || 'red',
                  timed: false,
                  list_type: i.data.default_type,
                  preset: i.data.default_preset,
                };
              })
          );
        })
        .flat();
    },
    newList(event: { date: string }): void {
      // console.log(event.date);
      this.newListDate = event.date;
      this.showNewListModal = true;
    },
    createLoadlistFromEvent(event: CalendarEntry): void {
      if (!event.list_type) {
        this.showSelectListTypeModal = true;
      } else {
        this.createLoadlist(event);
      }
    },
    createLoadlist(event: CalendarEntry): void {
      const offset = event.start.getTimezoneOffset();
      const etd = new Date(event.start.getTime() - offset * 60 * 1000).toISOString().split('T')[0];
      API.createLoadlist({
        name: event.name,
        list_type: event.list_type || 'SEA',
        group: event.group_id,
        length_dim: this.miscStore.preferences?.default_loadlist_length_dim || undefined,
        weight_dim: this.miscStore.preferences?.default_loadlist_weight_dim || undefined,
        etd: etd,
        data: [],
      })
        .then((data) => {
          this.isLoading = false;
          this.$emit('selectedLoadlist', data.data.id);
          if (!this.readonly) {
            this.$router.push({
              name: 'loadlist',
              params: { id: data.data.id },
            });
          }
        })
        .catch((error) => {
          console.log('Error creating loadlist');
          this.isLoading = false;
        });
    },
  },
});
</script>

<style>
.v-calendar .v-event {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
}
</style>
