<template>
  <div class="instructions">
    <div class="d-flex flex-wrap instructions-parent">
      <div v-for="(layer, step) in layers" :key="step" class="instruction-card pa-2">
        <v-card class="text-center" outlined>
          <b v-if="loadingMode == 'stacks'">{{ layer.value }} stacks</b>
          <b v-else>Step {{ step + 1 }}</b>
          <v-card-text class="instructions-vcard">
            <scene-component
              enableReuse
              :canvas-width="350"
              :canvas-height="350"
              :hold-object="container.hold"
              :custom-view-settings="{
                view: '3d-perspective',
                highlight: 'byIndex',
                highlightMode: 'zoom',
                highlighted_cargo_indexes: layer.cargoes,
                visible_cargo_indexes: layer.previous_cargoes,
              }"
              :key="step + loadingMode"></scene-component>
            <table class="mt-2 items-table ml-auto mr-auto">
              <tr v-for="(o, i) in layer.objects" :key="i">
                <td>
                  <v-icon v-bind:style="{ color: o.color }">fa-cube</v-icon>
                </td>
                <td class="text-left pl-2">
                  <b>{{ i + 1 }}</b
                  >: {{ o.label }}
                </td>
                <td class="text-left pl-2">{{ o.count }} pcs</td>
              </tr>
            </table>

            <!-- <p>
              ({{ layer.stack_info["z"].length }} layers,
              {{ layer.stack_info["y"].length }} rows,
              {{ layer.stack_info["x"].length }} columns)
            </p> -->
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

import { AugmentedHold } from '@/models/augmented/hold';
import sceneComponent from '@/components/Custom/SceneComponent.vue';
import { CargoLayer } from '@/models/LoadlistModel';

export default Vue.extend({
  name: 'loadplan-instructions',
  components: {
    sceneComponent,
  },
  props: {
    container: Object as PropType<AugmentedHold>,
    loadingMode: String as PropType<string>,
  },
  computed: {
    layers(): CargoLayer[] {
      if (this.loadingMode == 'stacks') {
        return (this.container as AugmentedHold).stacks;
      }
      return (this.container as AugmentedHold).layersWithPreviousCargoes;
    },
  },
});
</script>

<style scoped>
.instruction-card,
.instruction-card > .v-card,
.instructions-vcard,
.items-table,
.instruction-card {
  max-width: 400px;
}
.instruction-items {
  list-style-type: none;
  padding-left: 0;
}

@media print {
  .instruction-card {
    display: inline-block;
    width: 350px;
  }
  .instructions-parent {
    display: block !important;
  }

  #sceneComponent canvas {
    page-break-inside: avoid;
  }

  table > tr,
  td {
    page-break-inside: avoid !important;
  }
}

@media print and (orientation: landscape) {
  .instruction-card {
    width: 316px;
  }
}
</style>
