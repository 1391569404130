<template>
  <div class="align-center">
    <!-- <v-btn-toggle
      :value="by_property"
      dense
      @change="
        (v) => {
          if (v) {
            selector = {
              property: 'l',
              comparison: 'eq',
              value: 0,
            };
          } else {
            selector = [];
          }
          by_property = v;
        }
      "
    >
      <v-btn :value="false">List</v-btn>
      <v-btn :value="true">By Property</v-btn>
    </v-btn-toggle> -->

    <div class="d-md-flex">
      <v-select
        class="mr-md-2"
        style="flex: 1"
        v-model="selector.property"
        :items="properties"
        :menu-props="{ maxHeight: '400' }"
        label="All items with"
        @change="propChange" />
      <v-select
        class="mr-md-2"
        style="flex: 1"
        v-model="selector.comparison"
        :items="comparisons.filter((v) => (isOptionsField ? ['eq', 'ne'].includes(v.value) : true))"
        :menu-props="{ maxHeight: '400' }"
        label="Condition" />
      <div class="mr-md-2" style="flex: 2">
        <length-input-field
          v-if="['l', 'w', 'h', 'free_space'].includes(selector.property)"
          v-model="selector.value"
          :lengthDim="lengthDim"
          label="Value"
          :rules="[(v) => !!v || 'Required']"
          step="10" />
        <weight-input-field
          v-else-if="['wt'].includes(selector.property)"
          v-model="selector.value"
          :weightDim="weightDim"
          label="Value"
          :rules="[(v) => !!v || 'Required']"
          step="10" />
        <v-select
          v-else-if="isOptionsField"
          v-model="selector.value"
          :items="valueOptions"
          label="Value"
          :rules="[(v) => (!!v && !!v.length) || 'Select at least one']"
          multiple>
          <template v-slot:selection="{ item, index }">
            <span v-if="index < selector.value.length - 2">{{ item }},&nbsp;</span>
            <span v-else-if="index === selector.value.length - 2">{{ item }} or&nbsp;</span>
            <span v-else>{{ item }}</span>
          </template>
        </v-select>

        <v-text-field
          v-else
          v-model.number="selector.value"
          step="10"
          type="number"
          :label="property.text" />
      </div>
      <div class="py-4">
        <v-chip :color="matchCount ? 'primary' : ''">{{ matchCount }} items matched</v-chip>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import itemProperties, { ItemProperty } from '@/misc/itemProperties';
import { HoldInputItem, ItemSelector, Loadlist } from '@/models/LoadlistModel';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
import Vue, { PropType } from 'vue';
import LengthInputField from '@/components/Custom/LengthInputField.vue';
import WeightInputField from '@/components/Custom/WeightInputField.vue';
export default Vue.extend({
  components: { LengthInputField, WeightInputField },
  name: 'item-selector',
  data: function () {
    return {
      properties: itemProperties
        .props()
        .filter(
          (p) =>
            (p.type == 'integer' ||
              p.type === 'float' ||
              p.type === 'string' ||
              ['class_id', 'shipment_id'].includes(p.key)) &&
            !p.additional &&
            !p.readOnly &&
            p.key !== 'orientations'
        )
        .map((p) => {
          return { value: p.key, ...p };
        }),

      comparisons: [
        { text: 'Equals', value: 'eq' },
        { text: 'Not equals', value: 'ne' },
        { text: 'Less than', value: 'lt' },
        { text: 'Greater than', value: 'gt' },
      ],
      selector: this.value || {
        property: 'label',
        comparison: 'eq',
        value: [],
      },
    };
  },
  props: {
    items: Array as PropType<HoldInputItem[]>,
    value: Object as PropType<ItemSelector>, // string[] | ItemSelector
    class_ids: Array as PropType<string[]>,
  },
  watch: {
    selector: {
      handler(v) {
        this.$emit('input', v);
      },
      deep: true,
    },
  },
  computed: {
    ...mapStores(useLoadlistStore),
    isOptionsField(): boolean {
      return this.property?.type === 'string';
    },
    property(): ItemProperty {
      return this.properties.find((p) => p.key == this.selector.property);
    },
    lengthDim(): string {
      return this.loadlist?.length_dim;
    },
    weightDim(): string {
      return this.loadlist?.weight_dim;
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
    valueOptions(): string[] {
      if (this.isOptionsField) {
        let additional: string[] = [];
        if (this.selector.property == 'class_id') {
          additional = this.class_ids;
        }
        // Return only unique values
        return [
          ...new Set([
            ...this.items
              .map((v) => v[this.selector.property as keyof HoldInputItem] as string)
              .filter((v) => v)
              .sort()
              .filter((v, index, a) => index === 0 || a[index - 1] !== v),
            ...additional,
          ]),
        ];
      }
      return [];
    },
    matchCount(): number {
      const dim = this.property.dimension;
      const converter = dim
        ? (v: number) => v * this.$toSI(dim === 'length' ? this.lengthDim : this.weightDim)
        : (v: any) => v;

      return this.items
        .map((i) => i[this.selector.property as keyof HoldInputItem])
        .filter((v) => {
          const value = converter(v);

          let eq = this.selector.value == value;
          if (this.selector.value instanceof Array) {
            eq = this.selector.value.includes(value);
          }
          if (this.selector.comparison === 'eq') return eq;
          if (this.selector.comparison === 'ne') return !eq;
          if (this.selector.comparison === 'gt') return value > this.selector.value;
          else return value < this.selector.value;
        }).length;
    },
  },
  mounted(): void {},
  methods: {
    propChange(v: any): void {
      if (this.isOptionsField) {
        this.selector.value = [];
      } else {
        this.selector.value = 0;
      }
    },
  },
});
</script>
