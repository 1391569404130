import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VSheet,{staticClass:"d-flex align-center",attrs:{"tile":""}},[_c(VBtn,{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v(" Today ")]),_c(VBtn,{staticClass:"ma-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.calendar.prev()}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c(VBtn,{staticClass:"ma-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.calendar.next()}}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1),(_vm.$refs.calendar)?_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$refs.calendar.title))]):_vm._e()],1),_c(VSheet,[_c(VCalendar,{key:_vm.key,ref:"calendar",staticStyle:{"border-right":"1px solid"},attrs:{"type":"month","events":_vm.shownEntries,"eventColor":_vm.getEventColor,"eventHeight":_vm.eventHeight,"eventMore":false,"start":_vm.start,"weekdays":"1,2,3,4,5,6,0"},on:{"click:event":_vm.showEvent,"click:date":function($event){return _vm.newList($event)},"change":function($event){return _vm.getEntries($event.start.date, $event.end.date)}},scopedSlots:_vm._u([{key:"event",fn:function({ event }){return [_c('div',{staticClass:"d-flex align-center",style:({
            'border-left': `solid ${event.color} 3px`,
            margin: '0',
            height: '100%',
            padding: '2px 2px 2px 4px',
            borderRadius: 0,
            opacity:
              new Date(event.start.toDateString()) < new Date(new Date().toDateString())
                ? 0.3
                : 1.0,
            background: event.loadlist
              ? _vm.getEventAlternateColor(event)
              : 'repeating-linear-gradient(127deg, #ffffff,#ffffff 2px, #e7e7e7 2px, #e7e7e7 6px)',
          })},[_c('span',{style:({
              color: event.loadlist ? event.color : `#333333`,
              'font-weight': 'bold',
            })},[_vm._v(_vm._s(event.name))])])]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}),_c(VMenu,{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"z-index":"2147483001"},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c(VCard,{attrs:{"min-width":"350px","flat":""}},[_c(VToolbar,{attrs:{"color":_vm.selectedEvent.color,"dark":""}},[_c(VToolbarTitle,{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.name)}}),_c(VSpacer),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,[_c(VListItem,{attrs:{"disabled":!_vm.hasResults},on:{"click":function($event){return _vm.toggleLoadlistLock()}}},[_vm._v(" "+_vm._s(_vm.selectedLoadlist && _vm.selectedLoadlist.result.locked ? 'Unlock loadlist' : 'Lock loadlist')+" ")])],1)],1)],1),_c(VCardText,[(_vm.selectedEvent.loadlist)?_c('div',[_c('loadlist-preview',{attrs:{"loadlist":_vm.selectedLoadlist}})],1):_vm._e()]),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.selectedOpen = false}}},[_vm._v(" Close ")]),_c(VSpacer),(_vm.selectedEvent.loadlist)?_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({
                name: 'loadlist',
                params: { id: _vm.selectedEvent.loadlist.id },
              })}}},[_vm._v(" Open ")]):_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.createLoadlistFromEvent(_vm.selectedEvent)}}},[_vm._v(" Create loadlist ")])],1)],1)],1)],1),_c(VDialog,{attrs:{"max-width":"380"},model:{value:(_vm.showSelectListTypeModal),callback:function ($$v) {_vm.showSelectListTypeModal=$$v},expression:"showSelectListTypeModal"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Select type of loadlist")]),_c(VCardText,[_c(VList,_vm._l((_vm.$list_types),function(tyype){return _c(VListItem,{key:tyype.value,on:{"click":function($event){_vm.selectedEvent.list_type = tyype.value;
              _vm.createLoadlistFromEvent(_vm.selectedEvent);}}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"x-large":""}},[_vm._v(_vm._s(`$vuetify.icons.${tyype.value}`))]),_vm._v(" "+_vm._s(tyype.text)+" ")],1)],1)}),1)],1)],1)],1),_c('new-list-modal',{attrs:{"visible":_vm.showNewListModal,"group":_vm.groupId,"etd":_vm.newListDate},on:{"close":function($event){_vm.showNewListModal = false},"created":function($event){_vm.$emit('selectedLoadlist', $event);
      _vm.$router.push({ name: 'loadlist', params: { id: $event } });}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }