<template>
  <v-container>
    <v-row>
      <v-col cols="10" offset="1" sm="6" offset-sm="3">
        <v-card>
          <v-card-title>
            <img
              style="width: 100%; max-width: 300px; margin-left: auto; margin-right: auto"
              src="../../assets/svg/logo_black.svg" />
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="login" id="magiclink-form" autocomplete="on">
              <v-text-field
                label="Email"
                v-model="email"
                placeholder=" "
                type="text"
                autocomplete="email"></v-text-field>
            </v-form>
            <v-alert v-if="!formSent" outlined type="info">
              <p>
                Enter your email and you will recieve an email with a link that'll sign you in
                instantly
              </p>
            </v-alert>
            <v-alert v-else outlined type="success">
              <p>
                Please check your email now (also in your spam folder). The link is valid for 15
                minutes
              </p>
            </v-alert>
          </v-card-text>

          <v-card-actions>
            <v-btn
              block
              color="primary"
              type="submit"
              :loading="isLoading"
              :disabled="email.length === 0 || isLoading || formSent"
              form="magiclink-form"
              >Get my link</v-btn
            >
          </v-card-actions>
          <v-card-text class="text-center">
            <p>
              Login with Password
              <router-link to="/login/">Click here</router-link>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import API from '@/API';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';

export default Vue.extend({
  name: 'login',
  data() {
    return {
      isLoading: false,
      email: '',
      formSent: false,
    };
  },
  computed: {
    ...mapStores(useMiscStore),
    registerAdress(): string {
      return API.url + '/register/';
    },
    passwordResetAdress(): string {
      return API.url + '/pwr';
    },
    is_authenticated(): boolean {
      return this.miscStore.is_authenticated;
    },
  },
  methods: {
    login() {
      this.isLoading = true;

      API.magicLink({
        email: this.email.trim(),
      })
        .then(() => {
          this.isLoading = false;
          this.formSent = true;
        })
        .catch(() => {
          this.formSent = true;

          this.isLoading = false;
        });
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
