import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"10","offset":"1","sm":"6","offset-sm":"3"}},[_c(VCard,[_c(VCardTitle,[_c('img',{staticStyle:{"width":"100%","max-width":"300px","margin-left":"auto","margin-right":"auto"},attrs:{"src":require("../../assets/svg/logo_black.svg")}})]),_c(VCardText,[_c(VForm,{attrs:{"autocomplete":"on","id":"reset-confirm-form"},on:{"submit":function($event){$event.preventDefault();return _vm.reset_confirm.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"New password","placeholder":" ","type":"password","autocomplete":"current-password"},model:{value:(_vm.new_password1),callback:function ($$v) {_vm.new_password1=$$v},expression:"new_password1"}}),_c(VTextField,{attrs:{"label":"New password (again)","placeholder":" ","type":"password","autocomplete":"current-password"},model:{value:(_vm.new_password2),callback:function ($$v) {_vm.new_password2=$$v},expression:"new_password2"}})],1),(_vm.formSent)?_c(VAlert,{attrs:{"outlined":"","type":"success"}},[_c('p',[_vm._v(" Your password is now updated. "),_c('router-link',{attrs:{"to":"/login/"}},[_vm._v("Login here")])],1)]):_vm._e(),(_vm.errorMessages.length)?_c(VAlert,{attrs:{"outlined":"","type":"error"}},[_c('ul',_vm._l((_vm.errorMessages),function(message){return _c('li',{key:message},[_vm._v(" "+_vm._s(message)+" ")])}),0)]):_vm._e()],1),_c(VCardActions,[_c(VBtn,{attrs:{"block":"","color":"primary","type":"submit","loading":_vm.isLoading,"disabled":_vm.new_password1.length < 6 || _vm.new_password1 != _vm.new_password2 || _vm.isLoading || _vm.formSent,"form":"reset-confirm-form"}},[_vm._v("Reset password")])],1),_c(VCardText,{staticClass:"text-center"},[_c('p',[_vm._v(" Back to login "),_c('router-link',{attrs:{"to":"/login/"}},[_vm._v("Click here")])],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }