import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,[_c(VCardTitle,[_c('p',{staticClass:"text-h6"},[_vm._v("Subscription")])]),_c(VCardText,[(_vm.valid_subscription)?_c('div',{staticClass:"text-subtitle-1"}):_vm._e(),_c(VAlert,{attrs:{"outlined":"","color":"info","value":_vm.valid_subscription}},[(_vm.company.plan === '1')?_c('p',[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-check-circle")]),_vm._v("Trial version ")],1):(_vm.company.plan === '2')?_c('p',[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-check-circle")]),_vm._v(" Lite version ")],1):_c('p',[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-check-circle")]),_vm._v(" Standard version")],1),(_vm.company.has_ending_subscription)?_c('div',[_vm._v(" Subscription ends "+_vm._s(new Date(_vm.company.subscription_end).toLocaleDateString())+" ")]):_vm._e(),_c('br'),_vm._v("Any questions on your license, please contact "),_c('a',{attrs:{"href":"mailto:support@cargo-planner.com"}},[_vm._v("support@cargo-planner.com")])]),_c(VAlert,{attrs:{"outlined":"","type":"error","value":!_vm.valid_subscription}},[(_vm.company.plan === '1')?_c('span',[_vm._v("Trial ended")]):_c('span',[_vm._v("Subscription ended")]),_vm._v(" "+_vm._s(new Date(_vm.company.subscription_end).toLocaleDateString())+" ")])],1),(_vm.showCompanyLink)?_c(VCardText,[_c(VBtn,{attrs:{"block":"","to":{ name: 'company_settings' }}},[_vm._v("Update subscription")])],1):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }