<template>
  <v-dialog v-model="showModal" scrollable height="90vh" persistent>
    <v-card v-if="state === 1 && show" height="90vh">
      <v-card-title class="headline">Excel parser</v-card-title>
      <v-card-text class="d-flex flex-column">
        <v-alert type="error" v-if="showNeedAssistanceAlert">
          We were unable to read your excel sheet.
          <br />
          Try using our
          <a
            :href="'/static/templates/load_list_template_v1.5.xlsx'"
            class="white--text font-weight-bold text-decoration-underline">
            template
          </a>
          or go back to the data sheet and select the appropriate columns and rows in Excel, Copy
          <code>Ctrl+C</code>, then Paste
          <code>Ctrl+V</code>
          directly in the sheet.
        </v-alert>

        <v-alert type="info" v-else>
          Check if the following is correct, and modify manually if not. Also, remember to set the
          right
          <strong>length</strong>
          and
          <strong>weight dimensions</strong>.
          <br />
          <strong>Length</strong>,
          <strong>Width</strong>
          and
          <strong>Height</strong>
          columns needs to be provided before you can import your items.
          <p class="mb-0">
            While we are able to read most xlsx files, you can always use
            <a
              :href="'/static/templates/load_list_template_v1.5.xlsx'"
              class="white--text font-weight-bold text-decoration-underline">
              this template
            </a>
            to import your data.
          </p>
        </v-alert>

        <div>
          <v-row v-if="dimensions">
            <v-col sm="12" md="2" class="pb-0">
              <v-select
                label="Length unit"
                v-model="dimensions.lengthDim"
                @input="dirty = true"
                :items="['MM', 'CM', 'DM', 'M', 'IN', 'FT']"></v-select>
            </v-col>
            <v-col sm="12" md="2" class="pb-0">
              <v-select
                label="Weight dimension"
                v-model="dimensions.weightDim"
                @input="dirty = true"
                :items="['KG', 'LB']"></v-select>
            </v-col>
            <v-col sm="12" md="2" class="pb-0">
              <v-text-field
                label="Sheet No:"
                placeholder="E.g. 1"
                v-model="sheetNumber"
                type="number"
                @input="
                  readExcel();
                  dirty = true;
                "
                :rules="[(value) => parseInt(value) > 0 || 'Enter a valid number']"
                min="1"></v-text-field>
            </v-col>
            <v-col>
              <v-checkbox
                label="Hide empty columns"
                v-model="hideEmptyColumns"
                @change="updateVisibleColumns()"
                light></v-checkbox>
            </v-col>
            <v-col v-if="templates.length > 0">
              <div class="flex-column d-flex align-end">
                <v-btn class="mt-4" @click="showTemplates = true" color="" outlined light>
                  <v-icon>mdi-database</v-icon>
                  Templates
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <p class="my-0">
            <strong>Quantity:</strong>
            {{ totalQuantity }} pcs
          </p>
        </div>
        <div class="spinner-container" v-if="isLoading">
          <v-progress-circular
            :size="100"
            :width="7"
            color="primary"
            indeterminate></v-progress-circular>
        </div>

        <v-data-table
          v-else
          :items="itemRowsTable"
          :items-per-page="25"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: itemsPerPageOptions,
          }"
          item-key="uid"
          class="elevation-1 mt-4">
          <template slot="header">
            <tr class="header-row">
              <th>
                <div style="width: 68px"></div>
              </th>
              <template v-for="(column, index, key) in columns">
                <th v-if="visibleColumns.includes(column.index)">
                  <div class="d-flex">
                    <v-menu
                      offset-y
                      top
                      :close-on-content-click="false"
                      style="background-color: white">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="my-auto"
                          style="margin-right: -8px"
                          small
                          icon
                          v-bind="attrs"
                          v-on="on">
                          <v-icon small :color="column.filter ? 'warning' : ''">mdi-filter</v-icon>
                        </v-btn>
                      </template>
                      <div class="px-2 py-2" style="background-color: white">
                        <v-text-field
                          label="Equals"
                          clearable
                          v-model="column.filter"
                          @input="updateItemRowsTable()"></v-text-field>
                      </div>
                    </v-menu>
                    <v-autocomplete
                      :ref="'autocomplete-' + index"
                      style="min-width: 140px"
                      class="mx-2"
                      placeholder="Specify column"
                      return-object
                      item-text="text"
                      multiple
                      persistent-hint
                      density="compact"
                      :value="column.properties"
                      :items="headerOptions"
                      :menu-props="{ top: true, offsetY: true }"
                      @change="updateColumn($event, index)"
                      @blur="removeSearchFromAutomcomplete(index)"></v-autocomplete>
                  </div>
                </th>
              </template>
            </tr>
          </template>

          <template slot="item" slot-scope="props">
            <tr>
              <td>
                <v-btn icon>
                  <v-icon color="error" @click="removeRow(props.item)">mdi-minus-circle</v-icon>
                </v-btn>
              </td>
              <template v-for="(entry, index) in props.item.values">
                <td v-if="visibleColumns.includes(index)">
                  <div class="pl-4">
                    <v-tooltip bottom v-if="entry.error">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="warning">mdi-alert</v-icon>
                      </template>
                      <span>Invalid value for selected column</span>
                    </v-tooltip>
                    <span>{{ entry.value }}</span>
                  </div>
                </td>
              </template>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn @click.stop="showModal = false" :loading="isLoading" outlined>Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-checkbox
          class="mr-4"
          v-bind:label="'Replace current data with parsed data'"
          v-model="excelParseReplaceCurrent"
          light></v-checkbox>
        <v-btn color="primary" @click.stop="done" :disabled="!isImportEnabled">Import</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="showSaveOrImport" max-width="600">
      <v-card>
        <v-card-title>Save changes</v-card-title>
        <v-card-text>
          You have modified the intepretation of this file. Do you want to save your modifications
          as an
          <em>import template</em> for use with similar files?
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            @click.stop="
              newTemplate = { name: '', description: '' };
              showSaveOrImport = false;
              showNewTemplate = true;
            ">
            <v-icon>mdi-floppy</v-icon>
            Save & Import
          </v-btn>
          <v-spacer />
          <v-btn
            @click.stop="
              dirty = false;
              done();
            "
            color="primary">
            Just Import
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showNewTemplate" max-width="600">
      <v-card>
        <v-card-title>New Template</v-card-title>

        <v-card-text>
          Choose a name
          <v-form
            class="mt-4"
            v-model="validTemplateForm"
            ref="templateForm"
            lazy-validation
            @submit.prevent="saveAndDone()">
            <v-text-field
              label="Template Name"
              v-model="newTemplate.name"
              :rules="[
                (v) => !!v || 'A name is required',
                (v) =>
                  !(miscStore.company_settings?.import_templates || []).find((t) => t.name === v) ||
                  'This name is already taken',
              ]" />
            <v-textarea
              label="Template description"
              hint="What type of file it is, where it comes from, or how it's different from the automatic interpretation"
              v-model="newTemplate.description" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click.stop="showNewTemplate = false" outlined>Cancel</v-btn>
          <v-spacer />
          <v-btn @click.stop="saveAndDone()" color="success" :loading="isLoading">
            <v-icon>mdi-floppy</v-icon>
            Save & Import
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showTemplates" max-width="600">
      <v-card>
        <v-card-title>Import Templates</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item v-for="template in templates" v-bind:key="template.name" two-line>
              <v-list-item-content>
                <v-list-item-title>
                  {{ template.name }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ template.description }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="flex-row">
                <v-tooltip top :disabled="!miscStore.user.is_editor">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      x-large
                      v-on="on"
                      @click.stop="
                        selectedTemplate = template.name;
                        showRemoveTemplate = true;
                      ">
                      <v-icon color="error">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Remove template</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      x-large
                      v-on="on"
                      @click.stop="
                        useTemplate(template.name);
                        showTemplates = false;
                      ">
                      <v-icon color="success">mdi-import</v-icon>
                    </v-btn>
                  </template>
                  <span>Use this template</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click.stop="showTemplates = false" outlined>Done</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showRemoveTemplate" width="400">
      <v-card>
        <v-card-title>
          <span class="text-h5">Remove template?</span>
        </v-card-title>
        <v-card-text>
          Do you really want to remove the import template
          <b>"{{ selectedTemplate }}"</b>?
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="showRemoveTemplate = false" outlined>Cancel</v-btn>
          <v-spacer />
          <v-btn class="mr-2" color="error" @click="deleteTemplate(selectedTemplate)">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue';
import ExcelService from '@/services/excelService';
import { Hold, HoldInputItem } from '@/models/LoadlistModel';
import { CellValue, CellFormulaValue } from 'exceljs';
import ItemProperties, { ItemProperty } from '@/misc/itemProperties';
import { ExcelImportData, ItemRow } from '@/models/InputDataModel';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import {
  ColumnIndexForProperty,
  ColumnInfo,
  DisplayItemRow,
  ImportTemplate,
} from '@/models/ExcelImport';

import { ParseResult } from 'papaparse';

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        templateForm: any;
      };
    }
  >
).extend({
  name: 'excel-parser',
  data() {
    return {
      itemsPerPageOptions: [25, 50, 100, -1],
      isLoading: false,
      state: 1,
      sheetNumber: 1,
      itemRowsTable: [] as DisplayItemRow[],
      filteredRows: [] as ItemRow[],
      showNeedAssistanceAlert: false,
      columns: [] as ColumnInfo[],
      headerOptions: [] as ItemProperty[],
      hideEmptyColumns: true,
      visibleColumns: [] as number[],
      importData: undefined as ExcelImportData,
      totalQuantity: 0,
      dimensions: {
        weightDim: 'KG',
        lengthDim: 'M',
      },
      excelParseReplaceCurrent: true,
      truthyAnswers: ['y', 'yes', 'true'],
      falsyAnswers: ['n', 'no', 'false'],
      // Import Template variables
      dirty: false,
      showTemplates: false,
      showSaveOrImport: false,
      showNewTemplate: false,
      showRemoveTemplate: false,
      newTemplate: { name: '', description: '' },
      validTemplateForm: false,
      selectedTemplate: '',
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array as PropType<HoldInputItem[]>,
      default: () => [] as HoldInputItem[],
    },
    files: {
      type: Array as PropType<File[]>,
      default: () => [] as File[],
    },
    weightDim: {
      type: String,
      default: 'KG',
    },
    lengthDim: {
      type: String,
      default: 'M',
    },
  },
  mounted() {
    this.headerOptions = [
      ...ItemProperties.props()
        .filter((property) => !property.additional)
        .concat(
          (this.miscStore.company_settings?.extra_columns || []).map((v) => ({
            key: v.name,
            text: v.name,
            desc: v.desc,
            width: 100,
            input: 'text',
            type: 'string',
          }))
        )
        .sort((a, b) => (a.text > b.text ? 1 : -1)),
    ];
  },
  watch: {
    weightDim: {
      handler(newVal, oldVal) {
        this.dimensions.weightDim = newVal;
      },
      immediate: true,
    },
    lengthDim: {
      handler(newVal, oldVal) {
        this.dimensions.lengthDim = newVal;
      },
      immediate: true,
    },
    show: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.readExcel();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapStores(useMiscStore),
    isImportEnabled(): boolean {
      const propertyKeys = this.columns.flatMap((column) =>
        column.properties.flatMap((property) => property.key)
      );
      return propertyKeys.includes('l') && propertyKeys.includes('w') && propertyKeys.includes('h');
    },
    showModal: {
      get(): boolean {
        return this.show;
      },
      set(val: boolean): void {
        if (!val) {
          this.state = 1;
        }
        this.$emit('update:show', !!val);
      },
    },
    fileType(): string | null {
      if (!this.files.length) {
        return null;
      }

      return this.files[0].name.toLowerCase().split('.').pop();
    },
    holdsLibrary(): Hold[] {
      return this.miscStore.holds;
    },
    templates(): ImportTemplate[] {
      return this.miscStore.company_settings?.import_templates || [];
    },
  },
  filters: {
    yesno: function (value: boolean): string {
      return value ? 'Yes' : 'No';
    },
  },
  methods: {
    updateVisibleColumns(): void {
      // filters out ids of empty columns if hideEmptyColumns is true
      this.visibleColumns = this.hideEmptyColumns
        ? this.columns
            .filter(
              (header) =>
                header.properties.length > 0 ||
                !this.itemRowsTable.every(
                  (itemRow) =>
                    itemRow.values[header.index].value === undefined ||
                    itemRow.values[header.index].value === ''
                )
            )
            .map((itemRow) => itemRow.index)
        : this.columns.map((column, index) => index);
    },
    removeRow(row: DisplayItemRow): void {
      this.importData.itemRows.splice(
        this.importData.itemRows.findIndex((x) => x.index === row.index),
        1
      );
      this.updateItemRowsTable();
    },
    updateItemRowsTable() {
      // hides rows if filters are applied
      const columnsWithFilters = this.columns.filter((headers) => headers.filter);
      const filteredItemRows = this.importData.itemRows.filter((itemRow) => {
        return (
          columnsWithFilters.length === 0 ||
          columnsWithFilters.every((c) =>
            itemRow.values[c.index]?.toString().toLowerCase().includes(c.filter.toLowerCase())
          )
        );
      });
      this.filteredRows = filteredItemRows;
      this.itemRowsTable = this.createDisplayRows(filteredItemRows);
      this.totalQuantity = this.createItems(filteredItemRows).reduce(
        (acc, curr) => acc + (curr.qty || 1),
        0
      ); // TODO calculate only when qty column is updated
    },
    createDisplayRows(itemRows: ItemRow[]): DisplayItemRow[] {
      return itemRows.map((itemRow) => ({
        index: itemRow.index,
        values: itemRow.values.flatMap((value, index) => {
          const itemPropertiesOnColumn = this.columns[index].properties;
          const error =
            itemPropertiesOnColumn.some((itemProperty) => {
              const parsedValue = this.parseValue(value, itemProperty.type);
              const propertyValue = this.getCustomPropertyValue(parsedValue, itemProperty.key);
              return value !== undefined && propertyValue === undefined;
            }) || false;
          return { value, error };
        }),
      }));
    },
    calculateHeaders(
      presentItemPropertiesWithPositions: ColumnIndexForProperty[],
      numberOfColumns: number
    ): ColumnInfo[] {
      return Array.from({ length: numberOfColumns }, (_, index) => {
        const propertyItemsForColumn = presentItemPropertiesWithPositions
          .filter((ip) => ip.index === index)
          .map((ip) => ip.itemProperty);
        return { index, filter: '', properties: propertyItemsForColumn };
      });
    },
    updateColumn(propertiesForColumn: ItemProperty[], columnIndex: number) {
      this.dirty = true;
      this.removeSearchFromAutomcomplete(columnIndex);

      // removes the changed properties from all columns before adding them
      const columns = this.columns.map((column) => {
        column.properties = column.properties.filter(
          (property) =>
            !propertiesForColumn.some((propertyForColumn) => propertyForColumn.key === property.key)
        );
        return column;
      });
      columns[columnIndex].properties = propertiesForColumn;
      this.columns = columns;

      this.updateItemRowsTable();
    },
    removeSearchFromAutomcomplete(index: number): void {
      const key = `autocomplete-${index}`;
      const autocompleteRefs = this.$refs[key] as any;
      if (autocompleteRefs?.length === 1) {
        autocompleteRefs[0].lazySearch = '';
      }
    },
    readExcel: function (): void {
      const loadExcelJS = () => import('exceljs');
      this.isLoading = true;
      this.showNeedAssistanceAlert = false;
      loadExcelJS().then((exceljs) => {
        const workbook = new exceljs.Workbook();

        if (this.files[0].type === 'text/csv') {
          const loadPapa = () => import('papaparse');
          loadPapa().then((Papa) => {
            Papa.parse(this.files[0], {
              complete: (results: ParseResult<string>) => {
                const worksheet = workbook.addWorksheet('1');
                worksheet.addRows(results.data);

                const excelService = new ExcelService();
                const data = excelService.parseLoadlist(workbook, Number(this.sheetNumber));
                this.importData = data;
                this.isLoading = false;

                // gets a list of item properties with associated column position
                const itemPropertiesWithColumnIndex = this.headerOptions
                  .map((itemProperty) => ({
                    itemProperty,
                    index: data.options.get(itemProperty.key)?.mapping,
                  }))
                  .sort((a, b) => a.index - b.index);

                const numberOfColumns =
                  this.importData.itemRows.length > 0
                    ? this.importData.itemRows[0].values.length
                    : 0;
                const presentItemPropertiesWithPositions = itemPropertiesWithColumnIndex.filter(
                  (itemPropertyWithPosition) => itemPropertyWithPosition.index !== undefined
                );
                this.columns = this.calculateHeaders(
                  presentItemPropertiesWithPositions,
                  numberOfColumns
                );

                this.updateItemRowsTable();
                this.updateVisibleColumns();

                if (data.dimensions) {
                  this.dimensions = data.dimensions;
                }
              },
            });
          });
        } else {
          const arryBuffer = new Response(this.files[0]).arrayBuffer();

          arryBuffer
            .then((data) => {
              workbook.xlsx
                .load(data)
                .then(() => {
                  const excelService = new ExcelService();
                  const data = excelService.parseLoadlist(workbook, Number(this.sheetNumber));
                  this.importData = data;
                  this.isLoading = false;

                  // gets a list of item properties with associated column position
                  const itemPropertiesWithColumnIndex = this.headerOptions
                    .map((itemProperty) => ({
                      itemProperty,
                      index: data.options.get(itemProperty.key)?.mapping,
                    }))
                    .sort((a, b) => a.index - b.index);

                  const numberOfColumns =
                    this.importData.itemRows.length > 0
                      ? this.importData.itemRows[0].values.length
                      : 0;
                  const presentItemPropertiesWithPositions = itemPropertiesWithColumnIndex.filter(
                    (itemPropertyWithPosition) => itemPropertyWithPosition.index !== undefined
                  );
                  this.columns = this.calculateHeaders(
                    presentItemPropertiesWithPositions,
                    numberOfColumns
                  );

                  this.updateItemRowsTable();
                  this.updateVisibleColumns();

                  if (data.dimensions) {
                    this.dimensions = data.dimensions;
                  }
                })
                .catch((error) => {
                  this.isLoading = false;
                  this.showNeedAssistanceAlert = true;
                });
            })
            .catch((error) => {
              console.error(error);
              this.isLoading = false;
              this.state = 2;
            });
        }
      });
    },
    getIdsForLoadInContainers(loadInString: string | undefined): number[] | undefined {
      if (loadInString) {
        const containerNames = loadInString.trim().split(',');
        const containerIds = this.holdsLibrary
          .filter((hold) =>
            containerNames.some(
              (containerName) => hold.name.toLowerCase() === containerName.trim().toLowerCase()
            )
          )
          .map((h) => h.id);
        return containerIds.length > 0 ? containerIds : undefined;
      }
      return undefined;
    },
    parseNumber(value: string | undefined): number | undefined {
      return isNaN(Number(value)) ? undefined : Number(value);
    },
    isValueInList(value: string, variants: string[]) {
      return variants.some((ans) => ans.toLowerCase() === value.toLowerCase());
    },
    parseBoolean(value: string): boolean | undefined {
      if (this.isValueInList(value, this.truthyAnswers)) {
        return true;
      } else if (this.isValueInList(value, this.falsyAnswers)) {
        return false;
      }
      return undefined;
    },
    parseHoldSelect(value: string): number[] {
      return this.getIdsForLoadInContainers(value);
    },
    parseValue(value: CellValue, type: string) {
      if (value === undefined) {
        return undefined;
      }
      const valueString =
        typeof value === 'object'
          ? (value as CellFormulaValue).result.toString()
          : value.toString();
      switch (type) {
        case 'string':
        case 'list':
          return valueString;
        case 'float':
        case 'integer':
          return this.parseNumber(valueString);
        case 'bool':
          return this.parseBoolean(valueString);
        default:
          return undefined;
      }
    },
    getCustomPropertyValue(
      parsedValue: string | number | boolean | undefined,
      propertyKey: string
    ) {
      if (parsedValue === undefined) {
        return undefined;
      } else if (propertyKey === 'geometry') {
        const lowerCasedValue = (parsedValue as string).toLowerCase().replaceAll(' ', '_');
        const geometryAvailableValues = ItemProperties.props().find(
          (prop) => prop.key === propertyKey
        ).values;
        const geometryValue = geometryAvailableValues.find(
          (value) => value.key === lowerCasedValue
        )?.key;
        return geometryValue;
      } else if (propertyKey === 'allowed_containers') {
        return this.parseHoldSelect(parsedValue as string);
      }
      return parsedValue;
    },
    createItems(itemRows: ItemRow[]) {
      const holdItems = itemRows.map((itemRow) => {
        let holdItem: any = {};
        this.columns.forEach((column) =>
          column.properties.forEach((property) => {
            const importedValue = itemRow.values[column.index];
            const parsedValue = this.parseValue(importedValue, property.type);
            const propertyValue = this.getCustomPropertyValue(parsedValue, property.key);
            holdItem[property.key] = propertyValue;
          })
        );
        return holdItem;
      });
      return holdItems;
    },
    useTemplate(name: string): void {
      const template = JSON.parse(JSON.stringify(this.templates.find((t) => t.name === name)));
      if (!template) return;
      this.columns = template.columns;
      this.sheetNumber = template.sheetNumber;
      this.dimensions.lengthDim = template.lengthDim;
      this.dimensions.weightDim = template.weightDim;
      this.dirty = false;
    },
    deleteTemplate(name: string): void {
      const index = this.templates.findIndex((t) => t.name === name);
      if (index < 0) {
        console.log("Couldn't find selected template");
        return;
      }
      let import_templates = JSON.parse(
        JSON.stringify(this.miscStore.company_settings?.import_templates || [])
      ) as ImportTemplate[];
      import_templates.splice(index, 1);
      this.miscStore
        .updateCompanySettings({ ...(this.miscStore.company_settings || {}), import_templates })
        .catch((e) => console.error(e))
        .finally(() => {
          this.showRemoveTemplate = false;
          this.selectedTemplate = '';
        });
    },
    saveAndDone(): void {
      if (!this.$refs.templateForm.validate()) {
        return;
      }

      this.isLoading = true;
      // Create the template
      let template = JSON.parse(
        JSON.stringify({
          name: this.newTemplate.name,
          description: this.newTemplate.description,
          columns: this.columns,
          sheetNumber: this.sheetNumber,
          lengthDim: this.dimensions.lengthDim,
          weightDim: this.dimensions.weightDim,
        })
      ) as ImportTemplate;
      // Save the template to company settings
      let import_templates = JSON.parse(JSON.stringify(this.templates));
      import_templates.push(template);
      this.miscStore
        .updateCompanySettings({
          ...(this.miscStore.company_settings || {}),
          import_templates,
        })
        .then(() => {
          this.dirty = false;
          this.done();
        })
        .catch((e) => {
          console.error(e);
        });
    },
    done(): void {
      if (this.dirty && this.miscStore.user.is_editor) {
        // Modal to save the changes as a template
        this.showSaveOrImport = true;
        return;
      }
      this.$emit('input', [
        ...(this.excelParseReplaceCurrent ? [] : this.value),
        ...this.createItems(this.filteredRows),
      ]);
      if (this.dimensions) {
        this.$emit('updateWeightDim', this.dimensions.weightDim);
        this.$emit('updateLengthDim', this.dimensions.lengthDim);
      }
      this.showModal = false;
    },
  },
});
</script>

<style scoped>
th {
  user-select: none;
}
.v-data-table {
  flex: 1;
  display: flex;
  flex-direction: column;
}
>>> .v-data-table__wrapper {
  height: 100%;
  position: relative;
}
>>> .v-data-table > .v-data-table__wrapper > table {
  display: block;
  overflow-y: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.header-row {
  position: sticky;
  top: 0;
  z-index: 20;
  background: white;
}
tr th:first-child,
tr td:first-child {
  position: sticky;
  min-width: 50px;
  left: 0;
  z-index: 10;
  background: white;
}
>>> .v-data-table > .v-data-table__wrapper > table::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 12px;
}
>>> .v-data-table > .v-data-table__wrapper > table::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
>>> .v-select__selections > input {
  min-width: 0 !important;
}
.spinner-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pointer {
  cursor: pointer;
}
</style>
