<template>
  <v-container text-center>
    <v-row>
      <v-col cols="10" offset="1" sm="6" offset-sm="3">
        <v-card>
          <v-card-title>
            <img
              style="width: 100%; max-width: 300px; margin-left: auto; margin-right: auto"
              src="../../assets/svg/logo_black.svg" />
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="get_redirect_url" id="get-redirect-url-form">
              <v-text-field label="Your corporate email" v-model="email" type="text"></v-text-field>

              <v-alert outlined type="error" v-model="error">
                We could not find a connection to your corporate login page. Please contact us at
                support@cargo-planner.com or open the support chat below if you have any questions.
              </v-alert>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-btn
              form="get-redirect-url-form"
              block
              color="primary"
              type="submit"
              :loading="isLoading"
              :disabled="!domain || isLoading"
              >Login with SSO</v-btn
            >
          </v-card-actions>
          <v-card-text>
            <router-link to="/login/">Go back to login view</router-link>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import API from '@/API';

export default Vue.extend({
  name: 'sso-login',
  data() {
    return {
      showWrongLoginDetailsAlert: false,
      isLoading: false,
      email: '',
      error: false,
    };
  },
  computed: {
    domain(): string | null {
      const index = this.email.indexOf('@');
      if (index >= 0)
        return this.email
          .slice(index + 1, this.email.length)
          .trim()
          .toLowerCase();
      return null;
    },
  },
  methods: {
    get_redirect_url(): void {
      this.isLoading = true;
      this.error = false;

      API.ssoGetAuthUrl(this.domain)
        .then((data) => {
          window.location.replace(data.data.url);

          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.error = true;
        });
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
