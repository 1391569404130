<template>
  <v-simple-table class="cargo-table" dense>
    <thead>
      <tr>
        <th></th>
        <th
          class="text-center px-2"
          v-for="column in defaultColumns.filter(
            (column) => !['w', 'h', 'w#additional', 'h#additional'].includes(column.key)
          )"
          :key="column.key + column.dim"
          :colspan="['l', 'l#additional'].includes(column.key) ? 3 : 1"
          :class="{
            'left-border': ['l', 'wt', 'l#additional', 'wt#additional'].includes(column.key),
            'right-border': ['wt', 'wt#additional'].includes(column.key),
          }">
          <span v-if="column.key === 'l'">
            {{ loadlist.length_dim }}
          </span>
          <span
            v-else-if="
              column.key &&
              (column.key.startsWith('l#additional') || column.key.startsWith('wt#additional'))
            ">
            {{ column.dim }}
          </span>
          <span v-else-if="column.key === 'wt'">
            {{ loadlist.weight_dim }}
          </span>
          <span v-else-if="column.key === 'chargable_wt'">
            {{ loadlist.weight_dim }}
          </span>
          <span v-else></span>
        </th>
      </tr>
      <tr>
        <th class="text-left"></th>
        <th
          class="text-left"
          v-for="column in defaultColumns"
          :key="column.key + column.dim"
          :class="{
            'left-border': ['l', 'wt', 'l#additional', 'wt#additional'].includes(column.key),
            'right-border': ['wt', 'wt#additional'].includes(column.key),
          }">
          {{ column.text }}
        </th>

        <th class="text-left" v-for="column in customColumns" :key="column">
          {{ column }}
        </th>

        <th class="text-left" v-for="metric in customMetrics" :key="metric.name">
          {{ metric.name }} [{{ metric.unit | uppercase }}]
        </th>
      </tr>
    </thead>
    <template>
      <tbody v-for="(group, index) in bundledItems" :key="index">
        <tr v-for="(item, index2) in group" :key="index2">
          <td class="text-left" style="print-color-adjust: exact">
            <v-icon v-if="!item.from_container" v-bind:style="{ color: item.color }"
              >fa-cube</v-icon
            >
          </td>
          <td
            class="text-left"
            :class="{
              'font-weight-bold': item.from_container,
              'left-border': ['l', 'wt', 'l#additional', 'wt#additional'].includes(column.key),
              'right-border': ['wt', 'wt#additional'].includes(column.key),
            }"
            v-for="column in defaultColumns"
            :key="column.key + column.dim">
            <template v-if="column.key === 'chargable_wt'">
              <template v-if="loadplan.settings.shipping_factor">{{
                toLoadlistDim(chargableWeight(item))
              }}</template>
            </template>
            <template v-else-if="column.key === 'wt'">
              <template v-if="item.wt">{{ item.wt | roundTwoDecimals }} </template>
              <template v-else-if="item.WT">{{
                valueForDim(item.WT, loadlist.weight_dim)
              }}</template>
            </template>
            <template v-else-if="column.key === 'wt#additional'">
              <template v-if="item.wt">{{ toWeightDim(item.WT, column.dim) }}</template>
              <template v-else-if="item.WT">{{ valueForDim(item.WT, column.dim) }}</template>
            </template>
            <template v-else-if="column.key === 'qty'">
              {{ item.qty }}
            </template>
            <template v-else-if="column.key === 'l'">
              <template v-if="item.l">{{ item.l | roundDim(loadlist.length_dim) }}</template>
              <template v-else-if="item.L">{{ valueForDim(item.L, loadlist.length_dim) }}</template>
            </template>
            <template v-else-if="column.key === 'l#additional'">
              <template v-if="item.l">{{ toLengthDim(item.l, column.dim) }}</template>
              <template v-else-if="item.L">{{ valueForDim(item.L, column.dim) }}</template>
            </template>
            <template v-else-if="column.key === 'w'">
              <template v-if="item.w">{{ item.w | roundDim(loadlist.length_dim) }}</template>
              <template v-else-if="item.W">{{ valueForDim(item.W, loadlist.length_dim) }}</template>
            </template>
            <template v-else-if="column.key === 'w#additional'">
              <template v-if="item.w">{{ toLengthDim(item.w, column.dim) }}</template>
              <template v-else-if="item.W">{{ valueForDim(item.W, column.dim) }}</template>
            </template>
            <template v-else-if="column.key === 'h'">
              <template v-if="item.h">{{ item.h | roundDim(loadlist.length_dim) }}</template>
              <template v-else-if="item.H">{{ valueForDim(item.H, loadlist.length_dim) }}</template>
            </template>
            <template v-else-if="column.key === 'h#additional'">
              <template v-if="item.h">{{ toLengthDim(item.h, column.dim) }}</template>
              <template v-else-if="item.H">{{ valueForDim(item.H, column.dim) }}</template>
            </template>
            <template v-else-if="column.type === 'bool'">
              {{ item[column.key] ? 'Yes' : '' }}
            </template>
            <template v-else-if="column.summable && item[column.key]">
              {{ (item.qty || 1) * item[column.key] }}
            </template>
            <template v-else> {{ item[column.key] }} </template>
          </td>

          <td class="text-left" v-for="column in customColumns" :key="column">
            {{ item.metadata ? item.metadata[column] : '' }}
          </td>
          <td class="text-left" v-for="metric in customMetrics" :key="metric.name">
            {{ container.customMetric(metric.formula, item) }}
          </td>
        </tr>
      </tbody>
    </template>

    <tfoot v-if="container.weight">
      <tr>
        <td></td>
        <td
          v-for="column in defaultColumns"
          :key="column.key + column.dim"
          class="font-weight-bold">
          <template v-if="column.summable">
            <template v-if="column.key === 'qty'">
              <span v-if="container.numberOfPallets" class="font-weight-bold">
                {{ container.numberOfPallets }}
                <span class="font-weight-regular">({{ container.items_count }})</span>
              </span>
              <span v-else> {{ container.items_count }}</span>
            </template>
            <template v-else-if="column.key === 'wt'">
              {{ toLoadlistDim(container.weight) }}
            </template>
            <template v-else-if="column.key === 'wt#additional'">
              {{ toWeightDim(container.weight, column.dim) }}
            </template>
            <template v-else-if="column.key === 'chargable_wt'">
              {{ toLoadlistDim(container.chargableWeight(loadplan.settings.shipping_factor)) }}
            </template>
            <template v-else>
              {{
                bundledItems
                  .flat()
                  .filter((i) => parseInt(i[column.key]))
                  .map((i) => parseInt(i[column.key]) * (i.qty || 1))
                  .reduce((acc, cur) => {
                    return acc + cur;
                  }, 0)
              }}
            </template>
          </template>
        </td>
        <td></td>
        <!-- <td class="text-left" v-for="column in customColumns" :key="column">
          {{
            bundledItems
              .flat()
              .filter((i) => i.metadata && !isNaN(i.metadata[column]))
              .map((i) => i.metadata[column] * (i.qty || 1))
              .reduce((acc, cur) => {
                return acc + cur;
              }, 0)
          }}
        </td> -->
        <td class="text-left font-weight-bold" v-for="metric in customMetrics" :key="metric.name">
          {{
            bundledItems.reduce((acc, cur) => {
              return (
                acc +
                cur.reduce((acc2, cur2) => {
                  return container.customMetric(metric.formula, cur2);
                }, 0)
              );
            }, 0)
          }}
        </td>
      </tr>
    </tfoot>
  </v-simple-table>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import ItemProperties, { CustomItemProperty, ItemProperty } from '@/misc/itemProperties';
import dimTools from '@/misc/dimTools';
import { HoldDataWithIndices, HoldItem, Loadlist, Loadplan } from '@/models/LoadlistModel';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { AugmentedHold } from '@/models/augmented/hold';
import itemUtils from '@/misc/itemUtils';
import { CompanySettings } from '@/models/UserCompanyModel';

export default Vue.extend({
  name: 'loadplan-table',
  props: {
    container: {
      type: Object as PropType<AugmentedHold>,
    },
    presentationSettings: {
      type: Object as PropType<CompanySettings>,
      default: null,
    },
    columns: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
    lengthDims: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
    weightDims: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
    additionalLengthDim: {
      type: String,
      default: null,
    },
    additionalWeightDim: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapStores(useLoadlistStore),
    bundledItems(): HoldItem[][] {
      if (this.container.items_count > 0)
        return itemUtils.bundledItems(this.container.cargoesInLayerOrder);
      return itemUtils.bundledItems(this.container.cargoes);
    },
    defaultColumns(): ItemProperty[] {
      let columns = this.columns;
      this.lengthDims.forEach((dim) => {
        const additional = ['l#additional$' + dim, 'w#additional$' + dim, 'h#additional$' + dim];

        let index = columns.findIndex((i) => i.startsWith('wt#additional'));
        if (index < 0) index = columns.findIndex((i) => i.startsWith('h#additional'));
        if (index < 0) index = columns.indexOf('wt');
        if (index < 0) index = columns.indexOf('h');

        if (index >= 0) {
          columns = [...columns.slice(0, index + 1), ...additional, ...columns.slice(index + 1)];
        }
      });
      this.weightDims.forEach((dim) => {
        const additional = 'wt#additional$' + dim;
        let index = columns.findIndex((i) => i.startsWith('h#additional'));
        if (index < 0) index = columns.indexOf('h');
        if (index >= 0) {
          columns = [...columns.slice(0, index + 1), additional, ...columns.slice(index + 1)];
        }
      });

      return columns
        .map((i) => {
          return (
            ItemProperties.props().find((j) => j.key == i) || {
              dim: i.substring(i.indexOf('$') + 1),
              ...ItemProperties.props().find(
                (j: any) => j.key.indexOf('#') >= 0 && i.startsWith(j.key)
              ),
            }
          );
        })
        .filter((i) => !!i);
    },
    customColumns(): string[] {
      return this.container.customColumns;
    },
    customMetrics(): any[] {
      return this.presentationSettings?.metrics || [];
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return this.loadlistStore.loadplan;
    },
  },
  filters: {
    uppercase(val: string): string {
      return val ? val.toUpperCase() : '';
    },
    roundDim(val: number, dim: string): number {
      return dimTools.roundForDim(val, dim);
    },
  },
  methods: {
    valueForDim(value: number, dim: string): number {
      return dimTools.roundForDim(value * dimTools.fromSI(dim), dim);
    },
    toLengthDim(value: number, dim: string): number {
      return this.valueForDim(dimTools.toSI(this.loadlist.length_dim) * value, dim);
    },
    toWeightDim(value: number, dim: string): number {
      return this.valueForDim(value, dim);
    },
    toLoadlistDim(value: number): number {
      return this.valueForDim(value, this.loadlist.weight_dim);
    },
    chargableWeight(item: HoldItem): number {
      return Math.max(item.WT, item.L * item.W * item.H * this.loadplan.settings.shipping_factor);
    },
  },
});
</script>

<style scoped>
.cargo-table th,
.cargo-table td {
  border-collapse: collapse;
}
.cargo-table tbody tr td {
  border-bottom: none !important;
}
.cargo-table tbody tr:last-child td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
}
.cargo-table tbody tr:not(:only-child) td:first-child {
  border-left: 3px solid #4778b2;
}
.left-border {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}
.right-border {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}

@media print {
  .cargo-table {
    page-break-inside: avoid;
  }

  .cargo-table thead tr th,
  .cargo-table tbody tr td,
  .cargo-table tfoot tr td {
    font-size: 0.75rem !important;
    padding: 0 8px !important;
  }
}
</style>
