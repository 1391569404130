<template>
  <v-container>
    <v-alert :value="true" type="error" v-if="!user.accepted_policy">
      You must give your consent to our
      <span @click="showPrivacyPolicy = true" class="link">Privacy Policy</span>
      in order for you to continue using this Site
    </v-alert>

    <subscription></subscription>

    <v-card class="mt-3" v-if="!user.is_sso">
      <v-card-title>
        <p class="text-h6">Password</p>
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Old password"
          :error-messages="errors.old_password"
          v-model="old_password"
          type="password"
          :maxlength="100"></v-text-field>
        <v-text-field
          label="New password"
          :error-messages="errors.password"
          v-model="password"
          type="password"
          :maxlength="100"></v-text-field>
        <v-text-field
          label="Confirm new password"
          v-model="password2"
          type="password"
          :maxlength="100"></v-text-field>
        <v-btn
          block
          :disabled="!old_password || !password || password != password2"
          @click="
            update({
              old_password: old_password,
              password: password,
            })
          "
          color="primary">
          <v-icon left>mdi-sync</v-icon>Update password
        </v-btn>
      </v-card-text>
    </v-card>
    <v-card class="mt-3" v-if="!user.is_sso">
      <v-card-title>
        <p class="text-h6">Email</p>
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Email"
          :error-messages="errors.email"
          v-model="email"
          type="email"
          :maxlength="200"></v-text-field>
        <v-btn block @click="update({ email: new_email })" color="primary">
          <v-icon left>mdi-sync</v-icon>Update email
        </v-btn>
      </v-card-text>
    </v-card>
    <v-expansion-panels v-model="tokenPanel" class="mt-3" v-if="!user.is_sso">
      <v-expansion-panel @click="getToken">
        <v-expansion-panel-header>
          <p class="text-h6">API Keys</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-alert outlined type="info">
            <p>
              API usage may or may not be included in your current plan. If you expect to do more
              than 20 load plans per months, please get in touch with us at
              <a href="mailto:support@cargo-planner.com">support@cargo-planner.com</a>
            </p>
            <p>
              Please have a look at our developer documentation
              <a href="https://cargo-planner.com/docs/developer" target="_blank">here</a>
            </p>
          </v-alert>

          <v-text-field
            v-if="token"
            ref="tokenTextField"
            readonly
            outlined
            :value="token.key"
            :type="showToken ? 'text' : 'password'"
            :append-icon="showToken ? 'mdi-eye' : 'mdi-eye-off'"
            append-outer-icon="mdi-delete"
            :label="`API key (created ${token.created})`"
            @click:append="showToken = !showToken"
            @click:append-outer="deleteToken">
          </v-text-field>

          <v-btn v-else class="primary" @click="createToken">Create token</v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card class="mt-3">
      <v-card-title>
        <p class="text-h6">Privacy</p>
      </v-card-title>
      <v-card-actions>
        <v-btn class="my-2" @click="showPrivacyPolicy = true" color="default">
          <v-icon left>fa-file-contract</v-icon>Privacy policy
        </v-btn>

        <v-btn class="my-2" @click="showDatExportModal = true" color="default">
          <v-icon left>fa-file-export</v-icon>Export my data
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mt-3">
      <v-card-title>
        <p class="text-h6">Danger zone</p>
      </v-card-title>
      <v-card-text>
        <v-btn block class="my-2" @click="showForgetWarning1 = true" color="error">
          <v-icon left>fa-user-slash</v-icon>Delete my account
        </v-btn>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showForgetWarning1" width="600">
      <v-card>
        <v-card-title class="text-h5">Do you want to delete this account?</v-card-title>
        <v-card-text>
          <p>
            Deleting this account will remove all data stored about this account from the database
            and cannot be restored. This includes all loadlists that has been worked on and shared
            to external parties.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click.stop="showForgetWarning1 = false"
            >No, keep my account</v-btn
          >
          <v-spacer />
          <v-btn
            color="error"
            @click.stop="
              showForgetWarning1 = false;
              showForgetWarning2 = true;
            "
            >Yes, delete this account</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showForgetWarning2" width="600">
      <v-card>
        <v-card-title class="text-h5">Are you sure?</v-card-title>
        <v-card-actions>
          <v-btn
            color="error"
            @click.stop="
              showForgetWarning2 = false;
              deleteUser();
            "
            >Yes, delete this account now</v-btn
          >
          <v-spacer />
          <v-btn color="primary" text @click.stop="showForgetWarning2 = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDatExportModal" width="600">
      <v-card>
        <v-card-title class="text-h5">Data export</v-card-title>
        <v-card-text>
          This action will download your data as a text file. Be warned that this can take a few
          seconds to produce, hence you are only allowed to do one export per day.
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="showDatExportModal = false">Cancel</v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="dataExportLoading"
            :loading="dataExportLoading"
            @click.stop="takeout()"
            >Download</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar :timeout="5000" v-model="status_snackbar" top>
      Information updated
      <v-btn text color="primary" @click.native="status_snackbar = false">Ok</v-btn>
    </v-snackbar>

    <privacy-policy-modal
      :visible="showPrivacyPolicy"
      @close="showPrivacyPolicy = false"></privacy-policy-modal>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue';
import API from '@/API';
import Subscription from '@/components/Custom/SubscriptionInfo.vue';
import PrivacyPolicyModal from '@/components/Modals/PrivacyPolicy.vue';
import FileSaver from 'file-saver';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { CompanyStoreTemplate, User } from '@/models/UserCompanyModel';

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        tokenTextField: any;
      };
    }
  >
).extend({
  components: {
    Subscription,
    PrivacyPolicyModal,
  },
  data() {
    return {
      showPrivacyPolicy: false,
      showForgetWarning1: false,
      showForgetWarning2: false,
      showDatExportModal: false,
      dataExportLoading: false,
      errors: {},
      old_password: '',
      password: '',
      password2: '',
      new_email: '',
      email_errors: [],
      password_errors: {
        old_password: [],
        password: [],
      },
      tokenPanel: null,
      token: null,
      showToken: false,
      status_snackbar: false,
    };
  },
  mounted() {
    this.showPrivacyPolicy = !this.user.accepted_policy;
  },
  computed: {
    ...mapStores(useMiscStore),
    email: {
      get(): string {
        return this.user.email;
      },
      set(value: string): void {
        this.new_email = value;
      },
    },
    company(): CompanyStoreTemplate {
      return this.miscStore.company;
    },
    user(): User {
      return this.miscStore.user;
    },
  },
  methods: {
    update(userUpdateObject: any): void {
      this.errors = {};
      this.updateMe(userUpdateObject)
        .then((response) => {
          this.status_snackbar = true;
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.errors = error.response.data;
          }
        });
    },
    deleteUser(): void {
      API.deleteUser(this.user.id).then((response) => {
        this.logout();
      });
    },
    takeout(): void {
      this.dataExportLoading = true;
      API.takeout(this.user.id)
        .then((response) => {
          const blob = new Blob([response.data], {
            type: 'text/json',
          });
          FileSaver.saveAs(blob, 'Takeout.txt');
          this.dataExportLoading = false;
          this.showDatExportModal = false;
        })
        .catch((e) => {
          this.dataExportLoading = false;

          console.log(e);
        });
    },
    logout(): void {
      this.miscStore.logout(null);
    },
    updateMe(userUpdateObject: any): Promise<null> {
      return this.miscStore.updateMe(userUpdateObject);
    },
    getToken(): void {
      if (!isNaN(this.tokenPanel)) this.token = null;
      else
        API.getAuthToken()
          .then((response) => {
            this.token = response.data;
          })
          .catch((e) => {});
    },
    createToken(): void {
      API.createAuthToken()
        .then((response) => {
          this.token = response.data;
        })
        .catch((e) => {});
    },
    deleteToken(): void {
      API.deleteAuthToken()
        .then((response) => {
          this.token = null;
        })
        .catch((e) => {});
    },
    copyToken(): void {
      this.$refs.tokenTextField.$refs.input.select();
      navigator.clipboard.writeText(this.token.key);
    },
  },
});
</script>

<style scoped>
.link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
