import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{attrs:{"text-center":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"10","offset":"1","sm":"6","offset-sm":"3"}},[_c(VCard,[_c(VCardTitle,[_c('img',{staticStyle:{"width":"100%","max-width":"300px","margin-left":"auto","margin-right":"auto"},attrs:{"src":require("../../assets/svg/logo_black.svg")}})]),_c(VCardText,[_c(VForm,{attrs:{"id":"login-form","autocomplete":"on"},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"Username or email","placeholder":" ","type":"text","autocomplete":"username"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTextField,{attrs:{"label":"Password","placeholder":" ","type":"password","autocomplete":"current-password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),(_vm.errors.length)?_c(VAlert,{attrs:{"outlined":"","type":"error"}},_vm._l((_vm.errors),function(e){return _c('span',{key:e},[_vm._v(_vm._s(e))])}),0):_vm._e()],1),_c(VCardActions,[_c(VBtn,{attrs:{"block":"","color":"primary","type":"submit","loading":_vm.isLoading,"disabled":_vm.username.length === 0 || _vm.password.length === 0 || _vm.isLoading,"form":"login-form"}},[_vm._v("Login")])],1),_c(VCardText,[_c('p',[_c('router-link',{attrs:{"to":"/password_reset/"}},[_vm._v(" Forgot your password?")])],1),_c('p',[_vm._v(" Login without password? "),_c('router-link',{attrs:{"to":"/magic-link/"}},[_vm._v("Click here")])],1),_c('p',[_vm._v(" Login with Single Sign On? "),_c('router-link',{attrs:{"to":"/sso-login/"}},[_vm._v("Click here")])],1),_c('p',[_vm._v(" 10 day free trial? "),_c('router-link',{attrs:{"to":"/register/"}},[_vm._v("Sign up here")])],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }