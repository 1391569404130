import {
  Vector3,
  Mesh,
  BoxHelper,
  BoxGeometry,
  MeshLambertMaterial,
  BufferGeometry,
  Material,
  Color,
} from 'three';
import { Container } from '../container';
import { mergeGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { HoldItem } from '@/models/LoadlistModel';
import { BaseItem } from './baseItem';

class NestedItem extends BaseItem {
  hideLabels: boolean;
  itemLabels: string[];
  displayNestedItemAsCargo: boolean;
  selectionBox: Mesh;
  maxPPM: number;

  constructor(
    item_data: HoldItem,
    index: number,
    isInteractive: boolean,
    hideLabels: boolean,
    itemLabels: string[],
    displayNestedItemAsCargo: boolean,
    maxPPM: number
  ) {
    super(item_data, index, isInteractive);
    this.hideLabels = hideLabels;
    this.itemLabels = itemLabels;
    this.displayNestedItemAsCargo = displayNestedItemAsCargo;
    this.maxPPM = maxPPM;
    // @ts-ignore
    this.type = 'NestedItem';
  }
  async init(): Promise<void> {
    const containerObject = new Container(this.userData.item.from_container);
    await containerObject.init(
      null,
      0,
      this.hideLabels,
      this.itemLabels,
      this.displayNestedItemAsCargo,
      this.maxPPM
    );

    containerObject.updateWorldMatrix(true, true);
    const geometries: (BoxGeometry | BufferGeometry)[] = [];
    const materials: Material[] = [];

    containerObject.traverse((c) => {
      const child = c as Mesh;
      if (
        child.geometry &&
        child.material &&
        child.geometry.type !== 'EdgesGeometry' &&
        (child.material as Material).type !== 'LineBasicMaterial' &&
        child.type != 'Sprite'
      ) {
        let geometry = child.geometry.clone();
        const material = Array.isArray(child.material) ? child.material[0] : child.material;
        materials.push(material as Material);
        geometry.applyMatrix4(child.matrixWorld);
        if (geometry.index) {
          geometry = geometry.toNonIndexed();
        }
        geometries.push(geometry);
      }
    });

    const mergeGeometry = mergeGeometries(geometries, true);
    mergeGeometry.computeBoundingBox();
    const boxSize = new Vector3();
    mergeGeometry.boundingBox.getSize(boxSize);

    mergeGeometry.translate(
      -boxSize.x * 0.5 - mergeGeometry.boundingBox.min.x,
      -boxSize.y * 0.5 - mergeGeometry.boundingBox.min.y,
      -boxSize.z * 0.5 - mergeGeometry.boundingBox.min.z
    );
    this.geometry = mergeGeometry.clone();
    this.material = materials;

    // Add a helper object around each nested item
    const box = new BoxHelper(this, new Color(0xffff00));
    this.add(box);

    const boxGeometry = new BoxGeometry(boxSize.x, boxSize.y, boxSize.z);
    this.selectionBox = new Mesh(
      boxGeometry,
      new MeshLambertMaterial({
        color: 0xaaaaaa,
        emissive: 0xffffff,
        transparent: true,
        opacity: 0,
      })
    );
    this.selectionBox.scale.set(1.01, 1.01, 1.01);
    this.add(this.selectionBox);

    this.position.set(this.userData.item.pos.x, this.userData.item.pos.y, this.userData.item.pos.z);
    if (Array.isArray(this.userData.item.rotation)) {
      this.rotation.fromArray(this.userData.item.rotation);
    }

    this.scale.set(0.99, 0.99, 1.0);

    this.updateMatrix();
  }

  setSelectionOpacity(opacity = 0): void {
    (this.selectionBox.material as Material).opacity = opacity;
  }
  setColor(color = 0): void {
    if (color) {
      this.setSelectionOpacity(0.3);
    } else {
      this.setSelectionOpacity();
    }
  }

  setOpacity(opacity: number): void {
    // TODO: this doesn't work great for pallets with walls
    (this.material as Material[]).forEach((_, index) => {
      if (!this.isInteractive)
        (this.material as Material[])[index] = (this.material as Material[])[index].clone();
      (this.material as Material[])[index].transparent = opacity < 1.0;
      (this.material as Material[])[index].opacity = opacity;
    });
  }
  select(): void {
    super.select();
    this.setSelectionOpacity(0.5);
  }

  deselect(): void {
    super.deselect();
    this.setSelectionOpacity();
  }
}

export { NestedItem };
