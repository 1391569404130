<template>
  <div>
    <v-btn color="success" @click="addRule"> <v-icon>mdi-plus</v-icon>Add new rule </v-btn>
    <v-expansion-panels class="py-4 px-1" ref="loadRules" v-model="panel">
      <v-expansion-panel v-for="(ruleWithHeader, ruleIndex) in rulesWithHeaders" :key="ruleIndex">
        <v-expansion-panel-header>
          <span
            ><strong>{{ ruleWithHeader.title }}</strong></span
          >
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <load-rule-component
            :ref="'load-rule-' + ruleIndex"
            :itemRules="itemRules"
            :value="ruleWithHeader.rule"
            @input="(v) => updateRule(v, ruleIndex)"
            :key="ruleIndex"
            :ruleIndex="ruleIndex"
            :holds="holds"
            :items="items"
            :typeName="typeName"
            :class_ids="class_ids"
            @removeRule="removeRule" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-alert v-if="!rulesWithHeaders.length" type="info" outlined>No custom rules in use</v-alert>
  </div>
</template>

<script lang="ts">
import { HoldData, HoldInputItem, ItemSelector, LoadRule } from '@/models/LoadlistModel';
import Vue, { PropType } from 'vue';
import LoadRuleComponent from './LoadRule.vue';
import itemProperties, { ItemProperty } from '@/misc/itemProperties';
export default Vue.extend({
  name: 'custom-rules-editor',
  components: {
    LoadRuleComponent,
  },
  data: function () {
    return {
      panel: null,
      properties: itemProperties.props(),
      rulesWithHeaders: [] as { rule: LoadRule; title: string }[],
      itemRules: [
        {
          text: 'Cannot be loaded in',
          value: 'cannot_be_loaded_in',
        },
        {
          text: 'Cannot exceed quantity',
          value: 'max_qty',
        },
        {
          text: 'Cannot support',
          value: 'cannot_support',
        },
        {
          text: 'Can only support',
          value: 'can_only_support',
        },
        {
          text: 'Must be loaded in',
          value: 'must_be_loaded_in',
        },
        {
          text: 'Must be loaded first',
          value: 'must_be_loaded_first',
        },
        {
          text: 'Must be loaded along centerline',
          value: 'must_be_loaded_along_centerline',
        },
        {
          text: 'Must be loaded into secondary (palletize) containers',
          value: 'load_into_secondary',
        },
        {
          text: 'Must load with pattern',
          value: 'must_load_with_pattern',
        },
      ],
    };
  },
  props: {
    rules: Array as PropType<LoadRule[]>,
    typeName: String,
    holds: {
      type: Array as PropType<HoldData[]>,
      default: () => [] as HoldData[],
    },
    items: {
      type: Array as PropType<HoldInputItem[]>,
      default: () => [] as HoldInputItem[],
    },
    class_ids: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
  },
  computed: {
    itemProperties(): ItemProperty[] {
      return itemProperties.props();
    },
  },
  watch: {
    rules: {
      handler: function (rules: LoadRule[]): void {
        if (rules) {
          this.setRulesWithHeaders(rules);
        }
      },
      immediate: true,
    },
  },
  methods: {
    addRule(): void {
      this.$emit('addRule', {
        items: {
          property: 'sku',
          comparison: 'eq',
          value: 0,
        },
        condition: 'cannot_be_loaded_in',
        value: null,
        in_containers_with_ids: [],
      } as LoadRule);
      this.panel = (this.$refs as any).loadRules.items.length;
    },
    removeRule(i: number): void {
      this.panel = null;
      this.$emit('removeRule', i);
    },
    updateRule(v: any, ruleIndex: number): void {
      this.rules[ruleIndex] = v;
      this.setRulesWithHeaders(this.rules);
    },
    getRuleName(rule: LoadRule, ruleIndex: number): string {
      const itemSelector = rule.items as ItemSelector;
      const propertyName =
        this.itemProperties.find((prop) => prop.key === itemSelector?.property)?.text ||
        'Selected Items';
      const conditionName = this.itemRules.find(
        (itemRule) => itemRule.value === rule.condition
      ).text;
      const numberOfContainers = rule.in_containers_with_ids.length;
      return `Rule ${ruleIndex + 1} - ${propertyName} → ${conditionName} → ${
        numberOfContainers > 0
          ? `${numberOfContainers} ${this.typeName}(s)`
          : `Any ${this.typeName}s`
      }`;
    },
    setRulesWithHeaders(rules: LoadRule[]): void {
      this.rulesWithHeaders = rules.map((rule, index) => ({
        rule,
        title: this.getRuleName(rule, index),
      }));
    },
  },
});
</script>
