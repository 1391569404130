// To save how the user last setup the view in localstorage,
// instead of having independant variables that have the same function

import { ListType } from '@/models/LoadlistModel';

// start with UsageSettings.fetch(). All changes to variables will be auto-saved
export class UsageSettings {
  // values with defaults
  private _groupSimilar = true;
  private _defaultWorkspaceView = 'table';
  private _currentBaseType: ListType = 'SEA';
  private _showQuotations = false;
  private _hideProjects = false;
  private _loadlistView = 0;

  public get loadlistView(): number {
    return this._loadlistView;
  }
  public set loadlistView(value: number) {
    this._loadlistView = value;
    this.save();
  }

  public get showQuotations(): boolean {
    return this._showQuotations;
  }
  public set showQuotations(value: boolean) {
    this._showQuotations = value;
    this.save();
  }

  public get currentBaseType(): ListType {
    return this._currentBaseType;
  }
  public set currentBaseType(value: ListType) {
    this._currentBaseType = value;
    this.save();
  }

  public get groupSimilar(): boolean {
    return this._groupSimilar;
  }
  public set groupSimilar(value: boolean) {
    this._groupSimilar = value;
    this.save();
  }
  public get hideProjects(): boolean {
    return this._hideProjects;
  }
  public set hideProjects(value: boolean) {
    this._hideProjects = value;
    this.save();
  }
  public get defaultWorkspaceView(): string {
    return this._defaultWorkspaceView;
  }
  public set defaultWorkspaceView(value: string) {
    this._defaultWorkspaceView = value;
    this.save();
  }

  static localKey = 'usageSettings';

  static fetch(): UsageSettings {
    const storage = localStorage.getItem(UsageSettings.localKey);
    return storage ? Object.assign(new UsageSettings(), JSON.parse(storage)) : new UsageSettings();
  }

  public save(): void {
    new Promise(() => {
      localStorage.setItem(UsageSettings.localKey, JSON.stringify(this));
    });
  }
}
