<template>
  <div class="no-print">
    <v-navigation-drawer
      right
      app
      stateless
      clipped
      width="500"
      dark
      hide-overlay
      :value="show"
      :mini-variant="isMini"
      id="unloadedItemsToolbar"
      v-resize.quiet="onResize">
      <v-toolbar dense flat>
        <v-btn :ripple="false" icon @click="isMini = !isMini">
          <v-icon v-if="isMini" color="red" v-bind:class="{ blink: highlightWarningSymbol }"
            >mdi-alert</v-icon
          >
          <v-icon v-else v-bind:class="{ blink: highlightWarningSymbol }">mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>Unloaded Items</v-toolbar-title>
      </v-toolbar>
      <div v-if="!isMini">
        <v-container>
          <v-text-field
            label="Search for cargoes"
            single-line
            hide-details
            v-model="searchField"
            prepend-icon="mdi-magnify"
            clearable></v-text-field>
        </v-container>
        <v-toolbar dense flat v-if="unloaded_items_ordered.length">
          <v-select
            :items="orderByItems"
            v-model="orderBy"
            item-text="title"
            item-value="value"
            prepend-icon="mdi-sort-variant"
            hide-details="false"></v-select>

          <v-btn icon @click="isAsc = !isAsc" :ripple="false">
            <v-icon v-if="isAsc">mdi-chevron-down</v-icon>
            <v-icon v-else>mdi-chevron-up</v-icon>
          </v-btn>
          <v-btn text @click="selectAll" :ripple="false">
            <v-icon>mdi-checkbox-multiple-marked </v-icon>
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                :ripple="false"
                @click="showRemoveUnloadedItemsDialog = true"
                v-on="on"
                :disabled="!selected_items.length">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Remove selected unloaded items from this list</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                :ripple="false"
                @click="createNewLoadlist"
                v-on="on"
                :disabled="!selected_items.length">
                <v-icon>mdi-file-move</v-icon>
              </v-btn>
            </template>
            <span>Create new loadlist with these items</span>
          </v-tooltip>
        </v-toolbar>
        <div ref="itemsList">
          <v-virtual-scroll
            :items="unloaded_items_ordered"
            :height="itemsContainerHeight"
            item-height="85">
            <template v-slot:default="{ item }">
              <v-tooltip bottom :key="item.uid">
                <template v-slot:activator="{ on }">
                  <v-list-item
                    v-on="on"
                    :key="item.uid"
                    @dragstart="dragStart(item.uid, $event)"
                    :disabled="is_calculating"
                    style="cursor: pointer"
                    :ripple="false">
                    <v-list-item-avatar>
                      <v-checkbox
                        :input-value="checkedItems.includes(item.uid)"
                        @change="selectItem(item.uid)"></v-checkbox>
                    </v-list-item-avatar>

                    <v-list-item-content @mousedown="selectItem(item.uid)" :draggable="true">
                      <v-list-item-title class="text-subtitle-1">
                        <v-icon v-bind:style="{ color: item.color }">fa-cube</v-icon>
                        {{ item[itemLabel] }}
                        <v-chip class="mx-1" small v-if="item.shipment_id">
                          {{ item.shipment_id }}
                        </v-chip>
                        <v-chip class="mx-1" small v-if="item.priority">
                          Priority {{ item.priority }}
                        </v-chip>
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        {{ item.qty }} pcs x
                        {{ (item.l * $toSI(loadlist.length_dim)) | toLength }}
                        x
                        {{ (item.w * $toSI(loadlist.length_dim)) | toLength }}
                        x
                        {{ (item.h * $toSI(loadlist.length_dim)) | toLength }},
                        {{ (item.wt * $toSI(loadlist.weight_dim)) | toWeight }}
                        {{ item.not_stackable ? ' ≠ ' : '' }}
                        {{ item.orientations <= 3 ? ' ⇈ ' : '' }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle> </v-list-item-subtitle>

                      <span v-if="item.reason"
                        ><v-icon small color="red">mdi-alert</v-icon
                        >{{ item.reason | snakeToTitleCase }}</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <span
                  ><span class="font-weight-bold">{{ item.label || item.sku }} </span>
                  <br /><br />Drag to a container to load the selected item(s)<span
                    v-if="item.qty > 1"
                    ><br />Press <kbd>Alt</kbd> key to only load one of the items</span
                  >
                </span>
              </v-tooltip>
            </template>
          </v-virtual-scroll>
        </div>
      </div>
    </v-navigation-drawer>
    <v-snackbar app :timeout="10000" top v-model="showSnackbar" multi-line>
      {{ snackBarText }}
      <v-btn text color="pink" @click.native="showSnackbar = false">Ok</v-btn>
    </v-snackbar>

    <new-list-modal
      ref="newListModal"
      :visible="showAddNewLoadlistModal"
      @close="showAddNewLoadlistModal = false"
      @created="
        snackBarText = 'Loadlist created';
        showSnackbar = true;
      "></new-list-modal>
    <v-dialog v-if="showRemoveUnloadedItemsDialog" v-model="showRemoveUnloadedItemsDialog">
      <v-card>
        <v-card-title> Remove selected unloaded items from this loadlist? </v-card-title>

        <v-card-actions>
          <v-btn text @click.stop="showRemoveUnloadedItemsDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            class="error"
            @click.stop="
              removeUnloadedItems();
              showRemoveUnloadedItemsDialog = false;
            "
            >Yes, delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import newListModal from '@/components/Modals/NewList.vue';
import { HoldInputItem, Loadlist, UnloadedItem } from '@/models/LoadlistModel';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { useMiscStore } from '@/stores/miscStore';
import ItemUtils from '@/misc/itemUtils';
import { UserPreferences } from '@/models/UserCompanyModel';

export default Vue.extend({
  name: 'unloadedItems',
  components: {
    newListModal,
  },
  data() {
    return {
      show: false,
      isMini: true,
      isAsc: false,
      checkedItems: [],
      itemsContainerHeight: '500',
      orderBy: 'sku',
      orderByItems: [
        {
          title: 'SKU',
          value: 'sku',
        },
        {
          title: 'Name',
          value: 'label',
        },
        {
          title: 'Length',
          value: 'l',
        },
        {
          title: 'Width',
          value: 'w',
        },
        {
          title: 'Height',
          value: 'h',
        },
        {
          title: 'Weight',
          value: 'wt',
        },
        {
          title: 'Consignment',
          value: 'shipment_id',
        },
        {
          title: 'Priority',
          value: 'priority',
        },
      ],
      selectAllToggle: true,
      searchField: '',
      showSnackbar: false,
      snackBarText: '',
      showAddNewLoadlistModal: false,
      showRemoveUnloadedItemsDialog: false,
      highlightWarningSymbol: false,
    };
  },
  watch: {
    unloaded_items_length: {
      handler: function (newVal: number, oldVal: number): void {
        if (oldVal < newVal) {
          this.checkedItems = [];
          this.highlightWarningSymbol = true;
          setTimeout(() => {
            this.highlightWarningSymbol = false;
          }, 1000);
        }
        if (oldVal === 0 && newVal > 0) {
          this.expand(null);
        }

        this.show = newVal > 0;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapStores(useMiscStore, useLoadlistStore),
    preferences(): UserPreferences {
      return this.miscStore.preferences;
    },
    itemLabel(): string {
      return [...(this.preferences.item_labels || []), 'sku'].filter((i) => ['sku', 'label'])[0];
    },
    unloaded_items_ordered(): any[] {
      const sortItems = require('vuetify/lib/util/helpers.js').sortItems;
      let ordered = sortItems([...this.unloaded_items], [this.orderBy], [!this.isAsc]);
      if (this.searchField) {
        return ordered.filter(
          (item: any) => item.label.toLowerCase().indexOf(this.searchField.toLowerCase()) > -1
        );
      }
      return ordered;
    },
    unloaded_items_length(): number {
      return this.unloaded_items.length;
    },
    selected_items(): any[] {
      return this.unloaded_items_ordered.filter((i) => this.checkedItems.indexOf(i.uid) > -1);
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
    unloaded_items(): UnloadedItem[] {
      return this.loadlistStore.unloaded_items;
    },
    is_calculating(): boolean {
      return this.loadlistStore.is_calculating;
    },
  },
  methods: {
    expand(e: any): void {
      this.isMini = false;
    },
    selectAll(e: any): void {
      if (this.selectAllToggle) this.checkedItems = this.unloaded_items_ordered.map((i) => i.uid);
      else this.checkedItems = [];
      this.selectAllToggle = !this.selectAllToggle;
    },
    selectItem(item: any): void {
      // check
      const index = this.checkedItems.indexOf(item);
      if (index >= 0) {
        this.checkedItems.splice(index, 1);
      } else {
        this.checkedItems.push(item);
      }
    },
    dragStart(item: any, e: any): void {
      if (this.checkedItems.indexOf(item) < 0) this.checkedItems.push(item);
      let loadItems = this.selected_items as HoldInputItem[];
      if (e.altKey)
        loadItems = loadItems.map((i) => {
          return {
            ...i,
            qty: 1,
          };
        });
      e.dataTransfer.setData('text', JSON.stringify({ unloaded: loadItems }));
    },
    createNewLoadlist(): void {
      (this.$refs.newListModal as any).extendLoadlist({
        data: JSON.parse(JSON.stringify(this.selected_items)),
        length_dim: this.loadlist.length_dim,
        weight_dim: this.loadlist.weight_dim,
      });
      this.showAddNewLoadlistModal = true;
    },
    removeUnloadedItems() {
      this.loadlistStore.setLoadlistProperty({
        key: 'data',
        value: ItemUtils.removeItemsFromLoadlist(
          this.loadlist.data,
          JSON.parse(JSON.stringify(this.selected_items))
        ),
      });
    },
    onResize(): void {
      const itemsList = this.$refs.itemsList as HTMLElement;
      const navBar = this.$el.firstChild as HTMLElement;
      if (itemsList && navBar) {
        let top = itemsList.getBoundingClientRect().top;
        let bottom = navBar.getBoundingClientRect().bottom;
        const valueString = (bottom - top).toString();
        this.itemsContainerHeight = valueString;
      }
    },
  },
});
</script>

<style scoped>
.blink {
  animation: color-change 0.2s infinite;
}

@keyframes color-change {
  0% {
    color: red;
    transform: scale(1);
  }
  50% {
    color: orange;
    transform: scale(1.3);
  }
  100% {
    color: red;
    transform: scale(1);
  }
}
</style>
