<template>
  <div>
    <v-toolbar dense class="no-print my-2" elevation="1">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" :ripple="false" @click="showSummary = !showSummary">
            <v-icon>mdi-format-list-bulleted-square</v-icon>
          </v-btn>
        </template>
        <span>Loadlist summary</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="print" id="loadplanPrintButton">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </template>
        <span class="no-print">Print or save as PDF</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
            :disabled="lite_version"
            @click="getXlsx"
            id="loadplanDownloadExcelButton">
            <v-icon>fa-file-excel</v-icon>
          </v-btn>
        </template>
        <span v-if="!lite_version">Download loadplan as Excel file</span>
        <span v-else>Only Standard version allows loadplan to Excel</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="showLoadplanColumnsModal = true" id="loadplanColumnsButton">
            <v-icon>mdi-table-cog</v-icon>
          </v-btn>
        </template>
        <span>Set visible columns</span>
      </v-tooltip>
      <!-- <v-tooltip bottom v-if="usageSettings.showQuotations">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
            @click="showQuotationModal = true"
            id="loadplanQuotationButton"
          >
            <v-icon>mdi-currency-usd</v-icon>
          </v-btn>
        </template>
        <span>Set quotation settings</span>
      </v-tooltip> -->
      <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
      <v-menu offset-y v-model="showViewSettingsMenu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :icon="$vuetify.breakpoint.xsOnly"
            :text="$vuetify.breakpoint.smAndUp"
            v-bind="attrs"
            v-on="on"
            id="loadplanViewSettingsMenu">
            <v-icon left>mdi-tune</v-icon>
            <template v-if="$vuetify.breakpoint.smAndUp">View settings</template>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-checkbox v-model="simpleMode" label="Simple mode"></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox v-model="showRuler" label="Show Ruler"></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="usageSettings.groupSimilar"
              @change="page = 1"
              :label="`Group similar ${typeName}s`"></v-checkbox>
          </v-list-item>

          <v-menu offset-x left open-on-hover>
            <template v-slot:activator="{ on }">
              <v-list-item @click.stop.prevent v-on="on">
                <v-list-item-title>Show instructions</v-list-item-title>
                <v-list-item-action>
                  <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
            <v-list>
              <v-radio-group v-model="showInstructions">
                <v-list-item><v-radio value="" label="No instructions" /> </v-list-item>
                <v-list-item><v-radio value="true" label="In Layers" /> </v-list-item>
                <v-list-item><v-radio value="stacks" label="In Stacks" /> </v-list-item>
              </v-radio-group>
            </v-list>
          </v-menu>

          <v-list-item>
            <v-checkbox v-model="showWatermark" label="Print with company watermark"></v-checkbox>
          </v-list-item>

          <v-menu offset-x left open-on-hover>
            <template v-slot:activator="{ on }">
              <v-list-item @click.stop.prevent v-on="on">
                <v-list-item-title>Show dimensions</v-list-item-title>
                <v-list-item-action>
                  <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
            <v-list>
              <v-list-item
                v-for="i in lengthDimensionOptions.filter((i) => loadlist.length_dim != i)"
                :key="i">
                <v-checkbox v-model="lengthDimensions" :label="i" :value="i"></v-checkbox>
              </v-list-item>
              <v-divider />
              <v-list-item
                v-for="i in weightDimensionOptions.filter((i) => loadlist.weight_dim !== i)"
                :key="i">
                <v-checkbox v-model="weightDimensions" :label="i" :value="i"></v-checkbox>
              </v-list-item>
            </v-list>
          </v-menu>

          <!-- <v-list-item>
            <v-checkbox
              v-model="usageSettings.showQuotations"
              label="Show quotations"
            ></v-checkbox>
          </v-list-item> -->
        </v-list>
      </v-menu>
    </v-toolbar>

    <v-card class="my-2" v-if="showSummary">
      <v-card-title> Loadlist summary </v-card-title>
      <v-card-text>
        <summary-table></summary-table>
      </v-card-text>
    </v-card>

    <v-alert class="text-subtitle-1 my-1" color="info" outlined>
      <v-row>
        <v-col>
          <div class="text-h6">Notes</div>
          <div>{{ loadlist.notes }}</div>
          <div>{{ loadplan.notes }}</div>
        </v-col>
        <v-col class="d-flex align-end flex-column">
          <p>Prepared by: {{ loadlist.owner }}</p>
          <v-tooltip bottom v-if="is_authenticated">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-switch class="no-print" v-model="locked" inset label="Locked"></v-switch>
              </div>
            </template>
            <span>Lock this list - no modifications can be done while it is locked</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <div class="mb-8" v-if="!!totalQuotation && usageSettings.showQuotations">
        Quotation total: {{ currencySymbol }}{{ totalQuotation }}
      </div>

      <v-expansion-panels v-if="unloaded_items.length" v-model="showUnloaded" flat>
        <v-expansion-panel>
          <v-expansion-panel-header class="pa-0">
            <v-alert v-if="unloaded_items.length" class="text-subtitle-1" type="error" outlined
              >Some cargoes are not loaded</v-alert
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <container-table
              ref="notLoaded"
              :container="unloadedItemsContainer"
              :columns="sortedColumns"
              :presentationSettings="presentationSettings"
              :lengthDims="lengthDimensions"
              :weightDims="weightDimensions" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-alert
        v-if="loadplan.settings && loadplan.settings.error_margin"
        class="text-subtitle-1"
        type="error"
        outlined
        >Error margin: {{ loadplan.settings.error_margin }} %</v-alert
      >
    </v-alert>

    <hold-component
      ref="container"
      v-for="(container, index) in holds"
      :key="container.hold.uid"
      :id="container.hold.uuid"
      :container="container"
      :lengthDimensions="lengthDimensions"
      :weightDimensions="weightDimensions"
      :simpleMode="simpleMode"
      :showRuler="showRuler"
      :shipping_factor="loadplan.settings.shipping_factor"
      :columns="sortedColumns"
      :presentationSettings="presentationSettings"
      :showInstructions="showInstructions"
      :locked="locked"
      ><template v-slot:set-scene>
        <set-display :set="shouldRenderSet(container.hold, index)" :locked="locked" /> </template
    ></hold-component>

    <loadplan-columns
      v-if="showLoadplanColumnsModal"
      :visible="showLoadplanColumnsModal"
      @close="showLoadplanColumnsModal = false"
      @columnsUpdated="updatedColumns = $event"
      :default-columns="defaultColumns"></loadplan-columns>

    <quotation-component
      :visible="showQuotationModal"
      :holdTypes="holdTypes"
      :quotations="defaultQuotations"
      @close="showQuotationModal = false"
      @update="updateDefaultQuotation" />

    <v-pagination
      class="text-center no-print"
      :length="noPageButtons"
      v-model="page"
      @input="pageChange"></v-pagination>
    <img class="watermark" v-if="logo_url && showWatermark" :src="logo_url" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import FileSaver from 'file-saver';
import sceneComponent from '@/components/Custom/SceneComponent.vue';
import SummaryTable from '@/components/LoadlistDetail/Summary/SummaryTable.vue';
import loadplanColumns from '@/components/Modals/LoadplanColumns.vue';
import ContainerTable from './Table.vue';
import setDisplay from './SetDisplay.vue';
import HoldComponent from './Hold.vue';

import DimTools from '@/misc/dimTools';
import { VIEWS } from '@/graphics/sceneManager';
import ExcelService from '@/services/excelService';
import TableUtils from '@/misc/tableUtils';

import {
  HoldData,
  HoldDataWithIndices,
  UnloadedItem,
  Currency,
  KeyValue,
  Loadlist,
  Loadplan,
  Quotation,
  HoldItem,
} from '@/models/LoadlistModel';
import { UserPreferences, User, CompanySettings } from '@/models/UserCompanyModel';
import { Workbook } from 'exceljs';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { GroupedWorkerResponse } from '@/models/GroupingModel';
import quotationComponent from '../../Modals/Quotation.vue';
import { GroupedSet } from '@/models/SetsModel';
import { UsageSettings } from '../../../stores/usageSettings';
import { AugmentedHold } from '@/models/augmented/hold';
import { TablesData } from '@/models/InputDataModel';
import filters from '../../../filters';

const DEFAULT_COLUMNS = ['sku', 'label', 'qty', 'l', 'w', 'h', 'wt', 'not_stackable'];
const DEFAULT_PER_PAGE = 5;
export default Vue.extend({
  name: 'loadplan',
  components: {
    sceneComponent,
    ContainerTable,
    loadplanColumns,
    quotationComponent,
    setDisplay,
    HoldComponent,
    SummaryTable,
  },
  data() {
    return {
      groupedHolds: [] as HoldDataWithIndices[],
      sets: [] as GroupedSet[],
      showRuler: false,
      simpleMode: false,
      usageSettings: UsageSettings.fetch(),
      perPage: DEFAULT_PER_PAGE,
      page: 1,
      orderBy: '',
      isAsc: false,
      selectedColumns: [] as string[],
      isLoadingExcel: false,
      showUnloaded: null as number,
      showSummary: false,
      orderByOptions: [
        { text: 'None', value: '' },
        { text: 'Type', value: 'name' },
        { text: 'Weight', value: 'WT' },
        { text: 'Volume', value: 'volume' },
        { text: 'Shipment', value: 'shipments' },
      ],
      // instructionsView: "",
      // instructionOptions: [
      //   { text: "None", value: "" },
      //   { text: "In Perspective", value: VIEWS.THREED_PERSPECTIVE },
      //   { text: "From Side", value: VIEWS.THREED },
      // ],
      showInstructions: '',
      lengthDimensions: [] as string[],
      lengthDimensionOptions: ['MM', 'CM', 'DM', 'M', 'IN', 'FT'],
      weightDimensions: [] as string[],
      weightDimensionOptions: ['KG', 'LB', 'MT'],
      showWatermark: false,
      showLoadplanColumnsModal: false,
      showQuotationModal: false,
      showViewSettingsMenu: false,
      updatedColumns: [] as string[],
      volumeUnit: '',
      worker: null as Worker,
      totalQuotation: null as number,
      printTimer: null,
    };
  },
  watch: {
    'loadplan.holds': {
      handler: function (): void {
        this.updateGroups();
      },
      immediate: true,
    },
    'usageSettings.groupSimilar': {
      handler: function (a: boolean): void {
        this.updateGroups();
      },
      // immediate: true,
    },
  },
  computed: {
    ...mapStores(useMiscStore, useLoadlistStore),
    currencySymbol(): string {
      return this.quotation?.currency?.symbol || '$';
    },
    noPageButtons(): number {
      return Math.ceil(Math.max(this.groupedHolds.length / this.perPage, 1));
    },
    views(): {
      TOP: string;
      SIDE: string;
      SIDE2: string;
      FRONT: string;
      THREED: string;
      CUSTOM: string;
    } {
      return VIEWS;
    },
    holds(): AugmentedHold[] {
      let holds = this.groupedHolds;

      if (this.orderBy.length > 0) {
        holds = [...holds].sort((a, b) => {
          let aVal = a[this.orderBy as keyof HoldData];
          let bVal = b[this.orderBy as keyof HoldData];

          if (this.orderBy === 'shipments') {
            aVal = parseInt(aVal) || 0;
            bVal = parseInt(bVal) || 0;
          }
          return this.isAsc ? (aVal > bVal ? 1 : -1) : aVal > bVal ? -1 : 1;
        });
      }

      return holds
        .slice((this.page - 1) * this.perPage, (this.page - 1) * this.perPage + this.perPage)
        .map((h) => new AugmentedHold(h));
    },
    summary(): {
      no: number;
      volume: number;
      weight: number;
      cost: number;
      holdTypes: Record<string, number>;
    } {
      const s = {
        no: 0,
        volume: 0,
        weight: 0,
        cost: 0,
        holdTypes: {} as Record<string, number>,
      };
      this.loadplan.holds.forEach((h) => {
        s.volume += h.volume;
        s.weight += h.WT;
        s.no += h.items_count || 0;
        s.cost += h.cost || 0;
        s.holdTypes[h.name] = (s.holdTypes[h.name] || 0) + 1;
      });
      return s;
    },
    holdTypes(): { id: number; name: string }[] {
      const holdTypes: { id: number; name: string }[] = [];
      this.loadplan.holds.forEach((h) => {
        if (!holdTypes.find((i) => i.id === h.id)) holdTypes.push({ id: h.id, name: h.name });
      });
      return holdTypes;
    },
    defaultColumns(): string[] {
      return [
        ...DEFAULT_COLUMNS,
        ...(this.loadplan.settings.shipping_factor ? ['chargable_wt'] : []),
      ];
    },
    presentationSettings(): CompanySettings {
      return this.is_authenticated
        ? this.miscStore.company_settings
        : this.loadlist.presentation_settings;
    },
    columns(): string[] {
      if (this.selectedColumns?.length > 0) {
        return this.selectedColumns;
      }
      if (this.is_authenticated)
        return this.preferences?.visible_loadplan_columns || this.defaultColumns;
      return this.updatedColumns.length ? this.updatedColumns : this.defaultColumns;
    },
    sortedColumns(): string[] {
      return [...this.columns].sort((a, b) => {
        const indexA = DEFAULT_COLUMNS.indexOf(a);
        const indexB = DEFAULT_COLUMNS.indexOf(b);

        if (indexA === -1 && indexB === -1) return 0;
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
        return indexA - indexB;
      });
    },
    isImperial(): boolean {
      return this.loadlist.weight_dim === 'LB' || this.loadlist.length_dim === 'IN';
    },

    user(): User {
      return this.miscStore.user;
    },
    is_authenticated(): boolean {
      return this.miscStore.is_authenticated;
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return this.loadlistStore.loadplan;
    },
    loadplan_version(): number {
      return this.loadlistStore.loadplan_version;
    },
    unloaded_items(): UnloadedItem[] {
      return this.loadlistStore.unloaded_items;
    },
    lite_version(): boolean {
      return this.miscStore.lite_version;
    },
    preferences(): UserPreferences {
      return this.miscStore.preferences;
    },
    logo_url(): string {
      return this.loadlistStore.logo_url;
    },
    length_dim(): string {
      return this.miscStore.length_dim;
    },
    weight_dim(): string {
      return this.miscStore.weight_dim;
    },
    quotation(): Quotation | null {
      return this.loadplan?.quotation;
    },
    holdQuotations(): KeyValue {
      return { ...this.quotation?.quotationsForHolds } || {};
    },
    defaultQuotations(): KeyValue {
      return { ...this.quotation?.quotationsForTypes } || {};
    },
    typeName(): string {
      return this.$typeNames(this.loadlist.list_type);
    },
    unloadedItemsContainer(): AugmentedHold {
      return new AugmentedHold({ items: this.unloaded_items });
    },
    locked: {
      get(): boolean {
        return this.loadlistStore.is_locked;
      },
      set(value: boolean): void {
        this.loadlistStore.setLoadlistResultProperty({
          key: 'locked',
          value: value,
        });
        this.loadlistStore.saveLoadlist();
      },
    },
  },
  beforeRouteUpdate(to, _, next: any) {
    this.parseRouteQuery(to.query, to.params);
    next();
  },
  created() {
    this.parseRouteQuery(this.$route.query, this.$route.params);
  },
  mounted() {
    window.addEventListener('afterprint', this.afterPrint);
    this.volumeUnit = DimTools.getVolumeUnit();
  },
  beforeDestroy() {
    window.removeEventListener('afterprint', this.afterPrint);
    clearInterval(this.printTimer);
    if (this.worker) {
      this.worker.terminate();
    }
  },
  methods: {
    parseRouteQuery(query: any, params: any) {
      if (query.print) {
        this.perPage = 1000;
      } else if (params.page !== undefined) {
        this.page = parseInt(params.page, 10) + 1;
      }
      this.orderBy = (query.orderby as string) || '';
      // this.groupSimilar =
      //   query.groupSimilar !== "false" ||
      //   this.usageSettings.groupSimilar;
      this.isAsc = query.asc === 'true';
      this.showInstructions = ['true', '3d', '3d-perspective'].includes(query.instructions)
        ? 'true'
        : query.instructions === 'stacks'
          ? 'stacks'
          : '';

      this.simpleMode = query.simpleMode === 'true' ? true : false;
      this.showUnloaded = query.showunloaded === 'true' ? 0 : null;
      this.showRuler = query.showRuler === 'true';
      this.selectedColumns = query.columns ? JSON.parse(query.columns as string) : undefined;
      this.showWatermark = query.showWatermark === 'true';
    },
    groupHoldsFromWorker(e: GroupedWorkerResponse): void {
      this.groupedHolds = e.data.holds;
      this.sets = e.data.sets;
      this.useDefaultHoldQuotations();
    },
    shouldRenderSet(hold: HoldData, index: number) {
      return (this.loadplan.sets || []).find(
        (s) =>
          s.uuid == hold.set_uuid &&
          s.containers.length > 1 &&
          (index === 0 || this.holds[index - 1].hold.set_uuid != hold.set_uuid)
      );
    },
    updateGroups(): void {
      if (!this.worker) {
        this.worker = new Worker(new URL('@/workers/group_holds.worker.ts', import.meta.url));
        this.worker.onmessage = this.groupHoldsFromWorker;
      }
      this.worker.postMessage({
        holds: this.loadplan.holds,
        sets: this.loadplan.sets,
        disableGrouping: !this.usageSettings.groupSimilar,
      });
    },
    pageChange(page: number): void {
      this.$router.push({
        name: 'loadplan',
        params: { ...this.$route.params, page: String(page - 1) },
        query: {
          asc: this.isAsc.toString(),
          orderby: this.orderBy,
          instructions: this.showInstructions,
          groupSimilar: this.usageSettings.groupSimilar.toString(),
          showRuler: this.showRuler.toString(),
          simpleMode: this.simpleMode.toString(),
        },
      });
    },
    afterPrint(): void {
      this.perPage = 5;
    },
    print(): void {
      this.page = 1;
      this.perPage = this.loadplan.holds.length;
      this.$hideChatWidget();
      this.$nextTick(() => {
        if (!this.printTimer) {
          this.printTimer = setInterval(() => {
            if (window.cpl.currentRendering <= 0) {
              clearInterval(this.printTimer);
              this.printTimer = null;
              window.print();
            }
          }, 100);
        }
      });
    },

    getXlsx(): void {
      this.isLoadingExcel = true;
      this.page = 1;
      this.perPage = this.loadplan.holds.length;
      const loadExcelJS = () => import('exceljs');
      loadExcelJS()
        .then((exceljs) => {
          const excelService = new ExcelService();
          const tablesData: TablesData[] = (this.$refs.container as any)
            .map((e: any) => e.$el)
            .map((ce: any) => {
              const uuid = ce.getAttribute('id');
              const hold = this.groupedHolds.find((h) => h.uuid == uuid);
              const containerCount = hold ? hold.__indices.end - hold.__indices.start + 1 : 1;
              const containerTitle = ce.getElementsByClassName('container-title').item(0).innerText;
              const containerTitleText = this.getContainerTitleText(
                containerTitle,
                new AugmentedHold(hold)
              );
              let quotation = undefined;
              const quotationElement = ce
                .getElementsByClassName('quotation-input') // THIS ONE?
                .item(0);
              if (quotationElement) {
                quotation = '';
                const quotationValue = quotationElement
                  .getElementsByTagName('input')
                  .item(0)?.value;
                if (quotationValue) {
                  quotation = `${this.currencySymbol} ${quotationValue}`;
                }
              }
              const cargoTableElement = ce.getElementsByClassName('cargo-table').item(0);
              const tableElement = cargoTableElement.getElementsByTagName('table').item(0);
              const tableJson = TableUtils.loadTableToJson(tableElement);
              let notes = ce.getElementsByClassName('container-notes').item(0)?.innerText ?? '';

              return {
                containerTitleText,
                quotation,
                tableJson,
                notes,
                containerCount,
              };
            });

          const unloadedEl = (this.$refs.notLoaded as any)?.$el;
          if (unloadedEl) {
            const containerTitleText = 'NOT LOADED';
            const tableElement = unloadedEl.getElementsByTagName('table').item(0);
            const tableJson = TableUtils.loadTableToJson(tableElement, true);
            tablesData.unshift({
              containerTitleText,
              tableJson,
            });
          }

          const summaryElements = window.document
            .getElementById('loadlist-info')
            .getElementsByClassName('loadlist-summary')
            .item(0)
            ?.getElementsByClassName('summary'); // TODO: Check if it's this
          const summary = Array(summaryElements?.length || 0)
            .fill(0)
            .map((_, index) => {
              return (summaryElements.item(index) as any).innerText;
            });
          const workbook = excelService.loadplanToXlsx(new exceljs.Workbook(), {
            loadlist: this.loadlist,
            tablesData: tablesData,
            summary,
          });
          this.saveFile(`Loadplan - ${this.loadlist.name}.xlsx`, workbook);
          this.isLoadingExcel = false;
          this.perPage = 5;
        })
        .catch((error) => {
          console.log(error);
          this.isLoadingExcel = false;
          this.perPage = 5;
        });
    },
    getContainerTitleText(containerName: string, augmentedHold: AugmentedHold): string {
      const holdLength = filters.toLength(
        Math.max(augmentedHold.hold.L, augmentedHold.usedLength),
        false
      );
      const holdWidth = filters.toLength(
        Math.max(augmentedHold.hold.W, augmentedHold.usedWidth),
        false
      );
      const holdHeight = filters.toLength(
        Math.max(augmentedHold.hold.H, augmentedHold.usedHeight),
        true
      );
      const containerDimensions = `LxWxH ${holdLength}x${holdWidth}x${holdHeight}`;
      const containerWeight = `Gross weight ${filters.toWeight(augmentedHold.grossWeight, true)}`;
      return `${containerName}\n(${containerDimensions} - ${containerWeight})`;
    },
    async saveFile(fileName: string, workbook: Workbook): Promise<void> {
      const xls64 = await workbook.xlsx.writeBuffer();
      FileSaver.saveAs(
        new Blob([xls64], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        fileName
      );
    },
    renderSettings(index: number): string {
      return index + '-' + this.usageSettings.groupSimilar + '-' + this.showRuler;
    },
    updateHoldsQuotation(quotation: number, hold: HoldDataWithIndices) {
      const holdQuotations = this.holdQuotations;
      const { start, end } = hold.__indices;
      for (let index = start; index <= end; index++) {
        const uuid = this.loadplan.holds[index].uuid;
        holdQuotations[uuid] = quotation;
      }
      this.updateQuotation({ quotationsForHolds: holdQuotations });
    },
    updateDefaultQuotation(defaultQuotation: {
      currency: Currency;
      quotationsForTypes: KeyValue;
    }): void {
      const holdQuotations: KeyValue = {};
      for (const [key, value] of Object.entries(defaultQuotation.quotationsForTypes)) {
        const uuids = this.getAllUuidsForType(key);
        uuids.forEach((uuid) => (holdQuotations[uuid] = Number(value)));
      }
      this.updateQuotation({
        quotationsForHolds: holdQuotations,
        quotationsForTypes: defaultQuotation.quotationsForTypes,
        currency: defaultQuotation.currency,
      });
    },
    updateQuotation(quotation: Quotation): void {
      const { quotationsForHolds, quotationsForTypes, currency } = quotation;
      this.loadlistStore.setLoadplanProperty({
        key: 'quotation',
        value: JSON.parse(
          JSON.stringify({
            quotationsForHolds: quotationsForHolds ?? this.quotation?.quotationsForHolds,
            quotationsForTypes: quotationsForTypes ?? this.quotation?.quotationsForTypes,
            currency: currency ?? this.quotation?.currency,
          })
        ),
      });
      this.calculateTotalQuotation();
    },
    getAllUuidsForType(key: string): string[] {
      return this.loadplan.holds.filter((h) => h.id === Number(key)).map((h) => h.uuid);
    },
    useDefaultHoldQuotations(): void {
      const holdQuotations = this.holdQuotations;
      this.loadplan.holds.forEach((h) => {
        if (holdQuotations[h.uuid] === undefined) {
          holdQuotations[h.uuid] = this.defaultQuotations[h.id];
        }
      });
      this.updateQuotation({ quotationsForHolds: holdQuotations });
    },
    calculateTotalQuotation(): void {
      const uuids = this.loadplan.holds.map((h) => h.uuid);
      const quotations = Object.entries(this.quotation?.quotationsForHolds)
        .filter(([uuid, value]) => uuids.includes(uuid))
        .map(([uuid, value]) => Number(value));

      this.totalQuotation = quotations.length > 0 ? quotations.reduce((a, b) => a + b) : null;
    },
  },
  filters: {
    uppercase(val: string): string {
      return val ? val.toUpperCase() : '';
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
th {
  white-space: nowrap;
}
@media print {
  .cargo-table thead tr th,
  .cargo-table tbody tr td,
  .cargo-table tfoot tr td {
    font-size: 0.75rem !important;
    color-adjust: exact;
    padding: 0 8px !important;
  }
}

.quotation-input {
  width: 200px;
}
</style>
