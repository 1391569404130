<template>
  <v-dialog v-model="show" scrollable width="1200">
    <v-snackbar :timeout="5000" v-model="showSnackbar" bottom>
      {{ snackbarMessage }}
    </v-snackbar>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Table settings</v-toolbar-title>
      </v-toolbar>
      <v-tabs v-model="tab">
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab v-for="item in tabs" :key="item.key">
          {{ item.text }}
        </v-tab>
      </v-tabs>
      <v-card-text class="py-4" style="height: 500px">
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabs" :key="item.key">
            <v-card v-if="item.key === 'visible_columns'">
              <v-alert color="info" icon="fa-lightbulb" outlined
                >If you do not want to show all columns / cargo properties you can click on each
                chip below to hide those columns.</v-alert
              >

              <v-tooltip bottom v-for="column in defaultColumns" :key="column.key">
                <template v-slot:activator="{ on }">
                  <v-chip
                    v-on="on"
                    @click="toggleColumn(column.key)"
                    class="ma-2"
                    label
                    :color="hiddenColumns.includes(column.key) ? 'gray' : 'primary'">
                    {{ column.text }}
                  </v-chip>
                </template>
                <span>{{ column.desc }}</span>
              </v-tooltip>
            </v-card>
            <v-card flat v-else-if="item.key === 'custom_columns'">
              <v-alert color="info" icon="fa-lightbulb" outlined
                >Here you can add extra metadata fields which will also be shown in the load plan.
                Some examples:
                <ul>
                  <li>"Comment" field for item specific comments</li>
                  <li>"PO" field for purchase order information</li>
                </ul>
              </v-alert>
              <v-alert type="error" v-if="!isEditor">
                Only users with Editor role can do modify custom columns
              </v-alert>

              <v-form
                ref="columnForm"
                v-model="validColumnForm"
                lazy-validation
                :disabled="!isEditor">
                <v-row align="center" justify="center">
                  <v-col cols="2">
                    <v-text-field
                      v-model="newColumn.name"
                      label="Column name"
                      :rules="columnNameRules"></v-text-field>
                  </v-col>
                  <v-col cols="7">
                    <v-text-field v-model="newColumn.desc" label="Description"></v-text-field>
                  </v-col>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-col cols="2" v-on="on" v-bind="attrs">
                        <v-checkbox label="Summable" v-model="newColumn.summable"></v-checkbox>
                      </v-col>
                    </template>
                    <span>If enabled then the rows be summed on the load plan</span>
                  </v-tooltip>
                  <v-col cols="1"
                    ><v-btn icon color="green" @click="addColumn"
                      ><v-icon>mdi-plus-circle</v-icon></v-btn
                    ></v-col
                  >
                </v-row>
              </v-form>

              <v-row
                v-for="(item, index) in customColumns"
                :key="item.name"
                align="center"
                justify="center">
                <v-col cols="2">{{ item.name }}</v-col>
                <v-col cols="7">{{ item.desc }}</v-col>
                <v-col cols="2">{{ item.summable ? 'yes' : 'no' }}</v-col>

                <v-col cols="1"
                  ><v-btn
                    icon
                    color="red"
                    :disabled="!isEditor"
                    @click="customColumns.splice(index, 1)"
                    ><v-icon>mdi-minus-circle</v-icon></v-btn
                  ></v-col
                >
              </v-row>
              <v-alert v-if="!customColumns.length" color="info" icon="mdi-information" outlined
                >No fields added
              </v-alert>
            </v-card>
            <v-card flat v-else-if="item.key === 'data_defaults'">
              <data-defaults-component
                ref="dataDefaultsComponent"
                :value="preferences.defaultHoldInputItem"></data-defaults-component>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-card-actions>
        <v-btn text @click.stop="show = false">Close</v-btn>
        <v-spacer />
        <v-btn class="primary" :disabled="!isEditor" @click.stop="update"
          ><v-icon>mdi-autorenew</v-icon>Update</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue';
import ItemProperties, { CustomItemProperty, ItemProperty } from '@/misc/itemProperties';
import { CompanySerializer, CompanySettings, UserPreferences } from '@/models/UserCompanyModel';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import dataDefaultsComponent from '../Custom/DataDefaults.vue';

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        dataDefaultsComponent: Array<InstanceType<typeof dataDefaultsComponent>>;
      };
    }
  >
).extend({
  components: {
    dataDefaultsComponent,
  },
  props: {
    visible: Boolean,
  },
  computed: {
    ...mapStores(useMiscStore),
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    tabs(): { key: string; text: string }[] {
      return [
        { key: 'visible_columns', text: 'Visible Columns' },
        { key: 'custom_columns', text: 'Custom columns' },
        { key: 'data_defaults', text: 'Default values' },
      ];
    },
    defaultColumns(): ItemProperty[] {
      return ItemProperties.props().filter((i) => !i.required && !i.readOnly);
    },
    preferences(): UserPreferences {
      return this.miscStore.preferences;
    },
    companySettings(): CompanySettings {
      return this.miscStore.company_settings;
    },
    columnFormRef(): any {
      return this.$refs.columnForm;
    },
    company(): CompanySerializer {
      return this.miscStore.company;
    },
    isEditor(): boolean {
      return this.miscStore.user.is_editor;
    },
  },
  watch: {
    preferences: {
      handler(val: UserPreferences): void {
        if (val?.hidden_input_columns)
          this.hiddenColumns = JSON.parse(JSON.stringify(val.hidden_input_columns));
      },
      immediate: true,
    },
    companySettings: {
      handler(val: CompanySettings): void {
        if (val?.extra_columns) this.customColumns = JSON.parse(JSON.stringify(val.extra_columns));
      },
      immediate: true,
    },
  },
  data() {
    return {
      loading: false,
      validColumnForm: false,
      showSnackbar: false,
      snackbarMessage: '',
      tab: null as number,
      customColumns: [] as CustomItemProperty[],
      hiddenColumns: [] as string[],
      newColumn: {
        name: '',
        summable: false,
        desc: '',
      } as CustomItemProperty,
      columnNameRules: [
        (v: string) => !!v || 'Name is required',
        (v: string) => (v && v.length <= 20) || 'Name must be less than 20 characters',
        (v: string) =>
          (v && !this.$data.customColumns.map((i: CustomItemProperty) => i.name).includes(v)) ||
          'Name is already taken',
        (v: string) =>
          (v &&
            !ItemProperties.props()
              .map((i) => i.key)
              .includes(v)) ||
          'Reserved name',
      ],
    };
  },
  methods: {
    update(): void {
      if (this.tab === 0) {
        this.miscStore
          .updateMe({
            preferences: {
              hidden_input_columns: this.hiddenColumns,
            },
          })
          .then((_) => {
            this.snackbarMessage = 'Column settings updated';
            this.showSnackbar = true;
          });
      } else if (this.tab === 1) {
        this.miscStore
          .updateCompanySettings({
            extra_columns: this.customColumns,
          })
          .then((_) => {
            this.showSnackbar = true;
          });
      } else {
        const { item } = this.$refs.dataDefaultsComponent[0];
        this.miscStore
          .updateMe({
            preferences: {
              defaultHoldInputItem: item,
            },
          })
          .then((_) => {
            this.snackbarMessage = 'Default values updated';
            this.showSnackbar = true;
          });
      }
    },
    toggleColumn(key: string): void {
      const index = this.hiddenColumns.indexOf(key);
      if (index >= 0) {
        this.hiddenColumns.splice(index, 1);
      } else {
        this.hiddenColumns.push(key);
      }
    },
    addColumn(): void {
      if (this.columnFormRef[0].validate()) {
        this.customColumns.push(JSON.parse(JSON.stringify(this.newColumn)));
      }
    },
  },
});
</script>

<style></style>
