import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.readOnly)?_c('div',[_vm._v(_vm._s(_vm.text))]):_c('div',[_c(VSelect,_vm._b({staticStyle:{"margin-top":"0px"},attrs:{"value":_vm.internalValue,"items":_vm.alternatives,"value-comparator":_vm.valueComperator},on:{"input":function($event){$event ? _vm.$emit('input', $event) : () => {}}},scopedSlots:_vm._u([{key:"item",fn:function({ item, on, attrs }){return [_c(VListItem,_vm._g(_vm._b({on:{"click":function($event){item.value === 0 ? (_vm.showOrientationsDialog = true) : () => {}}}},'v-list-item',attrs,false),on),[_vm._v(" "+_vm._s(item.text)+" ")])]}}])},'v-select',_vm.$attrs,false)),(_vm.showOrientationsDialog)?_c(VDialog,{attrs:{"scrollable":""},model:{value:(_vm.showOrientationsDialog),callback:function ($$v) {_vm.showOrientationsDialog=$$v},expression:"showOrientationsDialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary elevation-0"}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.showOrientationsDialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VToolbarTitle,[_vm._v("Allowed orientations")])],1),_c(VCardText,{staticClass:"py-4"},[_c(VAlert,{attrs:{"type":"info","outlined":""}},[_vm._v(" Current selection: "),(_vm.value === 1)?_c('span',[_vm._v("Longship")]):(_vm.value === 3 || _vm.value === undefined || _vm.value === null)?_c('span',[_vm._v("Rotatable")]):(_vm.value === 63)?_c('span',[_vm._v("Tiltable")]):_c('span',[_vm._v(_vm._s(_vm.value)+" (Custom)")])]),_vm._l((_vm.chunkedRotationItems),function(chunk,i){return _c(VRow,{key:i},_vm._l((chunk),function(h,j){return _c(VCol,{key:j},[_c('div',{staticClass:"d-flex align-center"},[_c(VCheckbox,{staticClass:"ma-1",attrs:{"dense":"","label":h.orientation.label,"hide-details":"","input-value":_vm.internalValue & h.orientation.value},on:{"change":function($event){_vm.$emit(
                      'input',
                      ($event
                        ? _vm.internalValue | h.orientation.value
                        : _vm.internalValue & ~h.orientation.value) & _vm.geometryMask
                    )}}}),_c('scene-component',{attrs:{"hold-object":h.hold,"customViewSettings":{
                    hideContainer: true,
                  },"canvas-width":200,"canvas-height":100}})],1)])}),1)})],2),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();_vm.showOrientationsDialog = false}}},[_vm._v("Close")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.value},on:{"click":function($event){$event.stopPropagation();_vm.showOrientationsDialog = false}}},[_vm._v("Lock rotations")])],1)],1)],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }