<template>
  <v-container text-center>
    <v-row>
      <v-col cols="10" offset="1" sm="6" offset-sm="3">
        <v-card>
          <v-card-title>
            <img
              style="width: 100%; max-width: 300px; margin-left: auto; margin-right: auto"
              src="../../assets/svg/logo_black.svg" />
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="login" id="login-form" autocomplete="on">
              <v-text-field
                label="Username or email"
                v-model="username"
                placeholder=" "
                type="text"
                autocomplete="username"></v-text-field>
              <v-text-field
                label="Password"
                v-model="password"
                placeholder=" "
                type="password"
                autocomplete="current-password"></v-text-field>
            </v-form>

            <v-alert outlined type="error" v-if="errors.length">
              <span v-for="e in errors" :key="e">{{ e }}</span>
              <!-- <span v-if="tries < maxTries"
                >Could not login with provided details.</span
              >
              <span v-else>
                Your account has been locked for an hour. Please try again
                later. Contact support@cargo-planner.com or use the chat below
                if you have any questions.
              </span> -->
            </v-alert>
          </v-card-text>

          <v-card-actions>
            <v-btn
              block
              color="primary"
              type="submit"
              :loading="isLoading"
              :disabled="username.length === 0 || password.length === 0 || isLoading"
              form="login-form"
              >Login</v-btn
            >
          </v-card-actions>
          <v-card-text>
            <p>
              <router-link to="/password_reset/"> Forgot your password?</router-link>
            </p>
            <p>
              Login without password?
              <router-link to="/magic-link/">Click here</router-link>
            </p>
            <p>
              Login with Single Sign On?
              <router-link to="/sso-login/">Click here</router-link>
            </p>
            <p>
              10 day free trial?
              <router-link to="/register/">Sign up here</router-link>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import API from '@/API';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
export default Vue.extend({
  name: 'login',
  data() {
    return {
      showWrongLoginDetailsAlert: false,
      isLoading: false,
      username: '',
      password: '',
      errors: [],
    };
  },
  watch: {
    is_authenticated: {
      handler: function (authenticated: boolean): void {
        if (authenticated) {
          const redirect = this.$route.query.redirect
            ? (this.$route.query.redirect as string)
            : '/news';
          this.$router.push(redirect);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapStores(useMiscStore),
    registerAdress(): string {
      return API.url + '/register/';
    },
    passwordResetAdress(): string {
      return API.url + '/pwr';
    },
    is_authenticated(): boolean {
      return this.miscStore.is_authenticated;
    },
  },
  methods: {
    login(): void {
      this.isLoading = true;

      this.loginWithCredentials({
        username: this.username.trim(),
        password: this.password.trim(),
      })
        .then(() => {
          this.isLoading = false;
        })
        .catch((e) => {
          if (e?.response) {
            const errors = Object.values(e.response.data);
            if (!errors.length) errors.push('Could not login with provided details');
            this.errors = errors;
          }

          this.isLoading = false;
          this.showWrongLoginDetailsAlert = true;
        });
    },
    loginWithCredentials(details: { username: string; password: string }): Promise<undefined> {
      return this.miscStore.loginWithCredentials(details);
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
