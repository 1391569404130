<template>
  <v-dialog v-model="show" scrollable width="1200">
    <v-snackbar :timeout="5000" v-model="showSnackbar" bottom> Column settings updated </v-snackbar>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Visible columns</v-toolbar-title>
      </v-toolbar>

      <v-container>
        <v-tooltip bottom v-for="column in columns" :key="column.key">
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              @click="toggleColumn(column.key)"
              class="ma-2"
              label
              :color="selectedColumns.includes(column.key) ? 'primary' : 'gray'">
              {{ column.text }}
            </v-chip>
          </template>
          <span>{{ column.desc }}</span>
        </v-tooltip>
      </v-container>

      <v-card-actions>
        <v-btn text @click.stop="show = false">Close</v-btn>
        <v-spacer />
        <v-btn class="primary" @click.stop="update"><v-icon>mdi-autorenew</v-icon>Update</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import ItemProperties, { ItemProperty } from '@/misc/itemProperties';
import { UserPreferences } from '@/models/UserCompanyModel';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
export default Vue.extend({
  props: {
    visible: Boolean,
    defaultColumns: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
  },
  computed: {
    ...mapStores(useMiscStore),
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    columns(): ItemProperty[] {
      return ItemProperties.props().filter((i: ItemProperty) => !i.additional && !i.writeOnly);
    },
    preferences(): UserPreferences {
      return this.miscStore.preferences;
    },
  },
  watch: {
    preferences: {
      handler(val: UserPreferences): void {
        if (val?.visible_loadplan_columns)
          this.selectedColumns = JSON.parse(JSON.stringify(val.visible_loadplan_columns));
        else this.selectedColumns = JSON.parse(JSON.stringify(this.defaultColumns));
      },
      immediate: true,
    },
  },
  data() {
    return {
      loading: false,
      showSnackbar: false,
      selectedColumns: [] as string[],
    };
  },
  methods: {
    update(): void {
      if (this.miscStore.is_authenticated)
        this.updateMe({
          visible_loadplan_columns: this.selectedColumns,
        } as UserPreferences).then((_) => (this.showSnackbar = true));
      else this.showSnackbar = true;
      this.$emit('columnsUpdated', this.selectedColumns);
    },
    toggleColumn(key: string): void {
      const index = this.selectedColumns.indexOf(key);
      if (index >= 0) {
        this.selectedColumns.splice(index, 1);
      } else {
        this.selectedColumns.push(key);
      }
    },
    updateMe(preferences: UserPreferences): Promise<null> {
      return this.miscStore.updateMe({
        preferences: preferences,
      });
    },
  },
});
</script>

<style></style>
