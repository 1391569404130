import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VDataTable,{staticClass:"elevation-2",attrs:{"items":_vm.loadlists,"headers":_vm.headers,"headers-length":8,"options":_vm.pagination,"server-items-length":_vm.totalItems,"single-expand":"","loading":_vm.isLoading,"must-sort":"","footer-props":{
      disableItemsPerPage: true,
      itemsPerPageOptions: [10],
    },"id":"loadlistsTable"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function({ item, isExpanded }){return [_c('tr',{staticStyle:{"cursor":"pointer"},attrs:{"draggable":""},on:{"click":function($event){!_vm.readonly
            ? _vm.$router.push({ name: 'loadlist', params: { id: item.id } })
            : _vm.$emit('selectedLoadlist', item.id)},"dragstart":function($event){return _vm.dragStart(item, $event)}}},[_c('td',{staticStyle:{"width":"5%"}},[_c(VIcon,{staticStyle:{"pointer-events":"none"},attrs:{"x-large":""}},[_vm._v(" "+_vm._s(`$vuetify.icons.${item.list_type}`)+" ")])],1),_c('td',{class:{ 'font-weight-bold': isExpanded }},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.etd ? new Date(item.etd).toLocaleDateString() : '-')+" ")]),_c('td',[_vm._v(" "+_vm._s(new Date(item.modified_date).toLocaleDateString())+" ")]),_c('td',[_vm._v(_vm._s(item.pol))]),_c('td',[_vm._v(_vm._s(item.pod))]),(!_vm.readonly)?_c('td',[_c(VBtn,{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.fullLoadlist(item);
              _vm.showPreview = true;}}},[_c(VIcon,[_vm._v(" mdi-magnify ")])],1),_c(VBtn,{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.selectedList = item;
              _vm.showCopyLoadlistModal = true;}}},[_c(VIcon,[_vm._v(" mdi-content-copy ")])],1),_c(VBtn,{attrs:{"icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();_vm.selectedList = item;
              _vm.showDeleteLoadlistModal = true;}}},[_c(VIcon,[_vm._v(" mdi-delete ")])],1)],1):_c('td')])]}}])},[_c('template',{slot:"no-data"},[_c('div',{staticClass:"my-2"},[_c('p',{staticClass:"my-2"},[_vm._v("No load lists found.")]),(!_vm.searchString && !_vm.groupId)?_c(VBtn,{attrs:{"color":"success"},on:{"click":function($event){return _vm.$emit('generateExamples')}}},[_vm._v("Show Examples")]):_vm._e()],1)])],2),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.showPreview),callback:function ($$v) {_vm.showPreview=$$v},expression:"showPreview"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s((_vm.selectedLoadlist || { name: 'Loading' }).name))]),_c(VDivider),_c(VCardText,{staticClass:"grey lighten-4"},[_c('loadlist-preview',{attrs:{"loadlist":_vm.selectedLoadlist}})],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.showPreview = false}}},[_vm._v("Close")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
              name: 'loadlist',
              params: { id: _vm.selectedLoadlist.id },
            })}}},[_vm._v("Open")])],1)],1)],1),_c('delete-loadlist-modal',{attrs:{"visible":_vm.showDeleteLoadlistModal,"loadlistId":_vm.selectedList.id,"loadlistName":_vm.selectedList.name},on:{"deleted":function($event){_vm.fetchLoadlists();
      _vm.showDeleteLoadlistModal = false;},"close":function($event){_vm.showDeleteLoadlistModal = false}}}),_c('copy-loadlist-modal',{attrs:{"visible":_vm.showCopyLoadlistModal,"loadlistToCopy":_vm.selectedList},on:{"close":function($event){if ($event) {
        _vm.fetchLoadlists();
      }
      _vm.showCopyLoadlistModal = false;}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }