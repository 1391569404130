<template>
  <v-container>
    <v-row>
      <v-col>
        <v-select
          v-bind:items="$list_types"
          v-model="usageSettings.currentBaseType"
          hide-details
          outlined
          dense>
          <template #selection="{ item }">
            <v-icon> {{ `$vuetify.icons.${item.value}` }}</v-icon>
            &nbsp;
            {{ item.text }}
          </template></v-select
        >
      </v-col>
    </v-row>
    <load-presets-table-component
      class="mt-2"
      :list_type="usageSettings.currentBaseType"
      :showUsePreset="false"
      :showUpdatePreset="false"></load-presets-table-component>
  </v-container>
</template>

<script lang="ts">
import LoadPresetsTableComponent from '@/components/Custom/LoadPresetsTable.vue';
import { ListType } from '@/models/LoadlistModel';
import { UsageSettings } from '../stores/usageSettings';

export default {
  name: 'load-presets',
  components: {
    LoadPresetsTableComponent,
  },
  data() {
    return {
      usageSettings: UsageSettings.fetch(),
    };
  },
};
</script>

<style scoped></style>
