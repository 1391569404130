import {
  CargoLayer,
  HoldData,
  HoldDataWithIndices,
  HoldDataWithPosition,
  HoldItem,
  Indices,
} from '@/models/LoadlistModel';
import { Vector3 } from 'three';
import ContainerUtils from '@/misc/containerUtils';
import ItemUtils from '@/misc/itemUtils';
import { Parser } from 'expr-eval';
import { BoundingBox, Point } from '../LoadlistModel';

let create_items_dag: any = null;
import('@/wasm/pkg/cpl_wasm_functions').then((js) => {
  create_items_dag = js.create_items_dag;
});

// check if box1 has support from below from box2
function hasSupport(box1: BoundingBox, box2: BoundingBox) {
  return (
    Math.abs(box1.min.z - box2.max.z) < 0.001 &&
    box1.min.x < box2.max.x - 0.001 &&
    box1.max.x > box2.min.x + 0.001 &&
    box1.min.y < box2.max.y - 0.001 &&
    box1.max.y > box2.min.y + 0.001
  );
}

// This is the magic function to create a structure that checks which cargoes are below each cargo
function createDag(boxes: HoldItem[]): Map<number, number[]> {
  // If WASM items_dag function could not be loaded
  if (!create_items_dag) {
    const dag: any = new Map<number, number[]>();
    for (let i = 0; i < boxes.length; i++) {
      const arr = [];
      for (let j = 0; j < boxes.length; j++) {
        if (i === j) continue;
        if (hasSupport(boxes[i].bb, boxes[j].bb)) {
          arr.push(j);
        }
      }
      dag.set(i, arr);
    }
    return dag;
  } else {
    // Use the WASM function
    const result = JSON.parse(create_items_dag(boxes)) as number[][];
    return new Map(result.map((i, index) => [index, i]));
  }
}

export class AugmentedHold {
  hold: HoldData | HoldDataWithIndices | HoldDataWithPosition;
  cogOverride: Vector3;

  constructor(hold: HoldData | HoldDataWithIndices | HoldDataWithPosition, cogOverride?: Vector3) {
    this.hold = hold;
    this.cogOverride = cogOverride;
  }

  get items(): HoldItem[] {
    return this.hold.items;
  }
  get uuid(): string {
    return this.hold.uuid;
  }

  get volume(): number {
    return this.hold.volume;
  }

  get weight(): number {
    return this.hold.WT;
  }
  get tare(): number {
    return this.hold.tare;
  }

  get payload(): number {
    return this.hold.payload;
  }

  get name(): string {
    return this.hold.name;
  }
  get position_name(): string | null {
    return (this.hold as HoldDataWithPosition).position_name;
  }

  get __indices(): Indices {
    return (this.hold as HoldDataWithIndices).__indices;
  }

  get maxVolume(): number {
    const height = this.hold.max_height > 0 && !this.hold.H ? this.hold.max_height : this.hold.H;

    if (height < 0.001) return NaN;

    let containerVolume = this.hold.L * this.hold.W * height;

    // Remove contours volume
    if (containerVolume && this.hold.contours) {
      containerVolume -=
        (this.hold.contours.front_bottom_contour_l * this.hold.contours.front_bottom_contour_h ||
          0 + this.hold.contours.rear_bottom_contour_l * this.hold.contours.rear_bottom_contour_h ||
          0 + this.hold.contours.front_top_contour_l * this.hold.contours.front_top_contour_h ||
          0 + this.hold.contours.rear_top_contour_l * this.hold.contours.rear_top_contour_h ||
          0) *
        this.hold.W *
        0.5;

      containerVolume -=
        (this.hold.contours.side1_bottom_contour_l * this.hold.contours.side1_bottom_contour_h ||
          0 +
            this.hold.contours.side2_bottom_contour_l * this.hold.contours.side2_bottom_contour_h ||
          0 + this.hold.contours.side1_top_contour_l * this.hold.contours.side1_top_contour_h ||
          0 + this.hold.contours.side2_top_contour_l * this.hold.contours.side2_top_contour_h ||
          0) *
        this.hold.L *
        0.5;
    }
    return containerVolume;
  }

  get volumeUtilization(): number {
    return this.hold.volume / this.maxVolume;
  }
  get freeVolume(): number {
    return Math.max(this.maxVolume - this.hold.volume, 0);
  }
  get freeVolumePercentage(): number {
    return Math.max(1 - this.hold.volume / this.maxVolume, 0);
  }
  get axleWeights(): { title: string; value: number; warning: boolean }[] {
    const cog = this.cogOverride || this.cog;
    if (this.hold.axles && !!cog) {
      const wheelBaseLength = this.hold.axles.rear_axle_x - this.hold.axles.front_axle_x;

      const Wr = (this.grossWeight * (cog.x - this.hold.axles.front_axle_x)) / wheelBaseLength;
      const Wf = this.grossWeight - Wr;

      if (isNaN(Wf) || isNaN(Wr)) return null;

      return [
        {
          title: 'Front',
          value: Wf,
          warning: this.hold.axles.front_axle_max_weight
            ? this.hold.axles.front_axle_max_weight < Wf
            : false,
        },
        {
          title: 'Rear',
          value: Wr,
          warning: this.hold.axles.rear_axle_max_weight
            ? this.hold.axles.rear_axle_max_weight < Wr
            : false,
        },
      ];
    }
    return null;
  }
  get weightUtilization(): number {
    return this.hold.WT / this.hold.payload;
  }
  get grossWeight(): number {
    return this.hold.WT + (this.hold.tare || 0);
  }
  get freightTonnes(): number {
    return Math.max(this.hold.volume, this.hold.WT * 0.001) || 0.0;
  }
  get cog(): { x: number; y: number; z: number } {
    const cog = {
      x: this.hold.L * 0.5 * (this.hold.tare || 0),
      y: this.hold.W * 0.5 * (this.hold.tare || 0),
      z: this.hold.H * 0.5 * (this.hold.tare || 0),
    };

    this.hold.items.forEach((item) => {
      cog.x += item.pos.x * item.WT;
      cog.y += item.pos.y * item.WT;
      cog.z += item.pos.z * item.WT;
    });

    const totalWeight = this.grossWeight;
    if (totalWeight) {
      cog.x = cog.x / totalWeight;
      cog.y = cog.y / totalWeight;
      cog.z = cog.z / totalWeight;
    }

    return cog;
  }
  get cargoes(): HoldItem[] {
    return this.hold.items
      .map((i, index) => {
        return { ...i, index };
      })
      .filter((i) => i.qty > 0);
  }

  get loadFromBottom(): boolean {
    switch (this.hold.base_type) {
      case 'PALL':
      case 'AIR':
        return true;
      default:
        return this.hold.no_roof ? true : false;
    }
  }

  get cargoesInOrder(): HoldItem[] {
    if (this.loadFromBottom) {
      return this.cargoes.sort(
        (a: HoldItem, b: HoldItem) =>
          Math.round(a.bb?.min.z * 100) - Math.round(b.bb?.min.z * 100) ||
          Math.round(a.bb?.min.x * 100) - Math.round(b.bb?.min.x * 100) ||
          Math.round(a.bb?.min.y * 100) - Math.round(b.bb?.min.y * 100)
      );
    }
    return this.cargoes.sort(
      (a: HoldItem, b: HoldItem) =>
        Math.round(a.bb?.min.x * 100) - Math.round(b.bb?.min.x * 100) ||
        Math.round(a.bb?.min.z * 100) - Math.round(b.bb?.min.z * 100) ||
        Math.round(b.bb?.max.y * 100) - Math.round(a.bb?.max.y * 100)
    );
  }

  get items_count(): number {
    return this.hold.items_count;
  }
  get bundledItems(): HoldItem[][] {
    return ItemUtils.bundledItems(this.cargoes);
  }
  get usedLength(): number {
    return this.hold.items_bb.max.x - this.hold.items_bb.min.x;
  }
  get usedWidth(): number {
    return this.hold.items_bb.max.y - this.hold.items_bb.min.y;
  }
  get usedHeight(): number {
    return this.hold.items_bb.max.z - this.hold.items_bb.min.z;
  }
  get freeLength(): number {
    return this.hold.L - this.usedLength;
  }
  get freeWidth(): number {
    return this.hold.W - this.usedWidth;
  }
  get freeHeight(): number {
    return this.hold.H - this.usedHeight;
  }
  get pallets(): HoldData[] {
    return this.cargoes.filter((i) => i.from_container);
  }
  get numberOfPallets(): number {
    return this.pallets.length;
  }
  get oogValues(): { value: number; title: string; type: string }[] {
    return ContainerUtils.oogValues(this.hold);
  }
  get oogSummary(): string {
    return this.oogValues.map((oog) => oog.title).join(', ');
  }
  get customColumns(): string[] {
    const c = new Set<string>();
    for (const column of this.bundledItems
      .flat()
      .filter((i) => i.metadata)
      .flatMap((i) => Object.keys(i.metadata))) {
      c.add(column);
    }
    return [...c];
  }
  get cargoesInLayerOrder(): HoldItem[] {
    return this.layers.flatMap(({ cargoes }) => {
      return cargoes.map((index) => this.hold.items[index]);
    });
  }
  get layers(): CargoLayer[] {
    return this.loadFromBottom ? this.horizontalLayers : this.verticalRows();
  }
  get layersWithPreviousCargoes(): CargoLayer[] {
    const layers = this.layers;
    for (let i = 1; i < layers.length; i++) {
      layers[i].previous_cargoes = [...layers[i - 1].cargoes, ...layers[i - 1].previous_cargoes];
    }
    return layers;
  }

  get horizontalLayers(): CargoLayer[] {
    const layers: CargoLayer[] = [];

    this.cargoesInOrder.forEach((c: HoldItem) => {
      const p: Point = c.bb?.min || c.pos;
      const v = Math.max(Math.round(p.z * 10) / 10, 0);

      const index = layers.findIndex((i) => Math.abs(i.value - v) <= 0.1);

      if (index < 0) {
        layers.push({
          previous_cargoes: [],
          cargoes: [(c as any).index],
          value: v,
          objects: [{ label: c.label, color: c.color, count: 1 }],
        });
      } else {
        const existingCargoIndex = layers[index].objects.findIndex(
          (i) => i.label === c.label && i.color === c.color
        );
        // layer exists but this is the first cargo of its type in the layer
        if (existingCargoIndex < 0) {
          layers[index].objects.push({
            label: c.label,
            color: c.color,
            count: 1,
          });
          layers[index].cargoes.push((c as any).index);
        } else {
          layers[index].objects[existingCargoIndex].count += 1;
          layers[index].cargoes.push((c as any).index);
        }
      }
    });
    if (layers.length == 1) return this.verticalRows();
    layers.sort((a, b) => {
      return a.value - b.value;
    });

    return layers;
  }

  // get verticalChunks(): CargoLayer[] {
  //   let layers: CargoLayer[] = [];

  //   const itemsInOrder = this.cargoesInOrder
  //   const dag = createDag(itemsInOrder);
  //   while (dag.size) {
  //     for (let [key, val] of dag) {
  //       if (!val.length || !val.some(i => dag.has(i))) {

  //         let item = itemsInOrder[key];

  //         const existingLayer = layers.find(i => Math.abs(i.value.x - item.bb.min.x) < 0.001 && Math.abs(i.value.y - item.bb.min.y) < 0.001 && i.objects.some(
  //           (j) => j.label === item.label && j.color === item.color
  //         ))
  //         if (existingLayer) {
  //           existingLayer.objects[existingLayer.objects.findIndex(i => i.label === item.label && i.color === item.color)].count += 1;
  //           existingLayer.cargoes.push((item as any).index);
  //         } else {
  //           layers.push({
  //             previous_cargoes: [],
  //             cargoes: [(item as any).index],
  //             value: item.bb.min,
  //             objects: [{ label: item.label, color: item.color, count: 1 }],
  //           });
  //         }
  //         dag.delete(key);
  //         break;
  //       }
  //     }
  //   }

  //   return layers

  // }

  get stacks(): CargoLayer[] {
    const counted_stacks = [] as { layer: CargoLayer; id: string }[];
    this.verticalRows(true)
      // Creating the stack ID
      .map((layer) => ({
        layer: { ...layer, value: 1 },
        id: JSON.stringify(layer.objects),
      }))
      // counting the stacks
      .forEach(({ layer, id }) => {
        let index = counted_stacks.findIndex((d) => d.id === id);
        if (index < 0) {
          counted_stacks.push({ layer, id });
        } else {
          counted_stacks[index].layer.value += 1;
          counted_stacks[index].layer.previous_cargoes.push(...counted_stacks[index].layer.cargoes);
          counted_stacks[index].layer.cargoes = layer.cargoes;
        }
      });
    return counted_stacks.map((d) => d.layer);
  }

  verticalRows(shouldSeparateStacks = false): CargoLayer[] {
    const layers: CargoLayer[] = [];

    const itemsInOrder = this.cargoesInOrder;
    // const shouldSeparateStacks = false;
    const dag = createDag(itemsInOrder);
    while (dag.size) {
      for (const [key, val] of dag) {
        if (!val.length || !val.some((i) => dag.has(i))) {
          const item = itemsInOrder[key];
          const existingLayer = layers.find(
            (i, index) =>
              item.bb.max.x - 0.001 <= i.value.bb.max.x &&
              (!shouldSeparateStacks || Math.abs(item.bb.min.y - i.value.bb.min.y) <= 0.001) &&
              (!val.length ||
                !layers
                  .slice(index + 1)
                  .flatMap((v) => v.value.keys)
                  .some((v) => val.some((y) => y == v)))
          );

          if (existingLayer) {
            const i = existingLayer.objects.findIndex(
              (i) => i.label === item.label && i.color === item.color
            );
            if (i < 0)
              existingLayer.objects.push({
                label: item.label,
                color: item.color,
                count: 1,
              });
            else existingLayer.objects[i].count += 1;

            existingLayer.cargoes.push((item as any).index);
            existingLayer.value.keys.push(key);
          } else {
            layers.push({
              previous_cargoes: [],
              cargoes: [(item as any).index],
              value: { bb: item.bb, keys: [key] },

              objects: [{ label: item.label, color: item.color, count: 1 }],
            });
          }
          dag.delete(key);
          break;
        }
      }
    }

    return layers;
  }

  customMetric(formula: any, item: HoldItem): number {
    try {
      return Parser.evaluate(formula, {
        ItemLength: item.L,
        ItemWidth: item.W,
        ItemHeight: item.H,
        ItemWeight: item.WT,
        ItemQty: item.qty,
      });
    } catch (e) {
      return null;
    }
  }

  withIndices(): HoldDataWithIndices {
    return this.hold as HoldDataWithIndices;
  }

  chargableWeight(shipping_factor: number | null): number | null {
    if (shipping_factor) {
      return this.cargoes
        .map((i) => {
          return Math.max(shipping_factor * i.H * i.L * i.W, i.WT);
        })
        .reduce((a, b) => {
          return a + b;
        }, 0);
    }
    return null;
  }
  hasCoordinates(): boolean {
    return !!this.items.find(
      (i) => i.coordinates || i.from_container?.items?.find((c) => c.coordinates)
    );
  }
}
