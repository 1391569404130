<template>
  <div>
    <v-toolbar dense class="no-print mt-2" elevation="1">
      <div v-if="hasRecurringSchedule" class="border-right-1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn :disabled="!prevDate" icon v-on="on" :ripple="false" @click="goToPrevDate()">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>Previous date</span>
        </v-tooltip>
        {{ group.name }}
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn :disabled="!nextDate" icon v-on="on" :ripple="false" @click="goToNextDate()">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </template>
          <span>Next date</span>
        </v-tooltip>
      </div>
      <v-divider vertical class="mx-2" v-if="hasRecurringSchedule" />

      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-menu offset-y top>
            <template v-slot:activator="{ on: menu }">
              <v-btn v-on="{ ...tooltip, ...menu }" icon :ripple="false" id="editOptions">
                <v-icon color="success">fa-upload</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="selectExcelFile()">
                <v-list-item-title>Import from Excel file</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  objects = $refs.inputTable.getObjects();
                  showCargoLibrary = true;
                ">
                <v-list-item-title>Import from Cargo Library</v-list-item-title>
              </v-list-item>
              <v-list-item @click="downloadTemplate()">
                <v-list-item-title>Download Excel template</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <span>Import from...</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" :disabled="!validForm" :ripple="false" @click="gotoSummary">
            <v-icon>mdi-format-list-bulleted-square</v-icon>
          </v-btn>
        </template>
        <span>Loadlist summary</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            :ripple="false"
            @click="openLink('https://cargo-planner.com/docs/guide/cargo-properties')">
            <v-icon>mdi-help-circle</v-icon>
          </v-btn>
        </template>
        <span
          >Documentation - Cargo properties
          <v-icon small color="white">mdi-open-in-new</v-icon>
        </span>
      </v-tooltip>

      <v-divider v-if="$vuetify.breakpoint.smAndUp" class="mx-2" vertical></v-divider>

      <v-tooltip bottom v-if="$vuetify.breakpoint.smAndUp && isAuthenticated">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" :ripple="false" @click="showCopyLoadlistModal = true">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>Copy load plan</span>
      </v-tooltip>

      <v-tooltip bottom v-if="$vuetify.breakpoint.smAndUp && isAuthenticated">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" :ripple="false" @click="showDeleteLoadlistModal = true">
            <v-icon color="error">mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>Delete load plan</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <v-tooltip v-if="$vuetify.breakpoint.xsOnly" bottom :disabled="validForm">
        <template v-slot:activator="{ on }">
          <v-btn
            v-if="!readonly"
            v-on="on"
            @click="saveAndContinue"
            :loading="isLoading"
            icon
            :ripple="false">
            <v-icon color="success">fa-arrow-circle-right</v-icon>
          </v-btn>
        </template>
        <span>Fill in the required information at the bottom of the page</span>
      </v-tooltip>

      <v-tooltip v-else bottom :disabled="validForm">
        <template v-slot:activator="{ on }">
          <v-btn
            v-if="!readonly"
            v-on="on"
            class="success"
            @click="saveAndContinue"
            :loading="isLoading">
            Save and continue
            <v-icon right>fa-arrow-circle-right</v-icon>
          </v-btn>
        </template>
        <span>Fill in the required information at the bottom of the page</span>
      </v-tooltip>
    </v-toolbar>

    <packlist-reader
      v-model="objects"
      :show.sync="showParseExcelModal"
      :files="dropFiles"
      :weightDim="weight_dim"
      :lengthDim="length_dim"
      @updateWeightDim="weight_dim = $event"
      @updateLengthDim="length_dim = $event"></packlist-reader>

    <cargo-library-import-modal
      v-if="showCargoLibrary"
      v-model="objects"
      :visible="showCargoLibrary"
      :weightDim="weight_dim"
      :lengthDim="length_dim"
      @close="showCargoLibrary = false"></cargo-library-import-modal>

    <delete-loadlist-modal
      :visible="showDeleteLoadlistModal"
      :loadlistName="loadlist.name"
      :loadlistId="loadlist.id"
      @close="showDeleteLoadlistModal = false"
      @deleted="
        showDeleteLoadlistModal = false;
        $router.push({ name: 'loadlists', params: { page: 1 } });
      ">
    </delete-loadlist-modal>

    <copy-loadlist-modal
      :visible="showCopyLoadlistModal"
      :loadlistToCopy="loadlist"
      @close="showCopyLoadlistModal = false">
    </copy-loadlist-modal>

    <v-form class="mt-4" id="inputTable" v-model="validForm" ref="form" lazy-validation>
      <!-- <v-alert outlined type="info" dismissible>
        <p>You can hover over the column header to get more information on the specific column</p>
        <p>You can double click on columns with checkboxes to toggle all of them at once</p>
      </v-alert>-->

      <v-row>
        <v-col cols="12" sm="7">
          <v-text-field
            required
            label="Name of loadlist:"
            v-model="name"
            :rules="[(v) => !!v || 'Name is required']"></v-text-field>
        </v-col>
        <v-col cols="5" sm="2">
          <v-select
            id="editLengthDimSelect"
            required
            v-bind:items="lengthDims"
            v-model="length_dim"
            label="Length dimension"
            :rules="[(v) => !!v || 'Dimension is required']"></v-select>
        </v-col>
        <v-col cols="5" sm="2">
          <v-select
            required
            v-bind:items="weightDims"
            v-model="weight_dim"
            label="Weight dimension"
            :rules="[(v) => !!v || 'Dimension is required']"></v-select>
        </v-col>
        <v-col cols="2" sm="1" align-self="center">
          <v-btn
            icon
            outlined
            class="mb-2"
            @click="showExpansionPanel = showExpansionPanel === 0 ? null : 0">
            <v-icon>mdi-dots-vertical</v-icon></v-btn
          >
        </v-col>
      </v-row>

      <v-expansion-panels class="my-1" v-model="showExpansionPanel">
        <v-expansion-panel>
          <v-expansion-panel-content>
            <v-row>
              <v-col>
                <v-select
                  id="listTypeSelect"
                  required
                  v-bind:items="$list_types"
                  :value="loadlistType"
                  @input="listTypeChange($event)"
                  label="List type:"
                  :rules="[(v) => !!v || 'List type is required']"></v-select>
              </v-col>
              <v-col>
                <v-text-field label="Port of loading:" v-model="pol"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field label="Port of destination:" v-model="pod"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field class="mb-6" label="Customer:" v-model="customer"></v-text-field>
                <v-dialog
                  ref="dialog"
                  v-model="showEtdDialog"
                  :return-value.sync="etd"
                  persistent
                  width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field v-model="etd" label="ETD:" readonly v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="etd" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="showEtdDialog = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="saveEtd(etd)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12" sm="8">
                <v-textarea label="Notes:" v-model="notes" outlined></v-textarea>
              </v-col>
            </v-row> </v-expansion-panel-content></v-expansion-panel
      ></v-expansion-panels>
      <input-table
        ref="inputTable"
        :objects="objects"
        :mode="loadlist.list_type"
        :length-dim="length_dim"
        :weight-dim="weight_dim"
        :warnings="warnings"
        @moveItemsToLoadlist="moveItemsToLoadlist"></input-table>
    </v-form>

    <v-snackbar :timeout="5000" multi-line v-model="moveItemsInfoSnackbar" bottom>
      Data moved successfully
      <v-btn text color="info" @click.native="moveItemsInfoSnackbar = false">Close</v-btn>
    </v-snackbar>

    <confirm-modal
      v-if="showConfirmTypeChangeDialog"
      :visible="showConfirmTypeChangeDialog"
      :title="`Change list type`"
      :description="`Do you want to change the list type from ${$listTypeDisplayNames(
        prevLoadlistType
      )} to ${$listTypeDisplayNames(
        loadlistType
      )}? \nAll created load plans will be lost for ${loadlist.name}.`"
      confirmColor="error"
      @confirm="confirmLoadlistChange()"
      @close="discardLoadlistChange()"></confirm-modal>

    <v-dialog v-model="showNoItemsDialog" scrollable persistent width="600">
      <v-card>
        <v-card-title class="text-h5">No items</v-card-title>
        <v-card-text class="text-body-1 d-flex align-start">
          <v-icon color="red" class="mr-4 mt-2">fa-exclamation-triangle</v-icon>
          <p>
            You need to add at least <b>one</b> item row and for each row you need to fill the
            <b>length, width, height and weight</b> fields
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block @click.stop="showNoItemsDialog = false" :ripple="false"
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showWarningsDialog" scrollable width="600">
      <v-card>
        <v-card-title class="text-h5">Correct data?</v-card-title>
        <v-card-text v-for="warning in warnings" :key="warning.id" class="text-body-1">
          <div v-if="warning.id == 'HIGH_DENSITY'">
            <v-icon class="mr-2" color="red">fa-exclamation-triangle</v-icon>The density of certain
            cargoes is very high. Make sure that:<br />

            <ul class="headline-1 my-4 ml-4">
              <li>You have selected the correct weight and length dimension</li>
              <li>
                The weights are entered
                <strong>per item and not per row</strong>. You can divide the weights by each line
                quantity by right clicking inside the table and follow the menu
              </li>
              <li>
                That you are using the right decimal separator (Defaults to &quot;
                {{ decimalSeparator }} &quot;). You can change it by right clicking inside the table
                and follow the menu
              </li>
            </ul>
          </div>
          <div v-else-if="warning.id == 'LOW_DENSITY'">
            <v-icon class="mr-2" color="red">fa-exclamation-triangle</v-icon
            ><span>The density of certain cargoes is very low.</span> <br />

            <ul
              class="headline-1 my-4 ml-4"
              v-if="warnings.findIndex((i) => i.id == 'HIGH_DENSITY') < 0">
              <li>You might have selected incorrect weight and length dimension</li>
              <li>
                Are you using the right decimal separator (Defaults to &quot;
                {{ decimalSeparator }} &quot;)? You can change it by right clicking inside the table
                and follow the menu
              </li>
            </ul>
          </div>
          <div v-else-if="warning.id == 'NO_WEIGHT'">
            <v-icon class="mr-2" color="primary">fa-info-circle</v-icon>
            Some items has no weights provided. A weight has been derived from its volume and the
            default shipping factor (you can change shipping factor in 2. Setup -> Load settings).
          </div>
          <div v-else-if="warning.id == 'NO_LIMIT'">
            <v-icon class="mr-2" color="primary">fa-info-circle</v-icon>

            There is no quantity set for any of the provided items - This means that the best mix of
            provided items will be calculated
          </div>
          <div v-else-if="warning.id == 'FILLER'">
            <v-icon class="mr-2" color="primary">fa-info-circle</v-icon>

            Some items do not have a quantity set - This means they will act as "fillers". Please
            have have a look at the documentation to find out more.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="showWarningsDialog = false" :ripple="false">Cancel</v-btn>
          <v-spacer />
          <v-btn
            @click.stop="
              showWarningsDialog = false;
              overrideWarnings = true;
              saveAndContinue();
            "
            color="primary"
            :ripple="false"
            >Continue anyway</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <input
      type="file"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv"
      ref="fileInput"
      style="display: none"
      @click="resetFile"
      @change="selectedFile" />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { useMiscStore } from '@/stores/miscStore';
import InputTable from '@/components/Custom/CargoInputTable/index.vue';
import PacklistReader from '@/components/Modals/PacklistReader.vue';
import CargoLibraryImportModal from '@/components/Modals/CargoLibraryImport.vue';
import copyLoadlistModal from '@/components/Modals/CopyLoadlist.vue';
import deleteLoadlistModal from '@/components/Modals/DeleteLoadlist.vue';
import confirmModal from '@/components/Modals/Confirm.vue';
import {
  Loadlist,
  Loadplan,
  HoldInputItem,
  ListType,
  HoldData,
  LoadlistGroup,
} from '@/models/LoadlistModel';
import { Warning } from '@/misc/itemWarnings';
import { User } from '@/models/UserCompanyModel';
import containerUtils from '@/misc/containerUtils';
import API from '@/API';
import { APIResponse } from '@/models/APIModel';
import { RRule } from 'rrule';
import { getSerializerError } from '@/misc/errorUtils';

const LOW_DENSITY_LIMIT_WARNING = 40;
const HIGH_DENSITY_LIMIT_WARNING = 8000;

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        inputTable: InstanceType<typeof InputTable>;
        fileInput: HTMLInputElement;
        form: any;
        dialog: any;
      };
    }
  >
).extend({
  name: 'edit',
  components: {
    InputTable,
    PacklistReader,
    CargoLibraryImportModal,
    copyLoadlistModal,
    deleteLoadlistModal,
    confirmModal,
  },
  data() {
    return {
      isLoading: false,
      dropFiles: [],
      showParseExcelModal: false,
      showLibraryHoldsModal: false,
      showCargoLibrary: false,
      showEtdDialog: false,
      showExpansionPanel: null,
      validForm: false,
      lengthDims: [
        { text: 'MM', value: 'MM' },
        { text: 'CM', value: 'CM' },
        { text: 'DM', value: 'DM' },
        { text: 'M', value: 'M' },
        { text: 'IN', value: 'IN' },
        { text: 'FT', value: 'FT' },
      ],
      weightDims: [
        { text: 'KG', value: 'KG' },
        { text: 'MT', value: 'MT' },
        { text: 'LB', value: 'LB' },
      ],
      moveItemsInfoSnackbar: false,
      showNoItemsDialog: false,
      showWarningsDialog: false,
      overrideWarnings: false,
      warnings: [],
      showDeleteLoadlistModal: false,
      showCopyLoadlistModal: false,
      showConfirmTypeChangeDialog: false,
      loadlistType: null as ListType,
      prevLoadlistType: null as ListType,
    };
  },
  computed: {
    ...mapStores(useMiscStore, useLoadlistStore),
    name: {
      get(): string {
        return this.loadlistStore.loadlist.name;
      },
      set(value: string): void {
        this.loadlistStore.setLoadlistProperty({
          key: 'name',
          value: value,
        });
      },
    },
    objects: {
      get(): HoldInputItem[] {
        return this.loadlist.data;
      },
      set(value: HoldInputItem[]): void {
        this.loadlistStore.setLoadlistProperty({
          key: 'data',
          value: value,
        });
      },
    },
    length_dim: {
      get(): string {
        return this.loadlist.length_dim;
      },
      set(value: string): void {
        this.loadlistStore.setLoadlistProperty({
          key: 'length_dim',
          value: value,
        });
      },
    },
    weight_dim: {
      get(): string {
        return this.loadlist.weight_dim;
      },
      set(value: string): void {
        this.loadlistStore.setLoadlistProperty({
          key: 'weight_dim',
          value: value,
        });
      },
    },
    pol: {
      get(): string {
        return this.loadlist.pol;
      },
      set(value: string): void {
        this.loadlistStore.setLoadlistProperty({
          key: 'pol',
          value: value,
        });
      },
    },
    pod: {
      get(): string {
        return this.loadlist.pod;
      },
      set(value: string): void {
        this.loadlistStore.setLoadlistProperty({
          key: 'pod',
          value: value,
        });
      },
    },
    customer: {
      get(): string {
        return this.loadlist.customer;
      },
      set(value: string): void {
        this.loadlistStore.setLoadlistProperty({
          key: 'customer',
          value: value,
        });
      },
    },
    notes: {
      get(): string {
        return this.loadlist.notes;
      },
      set(value: string): void {
        this.loadlistStore.setLoadlistProperty({
          key: 'notes',
          value: value,
        });
      },
    },
    etd: {
      get(): string {
        return this.loadlist.etd;
      },
      set(value: string): void {
        this.loadlistStore.setLoadlistProperty({
          key: 'etd',
          value: value,
        });
      },
    },
    decimalSeparator(): string {
      return (0.1).toLocaleString().substr(1, 1);
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return this.loadlistStore.loadplan;
    },
    loadplans(): Loadplan[] {
      return this.loadlistStore.loadplans;
    },
    readonly(): boolean {
      return this.loadlistStore.readonly;
    },
    isAuthenticated(): boolean {
      return this.miscStore.is_authenticated;
    },
    group(): LoadlistGroup | undefined {
      const id = this.loadlistStore.loadlist.group;
      if (id !== undefined) {
        return this.loadlistStore.loadlistGroups.find((group) => group.id == id);
      }
    },
    hasRecurringSchedule(): boolean {
      const id = this.loadlistStore.loadlist.group;
      const group = this.loadlistStore.loadlistGroups.find((group) => group.id == id);
      return this.etd && !!group?.data?.rrule;
    },
    nextDate(): Date {
      if (!this.group?.data?.rrule || !this.etd) {
        return undefined;
      }
      const rrule = RRule.fromString(this.group.data.rrule);
      const date = new Date(this.etd);
      date.setDate(date.getDate() + 1);
      const nextDate = rrule.after(date, true);
      return nextDate || undefined;
    },
    prevDate(): Date {
      if (!this.group?.data?.rrule || !this.etd) {
        return undefined;
      }
      const rrule = RRule.fromString(this.group.data.rrule);
      const date = new Date(this.etd);
      date.setDate(date.getDate() - 1);
      const prev = rrule.before(date, true);
      return prev || undefined;
    },
  },
  mounted(): void {
    // this.$refs.form.validate();
  },
  beforeRouteLeave(to: never, from: never, next: any) {
    if (
      this.isOwner() &&
      JSON.stringify(this.objects) != JSON.stringify(this.$refs.inputTable.getObjects())
    ) {
      {
        this.objects = this.$refs.inputTable.getObjects();
        this.saveLoadlist()
          .catch(() => {})
          .finally(() => {
            next();
          });
      }
    } else {
      next();
    }
  },
  methods: {
    goToLoadlist(id: string): void {
      this.objects = this.$refs.inputTable.getObjects();

      this.saveLoadlist()
        .then(() => {
          return this.$router.push({
            name: 'loadlist',
            params: { id },
          });
        })
        .finally(() => {
          this.loadlistStore.getLoadlist(id);
        });
    },
    goToDate(date: Date, list: Loadlist): void {
      if (list) {
        // TODO: save before continuing
        this.goToLoadlist(list.id);
      } else if (this.group.data?.default_type) {
        // We need to create a default loadlist for this date
        API.createLoadlist({
          name: this.group.name,
          group: this.group.id,
          list_type: this.group.data.default_type,
          length_dim: this.miscStore.preferences?.default_loadlist_length_dim || undefined,
          weight_dim: this.miscStore.preferences?.default_loadlist_weight_dim || undefined,
          etd: date.toLocaleDateString('en-CA'),
          data: [],
        }).then((data) => {
          this.goToLoadlist(data.data.id);
        });
      } else {
        this.miscStore.error_message = 'Could not find or create the loadlist';

        // TODO: print that we can't create the loadlist
      }
    },
    goToNextDate(): void {
      const date = this.nextDate;
      if (date) {
        API.getLoadlists({
          group: this.group.id,
          etd_after: this.etd,
          etd_before: date.toLocaleDateString('en-CA'),
        })
          .then((data: APIResponse) => {
            const lists = data.data.results as Loadlist[];
            const next = lists.find((l) => l.id != this.loadlist.id);
            this.goToDate(date, next);
          })
          .catch((error: any) => {
            console.log('Error fetching loadlists');
          });
      }
    },
    goToPrevDate(): void {
      const date = this.prevDate;
      if (date) {
        API.getLoadlists({
          group: this.group.id,
          etd_after: date.toLocaleDateString('en-CA'),
          etd_before: this.etd,
        })
          .then((data: APIResponse) => {
            const lists = data.data.results as Loadlist[];
            const prev = lists.reverse().find((l) => l.id != this.loadlist.id);
            this.goToDate(date, prev);
          })
          .catch((error: any) => {
            console.log('Error fetching loadlists');
            this.isLoading = false;
          });
      }
    },
    selectExcelFile(): void {
      this.$refs.fileInput.click();
    },
    resetFile(e: any): void {
      e.target.value = null;
    },
    selectedFile(e: any): void {
      if (e.target.files && e.target.files[0]) {
        this.dropFiles = [e.target.files[0]];
      }
      this.showParseExcelModal = true;
    },
    gotoSummary(): void {
      this.objects = this.$refs.inputTable.getObjects();
      this.$router.push({ name: 'summary' });
    },
    updateLoadlistType(listType: ListType) {
      this.loadlistStore.setLoadlistProperty({
        key: 'list_type',
        value: listType,
      });
      this.loadlistStore.resetLoadlistResults();
    },
    saveAndContinue(): void {
      if (this.$refs.form.validate()) {
        const objects: HoldInputItem[] = this.$refs.inputTable.getObjects();
        if (!objects.length) {
          this.showNoItemsDialog = true;
          return;
        }

        this.warnings = [];
        const low_densities: number[] = [];
        const high_densities: number[] = [];
        const no_weights: number[] = [];

        const shipping_factor = this.loadplan?.settings?.shipping_factor || 200;

        objects.forEach((item, index) => {
          const m3 = item.l * item.w * item.h * Math.pow(this.$toSI(this.loadlist.length_dim), 3);

          if (!item.wt) {
            item.wt = m3 * shipping_factor;
            no_weights.push(index);
          }

          const density = (this.$toSI(this.loadlist.weight_dim) * item.wt) / m3;

          if (density > HIGH_DENSITY_LIMIT_WARNING) {
            high_densities.push(index);
          }
          if (density < LOW_DENSITY_LIMIT_WARNING) {
            low_densities.push(index);
          }
        });

        if (high_densities.length) {
          this.warnings.push({
            id: Warning.HIGH_DENSITY,
            indexes: high_densities,
          });
        }
        if (low_densities.length) {
          this.warnings.push({
            id: Warning.LOW_DENSITY,
            indexes: low_densities,
          });
        }
        if (no_weights.length) {
          this.warnings.push({
            id: Warning.NO_WEIGHT,
            indexes: no_weights,
          });
        }

        const no_qty = objects
          .map((i, index) => {
            if (i.qty === undefined) return index;
          })
          .filter((index) => index >= 0);

        if (no_qty.length === objects.length)
          this.warnings.push({ id: Warning.NO_LIMIT, indexes: no_qty });
        else if (no_qty.length) this.warnings.push({ id: Warning.FILLER, indexes: no_qty });

        if (this.warnings.length && !this.overrideWarnings) {
          this.showWarningsDialog = true;
        } else {
          this.isLoading = true;
          this.objects = objects;

          this.saveLoadlist()
            .then(() => {
              this.isLoading = false;
              this.$router.push({ name: 'setup' });
            })
            .catch((error: any) => {
              if (error?.response?.data)
                this.miscStore.error_message = getSerializerError(error.response.data);

              // if (
              //   error.response &&
              //   error.response.data &&
              //   Array.isArray(error.response.data.data)
              // ) {
              //   for (var i = 0; i < error.response.data.data.length; i++) {
              //     let keys = Object.keys(error.response.data.data[i]);
              //     if (keys.length) {
              //       this.errorMessage = `Row ${i + 1}: ${keys[0]} - ${
              //         error.response.data.data[i][keys[0]]
              //       }`;
              //       this.snackbar = true;
              //       break;
              //     }
              //   }
              // }

              this.isLoading = false;
            });
        }
      } else {
        this.$vuetify.goTo(this.$refs.form);
      }
    },
    doNotShowInputModeDialog(): void {
      localStorage.setItem('cplDontAskForInputMethod', 'true');
    },
    openLink(url: string): void {
      window.open(url, '_blank');
    },
    downloadTemplate(): void {
      const link = document.createElement('a');
      link.href = '/static/templates/load_list_template_v1.5.xlsx';
      link.download = 'load_list_template_v1.5.xlsx';
      link.click();
    },
    addItemToObjects(object: HoldInputItem): void {
      this.objects = [...this.objects, object];
    },
    saveEtd(etd: string): void {
      this.$refs.dialog.save(etd);
    },
    saveLoadlist(): Promise<undefined> {
      return this.loadlistStore.saveLoadlist();
    },
    isOwner(): boolean {
      if (!this.is_authenticated() || this.loadlist?.owner !== this.user().email) {
        return false;
      }
      return true;
    },
    user(): User {
      return this.miscStore.user;
    },
    is_authenticated(): boolean {
      return this.miscStore.is_authenticated;
    },
    confirmLoadlistChange(): void {
      this.showConfirmTypeChangeDialog = false;
      this.updateLoadlistType(this.loadlistType);
      this.prevLoadlistType = null;
    },
    discardLoadlistChange(): void {
      this.showConfirmTypeChangeDialog = false;
      this.loadlistType = this.prevLoadlistType;
      this.prevLoadlistType = null;
    },
    listTypeChange(listType: ListType): void {
      this.prevLoadlistType = this.loadlistType;
      this.loadlistType = listType;
      if (this.loadplan.holds?.length) {
        this.showConfirmTypeChangeDialog = true;
      } else {
        this.updateLoadlistType(this.loadlistType);
      }
    },
    moveItemsToLoadlist(data: { startRow: number; endRow: number; id: string }): void {
      for (let i = 0; i < this.loadplans.length; i++) {
        const removeItemsFromHolds: {
          from_container: HoldData;
          from_indices: number[];
        }[] = [];

        // Go through each load plan - and for each container, then check if the selected rows has been loaded - if so then save the indices
        for (let j = 0; j < this.loadplans[i].holds.length; j++) {
          let indices: number[] = [];
          for (let m = data.startRow; m <= data.endRow; m++) {
            indices = [
              ...indices,
              ...containerUtils.getAllCargoIndices(this.objects[m], this.loadplans[i].holds[j]),
            ];
          }
          if (indices.length)
            removeItemsFromHolds.push({
              from_container: this.loadplans[i].holds[j],
              from_indices: indices,
            });
        }
        // Remove these cargoes from the containers
        if (removeItemsFromHolds.length) {
          this.loadlistStore
            .calculateLoadplan({
              operation: 'remove_items',
              settings: this.loadplans[i].settings,
              containers: removeItemsFromHolds,
              hideProgress: false,
            })
            .then(
              (solution: any) => {
                solution.containers.forEach((c: HoldData) => {
                  const index = this.loadlist.result.versions[i].holds.findIndex(
                    (c2: HoldData) => c.uuid === c2.uuid
                  );
                  this.loadlist.result.versions[i].holds[index] = c;
                });
              },
              (error) => {}
            );
        }
      }
      API.getLoadlist(data.id).then((response) => {
        const newList: Loadlist = response.data;
        newList.data = [
          ...newList.data,
          ...this.objects.slice(data.startRow, data.endRow + 1).map((item) => {
            // We do not want conflicting SKUs
            return {
              ...item,
              sku:
                item.sku &&
                !isNaN(Number(item.sku)) &&
                item.sku.length <= 3 &&
                newList.data.some((i) => i.sku == item.sku)
                  ? `${item.sku}_`
                  : item.sku,
            };
          }),
        ];
        API.saveLoadlist(newList);

        // Remove the selected rows
        this.loadlistStore.setLoadlistProperty({
          key: 'data',
          value: [
            ...this.objects.slice(0, data.startRow),
            ...this.objects.slice(data.endRow + 1, this.objects.length),
          ],
        });

        this.saveLoadlist();
        this.moveItemsInfoSnackbar = true;
      });
    },
  },
  watch: {
    'loadlist.list_type': {
      handler: function (listType: ListType): void {
        this.loadlistType = listType;
      },
      immediate: true,
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
