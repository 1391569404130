import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{attrs:{"text-center":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"10","offset":"1","sm":"6","offset-sm":"3"}},[_c(VCard,[_c(VCardTitle,[_c('img',{staticStyle:{"width":"100%","max-width":"300px","margin-left":"auto","margin-right":"auto"},attrs:{"src":require("../../assets/svg/logo_black.svg")}})]),_c(VCardText,[_c(VForm,{attrs:{"id":"get-redirect-url-form"},on:{"submit":function($event){$event.preventDefault();return _vm.get_redirect_url.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"Your corporate email","type":"text"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VAlert,{attrs:{"outlined":"","type":"error"},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_vm._v(" We could not find a connection to your corporate login page. Please contact us at support@cargo-planner.com or open the support chat below if you have any questions. ")])],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"form":"get-redirect-url-form","block":"","color":"primary","type":"submit","loading":_vm.isLoading,"disabled":!_vm.domain || _vm.isLoading}},[_vm._v("Login with SSO")])],1),_c(VCardText,[_c('router-link',{attrs:{"to":"/login/"}},[_vm._v("Go back to login view")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }