<template>
  <v-text-field
    v-bind="$attrs"
    :value="displayValue"
    :min="min"
    v-on="inputListeners"
    :prefix="prefix"
    type="number"
    :error-messages="errors"></v-text-field>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import dimTools from '@/misc/dimTools';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';

export default Vue.extend({
  name: 'length-input-field',
  data() {
    return {
      displayValue: undefined as number,
      errors: [] as string[],
    };
  },
  props: {
    value: Number,
    min: {
      type: Number,
      default: 0,
    },
    sup: {
      type: Number,
      default: 1,
    },
    rules: Array as PropType<((v: number) => boolean | string)[]>,
    lengthDim: String,
  },
  computed: {
    ...mapStores(useMiscStore),
    lengthUnit(): string {
      const l = this.lengthDim || this.miscStore.length_dim;
      if (this.sup > 1) {
        if (l == 'IN' || l == 'FT') return 'FT';
        return 'M';
      }
      return l;
    },
    prefix(): string {
      return '[' + (this.lengthUnit || '').toLowerCase() + (this.sup > 1 ? this.sup : '') + ']';
    },
    inputListeners(): any {
      return {
        ...this.$listeners,
        input: (event: string) => {
          this.$emit('input', this.toInternalValue(event));
        },
        change: (event: string) => {
          this.$emit('change', this.toInternalValue(event));
        },
      };
    },
  },
  watch: {
    value: {
      handler: function (a: number): void {
        if (a !== undefined && !isNaN(a)) {
          this.errors = (this.rules?.map((r) => r(a)).filter((v) => v !== true) as string[]) || [];
        }
        if (!isNaN(this.value))
          this.displayValue = dimTools.roundForDim(
            this.value * Math.pow(this.$fromSI(this.lengthUnit), this.sup),
            this.lengthUnit
          );
      },
      immediate: true,
    },
  },
  methods: {
    toInternalValue(valString: string): number | null {
      const valNumber = parseFloat(valString);
      if (!isNaN(valNumber)) {
        const v = valNumber * Math.pow(this.$toSI(this.lengthUnit), this.sup);
        return v;
        //Round M to 3 decimals
        // return Math.round(v * 1000) / 1000;
      }
      return null;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
