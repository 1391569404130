<template>
  <v-container>
    <v-row>
      <v-col cols="10" offset="1" sm="6" offset-sm="3">
        <v-card>
          <v-card-title>
            <img
              style="width: 100%; max-width: 300px; margin-left: auto; margin-right: auto"
              src="../../assets/svg/logo_black.svg" />
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="reset" autocomplete="on" id="reset-form">
              <v-text-field
                label="Email"
                v-model="email"
                placeholder=" "
                type="text"
                autocomplete="email"></v-text-field>
            </v-form>
            <v-alert v-if="formSent" outlined type="success">
              <p>Please check your email now (also in your spam folder).</p>
            </v-alert>
          </v-card-text>

          <v-card-actions>
            <v-btn
              block
              color="primary"
              type="submit"
              :loading="isLoading"
              :disabled="email.length === 0 || isLoading || formSent"
              form="reset-form"
              >Reset password</v-btn
            >
          </v-card-actions>
          <v-card-text class="text-center">
            <p>
              Back to login page -
              <router-link to="/login/">Click here</router-link>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import API from '@/API';
import Vue from 'vue';

export default Vue.extend({
  name: 'password_reset',
  data() {
    return {
      isLoading: false,
      email: '',
      formSent: false,
    };
  },
  computed: {},
  methods: {
    reset() {
      this.isLoading = true;
      API.passwordReset({
        email: this.email.trim(),
      })
        .then(() => {
          this.isLoading = false;
          this.formSent = true;
        })
        .catch(() => {
          this.formSent = true;
          this.isLoading = false;
        });
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
