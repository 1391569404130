<template>
  <v-row class="py-2">
    <v-divider></v-divider>
    <v-col cols="10">
      <h2 class="mb-2 print-only">{{ loadlist.name }}</h2>
      <v-row>
        <v-col>
          <v-row>
            <v-col
              v-if="loadlist.customer || loadlist.pol || loadlist.etd"
              class="shipping-details">
              <div>
                <strong>Details</strong>
                <div v-if="loadlist.customer">
                  <span>{{ loadlist.customer }}</span>
                </div>
                <div v-if="loadlist.pol && loadlist.pod">
                  <span>{{ loadlist.pol }} → {{ loadlist.pod }}</span>
                </div>
                <div v-if="loadlist.etd">
                  <span>ETD: {{ loadlist.etd }}</span>
                </div>
              </div>
            </v-col>
            <v-col class="loadlist-summary" cols="5">
              <div v-show="$route.name === 'workspace' || $route.name === 'loadplan'">
                <loadlist-summary :loadlist="loadlist" noSliders />
                <router-link
                  v-if="numberOfCustomRules && $route.name === 'workspace'"
                  :to="{ name: 'setup', params: { settings: 'settings' } }">
                  {{ numberOfCustomRules }} custom rules used</router-link
                >
                <router-link
                  v-if="segregationTableInUse && $route.name === 'workspace'"
                  :to="{ name: 'setup', params: { settings: 'settings' } }">
                  Segregation table in use</router-link
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="2">
      <img v-if="logoUrl" :src="logoUrl" alt="Image" style="max-width: 100%; max-height: 120px" />
      <div v-else-if="user.is_admin">
        <v-alert outlined color="info" class="px-2 py-2 my-0">
          <router-link class="link" :to="{ name: 'company_settings' }"
            >Add your own logo here</router-link
          >
        </v-alert>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue';

import { Loadlist, Loadplan, UnloadedItem } from '@/models/LoadlistModel';
import { User } from '@/models/UserCompanyModel';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { useMiscStore } from '@/stores/miscStore';
import LoadlistSummary from '../Custom/LoadlistSummary.vue';

export default Vue.extend({
  name: 'loadlist-info',
  components: { LoadlistSummary },
  data() {
    return {};
  },
  computed: {
    ...mapStores(useMiscStore, useLoadlistStore),
    typeName(): string {
      return this.$typeNames(this.loadlist.list_type);
    },
    setName(): string {
      return this.$setNames(this.loadlist.list_type);
    },

    total_unloaded_items(): number {
      return this.unloadedItems
        .map((i) => i.qty || 0)
        .reduce((acc, cur) => {
          return acc + cur;
        }, 0);
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
    unloadedItems(): UnloadedItem[] {
      return this.loadlistStore.unloaded_items;
    },
    loadplan(): Loadplan {
      return this.loadlistStore.loadplan;
    },
    logoUrl(): string {
      return this.loadlistStore.logo_url;
    },
    user(): User {
      return this.miscStore.user;
    },
    numberOfCustomRules(): number {
      return this.loadplan?.settings?.rules?.length;
    },
    segregationTableInUse(): boolean {
      return (
        !!this.loadplan?.settings?.segregation_table && this.loadlist.data.some((i) => !!i.class_id)
      );
    },
  },
  methods: {},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.no-border {
  border: none !important;
}
.break-word {
  word-break: break-word;
}
.shipping-details {
  max-width: 360px;
}
.link {
  display: block;
  text-align: center;
}
.print-only {
  display: none;
}
@media print {
  .print-only {
    display: unset;
  }
}
</style>
