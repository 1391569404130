import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"instructions"},[_c('div',{staticClass:"d-flex flex-wrap instructions-parent"},_vm._l((_vm.layers),function(layer,step){return _c('div',{key:step,staticClass:"instruction-card pa-2"},[_c(VCard,{staticClass:"text-center",attrs:{"outlined":""}},[(_vm.loadingMode == 'stacks')?_c('b',[_vm._v(_vm._s(layer.value)+" stacks")]):_c('b',[_vm._v("Step "+_vm._s(step + 1))]),_c(VCardText,{staticClass:"instructions-vcard"},[_c('scene-component',{key:step + _vm.loadingMode,attrs:{"enableReuse":"","canvas-width":350,"canvas-height":350,"hold-object":_vm.container.hold,"custom-view-settings":{
              view: '3d-perspective',
              highlight: 'byIndex',
              highlightMode: 'zoom',
              highlighted_cargo_indexes: layer.cargoes,
              visible_cargo_indexes: layer.previous_cargoes,
            }}}),_c('table',{staticClass:"mt-2 items-table ml-auto mr-auto"},_vm._l((layer.objects),function(o,i){return _c('tr',{key:i},[_c('td',[_c(VIcon,{style:({ color: o.color })},[_vm._v("fa-cube")])],1),_c('td',{staticClass:"text-left pl-2"},[_c('b',[_vm._v(_vm._s(i + 1))]),_vm._v(": "+_vm._s(o.label)+" ")]),_c('td',{staticClass:"text-left pl-2"},[_vm._v(_vm._s(o.count)+" pcs")])])}),0)],1)],1)],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }