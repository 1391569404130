<template>
  <div class="text-center">
    <v-progress-circular indeterminate v-bind:size="100" color="primary"></v-progress-circular>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import API from '@/API';
import { useMiscStore } from '@/stores/miscStore';
export default Vue.extend({
  name: 'sso-callback',
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {},
  mounted() {
    const auth_code = this.$route.query.code as string;

    if (auth_code) {
      this.isLoading = true;
      API.ssoLogin(auth_code)
        .then((data) => {
          this.isLoading = false;
          this.getMe().then((_) => {
            this.$router.push('/news');
          });
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  },
  methods: {
    getMe(): Promise<undefined> {
      return useMiscStore().getMe();
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
