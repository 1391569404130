<template>
  <v-dialog :value="visible" @input="(val) => $emit('update:visible', val)" scrollable>
    <v-card height="90vh">
      <v-card-title>
        <v-toolbar flat>
          <v-btn icon @click="$emit('update:visible', false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <template v-slot:extension>
            <v-tabs v-model="active_tab" fixed-tabs>
              <v-tab v-for="tab in tabs" :key="tab.title">{{ tab.title }}</v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-tabs-items class="ma-3" v-model="active_tab">
          <v-tab-item v-for="tab in tabs" :key="tab.key">
            <v-card flat class="mb-4" v-if="tab.key === 'primary_settings'">
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card outlined flat>
                      <v-divider></v-divider>
                      <v-card-title>Cargo</v-card-title>
                      <v-card-text>
                        <overhang-input
                          label="Allowed overhang"
                          v-model="internal_settings.allowed_overhang"></overhang-input>

                        <v-row>
                          <v-col>
                            <length-input-field
                              v-model="internal_settings.max_stack_height"
                              label="Max stacking height">
                            </length-input-field>
                          </v-col>
                          <v-col>
                            <v-img
                              class="mx-auto"
                              width="200"
                              :cover="false"
                              src="@/assets/svg/explainers/max_stack_height.svg" />
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <v-label>Error margin [%]</v-label>
                            <v-slider
                              class="py-2"
                              v-model="internal_settings.error_margin"
                              hint="Make the cargoes smaller or larger. A value of 10 will make each cargo 10% larger. A value of -10 will make each cargo 10% smaller."
                              :persistent-hint="!!internal_settings.error_margin"
                              thumb-label="always"
                              :thumb-size="24"
                              :thumb-color="internal_settings.error_margin ? 'primary' : 'grey'"
                              track-color="light-grey"
                              :track-fill-color="
                                internal_settings.error_margin ? 'primary' : 'grey'
                              "
                              :max="50"
                              :min="-50"
                              step="1"
                              ticks="always"
                              tick-size="0"></v-slider>
                          </v-col>
                          <v-col class="my-auto">
                            <v-img
                              class="mx-auto"
                              width="200"
                              :cover="false"
                              src="@/assets/svg/explainers/error_margin.svg" />
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <v-checkbox v-model="internal_settings.max_layers_only_per_sku">
                              <template slot="label">
                                Cargo max layer option is only applicable on items with same SKU
                              </template>
                            </v-checkbox>
                          </v-col>
                          <v-col>
                            <v-img
                              class="mx-auto"
                              width="200"
                              :cover="false"
                              src="@/assets/svg/explainers/max_layer_for_sku_only.svg" />
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <v-checkbox v-model="internal_settings.allow_heavy_on_light_item">
                              <template slot="label">
                                Heavier cargoes can be loaded on top of lighter cargoes
                              </template>
                            </v-checkbox>
                          </v-col>
                          <v-col>
                            <v-img
                              class="mx-auto"
                              width="200"
                              :cover="false"
                              src="@/assets/svg/explainers/allow_heavier_on_lighter.svg" />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-checkbox v-model="internal_settings.single_stacking">
                              <template slot="label">
                                Each cargo can only be supported by max one cargo below
                              </template>
                            </v-checkbox>
                          </v-col>
                          <v-col>
                            <v-img
                              class="mx-auto"
                              width="200"
                              :cover="false"
                              src="@/assets/svg/explainers/supported_max_one.svg" />
                          </v-col>
                        </v-row>

                        <shipping-factor-input
                          label="Shipping factor"
                          v-model="internal_settings.shipping_factor"></shipping-factor-input>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-card outlined flat>
                      <v-card-title>Performance</v-card-title>
                      <v-card-text>
                        <v-label>Max calculation time (s)</v-label>
                        <v-row class="mt-2">
                          <v-col>
                            <v-slider
                              class="py-2"
                              v-model="internal_settings.timeout"
                              hint="A higher timeout (usually) means a better solution for complex lists. Max 10 seconds"
                              thumb-label="always"
                              :thumb-size="24"
                              :max="10"
                              :min="0"
                              step="1"
                              ticks="always"
                              tick-size="0"></v-slider>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-title>Loading style</v-card-title>
                      <!-- <div class="mt-8 subtitle-1">Loading style</div> -->
                      <v-card-text>
                        <v-select
                          v-model="internal_settings.load_style"
                          label="Loading style"
                          :items="loading_styles"
                          hint="How cargoes should be loaded. Auto (default) uses container characteristics to apply the most suitable"
                          persistent-hint></v-select>

                        <v-checkbox
                          label="Prioritize Center Of Gravity"
                          hint="If enabled then the center of gravity should be within 10% from the center"
                          persistent-hint
                          v-model="internal_settings.prioritize_cog"></v-checkbox>

                        <v-checkbox
                          :label="`Load ${typeName}s in sets`"
                          v-model="internal_settings.use_containers_in_sets"
                          :hint="`If this is true, the 1st ${typeName} type will be loaded, then the 2nd, then 1st again etc... Useful when using Road trains`"
                          persistent-hint></v-checkbox>

                        <v-checkbox
                          v-if="['ROAD'].includes(base_type)"
                          label="Enable Routing"
                          hint="Uses Item Destination to set the load order. Overrides priority if enabled"
                          persistent-hint
                          v-model="internal_settings.routing"></v-checkbox>
                        <v-text-field
                          :label="`Max number of ${typeName}s to be used`"
                          :value="internal_settings.max_containers"
                          @input="internal_settings.max_containers = parseInt($event) || null"
                          min="0"
                          step="1"
                          type="number"></v-text-field>
                      </v-card-text>
                      <v-card-title>Consignments / Groups</v-card-title>
                      <v-card-text>
                        <v-form>
                          <v-checkbox v-model="internal_settings.separate_shipments">
                            <template slot="label">
                              <v-row align="center">
                                <v-col> Consignments cannot be mixed </v-col>
                                <v-col>
                                  <v-img
                                    width="220"
                                    :cover="false"
                                    src="@/assets/svg/explainers/not_mixed.svg" />
                                </v-col>
                              </v-row>
                            </template>
                          </v-checkbox>
                          <v-checkbox v-model="internal_settings.group_shipments">
                            <template slot="label">
                              <v-row align="center">
                                <v-col> Group consignments together </v-col>
                                <v-col>
                                  <v-img
                                    width="220"
                                    src="@/assets/svg/explainers/group_shipments.svg" />
                                </v-col>
                              </v-row>
                            </template>
                          </v-checkbox>
                          <v-checkbox v-model="internal_settings.single_container_shipment">
                            <template slot="label">
                              <v-row align="center">
                                <v-col> Use at most a single container per consignment </v-col>
                                <v-col>
                                  <v-img
                                    width="220"
                                    src="@/assets/svg/explainers/single_container.svg" />
                                </v-col>
                              </v-row>
                            </template>
                          </v-checkbox>
                          <v-checkbox v-model="internal_settings.load_whole_shipments_only">
                            <template slot="label">
                              <v-row align="center">
                                <v-col> Entire consignments must be loaded or none </v-col>
                                <v-col>
                                  <v-img
                                    width="220"
                                    src="@/assets/svg/explainers/all_or_none.svg" />
                                </v-col>
                              </v-row>
                            </template>
                          </v-checkbox>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card flat class="mb-4" v-if="tab.key === 'segregation_table'">
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-card outlined flat>
                      <v-card-title>Segregation table </v-card-title>
                      <v-card-actions>
                        <segregation-table-editor
                          :input="internal_settings.segregation_table"
                          @update="updateSegragationTable"></segregation-table-editor>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <custom-rules-editor
              v-if="tab.key === 'custom_rules'"
              :key="ruleRender"
              :holds="holds"
              :items="items"
              :rules="internal_settings.rules"
              :typeName="typeName"
              :class_ids="class_ids"
              @addRule="addRule"
              @removeRule="removeRule" />
            <v-card flat class="mb-4" v-if="tab.key === 'secondary'">
              <v-card-text>
                <v-alert outlined type="info">
                  These settings affect the loading of the secondary equipment only
                </v-alert>
                <!-- <v-btn
                  @click="internal_settings.secondary_settings = null"
                  v-if="internal_settings.secondary_settings"
                  >Use same as for primary</v-btn
                > -->
                <v-btn
                  color="success"
                  @click="addSecondarySettings"
                  v-if="!internal_settings.secondary_settings">
                  <v-icon>mdi-plus</v-icon>Add settings
                </v-btn>

                <v-row v-if="internal_settings.secondary_settings">
                  <v-col cols="12" md="6">
                    <v-card outlined flat>
                      <v-card-title>Loading style</v-card-title>
                      <!-- <div class="mt-8 subtitle-1">Loading style</div> -->
                      <v-card-text>
                        <v-select
                          v-model="internal_settings.secondary_settings.load_style"
                          label="Loading style"
                          :items="loading_styles"></v-select>

                        <v-checkbox
                          label="Prioritize Center Of Gravity"
                          hint="If enabled then the center of gravity should be within 10% from the center"
                          persistent-hint
                          v-model="
                            internal_settings.secondary_settings.prioritize_cog
                          "></v-checkbox>

                        <v-checkbox
                          :label="`Load ${typeName}s in sets`"
                          v-model="internal_settings.secondary_settings.use_containers_in_sets"
                          :hint="`If this is true, the 1st ${typeName} type will be loaded, then the 2nd, then 1st again etc... Useful when using Road trains`"
                          persistent-hint></v-checkbox>

                        <v-text-field
                          :label="`Max number of ${typeName}s to be used`"
                          :value="internal_settings.secondary_settings.max_containers"
                          @input="
                            internal_settings.secondary_settings.max_containers =
                              parseInt($event) || null
                          "
                          min="0"
                          step="1"
                          type="number"></v-text-field>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-card outlined flat>
                      <v-card-title>Consignments</v-card-title>
                      <v-card-text>
                        <v-form>
                          <v-checkbox
                            v-model="internal_settings.secondary_settings.separate_shipments">
                            <template slot="label">
                              <v-row align="center">
                                <v-col> Consignments cannot be mixed </v-col>
                                <v-col>
                                  <v-img
                                    width="220"
                                    :cover="false"
                                    src="@/assets/svg/explainers/not_mixed.svg" />
                                </v-col>
                              </v-row>
                            </template>
                          </v-checkbox>
                          <v-checkbox
                            v-model="internal_settings.secondary_settings.group_shipments">
                            <template slot="label">
                              <v-row align="center">
                                <v-col> Group consignments together </v-col>
                                <v-col>
                                  <v-img
                                    width="220"
                                    src="@/assets/svg/explainers/group_shipments.svg" />
                                </v-col>
                              </v-row>
                            </template>
                          </v-checkbox>
                          <v-checkbox
                            v-model="
                              internal_settings.secondary_settings.single_container_shipment
                            ">
                            <template slot="label">
                              <v-row align="center">
                                <v-col> Use at most a single container per consignment </v-col>
                                <v-col>
                                  <v-img
                                    width="220"
                                    src="@/assets/svg/explainers/single_container.svg" />
                                </v-col>
                              </v-row>
                            </template>
                          </v-checkbox>
                          <v-checkbox
                            v-model="
                              internal_settings.secondary_settings.load_whole_shipments_only
                            ">
                            <template slot="label">
                              <v-row align="center">
                                <v-col> Entire consignments must be loaded or none </v-col>
                                <v-col>
                                  <v-img
                                    width="220"
                                    src="@/assets/svg/explainers/all_or_none.svg" />
                                </v-col>
                              </v-row>
                            </template>
                          </v-checkbox>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import SegregationTableEditor from './SegregationTableEditor.vue';
import ShippingFactorInput from './ShippingFactorInput.vue';
import OverhangInput from './OverhangInput.vue';

import { HoldData, ListType, LoadRule, HoldInputItem } from '@/models/LoadlistModel';
import { CalculationSettings } from '@/models/CalculationModel';
import CustomRulesEditor from './CustomRulesEditor/index.vue';
import LengthInputField from '@/components/Custom/LengthInputField.vue';

export const DEFAULT_SETTINGS = {
  timeout: 2,
  load_style: null,
  max_containers: null,
  use_containers_in_sets: false,
  error_margin: 0.0,
  allow_heavy_on_light_item: false,
  one_side_overhang: false,
  separate_shipments: false,
  group_shipments: false,
  single_container_shipment: false,
  rules: null,
  prioritize_cog: false,
  allowed_overhang: 0.0,
  max_stack_height: null,
  segregation_table: null,
  load_whole_shipments_only: false,
  shipping_factor: null,
  max_layers_only_per_sku: false,
  single_stacking: false,
  routing: false,
} as CalculationSettings;

const DEFAULT_SECONDARY_SETTINGS = {
  load_style: 'bottom_up',
} as CalculationSettings;

export default Vue.extend({
  components: {
    SegregationTableEditor,
    ShippingFactorInput,
    OverhangInput,
    CustomRulesEditor,
    LengthInputField,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object as PropType<CalculationSettings>,
      default: () => {
        return { ...DEFAULT_SETTINGS };
      },
    },
    title: {
      type: String,
      default: 'Load settings',
    },
    base_type: {
      type: String as PropType<ListType>,
      default: 'SEA',
    },
    holds: {
      type: Array as PropType<HoldData[]>,
      default: () => [] as HoldData[],
    },
    items: {
      type: Array as PropType<HoldInputItem[]>,
      default: () => [] as HoldInputItem[],
    },
  },
  computed: {
    tabs(): { key: string; title: string; show: boolean }[] {
      return [
        {
          key: 'primary_settings',
          title: 'Primary',
          show: true,
        },
        {
          key: 'secondary',
          title: 'Secondary',
          show: true,
        },
        {
          key: 'custom_rules',
          title: 'Custom Rules',
          show: true,
        },
        {
          key: 'segregation_table',
          title: 'Segregation table',
          show: true,
        },
      ].filter((i) => i.show);
    },
    visibleTabs(): any {
      return this.tabs.filter((t) => t.show);
    },
    typeName(): string {
      return this.$typeNames(this.base_type);
    },
    loading_styles() {
      return [
        { text: 'Auto', value: null },
        { text: 'Minimize used length', value: 'minimize_length' },
        { text: 'Load bottom up', value: 'bottom_up' },
      ];
    },
    class_ids(): string[] {
      if (this.internal_settings?.segregation_table) {
        return Object.keys(this.internal_settings?.segregation_table);
      }
      return [];
    },
  },
  watch: {
    settings: {
      handler(a: CalculationSettings): void {
        let load_style = undefined;
        if (a.load_style === undefined) {
          if (a.load_from_bottom) load_style = 'bottom_up';
          else if (a.load_direction == 'shortwall') load_style = 'minimize_length';
        }
        this.internal_settings = {
          ...this.internal_settings,
          load_style,
          ...JSON.parse(JSON.stringify(a)),
        };
      },
      immediate: true,
    },
    visible(a: boolean): void {
      // Only emit when closing the modal
      if (!a) this.$emit('update', JSON.parse(JSON.stringify(this.internal_settings)));
    },
  },
  data() {
    return {
      internal_settings: JSON.parse(JSON.stringify(DEFAULT_SETTINGS)) as CalculationSettings,
      ruleRender: 0,
      active_tab: 0,
    };
  },
  methods: {
    updateSegragationTable(segregation_table: any): void {
      this.$set(this.internal_settings, 'segregation_table', segregation_table);
    },
    addRule(rule: LoadRule): void {
      if (!this.internal_settings.rules) this.internal_settings.rules = [];
      this.internal_settings.rules.push(rule);
    },
    removeRule(index: number): void {
      this.internal_settings.rules.splice(index, 1);
      this.ruleRender++;
      if (!this.internal_settings.rules.length) this.internal_settings.rules = null;
    },
    addSecondarySettings(): void {
      this.$set(this.internal_settings, 'secondary_settings', {
        ...DEFAULT_SETTINGS,
        ...DEFAULT_SECONDARY_SETTINGS,
      });
    },
  },
});
</script>

<style>
.v-slider--horizontal {
  margin: 0;
}
</style>
