<template>
  <v-app>
    <v-app-bar v-if="!iframeMode" clipped-right app elevation="0" class="d-print-none">
      <v-btn
        v-if="is_authenticated && loadlist"
        style="margin-left: -12px"
        icon
        @click="showShareDialog = true">
        <v-icon>mdi-export-variant</v-icon>
      </v-btn>

      <v-toolbar-title id="appTitle" class="pl-0">{{ pageTitle }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu v-if="is_authenticated" bottom offset-y :nudge-width="100">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" id="appMenuBtn">
            <v-icon>mdi-account</v-icon>
            {{ user.username }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="$router.push({ name: 'general_settings' })">
            <v-list-item-title> <v-icon left>mdi-account</v-icon>My Account </v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item @click="logout()">
            <v-list-item-title> <v-icon left>mdi-logout</v-icon>Logout </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-if="is_authenticated && !iframeMode"
      :mini-variant="miniDrawer"
      app
      permanent
      stateless
      class="d-print-none">
      <v-list class="py-1">
        <v-list-item class="px-2" @click="miniDrawer = !miniDrawer" :inactive="!miniDrawer">
          <v-list-item-avatar rounded="0" class="logo-shadow">
            <v-img src="@/assets/svg/logo-notext-blue.svg"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-img
              contain
              :style="{ filter: `invert(${$vuetify.theme.dark ? 1 : 0})` }"
              src="@/assets/svg/logo-text.svg"></v-img>
          </v-list-item-content>
          <v-btn icon @click.stop="miniDrawer = !miniDrawer">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <v-list nav dense>
        <v-tooltip right :disabled="!miniDrawer">
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" link :to="{ name: 'loadlists', params: { page: 1 } }">
              <v-list-item-icon>
                <v-icon>mdi-format-list-bulleted</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Load plans</v-list-item-title>
            </v-list-item>
          </template>
          <span>Load plans</span>
        </v-tooltip>
      </v-list>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-tooltip right :disabled="!miniDrawer">
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" link :to="{ name: 'load-presets' }">
              <v-list-item-icon>
                <v-icon left>mdi-database</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Presets</v-list-item-title>
            </v-list-item>
          </template>
          <span>Presets</span>
        </v-tooltip>

        <v-tooltip right :disabled="!miniDrawer">
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" link :to="{ name: 'equipment-view' }">
              <v-list-item-icon>
                <v-icon>mdi-warehouse</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Equipment</v-list-item-title>
            </v-list-item>
          </template>
          <span>Equipment</span>
        </v-tooltip>

        <v-tooltip right :disabled="!miniDrawer">
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" link :to="{ name: 'cargo-library-view' }">
              <v-list-item-icon>
                <v-icon>fa-cubes</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Cargo Library</v-list-item-title>
            </v-list-item>
          </template>
          <span>Cargo Library</span>
        </v-tooltip>
      </v-list>
      <v-divider></v-divider>
      <v-list nav dense v-if="user.is_admin && !user.is_sso">
        <v-tooltip right :disabled="!miniDrawer">
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" link :to="{ name: 'company_settings' }">
              <v-list-item-icon>
                <v-icon left>mdi-domain</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Company settings</v-list-item-title>
            </v-list-item>
          </template>
          <span>Company settings</span>
        </v-tooltip>

        <v-tooltip right :disabled="!miniDrawer">
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" link :to="{ name: 'user_management' }">
              <v-list-item-icon>
                <v-icon left>mdi-account-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-title>User management</v-list-item-title>
            </v-list-item>
          </template>
          <span>User management</span>
        </v-tooltip>
      </v-list>

      <v-divider></v-divider>
      <v-list nav dense>
        <v-tooltip right :disabled="!miniDrawer">
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" href="https://cargo-planner.com/docs" target="_blank">
              <v-list-item-icon>
                <v-icon left>mdi-help-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-title
                >Documentation
                <v-icon small>mdi-open-in-new</v-icon>
              </v-list-item-title>
            </v-list-item>
          </template>
          <span>Documentation</span>
        </v-tooltip>

        <v-tooltip right :disabled="!miniDrawer">
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" link :to="{ name: 'news' }">
              <v-list-item-icon>
                <v-icon left>mdi-newspaper-variant-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Updates</v-list-item-title>
            </v-list-item>
          </template>
          <span>Updates</span>
        </v-tooltip>
      </v-list>
    </v-navigation-drawer>
    <v-main class="no-print-main">
      <v-container fluid grid-list-md class="pb-16 height100">
        <router-view></router-view>
      </v-container>
    </v-main>

    <share-loadlist-modal
      :visible="showShareDialog"
      @close="showShareDialog = false"></share-loadlist-modal>

    <v-footer id="footer" color="primary" class="d-print-none pa-3" app v-if="!iframeMode">
      <v-row justify="center" class="text-caption">
        <span class="white--text">
          <strong>
            <a class="white--text" href="http://www.cargo-planner.com">Cargo-Planner</a>
            &copy; {{ currentYear }}
          </strong>
          -
        </span>
        <a class="white--text" @click="showTOC = true">Terms and Conditions</a>
      </v-row>
    </v-footer>

    <terms-and-conditions-modal
      :visible="showTOC"
      @close="showTOC = false"></terms-and-conditions-modal>

    <v-snackbar timeout="-1" multi-line v-model="showNewUpdateModal" class="no-print" bottom>
      There is a new update available.
      <v-btn text color="primary" @click.stop="refreshPage">Refresh now</v-btn>
      <v-btn text @click.stop="showNewUpdateModal = false">Later</v-btn>
    </v-snackbar>

    <v-dialog v-model="showGLModal" scrollable>
      <v-card>
        <v-card-title class="text-h5">Problem with 3D rendering</v-card-title>
        <v-card-text>
          Your browser or graphics card does not seem to support WebGL. Check out
          <a href="https://get.webgl.org/">this page</a> to read more.
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="showGLModal = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="miscStore.showAccessCodeRequiredModal" :value="true" scrollable>
      <v-card>
        <v-card-title class="text-h5">Temporarily access code required</v-card-title>
        <v-card-text>
          For security reasons we need an extra access code in order to use the tool. Please get in
          touch with us.
          <v-text-field required label="Access code" v-model="tmp_access_code"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="updateAccessCode">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar :timeout="5000" v-model="showErrorSnackbar" top>
      {{ error_message }}
      <v-btn text color="primary" @click="showErrorSnackbar = false">Ok</v-btn>
    </v-snackbar>
    <app-tour v-if="!iframeMode"></app-tour>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import { SceneManager } from '@/graphics/sceneManager';
import TermsAndConditionsModal from '@/components/Modals/TermsAndConditions.vue';
import ShareLoadlistModal from '@/components/Modals/ShareLoadlist.vue';
import API from '@/API';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { useLoadlistStore } from '@/stores/loadlistStore';

import { CompanyStoreTemplate, User } from './models/UserCompanyModel';
import { Loadlist } from './models/LoadlistModel';
import axios from 'axios';

const debug = process.env.NODE_ENV !== 'production';
const PAGE_REFRESH_INTERVALL = debug ? 10 * 60000 : 30 * 60000;
const IS_IFRAME = !(window === window.parent);
let previousHtml: string = undefined;

export default Vue.extend({
  name: 'app',
  components: {
    TermsAndConditionsModal,
    ShareLoadlistModal,
  },
  data() {
    return {
      iframeMode: IS_IFRAME,
      miniDrawer: true,
      showTOC: false,
      showNewUpdateModal: false,
      showGLModal: false,
      showShareDialog: false,
      tmp_access_code: null as string,
    };
  },
  computed: {
    ...mapStores(useMiscStore, useLoadlistStore),
    pageTitle(): string {
      return this.loadlist?.name || this.$route.meta.title;
    },
    currentUrl(): string {
      const pathArray = location.href.split('/');
      const host = pathArray[2];
      return host;
    },
    currentYear(): number {
      return new Date().getFullYear();
    },
    showErrorSnackbar: {
      get(): boolean {
        return !!this.error_message;
      },
      set(value: boolean): void {
        this.miscStore.error_message = null;
      },
    },
    is_authenticated(): boolean {
      return this.miscStore.is_authenticated;
    },
    user(): User {
      return this.miscStore.user;
    },
    company(): CompanyStoreTemplate {
      return this.miscStore.company;
    },
    error_message(): string {
      return this.miscStore.error_message;
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
  },
  async created(): Promise<void> {
    try {
      await SceneManager.init();
    } catch (e) {
      this.showGLModal = true;
    }

    (window as any).cpl = {
      renderCount: 0,
      setLoadplan: function (loadlist: Loadlist, version: number) {
        useLoadlistStore().setLoadlist(loadlist);
        useLoadlistStore().setLoadplanVersion(version);
      },
    };

    const urlParams = new URLSearchParams(window.location.search);

    if (!urlParams.get('no_fetch')) {
      // Try to get profile and then download system holds
      useMiscStore()
        .getMe()
        .catch((e) => {})
        .finally(() => {
          const store = useMiscStore();
          store.getAllSystemEquipment();
        });
    }

    //PAGE REFRESH TIMER
    this.checkForUpdate();
    setInterval(() => {
      this.checkForUpdate()
        .then((newVersionAvailable: boolean) => {
          console.log('New update available');
          if (newVersionAvailable) {
            if (['loadlist', 'workspace', 'detail'].includes(this.$route.name)) {
              this.showNewUpdateModal = newVersionAvailable;
            } else {
              window.location.reload();
              window.location.reload();
              window.location.reload();
            }
          }
        })
        .catch((e) => {
          console.log('Could not check for update');
        });
    }, PAGE_REFRESH_INTERVALL);
  },
  mounted() {
    if (this.$route.query.dark) this.$vuetify.theme.dark = true;
  },
  methods: {
    refreshPage(): void {
      window.location.reload();
    },
    updateAccessCode(): void {
      API.instance.defaults.headers.common['tmp_access_code'] = this.tmp_access_code;
      this.miscStore.showAccessCodeRequiredModal = false;
    },
    logout() {
      this.miscStore.logout(null);
    },
    checkForUpdate() {
      return new Promise((resolve, reject) => {
        const pathArray = location.href.split('/');
        const url = pathArray[0] + '//' + pathArray[2];

        axios
          .get(`${url}/index.html?timestamp=${new Date().getTime()}`)
          .then((response) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(response.data, 'text/html');
            const metas = doc.head.querySelectorAll('meta');
            metas.forEach((meta) => {
              if (meta.getAttribute('property') === 'csp-nonce') {
                meta.remove();
              }
            });
            const html = doc.documentElement.outerHTML;

            if (!previousHtml) {
              previousHtml = html;
              resolve(false);
            } else if (previousHtml !== html) {
              previousHtml = html;
              if (useMiscStore().is_authenticated) {
                resolve(true);
              } else {
                window.location.reload();
              }
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});
</script>

<style>
@media all {
  img.watermark {
    display: none;
  }
}

@media print {
  img.watermark {
    display: block;
    position: fixed;
    width: 100%;
    max-width: 300px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-30deg);
    opacity: 0.2;
    z-index: 99999;
  }

  .no-print-main {
    padding: 0px !important;
  }

  .no-print {
    display: none !important;
  }

  .pb {
    page-break-after: always;
  }

  .pb:last-child {
    page-break-after: avoid;
  }

  .no-pb-inside {
    page-break-inside: avoid;
  }
}

@media screen {
  .only-print {
    display: none;
  }
}

@page {
  margin-top: 0.5cm;
  margin-bottom: 0.5cm;
  margin-left: 0.1cm;
  margin-right: 0.1cm;
}

.is-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.height100 {
  height: 100%;
}
.clickable {
  pointer-events: auto;
  cursor: pointer;
}
.draggable {
  cursor: grab;
}
</style>

<style lang="scss">
$cplBlue: rgb(70, 120, 178);
</style>
