import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"10","offset":"1","sm":"6","offset-sm":"3"}},[_c(VCard,[_c(VCardTitle,[_c('img',{staticStyle:{"width":"100%","max-width":"300px","margin-left":"auto","margin-right":"auto"},attrs:{"src":require("../../assets/svg/logo_black.svg")}})]),_c(VCardText,[_c(VForm,{attrs:{"id":"magiclink-form","autocomplete":"on"},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"Email","placeholder":" ","type":"text","autocomplete":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),(!_vm.formSent)?_c(VAlert,{attrs:{"outlined":"","type":"info"}},[_c('p',[_vm._v(" Enter your email and you will recieve an email with a link that'll sign you in instantly ")])]):_c(VAlert,{attrs:{"outlined":"","type":"success"}},[_c('p',[_vm._v(" Please check your email now (also in your spam folder). The link is valid for 15 minutes ")])])],1),_c(VCardActions,[_c(VBtn,{attrs:{"block":"","color":"primary","type":"submit","loading":_vm.isLoading,"disabled":_vm.email.length === 0 || _vm.isLoading || _vm.formSent,"form":"magiclink-form"}},[_vm._v("Get my link")])],1),_c(VCardText,{staticClass:"text-center"},[_c('p',[_vm._v(" Login with Password "),_c('router-link',{attrs:{"to":"/login/"}},[_vm._v("Click here")])],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }