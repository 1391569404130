<template>
  <div>
    <div>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <slot name="view" v-bind="{ renderKey }"> </slot>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="Quantity [#]:"
            type="number"
            persistent-hint
            hint="(Number of sets available)"
            :value="modified_set.qty"
            @change="$set(modified_set, 'qty', parseInt($event) || null)"></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Cost [$, €, £, ...]:"
            type="number"
            persistent-hint
            :hint="`(A number, relative to other ${typeName}s and sets)`"
            :value="modified_set.cost"
            @change="$set(modified_set, 'cost', parseFloat($event) || null)"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <weight-input-field
            label="Payload"
            :min="0"
            persistent-hint
            hint="(Max weight)"
            v-model="modified_set.payload"></weight-input-field>
        </v-col>
        <v-col cols="6">
          <length-input-field
            label="Max volume"
            :min="0"
            :sup="3"
            persistent-hint
            hint="(Max volume)"
            v-model="modified_set.max_volume"></length-input-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col> Equipment used in this {{ setName().toLowerCase() }} </v-col>
      </v-row>
      <v-row
        dense
        align="center"
        v-for="(hold, index) in modified_set.container_types"
        :key="hold.id">
        <v-col cols="12">
          <v-expansion-panels focusable class="unselectable">
            <v-expansion-panel @click="$vuetify.goTo($event.target)">
              <v-expansion-panel-header>{{ hold.name }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <inline-hold-edit-component
                  v-model="modified_set.container_types[index]"
                  :basetype="modified_set.base_type">
                  <!-- <template v-slot:view="{ renderKey }">
                                    <scene-component
                                      :hold-object="hold"
                                      :canvas-width="400"
                                      :canvas-height="200"
                                      :key="renderKey"
                                    ></scene-component>
                                  </template> -->
                </inline-hold-edit-component>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script lang="ts">
// import createHold from "@/misc/containerUtils.js";
import Vue from 'vue';
import inlineHoldEditComponent from '@/components/Custom/InlineHoldEdit.vue';
// TODO: turn into ts
export default Vue.extend({
  name: 'modify-set',
  components: {
    inlineHoldEditComponent,
  },
  data() {
    return {
      renderKey: 1,
      modified_set: JSON.parse(JSON.stringify(this.value)),
    };
  },
  props: ['value', 'basetype'],
  computed: {
    typeName(): string {
      return this.$typeNames(this.basetype);
    },
  },
  watch: {
    modified_set: {
      handler: function () {
        // let modified_set = createHold.assemble(
        //   JSON.parse(JSON.stringify(val))
        // );
        this.$emit('input', this.modified_set);
        // console.log(this.modified_set);

        this.renderKey++;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setName(): string {
      return this.$setNames(this.modified_set.base_type);
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
