<template>
  <v-dialog v-model="show">
    <v-card>
      <v-card-title class="text-h5">Sharing</v-card-title>
      <v-card-text>
        <v-alert type="info" outlined>
          You can provide access to a load plan through a sharable link which does not require
          authentication
        </v-alert>
        <v-select solo v-model="public_access" :items="publicAccessOptions"> </v-select>
        <v-text-field
          ref="urlTextField"
          :disabled="!public_access"
          single-line
          readonly
          outlined
          @click="selectLink"
          :value="thisUrl">
          <template v-slot:append-outer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn @click="copyLink" icon color="primary" :disabled="!public_access">
                  <v-icon v-on="on">fa-clone</v-icon>
                </v-btn>
              </template>
              Copy link to clipboard
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="loadlist"
                  :href="`mailto:?subject=Cargo-Planner Load plan: ${loadlist.name}&body=${thisUrl}`"
                  icon
                  :disabled="!public_access"
                  color="primary">
                  <v-icon v-on="on">fa-share</v-icon>
                </v-btn>
              </template>
              Send in email
            </v-tooltip>
          </template>
        </v-text-field>

        <strong>Notice:</strong> When sharing is enabled - anyone who enters above URL will be able
        to access this load plan. Evaluate this if doing very confidential work and be restrictive
        to whom you share the link with. Treat it like a secret.
      </v-card-text>

      <v-card-actions>
        <v-btn text @click.stop="show = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Loadlist } from '@/models/LoadlistModel';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { useLoadlistStore } from '@/stores/loadlistStore';

export default Vue.extend({
  props: {
    visible: Boolean,
  },

  data() {
    return {};
  },
  computed: {
    ...mapStores(useMiscStore, useLoadlistStore),
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    public_access: {
      get(): 'RO' | 'RW' | null {
        return this.loadlist ? this.loadlist.public_access : null;
      },
      set(value: 'RO' | 'RW' | null): void {
        this.loadlistStore.setLoadlistProperty({
          key: 'public_access',
          value: value,
        });
        this.saveLoadlist();
      },
    },
    publicAccessOptions() {
      return [
        { text: 'Disabled', value: null },
        { text: 'Read Only', value: 'RO' },
        { text: 'Read and Write', value: 'RW' },
      ];
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
    thisUrl(): string {
      if (this.public_access) {
        const url = new URL(this.loadlist.url);
        url.searchParams.set('length_dim', this.miscStore.length_dim);
        url.searchParams.set('weight_dim', this.miscStore.weight_dim);
        return url.href;
      }
      return '-';
    },
  },
  methods: {
    selectLink(e: any): void {
      e.target.select();
    },
    copyLink(): void {
      (this.$refs.urlTextField as any).$refs.input.select();
      document.execCommand('copy');
    },
    saveLoadlist() {
      this.loadlistStore.saveLoadlist();
    },
  },
});
</script>
