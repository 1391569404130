import { ExcelImportData, Dimensions, ItemRow, ColumnMapping } from '@/models/InputDataModel';
import {
  Alignment,
  BorderStyle,
  Cell,
  CellFormulaValue,
  CellValue,
  Fill,
  Row,
  Workbook,
  Worksheet,
} from 'exceljs';
import { InputDataTable, TablesData } from '@/models/InputDataModel';
import { Cargo, HoldInputItem, LengthDim, Loadlist, WeightDim } from '@/models/LoadlistModel';
import itemProperties from '@/misc/itemProperties';
import { useMiscStore } from '@/stores/miscStore';
const searchVariants = new Map(
  Object.entries({
    sku: ['item', 'sku'],
    label: ['cargo', 'desc', 'content', 'name', 'kollinummer', 'package'],
    l: ['length', 'lwh', 'lxwxh', 'l*w*h', 'länge', 'dimension'],
    w: ['width', 'weite', 'breite'],
    h: ['height', 'höhe'],
    wt: ['gross', 'g.w', 'gw', 'gr.wt', 'grwt', 'weight', '(kg', ' kg', 'brutto', 'gewicht', 'mt'],
    qty: ['quantity', 'qty', 'qnty', "q'ty", 'qua', 'pcs', 'units'],
    stackable: ['stack', 'stapel', 'stackable'],
    not_stackable: ['not stackable', 'no stack'],
    bottom_only: ['bottom', 'floor'],
    tiltable: ['tiltable'],
    longship: ['longship', 'lengthwise'],
    max_layers: ['layers'],
    max_load: ['max load', 'max weight on top'],
    shipment_id: ['shipment', 'group', 'consignment'],
    priority: ['prio'],
    geometry: ['geometry', 'shape'],
    palletize: ['palletize', 'pall', 'preload'],
    unit_qty: ['unit quantity', 'units per box'],
    class_id: ['class'],
    allowed_containers: ['load in'],
  })
);
const skuVariants = ['item', 'sku'];

const labelVariants = ['cargo', 'desc', 'content', 'name', 'kollinummer', 'package'];
const lengthVariants = ['length', 'lwh', 'lxwxh', 'l*w*h', 'länge', 'dimension'];
const widthVariants = ['width', 'weite', 'breite'];
const heightVariants = ['height', 'höhe'];
const weightVariants = [
  'gross',
  'g.w',
  'gw',
  'gr.wt',
  'grwt',
  'weight',
  '(kg',
  ' kg',
  'brutto',
  'gewicht',
  'mt',
];
const qtyVariants = ['quantity', 'qty', 'qnty', "q'ty", 'qua', 'pcs', 'units'];
const stackableVariants = ['stack', 'stapel', 'stackable'];
const notStackableVariants = ['not stackable', 'no stack'];
const truthyAnswers = ['y', 'yes', 'true'];
const stackableTruthyAnswers = [...truthyAnswers, 'stack'];
const bottomOnlyVariants = ['bottom', 'floor'];
const tiltableVariants = ['tiltable'];
const longshipVariants = ['longship', 'lengthwise'];
const layersVariants = ['layers'];
const maxloadVariants = ['max load', 'max weight on top'];
const shipmentVariants = ['shipment', 'group', 'consignment'];
const priorityVariants = ['prio'];
const geometryVariants = ['geometry', 'shape'];
const palletizeVariants = ['palletize', 'pall', 'preload'];
const unitQuantityVariants = ['unit quantity', 'units per box'];
const classVariants = ['class'];
const loadInVariants = ['load in'];
const orientationVariants = ['orientation'];
const destinationVariants = ['destination'];
const weightDimText = 'Weight dimension';
const lengthDimText = 'Length dimension';

const motToContainerName = (t: string) => {
  switch (t) {
    case 'PALL':
      return 'pallet';
    case 'ROAD':
      return 'trailer';
    case 'AIR':
      return 'ULD';
    default:
      return 'container';
  }
};

const weightDimensionMap = new Map([
  ['Kilograms', 'KG'],
  ['Tonnes', 'MT'],
  ['Pounds', 'LB'],
]);
const lengthDimensionMap = new Map([
  ['Millimeters', 'MM'],
  ['Centimeters', 'CM'],
  ['Decimeters', 'DM'],
  ['Meters', 'M'],
  ['Inches', 'IN'],
  ['Feet', 'FT'],
]);

const letters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

const fontName = 'Calibri';

const headerFill: Fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: {
    argb: 'FF5c9ce6',
  },
};

const dimensionsBorderMedium = {
  top: { style: 'medium' as BorderStyle, color: { argb: 'FF000000' } },
  left: { style: 'medium' as BorderStyle, color: { argb: 'FF000000' } },
  right: { style: 'medium' as BorderStyle, color: { argb: 'FF000000' } },
};
const allBordersMedium = {
  top: { style: 'medium' as BorderStyle, color: { argb: 'FF000000' } },
  bottom: { style: 'medium' as BorderStyle, color: { argb: 'FF000000' } },
  right: { style: 'medium' as BorderStyle, color: { argb: 'FF000000' } },
  left: { style: 'medium' as BorderStyle, color: { argb: 'FF000000' } },
};
const bottomBorderMedium = {
  bottom: { style: 'medium' as BorderStyle, color: { argb: 'FF000000' } },
};
const topThinBorder = {
  top: { style: 'thin' as BorderStyle, color: { argb: 'FF000000' } },
};
const alignCenterMiddle: Partial<Alignment> = {
  horizontal: 'center',
  vertical: 'middle',
  wrapText: true,
};
const boldFontStyling = { name: fontName, bold: true };
const headerFontStyling = { name: fontName, bold: true, size: 12 };
const titleFontStyling = { name: fontName, bold: true, size: 14 };

export default class ExcelService {
  loadlistToXlsx(
    workbook: Workbook,
    params: { loadlist: Loadlist; tablesJson: InputDataTable[] }
  ): Workbook {
    const worksheet = workbook.addWorksheet('Sheet1');
    const table = params.tablesJson[0];
    const headers = Object.values(table.headerRows[table.headerRows.length - 1])
      .map((header) => header.value)
      .filter((v) => v !== '');

    worksheet.columns = headers.map((headerName) => {
      return {
        key: headerName,
        width: 20,
        style: { font: { name: fontName } },
      };
    });

    const headerRow = worksheet.addRow(headers);
    headerRow.font = headerFontStyling;
    headerRow.border = bottomBorderMedium;

    const valuesRows = table.valuesRows;
    valuesRows.forEach((valuesRow) => {
      if (valuesRow.values[0] != null) {
        worksheet.addRow(valuesRow.values);
      } else {
        const totalRow = worksheet.addRow(valuesRow.values);
        totalRow.font = boldFontStyling;
        totalRow.border = bottomBorderMedium;
      }
    });
    return workbook;
  }

  cargoToXlsx(
    workbook: Workbook,
    params: {
      items: Cargo[];
      headers: { key: string; value: string; dimension?: string }[];
      toLength: (value: number, dim: LengthDim) => number;
      toWeight: (value: number, dim: WeightDim) => number;
    }
  ): Workbook {
    const worksheet = workbook.addWorksheet('Sheet1');
    const headers = params.headers;

    worksheet.columns = headers.map((headerName) => {
      return {
        key: headerName.key,
        width: 20,
        style: { font: { name: fontName } },
      };
    });

    const headerRow = worksheet.addRow(headers.map((h) => h.value));
    headerRow.font = headerFontStyling;
    headerRow.border = bottomBorderMedium;

    params.items
      .map((item) =>
        headers.map((h) => {
          const v = item.data[h.key as keyof HoldInputItem];
          if (h.dimension == 'length') {
            return params.toLength(v as number, item.length_dim);
          } else if (h.dimension == 'weight') {
            return params.toWeight(v as number, item.weight_dim);
          }
          return v;
        })
      )
      .forEach((item) => {
        worksheet.addRow(item);
      });
    return workbook;
  }

  // adds loadplan info to top of excel file. Returns current row.
  private addLoadplanInfoToXlsx(
    worksheet: Worksheet,
    row: number,
    loadlist: Loadlist,
    headersLength: number,
    summary: string[]
  ): number {
    const nameRow = worksheet.getRow(row);
    nameRow.font = titleFontStyling;
    nameRow.values = [loadlist.name];
    worksheet.mergeCells(nameRow.getCell(1).address, nameRow.getCell(headersLength).address);

    row += 1;

    if (loadlist.pol) {
      const loadingRow = worksheet.getRow(row);
      loadingRow.values = [`Port of Loading: ${loadlist.pol}`];
      worksheet.mergeCells(
        loadingRow.getCell(1).address,
        loadingRow.getCell(headersLength).address
      );
      row += 1;
    }
    if (loadlist.pod) {
      const destinationRow = worksheet.getRow(row);
      destinationRow.values = [`Port of Destination: ${loadlist.pod}`];
      worksheet.mergeCells(
        destinationRow.getCell(1).address,
        destinationRow.getCell(headersLength).address
      );
      row += 1;
    }
    if (loadlist.customer) {
      const customerRow = worksheet.getRow(row);
      customerRow.values = [`Customer: ${loadlist.customer}`];
      worksheet.mergeCells(
        customerRow.getCell(1).address,
        customerRow.getCell(headersLength).address
      );
      row += 1;
    }
    if (loadlist.etd) {
      const etdRow = worksheet.getRow(row);
      etdRow.values = [`ETD: ${loadlist.etd}`];
      worksheet.mergeCells(etdRow.getCell(1).address, etdRow.getCell(headersLength).address);
      row += 1;
    }
    if (loadlist.notes) {
      const notesRow = worksheet.getRow(row);
      notesRow.values = [`Notes: ${loadlist.notes}`];
      worksheet.mergeCells(notesRow.getCell(1).address, notesRow.getCell(headersLength).address);
      row += 1;
    }

    if (summary.length) {
      row += 1;
      const summaryRow = worksheet.getRow(row);
      summaryRow.font = headerFontStyling;
      summaryRow.values = ['Summary'];
      worksheet.mergeCells(
        summaryRow.getCell(1).address,
        summaryRow.getCell(headersLength).address
      );
      row += 1;
      summary.forEach((line) => {
        const r = worksheet.getRow(row);
        r.values = [line];
        worksheet.mergeCells(r.getCell(1).address, r.getCell(headersLength).address);
        row += 1;
      });
    }
    return row;
  }
  // adds header rows. Returns last header row.
  private addLoadplanHeaderRowsToXlsx(
    worksheet: Worksheet,
    tablesData: TablesData[],
    headers: string[],
    row: number
  ): Row {
    const tableInfo = tablesData[0];
    if (tableInfo.tableJson.headerRows.length > 1) {
      // get values for first header row
      const values: string[] = [];
      tableInfo.tableJson.headerRows[0].forEach((header) => {
        for (let i = 0; i < header.colspan; i++) {
          values.push(header.value);
        }
      });

      // set values for first header row
      const headerRowFirstLine = worksheet.getRow(row);
      headerRowFirstLine.values = values;
      let cell = 0;

      tableInfo.tableJson.headerRows[0].forEach((header) => {
        // merge cells in first header row
        if (header.colspan > 1) {
          worksheet.mergeCells(
            `${letters[cell]}${row}:${letters[cell + header.colspan - 1]}${row}`
          );
        }
        if (header.value) {
          worksheet.getCell(`${letters[cell]}${row}`).border = dimensionsBorderMedium;
        }
        cell += header.colspan;
      });
      row += 1;
    }

    // set values for second header row
    const headerRowSecondLine = worksheet.getRow(row);
    headerRowSecondLine.values = headers;

    headerRowSecondLine.eachCell((cell, colNumber) => {
      const columnLetter = worksheet.getColumn(colNumber).letter;
      const aboveCell = worksheet.getCell(`${columnLetter}${headerRowSecondLine.number - 1}`);
      // merge available cells with first header row
      if (!aboveCell.value) {
        aboveCell.value = cell.value;
        worksheet.mergeCells(`${aboveCell.address}:${cell.address}`);
      }
    });

    return headerRowSecondLine;
  }

  // adds total row at the bottom
  private addFinalTotalRow(worksheet: Worksheet, tablesData: TablesData[], row: number) {
    let i = 0;
    let sumRow: (string | number)[] = [];

    for (i = 0; i < tablesData.length; i++) {
      // sumRow = tablesData[i].tableJson.totalRow;
      if (!tablesData[i].tableJson.unloaded && tablesData[i].tableJson.totalRow?.values) {
        sumRow = Array.apply(null, Array(tablesData[i].tableJson.totalRow.values.length));
        sumRow[0] = 'Total';
        break;
      }
    }

    for (let index = 0; index < tablesData.length; index++) {
      if (tablesData[index].tableJson.unloaded || !tablesData[index].tableJson.totalRow) {
        continue;
      }
      const tableData = tablesData[index];
      const total = tableData.tableJson.totalRow;
      total.values?.forEach((currValue, i) => {
        const prevValue = sumRow[i];
        const value = Number(currValue) * tableData.containerCount;
        if (!value) {
          return;
        }
        if (prevValue === null || isNaN(Number(prevValue))) {
          sumRow[i] = value;
        } else {
          sumRow[i] = Number(prevValue) + value;
        }
      });
    }
    const insertedRow = worksheet.insertRow(row, sumRow);
    insertedRow.eachCell({ includeEmpty: true }, (cell) => {
      cell.font = boldFontStyling;
    });
  }

  private addValueRowsToXlsx(
    worksheet: Worksheet,
    tablesData: TablesData[],
    headers: string[],
    row: number,
    containerColumnName: string
  ) {
    tablesData.forEach((tableInfo) => {
      const valuesRows = tableInfo.tableJson.valuesRows;
      const startMergeRow = row + 1;
      valuesRows.forEach((valuesRow, index) => {
        const values = valuesRow.values;
        values[headers.indexOf(containerColumnName)] =
          index === 0 ? tableInfo.containerTitleText : '';
        values[headers.indexOf('Notes')] = index === 0 ? tableInfo.notes : '';
        if (tableInfo.quotation) {
          values[headers.indexOf('Quotation')] = index === 0 ? tableInfo.quotation : '';
        }

        if (values[1] != null) {
          // indent if pallet row
          if (!valuesRow.isFirstChild) {
            values[1] = ' ↳ ' + values[1];
          }
          row += 1;
          worksheet.insertRow(row, values);
        }
      });

      // adds total row for container
      if (!tableInfo.tableJson.unloaded) {
        row += 1;
        const totalRow = tableInfo.tableJson.totalRow;
        const insertedTotalRow = worksheet.insertRow(row, totalRow.values);
        insertedTotalRow.eachCell({ includeEmpty: true }, (cell) => {
          cell.font = boldFontStyling;
          cell.border = topThinBorder;
        });
      }

      // group container rows
      const mergeStart = startMergeRow;
      const mergeEnd = row;
      const mergedCells = [
        this.mergeCells(
          worksheet,
          mergeStart,
          headers.indexOf(containerColumnName) + 1,
          mergeEnd,
          headers.indexOf(containerColumnName) + 1
        ),
        this.mergeCells(
          worksheet,
          mergeStart,
          headers.indexOf('Notes') + 1,
          mergeEnd,
          headers.indexOf('Notes') + 1
        ),
      ];

      // adds quotation if present
      if (headers.indexOf('Quotation') >= 0) {
        mergedCells.push(
          this.mergeCells(
            worksheet,
            mergeStart,
            headers.indexOf('Quotation') + 1,
            mergeEnd,
            headers.indexOf('Quotation') + 1
          )
        );
      }

      // adds border to bottom row of merged cells
      worksheet.getRow(row).eachCell({ includeEmpty: true }, (cell) => {
        cell.border = {
          ...cell.border,
          bottom: bottomBorderMedium.bottom,
        };
      });

      // Highlight the unloaded items with a red-ish fill
      if (tableInfo.tableJson.unloaded) {
        mergedCells[0].fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'd77e6a' },
          bgColor: { argb: 'd77e6a' },
        };
        mergedCells[0].font = boldFontStyling;
      }
    });
    return row;
  }

  private mergeCells(
    worksheet: Worksheet,
    top: number,
    left: number,
    bottom: number,
    right: number
  ): Cell {
    worksheet.mergeCells(top, left, bottom, right);
    return worksheet.getCell(top, left);
  }

  private styleHeaderRows(worksheet: Worksheet, headerRow: Row): void {
    [worksheet.getRow(headerRow.number - 1), headerRow].forEach((row) =>
      row.eachCell({ includeEmpty: true }, (cell) => {
        cell.border = allBordersMedium;
        cell.fill = headerFill;
        cell.font = headerFontStyling;
      })
    );
  }

  // additional styling for cells
  private styleCells(worksheet: Worksheet, headerRow: Row, lastRow: number): void {
    worksheet
      .getRows(headerRow.number, lastRow - headerRow.number)
      .forEach((row) => (row.height = 16));
    this.styleHeaderRows(worksheet, headerRow);
    worksheet.columns.forEach(function (column) {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, function (cell, rowNumber) {
        const columnLength = cell.value ? cell.value.toString().length + 2 : 10;
        // calculates max column length
        if (rowNumber >= headerRow.number - 1) {
          cell.alignment = alignCenterMiddle;
          if (columnLength > maxLength) {
            maxLength = columnLength + 2;
          }
        }
        // sets borders for value rows
        if (
          rowNumber > headerRow.number &&
          (((column.number as any) <= headerRow.values.length) as any)
        ) {
          cell.border = {
            ...cell.border,
            left: allBordersMedium.left,
            right: allBordersMedium.right,
          };
        }
        // adds border for last total row
        if (rowNumber === lastRow) {
          cell.border = {
            ...cell.border,
            bottom: allBordersMedium.bottom,
          };
        }
      });
      column.width = maxLength < 6 ? 6 : maxLength;
    });
  }

  loadplanToXlsx(
    workbook: Workbook,
    params: { loadlist: Loadlist; summary: string[]; tablesData: TablesData[] }
  ): Workbook {
    const worksheet = workbook.addWorksheet('Sheet1');
    const loadlist = params.loadlist;
    const tablesData = params.tablesData;
    const containerColumnName =
      motToContainerName(loadlist.list_type).charAt(0).toUpperCase() +
      motToContainerName(loadlist.list_type).slice(1);
    const headers = this.getHeaders(tablesData, containerColumnName);
    worksheet.columns = headers.map((headerName: string) => {
      return { key: headerName };
    });

    let row = 1;
    row = this.addLoadplanInfoToXlsx(worksheet, row, loadlist, headers.length, params.summary);
    row += 1;
    const headerRow = this.addLoadplanHeaderRowsToXlsx(worksheet, tablesData, headers, row);
    row = this.addValueRowsToXlsx(
      worksheet,
      tablesData,
      headers,
      headerRow.number,
      containerColumnName
    );
    row += 1;
    this.addFinalTotalRow(worksheet, tablesData, row);
    this.styleCells(worksheet, headerRow, row);
    return workbook;
  }

  private getHeaders(tablesData: TablesData[], containerColumnName: string) {
    let headersList = Object.values(
      tablesData[0].tableJson.headerRows[tablesData[0].tableJson.headerRows.length - 1]
    )
      .map((header) => header.value)
      .filter((v) => v !== '');

    headersList = [containerColumnName, ...headersList];
    if (tablesData.some((td) => td.quotation !== undefined)) {
      headersList.push('Quotation');
    }
    headersList.push('Notes');
    return headersList;
  }

  parseLoadlist(workbook: Workbook, sheetNumber = 1): ExcelImportData | undefined {
    const searchForColumnIndex = (rowValues: CellValue[], variants: string[]) => {
      const index = rowValues.findIndex(
        (value) =>
          value && variants.some((variant) => value.toString().toLowerCase().includes(variant))
      );
      // exceljs parses first value as empty???
      return index > 0 ? index - 1 : undefined;
    };

    const searchForColumnIndexByLetter = (
      rowValues: CellValue[],
      letter: string
    ): number | undefined => {
      const index = rowValues.findIndex(
        (value) => value && value.toString().toLowerCase()[0] === letter
      );
      return index >= 0 ? index : undefined;
    };

    const getDimensions = (worksheet: Worksheet): Dimensions => {
      const includesWeightDim = worksheet.getCell('E2').text?.includes(weightDimText);
      const includesLengthDim = worksheet.getCell('E3').text?.includes(lengthDimText);
      if (includesWeightDim && includesLengthDim) {
        const weightDimText = worksheet.getCell('F2').text;
        const weightDimValue = weightDimensionMap.get(weightDimText);
        const lengthDimText = worksheet.getCell('F3').text;
        const lengthDimValue = lengthDimensionMap.get(lengthDimText);
        if (weightDimValue && lengthDimValue) {
          return {
            weightDim: weightDimValue,
            lengthDim: lengthDimValue,
          } as Dimensions;
        }
      }
      return null;
    };

    const getHeaderRow = (worksheet: Worksheet, startRow: number) => {
      const iteratedMappings = itemProperties
        .props()
        .filter((p) => !p.readOnly && !p.additional)
        .map((p) => p as { key: string; text: string; required?: boolean })
        .concat([
          { key: 'stackable', text: 'Stackable' },
          { key: 'tiltable', text: 'Tiltable' },
          { key: 'longship', text: 'Longship' },
        ])
        .concat(
          (useMiscStore().company_settings.extra_columns || []).map((v) => ({
            key: v.name,
            text: v.name,
          }))
        )
        // TODO: concat with custom columns
        .map((p) => {
          return {
            key: p.key,
            required: p.required,
            text: p.text,
            searchVariants: searchVariants.get(p.key) || [p.text.toLowerCase()],
            mapping: undefined,
          } as ColumnMapping;
        });

      for (let rowIndex = startRow; rowIndex < worksheet.rowCount; rowIndex++) {
        const row = worksheet.getRow(rowIndex);
        if (!row.hasValues) {
          continue;
        } else {
          const mappings = new Map(
            iteratedMappings.map((m) => {
              m.mapping = searchForColumnIndex(row.values as CellValue[], m.searchVariants);
              return [m.key, m];
            })
          );
          let l = mappings.get('l');
          let w = mappings.get('w');
          let h = mappings.get('h');
          if (l.mapping === undefined && w.mapping === undefined && h.mapping === undefined) {
            const lengthIndex = searchForColumnIndexByLetter(row.values as CellValue[], 'l');
            const widthIndex = searchForColumnIndexByLetter(row.values as CellValue[], 'w');
            const heightIndex = searchForColumnIndexByLetter(row.values as CellValue[], 'h');
            if (
              lengthIndex &&
              widthIndex &&
              heightIndex &&
              heightIndex === widthIndex + 1 &&
              widthIndex === lengthIndex + 1
            ) {
              l.mapping = lengthIndex;
              w.mapping = widthIndex;
              h.mapping = heightIndex;
            }
          }
          let required_null = [...mappings.entries()].find(
            ([_, m]) => m.required && m.mapping === undefined
          );
          if (!required_null) {
            return { mappings, rowNumber: row.number };
          }
        }
      }

      return {
        mappings: new Map(iteratedMappings.map((m) => [m.key, m])),
        rowNumber: startRow,
      };
    };

    const worksheet = workbook.worksheets[sheetNumber - 1];
    const dimensions = getDimensions(worksheet);
    const startRow = dimensions ? 4 : 0;
    const headerRowInfo = getHeaderRow(worksheet, startRow);

    if (headerRowInfo) {
      const rowsValues = worksheet
        .getRows(headerRowInfo.rowNumber + 1, worksheet.rowCount - headerRowInfo.rowNumber)
        .filter((row) => row.hasValues)
        .map((row) => (row.values as any).slice(1)) as CellValue[][]; // first element is always empty since exceljs begins at index 1

      const maxNumberOfValues = Math.max(
        worksheet.getRow(headerRowInfo.rowNumber).cellCount,
        ...rowsValues.map((x) => x.length)
      );
      let itemRows: ItemRow[] = rowsValues
        .map((itemRow, index) => {
          return {
            index,
            values: [...itemRow, ...Array(maxNumberOfValues - itemRow.length).fill(undefined)],
          };
        }) // fills sparse array,
        .map((itemRow) => {
          return {
            ...itemRow,
            values: itemRow.values.map((value) =>
              value !== undefined && typeof value === 'object'
                ? (value as CellFormulaValue).result?.toString()
                : value
            ),
          };
        }); // get simple value or value based on formula

      if (
        headerRowInfo.mappings.get('w')?.mapping === undefined &&
        headerRowInfo.mappings.get('h')?.mapping === undefined &&
        headerRowInfo.mappings.get('l')?.mapping !== undefined
      ) {
        headerRowInfo.mappings = this.addWidthAndHeightIfPresent(headerRowInfo.mappings, itemRows);
      }

      if (
        headerRowInfo.mappings.get('stackable')?.mapping !== undefined &&
        headerRowInfo.mappings.get('not_stackable')?.mapping === undefined
      ) {
        // flip values if stackable is used and map it not_stackable
        itemRows.forEach((itemRow, rowIndex) => {
          const stackableValue = itemRow.values[headerRowInfo.mappings.get('stackable').mapping];
          if (stackableValue) {
            itemRow.values[headerRowInfo.mappings.get('stackable').mapping] =
              stackableTruthyAnswers.includes(stackableValue.toString().toLowerCase())
                ? 'No'
                : 'Yes';
          }
        });
        headerRowInfo.mappings.get('not_stackable').mapping =
          headerRowInfo.mappings.get('stackable').mapping;
      }

      return {
        itemRows: itemRows,
        options: headerRowInfo.mappings,
        dimensions,
      };
    }
    return undefined;
  }

  addWidthAndHeightIfPresent(mappings: Map<string, ColumnMapping>, itemRows: ItemRow[]) {
    const lengthColIndex = mappings.get('l').mapping;
    const itemRowsDimensions = itemRows.map(
      (itemRow) => itemRow.values[lengthColIndex]?.toString()?.toLowerCase().split('x') || []
    );
    if (itemRowsDimensions.some((dimensions) => dimensions.length === 3)) {
      // add width and height columns. Increase column indices of all mappings after length col
      Object.entries(mappings).forEach(([col, index]) => {
        if (index > lengthColIndex) {
          mappings.get(col).mapping = index + 2;
        }
      });
      mappings.get('w').mapping = lengthColIndex + 1;
      mappings.get('h').mapping = lengthColIndex + 2;

      itemRows.forEach((itemRow, rowIndex) => {
        const dimensionValues = itemRowsDimensions[rowIndex];
        itemRow.values[mappings.get('l').mapping] = dimensionValues[0];
        itemRow.values.splice(mappings.get('w').mapping, 0, dimensionValues[1]);
        itemRow.values.splice(mappings.get('h').mapping, 0, dimensionValues[2]);
      });
    }
    return mappings;
  }
}
