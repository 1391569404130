<template>
  <div>
    <p v-if="stats.locked" class="pt-2">
      <v-icon color="red">mdi-lock</v-icon> This list is locked
    </p>
    <v-row>
      <v-col v-if="stats.set_count > 0">
        <h4 class="summary">
          {{ stats.set_count }}
          {{ setName }}s used
        </h4>
        <div v-for="(count, name) in stats.set_types" :key="name" class="summary">
          {{ count }} x {{ name }}
        </div>
      </v-col>
      <v-col>
        <h4 class="summary">
          {{ stats.hold_count }}
          {{ typeName }}s used
          <span v-if="stats.oog_holds > 0">({{ stats.oog_holds }} OoG)</span>
        </h4>
        <div v-for="(count, name) in stats.hold_types" :key="name" class="summary">
          {{ count }} x {{ name }}
        </div>
      </v-col>
    </v-row>
    <!-- <h4 class="mt-2">Cargo</h4> -->
    <div class="mt-2 summary">
      <b>{{ stats.loaded_items }}</b> items loaded
      <span v-if="stats.unloaded" class="font-weight-bold error--text"
        >({{ stats.unloaded }} not loaded)</span
      >
      <v-icon v-else color="success">mdi-check-circle</v-icon>
    </div>
    <div v-if="stats.chargable_weight">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <b v-on="on" v-bind="attrs">{{ stats.chargable_weight | toWeight }}</b>
          chargable weight
        </template>
        <span
          >The larger of <i>volumetric weight</i> and <i>actual weight</i> summed per cargo</span
        >
      </v-tooltip>
    </div>
    <div v-if="!noSliders" class="mt-4">
      <availability-slider
        v-if="stats.weight"
        :max="stats.payload"
        :used="stats.weight"
        :weightFilter="true"
        color="light-blue" />

      <div class="mt-4"></div>
      <availability-slider
        v-if="stats.volume"
        :max="stats.max_volume"
        :used="stats.volume"
        :weightFilter="false"
        color="light-green" />
    </div>
    <div v-else>
      <div class="mt-1">
        <div class="summary">
          <b>{{ stats.weight | toWeight }} </b>
          <span v-if="stats.payload && stats.set_count"
            >(
            <span
              :class="stats.weight / stats.payload > 1 ? ['error--text', 'font-weight-bold'] : []"
              >{{ (stats.weight / stats.payload) | percentage }}%</span
            >
            of
            <span>{{ stats.payload | toWeight }}</span
            >)
          </span>
        </div>
        <div class="summary">
          <b>{{ stats.volume | toVolume }} </b>
          <span v-if="stats.max_volume && stats.set_count">
            (<span
              :class="
                stats.volume / stats.max_volume > 1 ? ['error--text', 'font-weight-bold'] : []
              "
              >{{ (stats.volume / stats.max_volume) | percentage }}%</span
            >
            of
            <span>{{ stats.max_volume | toVolume }}</span
            >)
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { HoldData, Loadplan, Loadlist, UnloadedItem } from '../../models/LoadlistModel';
import { Set } from '../../models/SetsModel';
import AvailabilitySlider from './AvailabilitySlider.vue';
import { AugmentedHold } from '@/models/augmented/hold';
import { AugmentedSet } from '@/models/augmented/set';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
import containerUtils from '@/misc/containerUtils';

interface Stats {
  hold_count: number;
  hold_types: Record<string, number>;
  set_count: number;
  set_types: Record<string, number>;
  payload: number;
  max_volume: number;
  oog_holds: number;
  loaded_items: number;
  volume: number;
  weight: number;
  volumetric_weight: number;
  chargable_weight: number;
  unloaded: number;
  locked: boolean;
}

export default Vue.extend({
  name: 'loadlist-summary',
  components: {
    AvailabilitySlider,
  },
  props: {
    loadlist: Object as PropType<Loadlist>,
    noSliders: Boolean,
  },
  computed: {
    ...mapStores(useLoadlistStore),
    usedHolds(): HoldData[] {
      return (this.loadplan?.holds || []).filter((h: HoldData) => h.items_count);
    },
    sets(): AugmentedSet[] {
      return [
        ...(this.loadplan.sets || []),
        ...this.usedHolds
          .filter((c) => !c.set_uuid)
          .map((c) => containerUtils.createSetFromHold(c)),
      ].map((s: Set) => new AugmentedSet(s, this.usedHolds));
    },

    stats(): Stats {
      let set_count = 0,
        total_payload = 0,
        total_max_volume = 0;
      const set_types = this.sets.reduce((setTypes: Record<string, number>, s: AugmentedSet) => {
        if (s.set.containers.length > 1) {
          set_count += 1;
          setTypes[s.set.name as string] = (setTypes[s.set.name as string] || 0) + 1;
        }
        total_payload += s.payload;
        total_max_volume += s.maxVolume;
        return setTypes;
      }, {});
      const stats = this.usedHolds
        .map((h) => new AugmentedHold(h))
        .reduce(
          (prev, cur) => {
            const hold_types = prev.hold_types;
            hold_types[cur.name as string] = (hold_types[cur.name as string] || 0) + 1;
            return {
              ...prev,
              hold_count: prev.hold_count + 1,
              volume: prev.volume + cur.volume,
              weight: prev.weight + cur.weight,
              loaded_items: prev.loaded_items + cur.items_count,
              oog_holds: prev.oog_holds + (cur.oogValues.length ? 1 : 0),
              chargable_weight:
                prev.chargable_weight +
                (cur.chargableWeight(this.loadplan?.settings?.shipping_factor) || 0),
            } as Stats;
          },
          {
            hold_count: 0,
            hold_types: {},
            set_count,
            set_types: set_types,
            payload: total_payload,
            max_volume: total_max_volume,
            oog_holds: 0,
            loaded_items: 0,
            volume: 0,
            weight: 0,
            chargable_weight: 0,
            unloaded: this.total_unloaded_items,
            locked: !!this.loadlist.result?.locked,
          } as Stats
        );

      return stats;
    },
    loadplan(): Loadplan {
      return this.loadlist.result?.versions[0];
    },
    typeName(): string {
      return this.$typeNames(this.loadlist.list_type);
    },
    setName(): string {
      return this.$setNames(this.loadlist.list_type);
    },
    total_unloaded_items(): number {
      return this.unloadedItems
        .map((i) => i.qty || 0)
        .reduce((acc, cur) => {
          return acc + cur;
        }, 0);
    },
    unloadedItems(): UnloadedItem[] {
      return this.loadlistStore.unloaded_items;
    },
  },
});
</script>
