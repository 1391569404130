<template>
  <div class="d-flex flex-column flex-grow-1">
    <v-toolbar dense class="no-print mt-2 flex-grow-0" elevation="1">
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" v-on="on" outlined id="workspaceMoreBtn">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <div v-if="view != 'set'">
            <v-list-item @click="showAddUnitDialog = true">
              <v-list-item-title>
                <v-icon left color="green">mdi-plus-circle</v-icon>Add empty
                {{ typeName }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="showAddUnitDialog = true" :disabled="checkedUnits.length === 0">
              <v-icon left>fa-exchange-alt</v-icon>
              <v-list-item-title>Change type of selected {{ typeName }}s</v-list-item-title>
            </v-list-item>
            <v-list-item @click="removeSelectedUnits" :disabled="!checkedUnits.length">
              <v-icon left color="error">mdi-delete</v-icon>
              <v-list-item-title>Remove selected {{ typeName }}s</v-list-item-title>
            </v-list-item>
            <v-list-item @click="emptySelectedUnits" :disabled="!checkedUnits.length">
              <v-icon left color="error">mdi-delete-sweep</v-icon>
              <v-list-item-title>Empty selected {{ typeName }}s</v-list-item-title>
            </v-list-item>
            <v-list-item @click="createNewListFromSelected" :disabled="!checkedUnits.length">
              <v-icon left>mdi-call-split</v-icon>
              <v-list-item-title>Create new list with selected {{ typeName }}s</v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item @click="addToCargoLibrary" :disabled="checkedUnits.length != 1">
              <v-icon left>mdi-cube-send</v-icon>
              <v-list-item-title>Add to Cargo Library</v-list-item-title>
            </v-list-item>
          </div>
          <div v-else>
            <v-list-item @click="emptySelectedSets" :disabled="!checkedSets.length">
              <v-icon left>mdi-delete-sweep</v-icon>
              <v-list-item-title>Empty selected {{ setName }}s</v-list-item-title>
            </v-list-item>
            <v-list-item @click="removeSelectedSets" :disabled="!checkedSets.length">
              <v-icon left>mdi-delete</v-icon>
              <v-list-item-title>Remove selected {{ setName }}s</v-list-item-title>
            </v-list-item>
          </div>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-title>
              <v-checkbox
                v-model="groupSimilar"
                :label="`Group similar ${typeName}s`"
                :ripple="false"></v-checkbox>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip bottom v-if="view === 'table'">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="print"> <v-icon>mdi-printer</v-icon></v-btn>
        </template>
        <span class="no-print">Print or save as PDF</span>
      </v-tooltip>

      <v-btn-toggle v-model="showExpansionPanel" group dense multiple>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on">
              <v-icon>mdi-clipboard-text</v-icon>
            </v-btn>
          </template>
          <span>Loadplan notes</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
          <span>Search for cargo</span>
        </v-tooltip>
      </v-btn-toggle>

      <v-tooltip bottom v-if="$vuetify.breakpoint.smAndUp">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon :ripple="false" @click="showResetDialog = true">
            <v-icon>mdi-file-undo</v-icon>
          </v-btn>
        </template>
        <span>Reset workspace</span>
      </v-tooltip>

      <v-tooltip bottom v-if="$vuetify.breakpoint.smAndUp">
        <template v-slot:activator="{ on }">
          <v-btn icon color="green" @click="saveLoadlistResult" v-on="on" :ripple="false">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span> Save current layout </span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <v-btn-toggle
        v-if="$vuetify.breakpoint.smAndUp"
        mandatory
        dense
        v-model="view"
        id="viewToggleButton">
        <v-btn
          :class="{
            primary: view === 'set',
            'white--text': view === 'set',
          }"
          v-if="setWorkspaceAvailable"
          value="set"
          >Overview</v-btn
        >
        <v-btn
          :class="{
            primary: view === 'table',
            'white--text': view === 'table',
          }"
          value="table"
          >Table view</v-btn
        >
        <v-btn
          :class="{
            primary: view === 'planner',
            'white--text': view === 'planner',
          }"
          value="planner"
          >Planner view</v-btn
        >
      </v-btn-toggle>
    </v-toolbar>

    <v-expansion-panels class="my-1" v-model="showExpansionPanel" multiple>
      <v-expansion-panel>
        <v-expansion-panel-content>
          <v-textarea class="mt-2" label="Notes:" v-model="notes" outlined rows="3"></v-textarea>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-content>
          <v-text-field
            prepend-icon="mdi-magnify"
            label="Search for cargo:"
            v-model="searchInput"
            clearable></v-text-field>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-alert
      v-if="loadplan.holds.length === 0"
      class="mt-4"
      color="warning"
      icon="mdi-alert"
      outlined>
      This workspace has no {{ typeName }}s. Go back
      <router-link :to="{ name: 'setup' }">the Setup view</router-link> to start a calculation or
      add some {{ typeName }}s manually by licking
      <a @click="showAddUnitDialog = true">here</a>
    </v-alert>

    <set-workspace
      v-else-if="view === 'set'"
      :sets="groupedSets"
      :filteredHolds="filteredHolds"
      :searchInput="searchInput"
      :checkedSets="checkedSets"
      :settings="loadplan.settings"
      @sceneClick="gotoInteractiveView($event)"
      @toggleSetRow="checkSetRow($event)" />

    <planner-table-component
      id="plannerTable"
      v-else-if="view === 'planner'"
      :filteredHolds="filteredHolds"
      :checkedUnits="checkedUnits"
      :settings="loadplan.settings"
      @sceneClick="gotoInteractiveView($event)"
      @toggleSelectedRow="checkRow($event)"
      @showMapModal="toggleMapModal($event)"></planner-table-component>

    <workspace-table
      v-else
      :holds="filteredHolds"
      :sets="groupedSets"
      :searchInput="searchInput"
      :checkedUnits="checkedUnits"
      :print="willPrint"
      :groupSimilar="groupSimilar"
      :settings="loadplan.settings"
      @sceneClick="gotoInteractiveView($event)"
      @toggleSelectedRow="checkRow($event)"
      @showMapModal="toggleMapModal($event)" />

    <!-- ADD / CONVERT HOLD MODAL -->
    <v-dialog v-model="showAddUnitDialog" v-if="showAddUnitDialog" scrollable width="800">
      <v-card>
        <v-card-title class="text-h5">Add new / Convert selected {{ typeName }}s</v-card-title>
        <v-card-text>
          <p class="text-h5">Currently selected</p>
          <v-expansion-panels focusable>
            <v-expansion-panel
              v-for="hold in loadplan.selected_holds"
              :key="hold.id"
              :disabled="
                hold.qty && loadplan.holds.filter((h) => h.id === hold.id).length >= hold.qty
              ">
              <v-expansion-panel-header>
                <span>
                  <strong>
                    {{ hold.name }}
                  </strong>
                  - ({{ hold.L | toLength }} x
                  {{ hold.W | toLength }}
                  <span v-if="hold.H > 0">x {{ hold.H | toLength }}</span>
                  )
                  <span v-if="hold.qty">
                    -
                    {{ hold.qty - loadplan.holds.filter((h) => h.id === hold.id).length }}
                    left
                  </span>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <scene-component
                  :hold-object="hold"
                  :canvas-width="300"
                  :canvas-height="150"></scene-component>

                <v-btn color="primary" @click.stop="addUnit(hold)" :ripple="false">Add this</v-btn>
                <v-btn
                  class="ml-3"
                  color="primary"
                  :disabled="isLoading || checkedUnits.length === 0"
                  @click.stop="convertUnits(hold)"
                  :ripple="false"
                  >Convert to</v-btn
                >
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <p class="text-h5">Library</p>

          <container-library-component :holdType="loadlist.list_type">
            <template v-slot:items="{ paginatedItems: containers }">
              <v-expansion-panels focusable class="unselectable">
                <v-expansion-panel
                  v-for="hold in containers"
                  :key="hold.id"
                  @click="$vuetify.goTo($event.target)">
                  <v-expansion-panel-header>
                    <span>
                      <strong>
                        {{ hold.name }}
                      </strong>
                      - ({{ hold.data.L | toLength }} x
                      {{ hold.data.W | toLength }}
                      <span v-if="hold.data.H > 0">x {{ hold.data.H | toLength }}</span>
                      )
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <scene-component
                      :hold-object="hold.data"
                      :canvas-width="300"
                      :canvas-height="150"></scene-component>

                    <v-btn color="primary" @click.stop="addUnit(hold.data)" :ripple="false"
                      >Add this</v-btn
                    >
                    <v-btn
                      class="ml-3"
                      color="primary"
                      :disabled="isLoading || checkedUnits.length === 0"
                      @click.stop="convertUnits(hold.data)"
                      :ripple="false"
                      >Convert to</v-btn
                    >
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
          </container-library-component>
        </v-card-text>

        <v-card-actions>
          <v-btn text @click.stop="showAddUnitDialog = false" :ripple="false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showInfoResultDialog" scrollable width="800">
      <v-card>
        <v-card-title class="text-h5">Your first loadplan!</v-card-title>
        <v-card-text>
          <p>
            To give you a quick result - the result might sometimes not be as you expect it to be.
            Each cargo is different and the number of avaialbe load patterns are enormous. You do
            not have all the time in the world to wait for a result - thus we cannot guarantee that
            below result is the best.
            <br />
            <br />Therefore we have made it very easy for you to modify the result. Just click on
            any unit, and you will be able to move around the cargoes, and move cargoes between
            different units according to your preferences.
            <br />
            <br />Sometimes you will get a popup with Unloaded items. This popup contains cargoes
            that could not be fitted in the selected units, or cargoes that you have manually
            unloaded through the interactive view. To load these you can drag and drop the selected
            cargoes from the popup view, into the unit of your preference.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn block color="primary" @click.stop="showInfoResultDialog = false" :ripple="false"
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-modal
      :visible="showResetDialog"
      :title="`Reset all ${typeName}s?`"
      confirmColor="warning"
      @confirm="
        showResetDialog = false;
        resetHolds();
      "
      @close="showResetDialog = false"></confirm-modal>
    <map-modal-vue
      :visible="showMapModal"
      :hold="mapHold"
      @close="
        mapHold = undefined;
        showMapModal = false;
      "
      :pol="loadlist.pol"
      :pod="loadlist.pod"
      :withLine="loadplan.settings.routing" />
    <new-list-modal
      ref="splitListModal"
      :visible="showSplitListModal"
      @close="showSplitListModal = false"
      @created="splittedLoadlistCreated">
      <template v-slot:description>
        <v-alert type="info" outlined>
          This operation will create a new list with selected containers and remove those containers
          (and their cargoes) from the current load plan
        </v-alert>
      </template>
    </new-list-modal>

    <v-dialog v-model="showAddCargoLibrary" width="800">
      <v-card>
        <v-card-title class="text-h5">Add to Cargo-Library</v-card-title>
        <v-card-text>
          <p>
            You can add this {{ typeName }} to the Cargo-Library and import it into other loadlists
            as a piece of pre-planned cargo.
          </p>
          <v-text-field v-model="cargoName" />
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click.stop="
              showAddCargoLibrary = false;
              cargoName = undefined;
            "
            >Cancel</v-btn
          >
          <v-spacer />
          <v-btn
            color="primary"
            :loading="isLoading"
            @click.stop="saveCargoLibrary()"
            :ripple="false"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="showUnitNotesDialog" v-if="!!checkedUnits.length">
      <v-card>
        <v-card-title class="text-h5">Container notes</v-card-title>
        <v-card-text>
          <v-form>
            <v-textarea
              class="mt-2"
              label="Notes:"
              v-model="checkedUnits[0].notes"
              outlined
              rows="3"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="showUnitNotesDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import sceneComponent from '@/components/Custom/SceneComponent.vue';
import containerLibraryComponent from '@/components/Custom/ContainerLibrary.vue';
import ItemUtils from '@/misc/itemUtils';
import ContainerUtils from '@/misc/containerUtils';
import plannerTableComponent from '@/components/LoadlistDetail/Workspace/Planner/PlannerTable.vue';
import setWorkspace from '@/components/LoadlistDetail/Workspace/Sets/index.vue';
import workspaceTable from '@/components/LoadlistDetail/Workspace/Table/index.vue';
import confirmModal from '@/components/Modals/Confirm.vue';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { useLoadlistStore } from '@/stores/loadlistStore';
import MapModalVue from '@/components/Modals/MapModal.vue';
import newListModal from '@/components/Modals/NewList.vue';

import {
  HoldData,
  HoldDataWithIndices,
  Indices,
  Loadlist,
  Loadplan,
  UnloadedItem,
  UpdateLoadplanHoldsParams,
  HoldItem,
  Cargo,
} from '@/models/LoadlistModel';
import { CalcData } from '@/models/CalculationModel';
import { GroupedWorkerResponse } from '@/models/GroupingModel';
import { GroupedSet, Set } from '@/models/SetsModel';
import { UsageSettings } from '../../../stores/usageSettings';
import containerUtils from '@/misc/containerUtils';
import API from '@/API';

function itemSearch(i: HoldItem, s: string): boolean {
  return (
    i.label.toLowerCase().includes(s) ||
    (i.sku && i.sku.toLowerCase().includes(s)) ||
    (i.shipment_id ? i.shipment_id.toLowerCase().includes(s) : false)
  );
}

export default Vue.extend({
  name: 'workspace',
  components: {
    MapModalVue,
    sceneComponent,
    workspaceTable,
    containerLibraryComponent,
    plannerTableComponent,
    confirmModal,
    setWorkspace,
    newListModal,
  },
  data() {
    return {
      checkedUnits: [] as Indices[],
      checkedSets: [] as number[],
      groupedHolds: [] as HoldDataWithIndices[],
      groupedSets: [] as GroupedSet[],
      searchInput: '',
      isLoading: false,
      showPrintDialog: false,
      showExpansionPanel: [],
      showAddUnitDialog: false,
      showInfoResultDialog: false,
      setWorkspaceAvailable: false,
      showSplitListModal: false,
      view: undefined,
      showResetDialog: false,
      originalHolds: null,
      worker: null as Worker,
      willPrint: false,
      printTimer: null,
      groupSimilar: true,
      usageSettings: UsageSettings.fetch(),
      mapHold: undefined as HoldData,
      showMapModal: false,
      showAddCargoLibrary: false,
      cargoName: undefined as string,
    };
  },
  watch: {
    'loadplan.set_types': {
      handler: function (a) {
        if (
          this.loadplan.set_types?.length > 0 ||
          (this.loadplan.settings?.use_containers_in_sets &&
            this.loadplan.selected_holds?.length > 1)
        ) {
          this.setWorkspaceAvailable = true;
        }
      },
      immediate: true,
    },
    'loadplan.holds': {
      handler: function (a) {
        if (!this.worker) {
          this.worker = new Worker(new URL('@/workers/group_holds.worker.ts', import.meta.url));
          this.worker.onmessage = this.groupHoldsFromWorker;
        }
        this.worker.postMessage({
          holds: this.loadplan.holds,
          sets: this.loadplan.sets,
        });
      },
      immediate: true,
    },
    view: {
      handler: function (view) {
        if (this.$route.query.view != view) {
          this.$router.replace({
            ...this.$router.currentRoute,
            query: { ...this.$route.query, view: view },
          });
          this.usageSettings.defaultWorkspaceView = view;
        }
      },
      immediate: false,
    },
    groupSimilar(grouped: boolean): void {
      this.updateGroups();
      this.usageSettings.groupSimilar = grouped;
    },
  },
  computed: {
    ...mapStores(useMiscStore, useLoadlistStore),
    filteredHolds(): HoldDataWithIndices[] {
      let holds = this.groupedHolds;

      if (this.searchInput) {
        holds = holds.filter((h) =>
          h.items.some((i) => {
            return (
              itemSearch(i, this.searchInput.toLowerCase()) ||
              (i.from_container
                ? i.from_container.items.some((i2) =>
                    itemSearch(i2, this.searchInput.toLowerCase())
                  )
                : false)
            );
          })
        );
      }
      return holds;
    },

    notes: {
      get(): string {
        return this.loadplan.notes;
      },
      set(value: string): void {
        this.loadlistStore.setLoadplanProperty({
          key: 'notes',
          value: value,
        });
      },
    },
    typeName(): string {
      return this.$typeNames(this.loadlist.list_type);
    },
    setName(): string {
      return this.$setNames(this.loadlist.list_type);
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return this.loadlistStore.loadplan;
    },
    loadplan_version(): number {
      return this.loadlistStore.loadplan_version;
    },
    unloadedItems(): UnloadedItem[] {
      return this.loadlistStore.unloaded_items;
    },
    liteVersion(): boolean {
      return this.miscStore.lite_version;
    },
  },
  created() {
    this.originalHolds = JSON.parse(JSON.stringify(this.loadplan.holds));
  },
  mounted() {
    this.usageSettings = UsageSettings.fetch();
    if (!this.$route.query.print && !localStorage.getItem('cplInfoResultViewV1')) {
      this.showInfoResultDialog = true;
      localStorage.setItem('cplInfoResultViewV1', 'true');
    }
    window.addEventListener('afterprint', this.afterPrint);
    this.groupSimilar = !(
      this.$route.query.groupSimilar === 'false' || this.usageSettings.groupSimilar === false
    );
    const view = this.$route.query.view
      ? String(this.$route.query.view)
      : this.usageSettings.defaultWorkspaceView;
    this.view = view;
  },
  beforeDestroy() {
    if (this.worker) {
      this.worker.terminate();
    }
    window.removeEventListener('afterprint', this.afterPrint);
  },
  methods: {
    resetHolds(): void {
      this.loadlistStore.setLoadplanProperty({
        key: 'holds',
        value: JSON.parse(JSON.stringify(this.originalHolds)),
      });
    },
    groupHoldsFromWorker(e: GroupedWorkerResponse) {
      this.groupedHolds = e.data.holds;
      this.groupedSets = e.data.sets;
    },
    toggleMapModal(uuid: string): void {
      this.showMapModal = true;
      this.mapHold = this.filteredHolds.find((h) => h.uuid == uuid);
    },
    checkRow(val: Indices): void {
      const index = this.checkedUnits.indexOf(val);
      if (index >= 0) {
        this.checkedUnits.splice(index, 1);
      } else {
        this.checkedUnits.push(val);
      }
    },
    checkSetRow(row: number): void {
      const index = this.checkedSets.indexOf(row);
      if (index >= 0) {
        this.checkedSets.splice(index, 1);
      } else {
        this.checkedSets.push(row);
      }
    },
    checkRows(rows: Indices[]): void {
      const checkedCount = rows.filter((row) => this.checkedUnits.indexOf(row) >= 0).length;
      if (checkedCount === rows.length) {
        rows.forEach((row) => {
          this.checkRow(row);
        });
      } else if (checkedCount === 0) {
        rows.forEach((row) => this.checkedUnits.push(row));
      } else {
        rows
          .filter((row) => this.checkedUnits.indexOf(row) < 0)
          .forEach((row) => this.checkedUnits.push(row));
      }
    },
    print() {
      this.willPrint = true;
      this.$hideChatWidget();
      this.$nextTick(() => {
        if (!this.printTimer) {
          this.printTimer = setInterval(() => {
            if (window.cpl.currentRendering <= 0) {
              clearInterval(this.printTimer);
              this.printTimer = null;
              window.print();
            }
          }, 100);
        }
      });
    },

    afterPrint(): void {
      this.willPrint = false;
    },
    addUnit(unit: HoldData): void {
      const hold = ContainerUtils.createEmpty(unit);
      this.loadlistStore.updateLoadplanSets({
        sets: [ContainerUtils.createSetFromHold(hold)],
      });
      this.updateLoadplanHolds({
        holds: [hold],
      });

      this.showAddUnitDialog = false;
    },
    convertUnits(unit: HoldData): void {
      this.isLoading = true;
      const convertItems = [] as HoldItem[];
      let startFromIndex = Infinity;
      this.checkedUnits.forEach((holdIndices) => {
        for (let i = holdIndices.start; i <= holdIndices.end; i++) {
          this.loadplan.holds[i].items
            .filter((i) => i.qty > 0)
            .forEach((item) => {
              convertItems.push({
                ...item,
                qty: 1,
              });
            });
        }
        startFromIndex = Math.min(startFromIndex, holdIndices.start);
      });
      const bundledItems = ItemUtils.bundledItems(convertItems, true).flat();

      const calcData = {
        items: bundledItems,
        container_types: [unit],
      } as CalcData;

      this.calculateLoadplan(calcData).then(
        (solution) => {
          this.removeSelectedUnits();
          this.loadlistStore.updateLoadplanSets({ sets: solution.sets });
          this.updateLoadplanHolds({
            index: startFromIndex,
            holds: solution.containers,
          });

          this.showAddUnitDialog = false;
          this.isLoading = false;
        },
        (_) => {
          this.isLoading = false;
        }
      );
    },
    removeSelectedUnits(): void {
      this.removeUnits(this.checkedUnits);
      this.checkedUnits = [];
    },
    removeSelectedSets(): void {
      this.checkedSets.forEach((setIndex) => {
        const units = this.checkedSetToUnits(setIndex);
        this.removeUnits(units);
      });
      this.checkedSets = [];
    },
    removeUnits(units: Indices[]): void {
      units
        .slice()
        .sort((a, b) => (a.start > b.start ? -1 : 1))
        .forEach((val) => {
          for (let i = val.end; i >= val.start; i--) {
            // remove the hold from the set
            // and remove the set if it's empty
            const hold = this.loadplan.holds[i];
            const setIndex = this.loadplan.sets.findIndex((s) => s.uuid == hold?.set_uuid);
            if (setIndex >= 0) {
              const set = JSON.parse(JSON.stringify(this.loadplan.sets[setIndex])) as Set;
              const holdIndex = set.containers.findIndex((c) => c.uuid == hold.uuid);
              if (holdIndex >= 0) {
                set.containers.splice(holdIndex, 1);
                if (set.containers.length === 0) {
                  this.loadlistStore.updateLoadplanSets({
                    replace: 1,
                    index: setIndex,
                  });
                } else {
                  this.loadlistStore.updateLoadplanSets({
                    replace: 1,
                    index: setIndex,
                    sets: [set],
                  });
                }
              }
            }
            this.updateLoadplanHolds({
              index: i,
              replace: 1,
            });
          }
        });
    },
    checkedSetToUnits(setIndex: number): Indices[] {
      const uuid = this.loadplan.sets[setIndex].uuid;
      const units = this.loadplan.holds
        .map((hold, index) => index)
        .filter((index) => this.loadplan.holds[index].set_uuid === uuid)
        .map((index) => {
          return { start: index, end: index } as Indices;
        });
      return units;
    },

    emptySelectedUnits(): void {
      this.emptyUnits(this.checkedUnits);
      this.checkedUnits = [];
    },
    emptySelectedSets(): void {
      this.checkedSets.forEach((val) => {
        const units = this.checkedSetToUnits(val);
        this.emptyUnits(units);
      });
      this.checkedSets = [];
      this.updateGroups();
    },
    emptyUnits(units: Indices[]): void {
      units.forEach((val) => {
        for (let i = val.end; i >= val.start; i--) {
          const hold = this.loadplan.holds[i];
          const new_hold = ContainerUtils.createEmpty(hold);
          new_hold.uuid = hold.uuid;
          new_hold.set_uuid = hold.set_uuid;
          this.updateLoadplanHolds({
            index: i,
            replace: 1,
            holds: [new_hold],
          });
        }
      });
    },

    addToCargoLibrary(): void {
      this.checkedUnits.forEach((val) => {
        const hold = this.loadplan.holds[val.start];
        this.cargoName = hold.name;
      });
      this.showAddCargoLibrary = true;
    },
    saveCargoLibrary(): void {
      this.checkedUnits.forEach((val) => {
        const hold = this.loadplan.holds[val.start];
        // console.log(hold);
        const item = containerUtils.createNestedItemFromHold(hold);
        // console.log(item);
        item.label = this.cargoName;
        const cargo = {
          name: this.cargoName,
          data: item,
          length_dim: 'M',
          weight_dim: 'KG',
        } as Cargo;
        API.createCargo(cargo)
          .then((r) => console.log('response:', r))
          .catch((e) => console.log('error', e.response.data))
          .finally(() => {
            this.showAddCargoLibrary = false;
            this.isLoading = false;
            this.miscStore.downloadCargoes();
          });
        // console.log(item);
      });
    },
    createNewListFromSelected(): void {
      const moveContainers = [] as HoldData[];
      const moveItems = [] as HoldItem[];
      this.checkedUnits.forEach((holdIndices) => {
        for (let i = holdIndices.start; i <= holdIndices.end; i++) {
          moveContainers.push(this.loadplan.holds[i]);
          this.loadplan.holds[i].items
            .filter((i) => i.qty > 0)
            .forEach((item) => {
              moveItems.push({
                ...item,
                qty: 1,
              });
            });
        }
      });
      const bundledItems = ItemUtils.bundledItems(moveItems, true).flat();

      const filtered_loadlist_data = ItemUtils.removeItemsFromLoadlist(
        this.loadlist.data,
        JSON.parse(JSON.stringify(bundledItems as UnloadedItem[]))
      );

      (this.$refs.splitListModal as any).extendLoadlist({
        data: bundledItems,
        result: {
          versions: [
            {
              ...this.loadplan,
              holds: moveContainers,
            },
          ],
        },
        length_dim: this.loadlist.length_dim,
        weight_dim: this.loadlist.weight_dim,
      });
      this.showSplitListModal = true;

      (this.$refs.splitListModal as any).$once('created', () => {
        this.loadlistStore.setLoadlistProperty({
          key: 'data',
          value: filtered_loadlist_data,
        });
        this.removeSelectedUnits();
      });
    },
    splittedLoadlistCreated(): void {},
    gotoInteractiveView(index: number): void {
      if (this.liteVersion) {
        return;
      }
      this.$router.push({
        name: 'detail',
        params: {
          version: String(this.loadplan_version),
          hold: String(index),
        },
      });
    },
    updateLoadplanHolds(data: UpdateLoadplanHoldsParams): void {
      this.loadlistStore.updateLoadplanHolds(data);
    },
    calculateLoadplan(calcData: CalcData): Promise<{
      containers: HoldData[];
      unloaded_items: UnloadedItem[];
      sets: Set[];
    }> {
      return this.loadlistStore.calculateLoadplan(calcData);
    },
    saveLoadlistResult(): void {
      this.loadlistStore.saveLoadlistResult();
    },
    updateGroups(): void {
      if (!this.worker) {
        this.worker = new Worker(new URL('@/workers/group_holds.worker.ts', import.meta.url));
        this.worker.onmessage = this.groupHoldsFromWorker;
      }
      this.worker.postMessage({
        holds: this.loadplan.holds,
        sets: this.loadplan.sets,
        disableGrouping: !this.groupSimilar,
      });
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-input--switch {
  height: 24px;
}
</style>
