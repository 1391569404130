import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,[_c(VCol,[_c(VSelect,{attrs:{"items":_vm.$list_types,"hide-details":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c(VIcon,[_vm._v(" "+_vm._s(`$vuetify.icons.${item.value}`))]),_vm._v("   "+_vm._s(item.text)+" ")]}}]),model:{value:(_vm.usageSettings.currentBaseType),callback:function ($$v) {_vm.$set(_vm.usageSettings, "currentBaseType", $$v)},expression:"usageSettings.currentBaseType"}})],1)],1),_c('load-presets-table-component',{staticClass:"mt-2",attrs:{"list_type":_vm.usageSettings.currentBaseType,"showUsePreset":false,"showUpdatePreset":false}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }